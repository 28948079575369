import { useRootStore } from 'hooks'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import QRCodeStudentNavItem from 'modules/student/components/nav-items/QRCodeStudentNavItem'
import React from 'react'
import { Dropdown } from 'tabler-react'
import useReactRouter from 'use-react-router'
import { useGetCoachStudentData } from '../../modules/student/hooks/useGetStudentById'

const HeaderNavItems = () => {
  const { history } = useReactRouter()
  const { isRigger, isStudent, isCoachSuperAdmin, isAdmin } =
    useGetCurrentUserType()
  const { currentUser, currentCoachTeam } = useRootStore()
  const { student, loading } = useGetCoachStudentData({
    student_id: currentUser.student_id,
    skip: !currentUser.student_id
  })

  return (
    <>
      <Dropdown.Item
        icon="user"
        className="cursor-pointer"
        onClick={() => {
          history.push(`/user/profile`)
        }}
      >
        Profile
      </Dropdown.Item>
      <Dropdown.Item
        icon="settings"
        className="cursor-pointer"
        onClick={() => {
          if (isAdmin) {
            history.push(`/admin/settings`)
          } else if (isStudent) {
            history.push(`/user/settings`)
          } else {
            history.push(`/profile/settings`)
          }
        }}
      >
        User Settings
      </Dropdown.Item>
      {!isAdmin && !isStudent && (
        <>
          {currentUser?.student_id ? (
            <>
              <Dropdown.ItemDivider />
              <Dropdown.Item
                icon="user-check"
                className="cursor-pointer"
                onClick={() => {
                  history.push(`/user/settings`)
                }}
              >
                SKY.D: {currentUser?.student_id}
              </Dropdown.Item>
            </>
          ) : (
            <>
              <Dropdown.ItemDivider />
              <Dropdown.Item icon="user-check" className="text-muted">
                SKY.D: Undefined
                <small className="text-center d-block">Contact Support</small>
              </Dropdown.Item>
            </>
          )}
        </>
      )}
      {isStudent || (isCoachSuperAdmin && currentUser?.student_id) ? (
        <QRCodeStudentNavItem
          token={student?.token || currentUser.token}
          id={currentUser?.student_id || currentUser.id}
          firstName={student?.first_name || currentUser.first_name}
          lastName={student?.last_name || currentUser.last_name}
          profileAvatar={student?.profile_avatar || currentUser.profile_avatar}
        />
      ) : null}
      {!isAdmin && isCoachSuperAdmin && (
        <>
          <Dropdown.ItemDivider />
          {currentCoachTeam?.slug && (
          <Dropdown.Item
            icon="globe"
            className="cursor-pointer"
            onClick={() => {
              history.push(`/${currentCoachTeam?.slug}`)
            }}
          >
            Public Page
          </Dropdown.Item>
          )}
          <Dropdown.Item
            icon="settings"
            className="cursor-pointer"
            onClick={() => {
              history.push(`/team-details/${currentUser?.id}}`)
            }}
          >
            Team Settings
          </Dropdown.Item>
        </>
      )}
      <Dropdown.ItemDivider />
      {isRigger ? (
        <>
          <Dropdown.Item
            className="cursor-pointer"
            icon="briefcase"
            onClick={() => {
              history.push(`/rigger/myjobs`)
            }}
          >
            My Jobs
          </Dropdown.Item>
          <Dropdown.ItemDivider />
        </>
      ) : null}
      <Dropdown.Item
        className="cursor-pointer"
        icon="help-circle"
        onClick={() => {
          history.push(`/help`)
        }}
      >
        Help
      </Dropdown.Item>
      <Dropdown.Item
        className="cursor-pointer"
        icon="log-out"
        onClick={() => {
          localStorage.clear()
          window.location.href = '/auth/logout'
        }}
      >
        Log out
      </Dropdown.Item>
    </>
  )
}

export default HeaderNavItems;
