import gql from 'graphql-tag'

export const GET_INVOICE = gql`
  query GET_INVOICE($id: Int!) {
    invoice(id: $id) {
      id
      company_email
      company_name
      company_phone
      created_by
      created_on
      customer_email
      customer_name
      customer_phone
      invoice_amount
      invoice_notes
      invoice_terms
      invoice_type_id
      status
      student_id
      team_id

      paymentDetail {
        total
        totalComp
        outstanding
        totalPaid
        totalFees
      }

      student {
        id
        address1
        address2
        city
        country
        dob
        email
        first_name
        height
        is_disabled_on
        is_disabled_on
        jump_weight
        last_name
        middle_name
        phone_country_code
        phone_number
        postal_code
        preference_24h
        preference_units
        preferred_name
        profile_avatar
        profile_picture
        province
        registration_id
        shirt_size_id
        tunnel_time
        token
        total
        weight
        nac
        gender
        jump_count

        auth_merits {
          member_id
        }

        uspa {
          first_name
          last_name
          email
          updated_on
          merits_uspa_member_id
          merits_uspa_member_expiration
          template_id
          template_title
        }

        teams {
          id
          name
          default_account_type_id
          default_location_id
          default_location_name
          feature_accounting
          feature_alpha
          feature_app_reports
          feature_booking_calendar
          feature_camps
          feature_cancel_transactions
          feature_coach_account
          feature_dashboard
          feature_dashboard_groups
          feature_disable_students
          feature_dzm_account
          feature_edit_transactions
          feature_locked_account_actions
          feature_manifest
          feature_open_add_students
          feature_paid_status
          feature_recurring_transactions
          feature_registrations
          feature_require_uspa
          feature_rigger_account
          feature_schedule_open
          feature_slot_registrations
          feature_student_progression
          feature_transfer_time
          feature_student_checkin
          is_public
          is_published
          paypal_me
          paypal
          preference_units
          team_bio
          team_coach_hour_fee
          team_email
          team_phone
          team_url
          venmo
        }
      }

      team {
        id
        default_currency
        invoice_terms
        name
        paypal
        payment_fee_customer
        payment_fee_flat
        payment_fee_percentage
        stripe_account_id
        team_email
        team_logo_wide_image
        team_phone
        venmo
      }
    }
  }
`
