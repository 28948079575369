import React from 'react'
import { Formik } from 'formik'
import FormField from '../../../../components/FormField'
import { useMutation } from '@apollo/client'
import { UPDATE_STUDENT_MUTATION } from '../../../../graphql/UPDATE_STUDENT_MUTATION'
import { Button, Grid } from 'tabler-react'

const StudentBurbleForm = ({ toggle, student_id }) => {
  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION)
  return (
    <Formik
      initialValues={{ burble_id: '' }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        updateStudent({
          variables: {
            student: {
              id: Number(student_id),
              burble_id: Number(values.burble_id)
            }
          },
          refetchQueries: ['GET_STUDENT_BY_ID']
        })
        setSubmitting(false)
        resetForm()
        toggle()
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => {
        return (
          <form>
            <Grid.Row className="mb-4">
              <Grid.Col xs={12} sm={12} lg={6}>
                <FormField
                  name={'burble_id'}
                  value={values.burble_id}
                  placeholder='Enter Burble ID (if available)'
                  onChange={handleChange}
                />
              </Grid.Col>
              <Grid.Col className="pt-2" xs={12} sm={12} lg={6}>
                <Button
                  pill
                  type="submit"
                  disabled={isSubmitting}
                  className="btn-gray-dark float-right btn-sm"
                  onClick={(e) => {
                    e.preventDefault()
                    handleSubmit()
                  }}
                >
                  {isSubmitting ? 'Processing...' : 'SAVE'}
                </Button>
                <Button
                  pill
                  color="white"
                  className="float-right btn-sm mr-2"
                  onClick={toggle}
                >
                  CANCEL
                </Button>
              </Grid.Col>
            </Grid.Row>
          </form>
        )
      }}
    </Formik>
  )
}

export default StudentBurbleForm
