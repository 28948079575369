import useDebounceCallback from 'modules/common/utils/useDebounceCallback'
import useGetStudentSearchQuery from 'modules/student/hooks/useGetStudentSearchQuery'
import React, { useMemo, useState } from 'react'
import Select, { components } from 'react-select'
import { Form } from 'tabler-react'
import useReactRouter from 'use-react-router'

import autoCompleteStyles from './Forms/autocompleteStyles'
import { Box } from '@material-ui/core'

const StudentSearchBars = () => {
  const { history } = useReactRouter()
  const [search, setSearch] = useState('')

  const { data, queryFilter, setQueryFilter } = useGetStudentSearchQuery()

  const loadOptions = useMemo(() => {
    if (!data?.getStudentSearchQuery || !search) {
      return []
    }

    const teams = data.getStudentSearchQuery?.teams
      .filter((team) => !!team.teamSlug)
      .map((team) => ({
        label: team.teamName,
        id: `team-${team.teamId}`,
        value: team.teamId,
        type: 'team',
        slug: team.teamSlug
      }))

    const locations = data.getStudentSearchQuery?.locations.map((location) => ({
      label: location.locationName,
      id: `location-${location.locationId}`,
      value: location.locationId,
      type: 'location'
    }))

    const events = data.getStudentSearchQuery?.events.map((event) => ({
      label: event.campName,
      id: `event-${event.campId}`,
      value: event.campId,
      type: 'event',
      location: event.locationName
    }))

    return [...teams, ...locations, ...events]
  }, [data, search])

  const onChange = () => {
    setQueryFilter({ ...queryFilter, q: search })
  }

  const debouncedOnChange = useDebounceCallback(onChange)

  return (
    <Form>
      <Select
        backspaceRemovesValue={true}
        className="input-group"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
          Option: (props) => {
            return (
              <components.Option {...props}>
                <Box
                  display="flex"
                  alignItems="start"
                  justifyContent="space-between"
                >
                  <span>{props.data.label}</span>
                  <>
                    {props.data.type === 'location' ? (
                      <i className="fe fe-map-pin mt-1 text-danger"></i>
                    ) : props.data.type === 'team' ? (
                      <i className="fe fe-users mt-1 text-primary"></i>
                    ) : props.data.type === 'event' ? (
                      <>
                        <span className={'text-muted'}>
                          {props.data.location}
                        </span>
                        <i className="fe fe-calendar mt-1 text-success"></i>
                      </>
                    ) : (
                      props.data.type
                    )}
                  </>
                </Box>
              </components.Option>
            )
          }
        }}
        escapeClearsValue={true}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.id}
        isClearable={true}
        options={loadOptions}
        name="search"
        onChange={(e) => {
          if (!e) {
            return
          }

          if (e.type === 'team') {
            window.location.href = `/${e.slug}`
            // history.push(`/${e.slug}`)
            return
          }

          if (e.type === 'location') {
            history.push(`/locations/${e.value}`)
            return
          }

          if (e.type === 'event') {
            history.push(`/calendar/${e.value}`)
            return
          }
        }}
        onInputChange={(inputValue) => {
          debouncedOnChange()
          setSearch(inputValue)
        }}
        placeholder="Search SKYCRU"
        styles={autoCompleteStyles}
        menuPortalTarget={document.body}
      />
    </Form>
  )
}

export default StudentSearchBars
