import { useQuery } from '@apollo/client';
import { GET_USER_LOCATION } from "graphql/GET_USER_LOCATION";
import { GET_USER_LOCATIONS } from "graphql/GET_USER_LOCATIONS";
import React, { useMemo } from "react";
import { Alert, Button, Card, Dimmer, Icon } from "tabler-react";
import useReactRouter from "use-react-router";

interface IStudentCamps {
  studentId: number;
}

const StudentLocations = ({ studentId }: IStudentCamps) => {
  const { history } = useReactRouter();
  const { data, loading } = useQuery(GET_USER_LOCATIONS, {
    variables: {
      userLocationFilter: {
        student_id: studentId,
        flag_type_id: 1,
      },
    },
  });

  const { data: dataTunnelLocation, loading: tunnelLocationLoading } = useQuery(
    GET_USER_LOCATION,
    {
      variables: {
        student_id: studentId,
        flag_type_id: 6,
        location_type: "tunnel",
      },
    }
  );

  const defaultTunnelLocation = useMemo(() => {
    if (!tunnelLocationLoading && !!dataTunnelLocation?.getUserLocation) {
      return dataTunnelLocation.getUserLocation;
    }

    return null;
  }, [dataTunnelLocation, tunnelLocationLoading]);

  const { data: userLocation, loading: userLocationLoading } = useQuery(
    GET_USER_LOCATION,
    {
      variables: {
        student_id: studentId,
        flag_type_id: 6,
        location_type: "dropzone",
      },
    }
  );

  const defaultHomeLocation = useMemo(() => {
    if (!userLocationLoading && !!userLocation?.getUserLocation) {
      return userLocation.getUserLocation;
    }

    return null;
  }, [userLocation, userLocationLoading]);

  const locations = useMemo(() => {
    if (!loading && data?.getUserLocations.length > 0) {
      return data.getUserLocations;
    }

    return [];
  }, [data, loading]);

  return (
    <Dimmer active={loading} loader={loading}>
      <Card statusColor="gray-dark">
        <Card.Header>
          <Card.Title>
            <Icon name="map-pin" className="mr-2 ml-0 text-blue" />
            Locations
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {defaultHomeLocation && (
            <>
              <Button
                pill
                color="primary"
                size="sm"
                icon="home"
                className="mr-2 mb-2"
                key={defaultHomeLocation.id}
              >
                <span
                  className="cursor-pointer text-white"
                  onClick={() =>
                    history.push(`/locations/${defaultHomeLocation.id}`)
                  }
                >
                  {defaultHomeLocation.name}
                </span>
              </Button>
            </>
          )}
          {defaultTunnelLocation && (
            <>
              <Button
                pill
                color="primary"
                size="sm"
                icon="home"
                className="mb-2"
                key={defaultTunnelLocation.id}
              >
                <span
                  className="cursor-pointer text-white"
                  onClick={() =>
                    history.push(`/locations/${defaultTunnelLocation.id}`)
                  }
                >
                  {defaultTunnelLocation.name}
                </span>
              </Button>
            </>
          )}
          {!loading && locations.length <= 0 && !defaultHomeLocation && !defaultTunnelLocation ? (
            <>
              <Alert type="info text-center">
                <strong>No Locations</strong>
              </Alert>
            </>
          ) : (
            <Button.List className="mt-3">
              {!loading &&
                locations.map((location) => (
                  <Button
                    pill
                    icon="map-pin"
                    color="info"
                    size="sm"
                    key={location.id}
                    onClick={() => history.push(`/locations/${location.id}`)}
                  >
                    {location.name}
                  </Button>
                ))}
            </Button.List>
          )}
        </Card.Body>
      </Card>
    </Dimmer>
  );
};
export default StudentLocations;
