import React, { useState } from 'react'
import { Button, List, Text } from 'tabler-react'
import StudentProgressionNotesForm from './Forms/StudentProgressionNotesForm'

type StudentTeamProgressionItemProps = {
  progress: any
  studentId: number
  campId?: number
}

const StudentTeamProgressionItem = ({
  progress,
  studentId,
  campId,
}: StudentTeamProgressionItemProps) => {
  const [isEdittingNote, setIsEdittingNote] = useState(false);

  return (
    <>
      <List.GroupItem action>
        <div>
        {progress.is_exist ? (
          <>
          {!isEdittingNote ? (
            <>
            <Button
              className={`cursor-pointer float-right ${
                progress.studentProgressionStatus === 1
                  ? "text-success"
                  : "text-muted"
              }`}
              color="white"
              icon="check-circle"
              size="sm"
              onClick={() => {
                if (progress.studentProgressionId) {
                  setIsEdittingNote(true)
                }
              }}
              disabled={!progress.studentProgressionId}
            />
            {progress.rating ? (
              <Button
                color="white"
                size="sm"
                className={`cursor-pointer float-right ${
                  progress.rating === 10
                  ? "text-success"
                  : progress.rating < 5
                  ? "text-danger"
                  : "text-primary"
                }`}
                onClick={() => {
                  if (progress.studentProgressionId) {
                    setIsEdittingNote(true)
                  }
                }}
              >
                {progress.rating > 0 && (
                  <span className="text-success px-1">{progress.rating}</span>
                )}
              </Button>
            ) : null}
            </>
          ) : (
            <Button
                icon="x"
                color="white"
                size="sm"
                className="float-right"
                onClick={() => {
                  if (progress.studentProgressionId) {
                    setIsEdittingNote(false)
                  }
                }}
              />
          )}
          </>
        ) : null}
          <span
            className={'cursor-pointer'}
            onClick={() => {
              if (progress.studentProgressionId) {
                setIsEdittingNote(true)
              }
            }}
          >
            {progress.title}
          </span>
          <Text.Small className="d-block text-muted">
            {isEdittingNote ? (
              <StudentProgressionNotesForm
                studentTeamProgression={progress}
                setIsEdittingNote={setIsEdittingNote}
                studentId={studentId}
                campId={campId}
              />
            ) : (
              <>
                {progress.studentProgressionNotes && (
                  <>
                    <i>{progress.studentProgressionNotes}</i>
                  </>
                )}
              </>
            )}
          </Text.Small>
        </div>
      </List.GroupItem>
    </>
  )
};

export default StudentTeamProgressionItem;
