import { useQuery } from '@apollo/client'
import { EHostnames } from 'components/constants'
import { UserStoreContext } from 'contexts/userStoreContext'
import { GET_STUDENTS_BY_FILTER_QUERY } from 'graphql/GET_STUDENTS_BY_FILTER_QUERY'
import { useRootStore } from 'hooks/useRootStore'
import { observer } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import useGetNodeEnvironment from 'modules/common/hooks/useGetNodeEnvironment'
import HeaderNavItems from 'pages/layout/HeaderNavItems'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Avatar, Button, Container, Dropdown, Nav, Text } from 'tabler-react'
import useReactRouter from 'use-react-router'
import GenerateQRModal from 'components/Students/Modal/GenerateQRModal'
import FighterProfileButton from '../../modules/fighters/components/buttons/FighterProfileButton'
import SiteLogo from './SiteLogo'
import TeamSearchMenu from '../../modules/team/components/forms/TeamSearchMenu'
import admin_avatar from 'assets/images/skycruFAVICON48.png'
import AdminSearchBar from '../../components/Admin/AdminSearchBar'
import UserIdentityModal from '../../modules/user/profile/components/UserIdentityModal'
import { EUserTypes } from '../../components/User/userTypes'

interface HeaderProps {
  collapse: boolean
  setCollapse: (collapse: boolean) => void
}

type TeamsArray =
  | {
      value: string
      onClick: () => void
    }
  | { isDivider?: boolean }

const Header: FC<HeaderProps> = ({ collapse, setCollapse }) => {
  const { history } = useReactRouter()
  const rootStore = useRootStore()
  const { isAdmin, isCoachSuperAdmin, isStudent } =
    useGetCurrentUserType()

  const [isQRModalOpen, setIsQRModalOpen] = useState(false)
  const [qrValue, setQrValue] = useState('')
  const [isTeamSearchOpen, setIsTeamSearchOpen] = useState(false)
  const handleToggleQRModal = () => setIsQRModalOpen(!isQRModalOpen)

  const { currentCoachTeam, currentUser, domainData } = rootStore
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const nodeEnv = useGetNodeEnvironment()

  const teamsDropdownItems = useMemo(() => {
    const teamsArr: TeamsArray[] = rootStore.coachTeams.map((coachTeam) => {
      return {
        value: coachTeam.name,
        onClick: () => {
          applySnapshot(rootStore, {
            ...rootStore,
            currentCoachTeam: { ...coachTeam },
            currentUser: {
              ...currentUser,
              type: coachTeam?.coach_business_type ?? currentUser?.type
            }
          })
          history.push('/')
        }
      }
    })

    teamsArr.push({ isDivider: true })

    if (rootStore.currentCoachTeam?.slug) {
      teamsArr.push({
        value: 'Public Page',
        onClick: () => {
          history.push(`/${rootStore.currentCoachTeam?.slug}`)
        }
      })
    }

    //  if (isCoachAdmin || isCoachSuperAdmin) {
    teamsArr.push({
      value: 'Settings',
      onClick: () => {
        history.push(`/team-details/${rootStore.currentCoachTeam?.id}`)
      }
    })
    //  }
    return teamsArr
  }, [rootStore.coachTeams, rootStore.currentCoachTeam, currentUser])

  const userStore = useContext(UserStoreContext)

  const { loading, error, data } = useQuery(GET_STUDENTS_BY_FILTER_QUERY, {
    variables: {
      filter: {
        limit: 1,
        student_id: currentUser.id.toString()
      }
    }
  })

  // Set filtered students everytime data changes
  useEffect(() => {
    if (!loading && !error && data) {
      if (currentUser.type === 'student') {
        userStore.loadUser(data[Object.keys(data)[0]][0])
      }
    }
  }, [data])

  const { first_name, last_name, email, profile_avatar } = currentUser

  return (
    <div className="header py-4">
      <Container>
        {!isAdmin && rootStore.hasMissingData === 1 && (
          <UserIdentityModal
            user={{ id: currentUser.id, first_name, last_name }}
            type={
              currentUser.type === EUserTypes.student
                ? EUserTypes.student
                : 'coach'
            }
          />
        )}
        <GenerateQRModal
          isOpen={isQRModalOpen}
          handleClose={handleToggleQRModal}
          qrValue={qrValue}
          setQrValue={setQrValue}
        />

        <div className="d-flex justify-content-between">
          <span
            className="header-toggler d-lg-none ml-lg-0 cursor-pointer"
            onClick={() => setCollapse(!collapse)}
          >
            <span className="header-toggler-icon mt-1" />
          </span>
          <span
            className="header-brand cursor-pointer"
            onClick={() => history.push(`/`)}
          >
            {currentCoachTeam?.team_logo_header ? (
              <div
                className="header-team-logo-wide d-none d-lg-block"
                style={{
                  backgroundImage: `url(/api/s3/uploads/${currentCoachTeam?.team_logo_header})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  height: '40px',
                  width: '250px',
                  backgroundPosition: 'left'
                }}
              />
            ) : (
              <SiteLogo customClass={'d-none d-lg-block'} />
            )}
          </span>
          <div className="d-flex">
            {!currentUser && (
              <Button
                color="primary"
                size="sm"
                onClick={() => history.push(`/login`)}
              >
                Login
              </Button>
            )}
          </div>
          <div className="d-flex">
            {isAdmin && (
              <div className="d-flex">
                <span
                  className={`${
                    isSearchOpen ? 'd-sm-block' : 'd-none'
                  } d-lg-none`}
                >
                  <AdminSearchBar onBlurProp={() => setIsSearchOpen(false)} />
                </span>
                <Button
                  icon="activity"
                  color="white"
                  size="sm"
                  className="d-none d-lg-block"
                  onClick={() => history.push(`/admin/user-events`)}
                />
                <Button
                  icon="maximize"
                  color="white"
                  size="sm"
                  className={isSearchOpen ? 'd-none' : ''}
                  onClick={handleToggleQRModal}
                />
                <Button
                  icon="search"
                  color="white"
                  size="sm"
                  className={`${
                    isSearchOpen ? 'd-none' : 'd-sm-block'
                  } d-lg-none`}
                  onClick={() => setIsSearchOpen(true)}
                />
              </div>
            )}
            {!currentCoachTeam && !isAdmin && !isStudent && (
              <Button
                color="white"
                size="sm"
                className="text-muted float-right"
                onClick={() => history.push(`/pricing/events`)}
              >
                Organizer
              </Button>
            )}
            {isStudent && domainData?.key === EHostnames.FAIRFIGHTS && (
              // !isFighter
              <FighterProfileButton />
            )}
            <div className="dropdown d-flex">
              {!isAdmin && !isCoachSuperAdmin && !isStudent && (
                <Dropdown
                  triggerContent={currentCoachTeam?.name}
                  className="mt-1 text-secondary cursor-pointer"
                  position="bottom-end"
                  arrow={true}
                  arrowPosition="right"
                  itemsObject={teamsDropdownItems}
                />
              )}
              {isCoachSuperAdmin && !isStudent && !isAdmin && (
                <>
                  {!isTeamSearchOpen && (
                    <Button
                      color="white"
                      size="sm"
                      className="text-abyss"
                      onClick={() => setIsTeamSearchOpen(true)}
                    >
                      {currentCoachTeam?.name}
                    </Button>
                  )}
                  {isTeamSearchOpen && (
                    <TeamSearchMenu toggle={() => setIsTeamSearchOpen(false)} />
                  )}
                </>
              )}
              <Dropdown
                as={Nav.Item}
                link={false}
                triggerClassName="d-flex align-items-center py-0 px-lg-0 px-2 text-color ml-2 cursor-pointer"
                triggerAs={Nav.Link}
                triggerContent={
                  <React.Fragment>
                    <Avatar
                      size="md"
                      imageURL={
                        profile_avatar
                          ? `/api/s3/uploads/${profile_avatar}`
                          : isAdmin || isCoachSuperAdmin
                          ? admin_avatar
                          : '//www.gravatar.com/avatar?d=mp'
                      }
                    />
                    <span className="ml-2 d-none d-lg-block leading-none">
                      <Text>{`${first_name} ${last_name}`}</Text>
                      <Text.Small className="text-muted d-block mt-1">
                        {email}
                      </Text.Small>
                    </span>
                  </React.Fragment>
                }
                position="bottom-end"
                arrow={true}
                arrowPosition="right"
                toggle={false}
                items={<HeaderNavItems />}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default observer(Header)
