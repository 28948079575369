import { useMutation } from '@apollo/client'
import EventGroupsListItemEditName from 'components/Calendar/Forms/EventGroupsListItemEditName'
import GroupFormModal from 'components/Calendar/Modal/GroupFormModal'
import { DELETE_GROUP } from 'graphql/DELETE_GROUP'
import { DELETE_STUDENT_FROM_GROUP } from 'graphql/DELETE_STUDENT_FROM_GROUP'
import { EDIT_GROUP } from 'graphql/EDIT_GROUP'
import { GET_GROUPS } from 'graphql/GET_GROUPS'
import React, { useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { toast } from 'react-toastify'
import { Button, Card, Dropdown, Icon, Tag } from 'tabler-react'
import { Types } from '../../types/graphql'
import GroupEmailModalForm from '../../modules/group/components/forms/GroupEmailModalForm'
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType'

interface EventGroupsListItemProps {
  group: Types.Group
  groupList: Types.Group[]
}

const EventGroupsListItem = ({
  group,
  groupList,
}: EventGroupsListItemProps) => {
  const { isCoachSuperAdmin } = useGetCurrentUserType()
  const [isEditting, setIsEditting] = React.useState(false);
  const [sendGroupEmailOpen, setSendGroupEmailOpen] = useState(false)
  const [deleteGroup] = useMutation(DELETE_GROUP, {
    onCompleted: () => {
      toast.success("Group deleted");
    },
  });
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [removeGroup] = useMutation(EDIT_GROUP, {
    onCompleted: () => {
      toast.success("Group updated");
    },
  });

  const [removeStudent] = useMutation(DELETE_STUDENT_FROM_GROUP, {
    onCompleted: () => {
      toast.success("User removed from group");
    },
  });

  const groupMembersLength = useMemo(() => {
    return group.members.length;
  }, [group.members]);

  const handleDelete = () => {
    if (groupMembersLength > 0) {
      return;
    }
    if (window.confirm("Are you sure you want to delete this group?")) {
      deleteGroup({
        variables: {
          id: group.id,
        },
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              filter: {
                camp_id: Number(group.camp_id),
                team_id: Number(group.team_id),
                status: "Active",
              },
            },
          },
        ],
      });
    }
  };

  const handleRemove = () => {
    if (
      window.confirm(
        "Are you sure you want to move this group to the dashboard?",
      )
    ) {
      removeGroup({
        variables: {
          updateGroupInput: {
            id: group.id,
            camp_id: null,
          },
        },
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              filter: {
                camp_id: Number(group.camp_id),
                team_id: Number(group.team_id),
                status: "Active",
              },
            },
          },
        ],
      });
    }
  };

  const handleArchive = () => {
    if (window.confirm("Are you sure you want to archive this group?")) {
      removeGroup({
        variables: {
          updateGroupInput: {
            id: group.id,
            status: "Archived",
          },
        },
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              filter: {
                camp_id: Number(group.camp_id),
                team_id: Number(group.team_id),
                status: "Active",
              },
            },
          },
        ],
      });
    }
  };

  const handleDeleteStudent = (studentId: number) => {
    if (
      window.confirm(
        "Are you sure you want to remove this user from the group?",
      )
    ) {
      removeStudent({
        variables: {
          groupId: group.id,
          studentId: studentId,
        },
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              filter: {
                camp_id: Number(group.camp_id),
                team_id: Number(group.team_id),
                status: "Active",
              },
            },
          },
        ],
      });
    }
  };

  const groupMembers = useMemo(
    () =>
      group.members.map((member) => ({
        id: member.id,
        first_name: member.first_name,
        last_name: member.last_name,
        phone_country_code: member.phone_country_code,
        phone_number: member.phone_number,
        email: member.email,
      })),
    [group.members],
  );

  const handleDrag = (e, studentId, groupId) => {
    e.dataTransfer.setData("studentId", studentId);
    e.dataTransfer.setData("action", "transfer");
    e.dataTransfer.setData("previousGroupId", groupId);
  };

  return (
    <>
      <Card className="group-card">
        <Card.Header
        //  backgroundURL={group.image && `/api/s3/uploads/${group.image}`}
          className={
          //  !group.image &&
            group.color
              ? 'px-3 py-1 text-white bg-' + group.color
              : 'px-3 py-1 text-white opacity bg-gray-dark'
          }
          style={{ maxHeight: '100%', maxWidth: '10px' }}
        >
          <Card.Title
            className={
              group.color
                ? 'cursor-pointer px-3 py-1 text-white bg-' + group.color
                : ''
            }
          >
            {isEditting && (
              <EventGroupsListItemEditName
                group={group}
                toggleEdit={setIsEditting}
              />
            )}
            {!isEditting && (
              <span onClick={() => setIsEditting(true)}>
                <Tag color={group.color}>{groupMembersLength}</Tag> {group.name}
              </span>
            )}
          </Card.Title>
          <Card.Options>
            <Dropdown
              className={
                'float-right cursor-pointer ' + (group.color && 'text-white')
              }
              toggle={false}
              position="bottom-end"
              arrow={true}
              arrowPosition="right"
              class="cursor-pointer"
              triggerContent={<Icon name="more-vertical" />}
              items={[
                <>
                  <Dropdown.Item icon="download-cloud" as="Button">
                    <CSVLink
                      data={groupMembers}
                      filename="event_group_members.csv"
                      className="ml-0 text-dark"
                    >
                      CSV
                    </CSVLink>
                  </Dropdown.Item>
                  <Dropdown.Item
                    icon="edit"
                    onClick={() => setIsEditModalOpen(true)}
                  >
                    Edit
                  </Dropdown.Item>
                  {isCoachSuperAdmin && ('')}
                  <Dropdown.Item
                    icon="mail"
                    onClick={() => setSendGroupEmailOpen(true)}
                  >
                    Email
                  </Dropdown.Item>
                  <Dropdown.Item icon="home" onClick={handleRemove}>
                    Move
                  </Dropdown.Item>
                  <Dropdown.Item icon="minus-circle" onClick={handleArchive}>
                    Archive
                  </Dropdown.Item>
                  <Dropdown.Item icon="x-circle" onClick={handleDelete}>
                    Delete
                  </Dropdown.Item>
                </>
              ]}
            />
          </Card.Options>
        </Card.Header>
        <Card.Body>
          {group.members.map((member) => {
            // Check if student is in multiple groups
            // If so, display indicator icon
            const studentGroups = groupList.filter((group) =>
              group.members.some((student) => student.id === member.id)
            )

            return (
              <div
                draggable
                onDragStart={(e) =>
                  handleDrag(e, member.id.toString(), group.id)
                }
                key={member.id}
              >
                <Button
                  outline
                  block
                  color="primary"
                  className="registered-student mb-1 p-1 text-left d-flex align-items-center w-100"
                  size="sm"
                  title={`${member.first_name} ${member.last_name}`}
                >
                  <div className="d-block w-100">
                    <span>
                      {`${member.first_name} ${member.last_name}`}
                      {studentGroups.length > 1 && (
                        <Icon name="users" className="ml-1" />
                      )}
                    </span>

                    <Icon
                      name="x-circle"
                      className={'float-right cursor-pointer'}
                      onClick={() => handleDeleteStudent(Number(member.id))}
                    />
                  </div>
                </Button>
              </div>
            )
          })}
        </Card.Body>
      </Card>
      <GroupEmailModalForm
        isOpen={sendGroupEmailOpen}
        toggle={() => setSendGroupEmailOpen(false)}
        group={group}
      />
      <GroupFormModal
        isOpen={isEditModalOpen}
        toggle={setIsEditModalOpen}
        group={group}
      />
    </>
  )
};
export default EventGroupsListItem;
