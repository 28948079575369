import { useQuery } from '@apollo/client';
import UserPostsListItem from 'components/User/UserPostsListItem';
import { DEFAULT_LIMIT } from 'components/constants';
import { GET_USER_POSTS } from 'graphql/GET_USER_POSTS';
import { GET_USER_POSTS as GET_USER_POSTS_TYPE } from 'graphql/types/GET_USER_POSTS';
import { observer } from 'mobx-react';
import React from 'react';
import { Dimmer } from 'tabler-react';

const UserPostsList = (props) => {
  const { data, loading } = useQuery<GET_USER_POSTS_TYPE>(GET_USER_POSTS, {
    variables: {
      userPostOptions: {
        camp_id: props.campId,
        limit: DEFAULT_LIMIT,
        offset: 0
      }
    }
  });

  return (
    <Dimmer loading={loading}>
      {data?.userPosts.length > 0 && (
        <h5 className="ml-1 mt-5 text-muted float-right">RECENT ACTIVITY</h5>
      )}
      {data?.userPosts.map((userpost) => (
        <UserPostsListItem userPost={userpost} key={userpost.id} />
      ))}
    </Dimmer>
  );
};

export default observer(UserPostsList);
