import React from 'react';
import { Dropdown, Nav } from 'tabler-react';
import { Link } from "react-router-dom";
import { useRootStore } from 'hooks/useRootStore';
import SearchSKYCRU from '../../components/Students/StudentSearchBars';
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType';

const VisitorNav = () => {
  const rootStore = useRootStore();
  const { currentCoachTeam } = rootStore;
  const { isCoach } = useGetCurrentUserType();

  return (
    <>
      <Nav.Item icon='home' to='/' value='/' />
      <li className='nav-item ml-lg-auto mb-4 mb-lg-0'>
        {!currentCoachTeam && isCoach && (
          <>
            <Dropdown
              toggle={false}
              icon="calendar"
              position="bottom-end"
              flex
              className="mr-4 dropdown-header-icon-lg"
              items={
                <>
                  <Link
                    to="/calendar"
                    className="dropdown-item d-none d-md-block"
                  >
                    Calendar
                  </Link>
                  <Link to="/events" className="dropdown-item">
                    Events
                  </Link>
                </>
              }
            />
          </>
        )}
        <SearchSKYCRU />
      </li>
    </>
  );
};

export default VisitorNav;
