import { StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 14
  },
  textMedium: {
    fontSize: 14
  },
  textSmall: {
    fontSize: 10
  },
  jobName: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  title: {
    fontSize: 18
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  table: {
    display: 'flex',
    width: 'auto',
    marginBottom: 10
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    borderStyle: 'solid',
    borderBottomWidth: 0.5,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: '20%',
    border: 'none',
    paddingTop: 5,
    paddingBottom: 5,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10
  },
  invoiceTerms: {
    padding: 10,
    border: 1,
    marginTop: 30,
    fontSize: 10
  },
  box: {
    display: 'flex',
    width: 'auto'
  },
  boxRow: {
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start'
  },
  boxCol: {
    width: '33%',
    border: 'none',
    paddingTop: 5,
    paddingBottom: 5
  }
})

export default styles;
