import React from 'react'
import Modal from '../../Modal'
import { Formik } from 'formik'
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import { ADD_COACH } from '../../../graphql/ADD_COACH'
import { toast } from 'react-toastify'
import FormField from '../../FormField'
import { Button, Form, Grid } from 'tabler-react'
import PhoneInput from 'react-phone-input-2'
import { GET_COACH_EMAIL_VALIDATION } from '../../../graphql/GET_COACH_EMAIL_VALIDATION'
import { GET_COACH_PHONE_VALIDATION } from '../../../graphql/GET_COACH_PHONE_VALIDATION'
import { useRootStore } from '../../../hooks'
import useReactRouter from 'use-react-router'

const AddCoachFromSearch = ({ isModalOpen, toggleModal, name }) => {
  const rootStore = useRootStore()
  const { history } = useReactRouter()
  const [addCoach] = useMutation(ADD_COACH, {
    onError: (error: ApolloError) =>
      toast.error(error?.graphQLErrors[0]?.message),
    onCompleted: () => toast.success('Coach Added')
  })

  const [validateEmail, { data: emailData }] = useLazyQuery(
    GET_COACH_EMAIL_VALIDATION,
    {
      fetchPolicy: 'network-only'
    }
  )
  const [validatePhone, { data: phoneData }] = useLazyQuery(
    GET_COACH_PHONE_VALIDATION,
    {
      fetchPolicy: 'network-only'
    }
  )

  const HandleValidateEmail = async (email) => {
    if (email === '') return
    validateEmail({
      variables: { email }
    })
  }

  const HandleValidatePhone = async (phone) => {
    if (phone.length <= 1) return
    return validatePhone({
      variables: { phone }
    })
  }

  return (
    <Formik
      initialValues={{
        first_name: name || '',
        last_name: '',
        email: '',
        phone_number: '',
        phone_country_code: 1
      }}
      enableReinitialize={true}
      onSubmit={async (values, { setSubmitting, resetForm, setFieldError }) => {
        let errorFound = false
        if (emailData?.validateCoachEmail) {
          setFieldError('email', 'Email already exists.')
          errorFound = true
        }

        if (phoneData?.validateCoachPhone) {
          setFieldError('phone_number', 'Phone number already exists.')
          errorFound = true
        }
        if (!values.phone_number) {
          values.phone_country_code = undefined
        }

        if (errorFound) {
          setSubmitting(false)
          return false
        } else {
          const coachResult = await addCoach({
            variables: {
              coach: {
                ...values
              }
            }
          })
          if (!coachResult.errors) {
            setSubmitting(false)
            resetForm()
            rootStore.loadCoach(coachResult.data.addCoach)
            history.push(`/admin/coach-details`)
            toggleModal(!isModalOpen)
          }
        }
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        errors,
        touched
      }) => (
        <Modal
          title={`Add Coach`}
          open={isModalOpen}
          onClose={() => toggleModal(!isModalOpen)}
          content={
            <form onSubmit={handleSubmit}>
              <Grid.Row>
                <Grid.Col md={6} sm={12} xs={12}>
                  <FormField
                    name="first_name"
                    label="First Name"
                    placeholder="First Name"
                    type="text"
                    value={values.first_name}
                    onChange={handleChange}
                  />
                </Grid.Col>
                <Grid.Col md={6} sm={12} xs={12}>
                  <FormField
                    name="last_name"
                    label="Last Name"
                    placeholder="Last Name"
                    type="text"
                    value={values.last_name}
                    onChange={handleChange}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row className="mb-4">
                <Grid.Col sm={12} md={12} lg={6} className="pl-3 pr-0">
                  <Form.Group label="Phone:">
                    <PhoneInput
                      inputProps={{
                        className: 'form-control w-100',
                        name: 'phone_number',
                        //  required: true,
                        onChange: (e) => {
                          e.preventDefault()
                          const target = e.target
                          const phone = target.value.split(' ')
                          const countryCode = phone.shift().replace(/\D/g, '')
                          setFieldValue(
                            'phone_number',
                            phone.join('').replace(/\D/g, '')
                          )
                          setFieldValue(
                            'phone_country_code',
                            Number(countryCode)
                          )
                        },
                        onBlur: async (e) => {
                          e.preventDefault()
                          await HandleValidatePhone(
                            e.target.value
                              .split(' ')
                              .join('')
                              .replace(/\D/g, '')
                          )
                        }
                      }}
                      value={`${values.phone_country_code}${values.phone_number}`}
                    />
                    <span className="field-error text-danger">
                      {errors.phone_number &&
                        touched.phone_number &&
                        errors.phone_number}
                    </span>
                  </Form.Group>
                </Grid.Col>
                <Grid.Col xs={12} sm={12} lg={6}>
                  <Form.Group label="Email:">
                    <FormField
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={handleChange}
                      onBlur={(e) => HandleValidateEmail(e.target.value)}
                      value={values.email}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </form>
          }
          actions={
            <Button
              className="btn"
              pill
              color="gray-dark"
              size="sm"
              disabled={isSubmitting}
              loading={isSubmitting}
              type="submit"
              onClick={handleSubmit}
            >
              ADD{isSubmitting ? 'ING' : ''} ACCOUNT
            </Button>
          }
        />
      )}
    </Formik>
  )
}

export default AddCoachFromSearch
