import { CoachEvents } from 'components/Coaches/CoachDetails'
import EditProfileForm from 'components/User/Forms/EditProfileForm'
import UserDzLocationForm from 'components/User/Forms/UserDzLocationForm'
import UserProfileForm from 'components/User/Forms/UserProfileForm'
import UserCard from 'components/User/Profile/UserCard'
import UserContactInfo from 'components/User/Profile/UserContactInfo'
import UserActivitiesList from 'components/User/UserActivitiesList'
import { useRootStore } from 'hooks'
import { observer } from 'mobx-react'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import { useState } from 'react'
import { Button, Card, Grid } from 'tabler-react'
import { EHostnames } from 'components/constants'
import UserCredentials from '../../modules/user/credentials/components/UserCredentials'
import { Types } from '../../types/graphql'
import { clone } from 'mobx-state-tree'
import StudentMeritsButton from 'components/Students/StudentMeritsButton'

interface UserSettingsProps {
  user: any
  student?: Types.Student
  coach?: Types.Coach
  type: string
}

const UserSettings = ({ user, student, coach, type }: UserSettingsProps) => {
  const { domainData } = useRootStore()
  const { isAdmin, isStudent, isCoachSuperAdmin } = useGetCurrentUserType()
  const [display, setDisplay] = useState('settings')
  const userClone = clone(user)
  const userCardUser = student ?? coach ?? user
  return (
    <Grid.Row>
      <Grid.Col lg={4} sm={12} xs={12}>
        {!isAdmin && (
          <UserCard
            firstName={userCardUser?.first_name}
            lastName={userCardUser?.last_name}
            profileAvatar={userCardUser?.profile_avatar}
            profilePicture={userCardUser?.profile_picture}
            userType={type}
            studentId={userCardUser?.id}
          />
        )}
        {!isAdmin && (
          <UserContactInfo user={userCardUser} userClone={userCardUser} />
        )}
        {student && domainData?.key === EHostnames.SKYCRU && (
          <>
            <UserDzLocationForm user={student} />
          </>
        )}
        {student && <UserActivitiesList studentId={student.id} />}
        {isCoachSuperAdmin && (
          <>
            <StudentMeritsButton
              studentId={coach?.student_id.toString() ?? student?.id.toString()}
            />
            <Button
            block
            icon="bold"
            color="gray-dark"
            className="mt-2"
            disabled
          >
            Burble
          </Button>
        </>
        )}
      </Grid.Col>
      <Grid.Col lg={8} sm={12} xs={12}>
        {student && (
          <Card>
            <UserProfileForm userProfile={student} />
          </Card>
        )}
        {isCoachSuperAdmin && location.pathname !== '/user/settings' && (
          <Grid.Row className="mb-2">
            <Grid.Col>
              <Button.List className="float-right">
                <Button color="light" disabled>
                  Admins View Only
                </Button>
                <Button
                  color={`${display === 'activity' ? 'primary' : 'white'}`}
                  onClick={() => setDisplay('activity')}
                >
                  Activity
                </Button>
                <Button
                  color={`${display === 'settings' ? 'primary' : 'white'}`}
                  onClick={() => setDisplay('settings')}
                >
                  Settings
                </Button>
              </Button.List>
            </Grid.Col>
          </Grid.Row>
        )}
        {display === 'activity' && (
          <Grid.Row>
            <Grid.Col>
              <CoachEvents coachId={user.id} />
            </Grid.Col>
          </Grid.Row>
        )}
        {!isStudent &&
          !isAdmin &&
          display === 'settings' &&
          location.pathname !== '/user/settings' && (
            <>
              <EditProfileForm user={userClone} />
            </>
          )}{' '}
        {student && <UserCredentials studentId={student.id} />}
      </Grid.Col>
    </Grid.Row>
  )
}

export default observer(UserSettings)
