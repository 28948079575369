import { useMutation } from '@apollo/client'
import Modal from 'components/Modal'
import { Formik } from 'formik'
import { UPDATE_REGISTRATION_STATUS_BULK } from 'graphql/UPDATE_REGISTRATION_STATUS_BULK'
import { useRootStore } from 'hooks'
import useGetRegistrationStatus from 'modules/team-registration-status/hooks/useGetRegistrationStatus'
import { toast } from 'react-toastify'
import { Button, Card, Dropdown, Grid, Icon } from 'tabler-react'
import { GET_GROUPS } from '../../../graphql/GET_GROUPS'

export const BulkStatusChangeModal = ({ isOpen, toggle, students, teamId }) => {
  const { currentUser } = useRootStore()
  const { teamStatusOptions } = useGetRegistrationStatus(teamId)
  const [bulkUpdateUserRegistrationStatus] = useMutation(
    UPDATE_REGISTRATION_STATUS_BULK,
    {
      refetchQueries: ['GET_REGISTERED_STUDENTS', 'GET_GROUPS'],
      onCompleted: (data) => {
        // display toast success
        toast.success('Registrations updated')
      },
      onError: () => {
        toast.error('Something went wrong!')
      }
    }
  )

  const renderForm = ({ values, handleSubmit, setFieldValue }) => {
    const standardStatusItems = teamStatusOptions.map((stat) => {
      return {
        className: 'm-0 p-0',
        value: (
          <span
            className={`${stat.color && 'text-' + stat.color} cursor-pointer`}
          >
            <Icon name={stat.icon} className="mr-2" />
            {stat.name}
          </span>
        ),
        onClick: () => {
          setFieldValue('status', stat.name)
          setFieldValue('registration_status_id', stat.id)
        }
      }
    })
    return (
      <Modal
        title={`Change Status: ${students.length} selected`}
        maxWidth={'xs'}
        open={isOpen}
        onClose={toggle}
        content={
          <Card.Body className={'min-height-12'}>
            <Grid.Row className={'mt-2'}>
              <Grid.Col width={6}>
                <Dropdown
                  triggerContent={
                    <>
                      {values.status ? (
                        <Button
                          outline
                          color={
                            teamStatusOptions.find(
                              (stat) => stat.name === values.status
                            ).color
                          }
                          icon={
                            teamStatusOptions.find(
                              (stat) => stat.name === values.status
                            ).icon
                          }
                        >
                          {values.status}
                        </Button>
                      ) : (
                        <Button outline color={'secondary'}>
                          SELECT STATUS
                        </Button>
                      )}
                    </>
                  }
                  position="bottom-end"
                  arrow={true}
                  arrowPosition="right"
                  toggle={false}
                  itemsObject={standardStatusItems}
                  className="m-0 p-0 cursor-pointer"
                />
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        }
        actions={[
          <Button
            block
            className="float-right w-25"
            color="primary"
            onClick={() => {
              handleSubmit()
            }}
          >
            UPDATE
          </Button>
        ]}
      />
    )
  }
  return (
    <Formik
      initialValues={{
        status: '',
        registration_status_id: '',
        coach_id: currentUser.id
      }}
      onSubmit={async (values, actions) => {
        const param = {
          ...values,
          registrationIds: students
            .filter(
              (s) => s.status.toLowerCase() !== values.status.toLowerCase()
            )
            .map((student) => student.user_registrations_id)
        }
        await bulkUpdateUserRegistrationStatus({
          variables: {
            bulkUpdateUserRegistrationStatus: param
          }
        })
        actions.setSubmitting(false)
        toggle()
      }}
    >
      {(props) => renderForm(props)}
    </Formik>
  )
}
