import React from 'react'
import { Button } from 'tabler-react'

const PaypalButton = ({ paypal, outstanding }) => {
  const paypalLink = () => {
    window.open(
      `https://www.paypal.me/${paypal}/${outstanding}`,
      '_blank'
    )
  }
  return (
    <Button
      icon="dollar-sign"
      color="gray-dark"
      onClick={paypalLink}
      className="mr-2"
      disabled={outstanding === 0}
    >
      PayPal
    </Button>
  )
}

export default PaypalButton
