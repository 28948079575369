import React from 'react'
import { Formik } from 'formik'
import { useMutation } from '@apollo/client'
import { Button, Text } from 'tabler-react'
import Modal from '../../../../components/Modal'
import FormField from '../../../../components/FormField'
import { SEND_STUDENT_ACTIVATION_EMAIL } from '../../../../graphql/SEND_STUDENT_ACTIVATION_EMAIL'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

const sendActivationSchema = Yup.object().shape({
  email: Yup.string().email().required('This field is required.')
})

const SendActivationEmailModal = ({ isOpen, onClose, student }) => {
  const [sendActivation] = useMutation(SEND_STUDENT_ACTIVATION_EMAIL, {
    onCompleted: () => toast.success('Activation email sent.'),
    refetchQueries: ['GET_STUDENTS_ON_DASHBOARD']
  })
  return (
    <Formik
      initialValues={{
        email: student.email || '',
        studentId: student.id
      }}
      validationSchema={sendActivationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        sendActivation({
          variables: { ...values }
        })
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Modal
          title={`${student.first_name} ${student.last_name}`}
          open={isOpen}
          content={
            <form>
              <FormField
                label={'Email'}
                name={'email'}
                value={values.email}
                onChange={handleChange}
              />
              <Text.Small className="mt-3 text-muted">This will email a temporary password to the user so they can login and activate their account.</Text.Small>
            </form>
          }
          onClose={onClose}
          actions={
            <>
              <Button pill size={'sm'} color={'white'}>
                CANCEL
              </Button>
              <Button
                pill
                icon={'mail'}
                size={'sm'}
                color={'primary'}
                onClick={handleSubmit}
              >
                SEND ACTIVATION
              </Button>
            </>
          }
        />
      )}
    </Formik>
  )
}

export default SendActivationEmailModal
