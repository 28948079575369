import { useQuery } from '@apollo/client'
import { useGetUserIsStudent } from 'modules/common/hooks/useGetCurrentUserType'
import AddMembershipModal from 'modules/org-credential/components/modals/AddMembershipModal'
import QRCodeUserRegistration from 'modules/user/registrations/components/buttons/QRCodeUserRegistration'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { Button, Card, Grid, Text } from 'tabler-react'
import { GET_STUDENT_GEARS } from '../../graphql/GET_STUDENT_GEARS'
import { GET_USER_LOCATION } from '../../graphql/GET_USER_LOCATION'
import { useRootStore } from '../../hooks'
import AvatarUpload from '../../modules/user/profile/components/images/AvatarUpload'
import { Types } from '../../types/graphql'
import {
  formatHeight,
  getAge,
  getWingLoad,
  inchToCm
} from '../../utils/numberFormat'
import { SHIRT_SIZES } from '../constants'
import AADGearModalForm from '../Gear/Modal/AADGearModalForm'
import MainGearLoadLimit from '../Gear/Modal/MainGearLoadLimit'
import MainGearModalForm from '../Gear/Modal/MainGearModalForm'
import ShowGearModal from '../Gear/Modal/ShowGearModal'
import StudentLink from '../Students/StudentLink'
import { EUserTypes } from '../User/userTypes'
import RegistrationStudentDetailContactModal from './Forms/RegistrationStudentDetailContactModal'
import { useGetMeritsByStudentId } from '../../modules/user/hooks/merits'
import StudentMeritsButton from 'components/Students/StudentMeritsButton'
import RegistrationStudentDetailUpdate from './Forms/RegistrationStudentDetailUpdate'
import RegistrationStudentLocationUpdateModal from './Forms/RegistrationStudentLocationUpdateModal'
import avatar from 'assets/images/avatar.png'

interface IRegistrationStudentDetailsProps {
  userRegistrationDetails: Types.UserRegistrationsById
  campRequirements: string[]
}

const RegistrationStudentDetails = ({
  userRegistrationDetails,
  campRequirements
}: IRegistrationStudentDetailsProps) => {
  const { currentUser } = useRootStore()
  const isStudent = useGetUserIsStudent()
  const [isStudentDetailUpdateModalOpen, setIsStudentDetailUpdateModalOpen] =
    useState<boolean>(false)
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false)
  const [isGearModalOpen, setIsGearModalOpen] = useState<boolean>(false)
  const [isAADModalOpen, setIsAADModalOpen] = useState<boolean>(false)
  const [isShowGearModalOpen, setIsShowGearModalOpen] = useState<boolean>(false)
  const [mainGear, setMainGear] = useState(null)
  const [isGearSelectionOpen, setIsGearSelectionOpen] = useState(false)
  const [isAddMembershipModalOpen, setIsAddMembershipModalOpen] =
    useState(false)
  const [isAddLicenseModalOpen, setIsAddLicenseModalOpen] = useState(false)
  const [isStudentLocationModalOpen, setIsStudentLocationModalOpen] =
    useState(false)
  const [field, setField] = useState<string>('')

  //MERITS
  const {
    memberLicense,
    hasLifetimeMembership,
    sortedABCDLicenses,
    loading,
    burble
  } = useGetMeritsByStudentId(userRegistrationDetails.student_id)

  const { data: dataTunnelLocation, loading: tunnelLocationLoading } = useQuery(
    GET_USER_LOCATION,
    {
      variables: {
        student_id: Number(userRegistrationDetails?.student_id),
        flag_type_id: 6,
        location_type: 'tunnel'
      }
    }
  )

  const defaultTunnelLocation = useMemo(() => {
    if (!tunnelLocationLoading && !!dataTunnelLocation?.getUserLocation) {
      return dataTunnelLocation.getUserLocation
    }

    return null
  }, [dataTunnelLocation, tunnelLocationLoading])

  const { data: userLocation, loading: userLocationLoading } = useQuery(
    GET_USER_LOCATION,
    {
      variables: {
        student_id: Number(userRegistrationDetails?.student_id),
        flag_type_id: 6,
        location_type: 'dropzone'
      }
    }
  )

  const defaultHomeLocation = useMemo(() => {
    if (!userLocationLoading && !!userLocation?.getUserLocation) {
      return userLocation.getUserLocation
    }

    return null
  }, [userLocation, userLocationLoading])

  //Gears
  const { data: gearsData, loading: gearsLoading } = useQuery(
    GET_STUDENT_GEARS,
    {
      variables: {
        getUserGearOptions: {
          student_id: Number(userRegistrationDetails?.student_id)
        }
      }
    }
  )

  const studentMainCanopy = useMemo(() => {
    if (!gearsLoading && gearsData?.getUserGears) {
      const canopy = gearsData.getUserGears.filter(
        (gear) => gear.gear_model.gear_type_id === 1
      )

      if (canopy.length >= 1) {
        setMainGear(canopy[0])
      }

      return canopy ?? null
    }

    return null
  }, [gearsData, gearsLoading])

  const studentAAD = useMemo(() => {
    if (!gearsLoading && gearsData?.getUserGears) {
      const aad = gearsData.getUserGears.filter(
        (gear) => gear.gear_model.gear_type_id === 4
      )

      if (aad.length === 0) return null

      return aad
    }

    return null
  }, [gearsData, gearsLoading])

  const orgMembership = userRegistrationDetails?.orgCredentials.find(
    (oc) => oc.membership
  )

  const orgCredentials = userRegistrationDetails?.orgCredentials
    .filter((oc) => oc.license || oc.nac_id)
    .sort((a, b) => a.license.localeCompare(b.license))

  const highestCredential = orgCredentials
    ? orgCredentials[orgCredentials.length - 1]
    : null

  return (
    <>
      <Card className="registration">
        <Card.Header
          className="card-header position-relative"
          backgroundURL={
            userRegistrationDetails.profile_avatar
              ? `/api/s3/uploads/${userRegistrationDetails.profile_avatar}`
              : avatar
          }
        >
          {!userRegistrationDetails?.profile_avatar && (
            <AvatarUpload
              className="position-absolute bottom-0 right-0 "
              studentId={Number(userRegistrationDetails?.student_id)}
              profileAvatar={userRegistrationDetails?.profile_avatar}
              refetchQueries={['GET_USER_REGISTRATIONS_BY_IDS']}
            />
          )}
        </Card.Header>
        <Card.Body className="pl-3 pr-3">
          <Grid.Row className="border-bottom">
            <Grid.Col>
              <h3 className="text-center">
                {currentUser.type !== EUserTypes.student && (
                  <StudentLink
                    studentId={Number(userRegistrationDetails?.student_id)}
                    studentName={`${userRegistrationDetails.first_name} ${userRegistrationDetails.last_name}`}
                  />
                )}
                {currentUser.type === EUserTypes.student &&
                  `${userRegistrationDetails?.first_name} ${userRegistrationDetails?.last_name}`}
              </h3>
            </Grid.Col>
          </Grid.Row>
          {currentUser.type !== EUserTypes.student && (
            <Grid.Row className="border-bottom">
              <Grid.Col className="text-center border-right">
                {userRegistrationDetails?.email ? (
                  <Button
                    className="my-3 text-primary"
                    icon="mail"
                    color={'white'}
                    onClick={(e) => {
                      e.preventDefault()
                      window.location.href =
                        'mailto:' + userRegistrationDetails?.email
                    }}
                  >
                    EMAIL
                  </Button>
                ) : (
                  <Button
                    className="my-3 text-muted"
                    icon="mail"
                    color={'white'}
                    onClick={() => setIsContactModalOpen(true)}
                  >
                    No Email
                  </Button>
                )}
              </Grid.Col>
              <Grid.Col className="text-center border-0">
                {userRegistrationDetails?.phone_number ? (
                  <Button
                    className="my-3 text-primary"
                    icon="phone"
                    color={'white'}
                    onClick={(e) => {
                      e.preventDefault()
                      window.location.href =
                        'tel:' +
                        userRegistrationDetails?.phone_country_code +
                        userRegistrationDetails?.phone_number
                    }}
                  >
                    CALL
                  </Button>
                ) : (
                  <Button
                    className="my-3 text-muted"
                    icon="phone"
                    color={'white'}
                    onClick={() => setIsContactModalOpen(true)}
                  >
                    No Phone
                  </Button>
                )}
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row className="mt-3 mb-3">
            {campRequirements.includes('height') && (
              <Grid.Col width={6} className="text-center">
                <Text className="text-muted">Height</Text>
                <h4
                  onClick={() => {
                    setField('height')
                    setIsStudentDetailUpdateModalOpen(true)
                  }}
                  className={
                    'cursor-pointer' +
                    (!userRegistrationDetails?.height ? ' text-danger ' : '')
                  }
                >
                  {userRegistrationDetails?.height > 0 ? (
                    formatHeight(userRegistrationDetails?.height)
                  ) : (
                    <Button color="white" size="sm" className="text-muted">
                      Unknown
                    </Button>
                  )}
                </h4>
              </Grid.Col>
            )}
            {campRequirements.includes('weight') && (
              <Grid.Col width={6} className="text-center">
                <Text className="text-muted">Weight</Text>
                <h4
                  onClick={() => {
                    setField('weight')
                    setIsStudentDetailUpdateModalOpen(true)
                  }}
                  className={
                    'cursor-pointer' +
                    (!userRegistrationDetails?.weight ? ' text-danger ' : '')
                  }
                >
                  {userRegistrationDetails?.weight > 0 ? (
                    userRegistrationDetails?.weight + ' lbs'
                  ) : (
                    <Button color="white" size="sm" className="text-muted">
                      Unknown
                    </Button>
                  )}
                  <span>{''}</span>
                </h4>
              </Grid.Col>
            )}
            {campRequirements.includes('dob') && (
              <Grid.Col width={6} className="text-center">
                <Text className="text-muted">Age</Text>
                <h4
                  onClick={() => {
                    setField('dob')
                    setIsStudentDetailUpdateModalOpen(true)
                  }}
                  className={
                    'cursor-pointer' +
                    (!userRegistrationDetails?.dob ? ' text-danger ' : '')
                  }
                >
                  {userRegistrationDetails?.dob ? (
                    getAge(userRegistrationDetails?.dob)
                  ) : (
                    <Button color="white" size="sm" className="text-muted">
                      Unknown
                    </Button>
                  )}
                </h4>
              </Grid.Col>
            )}
            {/* Only display Wing Load if camp type is Skydiving */}
            {campRequirements.includes('wing_load') &&
              userRegistrationDetails.sportTypeId === 2 && (
                <Grid.Col width={6} className="text-center">
                  <Text className="text-muted">Wing Load</Text>
                  <h4>
                    {!!studentMainCanopy &&
                    mainGear &&
                    userRegistrationDetails?.weight > 0 ? (
                      <span
                        className={'cursor-pointer'}
                        onClick={() => {
                          if (studentMainCanopy.length > 1) {
                            setIsGearSelectionOpen(true)
                          }
                        }}
                      >
                        {getWingLoad(
                          Number(mainGear.gear_canopy_size),
                          Number(userRegistrationDetails?.weight)
                        )}
                        {studentMainCanopy.length > 1 && (
                          <i className="fe fe-more-horizontal ml-2" />
                        )}
                        <Text.Small className="text-muted d-block">
                          {mainGear.gear_model.name} {mainGear.gear_canopy_size}
                        </Text.Small>
                      </span>
                    ) : (
                      <Button
                        color="white"
                        size="sm"
                        className="text-danger"
                        onClick={() => setIsGearModalOpen(true)}
                      >
                        Unknown
                      </Button>
                    )}
                  </h4>
                </Grid.Col>
              )}
            {campRequirements.includes('jump_count') && ''}
            <Grid.Col width={6} className="text-center">
              <Text className="text-muted">Total Jumps</Text>
              <h4
                className={
                  'cursor-pointer' +
                  (campRequirements.includes('jump_count') &&
                  !userRegistrationDetails?.jump_count
                    ? ' text-danger'
                    : '')
                }
                onClick={() => {
                  setField('jump_count')
                  setIsStudentDetailUpdateModalOpen(true)
                }}
              >
                {userRegistrationDetails?.jump_count
                  ? userRegistrationDetails?.jump_count
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : 'Unknown'}
              </h4>
            </Grid.Col>

            {campRequirements.includes('tunnel_time') && ''}
            <Grid.Col width={6} className="text-center">
              <Text className="text-muted">Total Tunnel Time</Text>
              <h4
                className={
                  'cursor-pointer' +
                  (campRequirements.includes('tunnel_time') &&
                  !userRegistrationDetails?.tunnel_time
                    ? ' text-danger'
                    : '')
                }
                onClick={() => {
                  setField('tunnel_time')
                  setIsStudentDetailUpdateModalOpen(true)
                }}
              >
                {userRegistrationDetails?.tunnel_time
                  ? userRegistrationDetails?.tunnel_time
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' hours'
                  : 'Unknown'}
              </h4>
            </Grid.Col>

            {campRequirements.includes('country') && (
              <Grid.Col width={6} className="text-center">
                <Text className="text-muted">Country</Text>
                <h4
                  className={
                    'cursor-pointer' +
                    (!userRegistrationDetails?.country ? ' text-danger' : '')
                  }
                  onClick={() => {
                    setField('country')
                    setIsStudentDetailUpdateModalOpen(true)
                  }}
                >
                  {userRegistrationDetails?.country ?? 'Unknown'}
                </h4>
              </Grid.Col>
            )}
            {campRequirements.includes('home_dz') && ''}
            <Grid.Col width={6} className="text-center">
              <Text className="text-muted">Home DZ</Text>
              <h4
                className={
                  'cursor-pointer' +
                  (campRequirements.includes('home_dz') &&
                  !defaultHomeLocation?.name
                    ? ' text-danger'
                    : '')
                }
                onClick={() => {
                  setField('dropzone')
                  setIsStudentLocationModalOpen(true)
                }}
              >
                {defaultHomeLocation?.name ?? 'Unknown'}
              </h4>
            </Grid.Col>
            {campRequirements.includes('home_tunnel') && ''}
            <Grid.Col width={6} className="text-center">
              <Text className="text-muted">Home Tunnel</Text>
              <h4
                className={
                  'cursor-pointer' +
                  (campRequirements.includes('home_tunnel') &&
                  !defaultTunnelLocation?.name
                    ? ' text-danger'
                    : '')
                }
                onClick={() => {
                  setField('tunnel')
                  setIsStudentLocationModalOpen(true)
                }}
              >
                {defaultTunnelLocation?.name ?? 'Unknown'}
              </h4>
            </Grid.Col>
            {campRequirements.includes('burble') && (
              <Grid.Col width={6} className="text-center">
                <Text className="text-muted">Burble</Text>
                <h4
                  className={
                    'cursor-pointer' +
                    (!userRegistrationDetails?.burble_id ? ' text-danger' : '')
                  }
                  onClick={() => {
                    setField('burble')
                    setIsStudentDetailUpdateModalOpen(true)
                  }}
                >
                  {!burble && !userRegistrationDetails?.burble_id && 'Unknown'}
                  {burble || !!userRegistrationDetails?.burble_id ? (
                    <i className="fe fe-check-circle text-success" />
                  ) : (
                    ''
                  )}
                </h4>
              </Grid.Col>
            )}
          </Grid.Row>
          <Grid.Row>
            {campRequirements.includes('reach') && (
              <Grid.Col width={6} className="text-center">
                <Text className="text-muted">Reach</Text>
                <h4
                  className={
                    'cursor-pointer' +
                    (!userRegistrationDetails?.reach ? ' text-danger' : '')
                  }
                  onClick={() => {
                    setField('reach')
                    setIsStudentDetailUpdateModalOpen(true)
                  }}
                >
                  {userRegistrationDetails?.reach
                    ? `${inchToCm(userRegistrationDetails?.reach)}cm`
                    : 'Unknown'}
                </h4>
              </Grid.Col>
            )}
            {campRequirements.includes('stance') && (
              <Grid.Col width={6} className="text-center">
                <Text className="text-muted">Stance</Text>
                <h4
                  className={
                    'cursor-pointer' +
                    (!userRegistrationDetails?.stance ? ' text-danger' : '')
                  }
                  onClick={() => {
                    setField('stance')
                    setIsStudentDetailUpdateModalOpen(true)
                  }}
                >
                  {userRegistrationDetails?.stance ?? 'Unknown'}
                </h4>
              </Grid.Col>
            )}
          </Grid.Row>
        </Card.Body>
        <Card.Footer className="pl-4 pr-4">
          {campRequirements.includes('shirt_size') && (
            <Button
              color="white"
              size="sm"
              onClick={() => {
                setField('shirt_size_id')
                setIsStudentDetailUpdateModalOpen(true)
              }}
              className={
                'float-left cursor-pointer' +
                (userRegistrationDetails?.shirt_size_id
                  ? ' text-muted'
                  : ' text-danger')
              }
            >
              Shirt Size:{' '}
              {userRegistrationDetails?.shirt_size_id
                ? SHIRT_SIZES.find(
                    (size) =>
                      size.value === userRegistrationDetails?.shirt_size_id
                  )?.name
                : 'Unknown'}
            </Button>
          )}

          {/* Only display AAD button list if camp type is Skydiving */}
          {userRegistrationDetails.sportTypeId === 2 && (
            <Button.List align="right">
              {!studentAAD ? (
                <Button
                  icon="plus-circle"
                  color="white"
                  size="sm"
                  className="text-muted"
                  onClick={() => setIsAADModalOpen(true)}
                >
                  AAD
                </Button>
              ) : (
                <Button
                  icon="check-circle"
                  color="white"
                  size="sm"
                  className="text-success"
                  onClick={() => setIsShowGearModalOpen(true)}
                >
                  AAD
                </Button>
              )}
            </Button.List>
          )}
        </Card.Footer>
      </Card>
      {(userRegistrationDetails.org_id ||
        campRequirements.includes('member')) && (
        <Card>
          <Card.Body>
            <Grid.Row width={12}>
              <Grid.Col width={2}>
                <Button icon="award" color="info" size="lg" />
              </Grid.Col>
              <Grid.Col width={5}>
                {orgMembership && (
                  <>
                    {memberLicense && orgMembership.org_id === '1' && (
                      <span>
                        <Text>
                          USPA{' '}
                          <strong>{memberLicense.merits_uspa_member_id}</strong>
                          <i className="fe fe-check-circle text-cyan ml-2" />
                        </Text>
                        <Text.Small className="text-muted">
                          {memberLicense.merits_uspa_member_expiration
                            ? memberLicense.merits_uspa_member_expiration
                            : 'Lifetime Member'}
                        </Text.Small>
                      </span>
                    )}
                    {!memberLicense && orgMembership.org_id && (
                      <span
                        className="cursor-pointer"
                        onClick={() => setIsAddMembershipModalOpen(true)}
                      >
                        <Text>
                          {orgMembership.org_slug.toUpperCase()}{' '}
                          <strong>{orgMembership?.membership}</strong>
                        </Text>
                        <Text.Small className="text-muted">
                          {orgMembership.expires}
                        </Text.Small>
                      </span>
                    )}
                  </>
                )}
                {!orgMembership && !memberLicense && (
                  <>
                    <Button
                      outline
                      icon="plus"
                      color="secondary"
                      size="sm"
                      onClick={() => setIsAddMembershipModalOpen(true)}
                    >
                      Org Membership
                    </Button>
                  </>
                )}
                <AddMembershipModal
                  first_name={userRegistrationDetails.first_name}
                  last_name={userRegistrationDetails.last_name}
                  email={userRegistrationDetails.email}
                  membership={orgMembership?.membership}
                  studentId={Number(userRegistrationDetails.student_id)}
                  isOpen={isAddMembershipModalOpen}
                  toggle={() =>
                    setIsAddMembershipModalOpen(!isAddMembershipModalOpen)
                  }
                />
              </Grid.Col>
              <Grid.Col width={5}>
                {Number(orgMembership?.org_id) === 1 &&
                  sortedABCDLicenses.length > 0 && (
                    <span>
                      <Text>
                        {sortedABCDLicenses[
                          sortedABCDLicenses.length - 1
                        ]?.template_title?.toUpperCase()}
                      </Text>
                      <Text.Small>
                        <strong>
                          {sortedABCDLicenses[
                            sortedABCDLicenses.length - 1
                          ]?.merits_uspa_license?.toUpperCase()}
                        </strong>
                      </Text.Small>
                    </span>
                  )}
                {sortedABCDLicenses.length <= 0 && orgCredentials.length > 0 && (
                  <span
                    className="cursor-pointer"
                    onClick={() => setIsAddMembershipModalOpen(true)}
                  >
                    <Text>
                      {highestCredential?.nac_slug?.toUpperCase() ||
                        highestCredential.license}{' '}
                    </Text>
                    <Text.Small>
                      <strong>{highestCredential?.license_number}</strong>
                    </Text.Small>
                  </span>
                )}
                {orgMembership &&
                  orgCredentials.length <= 0 &&
                  sortedABCDLicenses.length <= 0 && (
                    <>
                      <Button
                        outline
                        icon="plus"
                        color="secondary"
                        size="sm"
                        onClick={() => setIsAddMembershipModalOpen(true)}
                      >
                        License
                      </Button>
                    </>
                  )}
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
          {isStudent &&
            orgMembership?.org_id === '1' &&
            !memberLicense?.merits_uspa_member_id && (
              <Card.Footer>
                <StudentMeritsButton
                  studentId={userRegistrationDetails.student_id.toString()}
                />
              </Card.Footer>
            )}
        </Card>
      )}
      {isStudent &&
        moment(userRegistrationDetails?.start).isAfter() &&
        moment(userRegistrationDetails?.end).isBefore() && (
          <QRCodeUserRegistration userRegistration={userRegistrationDetails} />
        )}
      <RegistrationStudentLocationUpdateModal
        student={userRegistrationDetails}
        isModalOpen={isStudentLocationModalOpen}
        toggleModal={() => setIsStudentLocationModalOpen(false)}
        locationType={field}
      />
      <RegistrationStudentDetailUpdate
        isModalOpen={isStudentDetailUpdateModalOpen}
        toggleModal={() => setIsStudentDetailUpdateModalOpen(false)}
        student={userRegistrationDetails}
        field={field}
      />
      <MainGearModalForm
        isModalOpen={isGearModalOpen}
        toggleModal={setIsGearModalOpen}
        student_id={Number(userRegistrationDetails?.student_id)}
        team_id={Number(userRegistrationDetails?.team_id)}
        student={userRegistrationDetails}
      />
      <AADGearModalForm
        isModalOpen={isAADModalOpen}
        toggleModal={setIsAADModalOpen}
        student_id={Number(userRegistrationDetails?.student_id)}
        team_id={Number(userRegistrationDetails?.team_id)}
        student={userRegistrationDetails}
      />
      <RegistrationStudentDetailContactModal
        isModalOpen={isContactModalOpen}
        toggleModal={setIsContactModalOpen}
        student={userRegistrationDetails}
      />
      <MainGearLoadLimit
        student={userRegistrationDetails}
        gears={studentMainCanopy}
        toggleModal={setIsGearSelectionOpen}
        isModalOpen={isGearSelectionOpen}
        setMainGear={setMainGear}
      />
      <ShowGearModal
        toggleModal={setIsShowGearModalOpen}
        isModalOpen={isShowGearModalOpen}
        userGear={studentAAD && studentAAD[0]}
      />
    </>
  )
}
export default RegistrationStudentDetails
