import { useRootStore } from 'hooks'
import { observer } from 'mobx-react'
import React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Nav } from 'tabler-react'
import AdminSearchBar from '../../components/Admin/AdminSearchBar'

const AdminNav = () => {
  const { currentUser } = useRootStore()

  return (
    <>
      <Nav.Item icon="home" to="/" value="/" />
      <Nav.Item icon="shopping-bag" to="/admin/marketplace" value="Marketplace" />
      <Nav.Item icon="users" to="/admin/mycru" value="MyCRU" />
      <Nav.Item icon="credit-card" to="/payments" value="Payments" />
      <li className="nav-item ml-lg-auto mb-4 mb-lg-0">
        <Dropdown
          toggle={false}
          icon="list"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/admin/aircraft" className="dropdown-item">
                Aircraft
              </Link>
              <Link to="/admin/gear" className="dropdown-item">
                Gear
              </Link>
              <Link to="/invoices" className="dropdown-item">
                Invoices
              </Link>
              <Link to="/rigger/dashboard" className="dropdown-item">
                Jobs
              </Link>
              <Link to="/admin/logbook" className="dropdown-item">
                Logbook Logs
              </Link>
              <Link to="/admin/products" className="dropdown-item">
                Products
              </Link>
              <Link to="/admin/reserve-logs" className="dropdown-item">
                Reserve Logs
              </Link>
              <Link to="/admin/style-guide" className="dropdown-item">
                Style Guide
              </Link>
              <Link to="/admin/transaction-list" className="dropdown-item">
                Transactions
              </Link>
            </>
          }
        />
        <Dropdown
          toggle={false}
          icon="map-pin"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/admin/locations" className="dropdown-item">
                Locations
              </Link>
            </>
          }
        />
        <Dropdown
          toggle={false}
          icon="calendar"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <Link
                to="/calendar"
                className="dropdown-item d-none d-md-block"
              >
                Calendar
              </Link>
              <Link to="/admin/event-list" className="dropdown-item">
                Events
              </Link>
              <Link to="/admin/user-registrations" className="dropdown-item">
                Registrations
              </Link>
            </>
          }
        />
        <Dropdown
          toggle={false}
          icon="users"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/admin/user-events" className="dropdown-item">
                Activity
              </Link>
              {currentUser.id === 1 && (
                <Link to="/admin/admin-list" className="dropdown-item">
                  Admins
                </Link>
              )}
              <Link to="/admin/coach-list" className="dropdown-item">
                Coaches
              </Link>
              <Link to="#" className="dropdown-item text-muted">
                Credentials
              </Link>
              <Link to="/fighter/search" className="dropdown-item">
                Fighters
              </Link>
              <Link to="/admin/org-list" className="dropdown-item">
                Orgs
              </Link>
              <Link
                to="/admin/sessions-list"
                className="dropdown-item text-muted"
              >
                Sessions
              </Link>
              <Link to="/admin/team-list" className="dropdown-item">
                Teams
              </Link>
              <Link to="/admin/user-list" className="dropdown-item">
                Users
              </Link>
            </>
          }
        />
        <span className='d-none d-lg-block'>
          <AdminSearchBar />
        </span>
      </li>
    </>
  )
}

export default observer(AdminNav);
