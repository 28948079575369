import { Loading } from 'components/Loading'
import React, { useContext } from 'react'
import { UserStoreContext } from '../../contexts/userStoreContext'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'
import { Card, Grid, Text } from 'tabler-react'
import useReactRouter from 'use-react-router'
import UserCard from '../../components/User/Profile/UserCard'
import UserActivitiesList from '../../components/User/UserActivitiesList'
import { useRootStore } from '../../hooks'
import useGetStudentById from '../../modules/student/hooks/useGetStudentById'
import UserRoleList from '../../modules/user/roles/components/list/UserRoleList'
import { formatHeight, getAge } from '../../utils/numberFormat'
import { EUserTypes } from './userTypes'
import UserVerify from './UserVerify'
import UserEmergencyContact from './UserEmergencyContact'

const FighterDetails = () => {
  const { user } = useContext(UserStoreContext)
  const { history } = useReactRouter()
  const { fighterId } = useParams<{ fighterId: string }>()
  const { domainData, currentUser } = useRootStore()
  const { data, loading } = useGetStudentById(fighterId)
  const student = data?.getStudentById

  if (loading) return <Loading />

  return (
    <>
      <Helmet>
        <title>
          {domainData.name +
            ' | ' +
            student?.first_name +
            ' ' +
            student?.last_name}
        </title>
      </Helmet>
      <Grid.Row>
        <Grid.Col lg={4} sm={12} xs={12}>
          <UserCard
            firstName={student?.first_name}
            lastName={student?.last_name}
            profileAvatar={student?.profile_avatar}
            profilePicture={student?.profile_picture}
          />
          <Card statusColor="">
            <Card.Header>
              <Card.Title>Photos</Card.Title>
            </Card.Header>
            {/* ADD UP TO 9 PHOTOS, DISPLAY AS THUMBNAILS IN A 3X3 GRID */}
            <Card.Body></Card.Body>
          </Card>
          <UserEmergencyContact user={user} />
          <Card statusColor="">
            <Card.Header>
              <Card.Title>Medical</Card.Title>
            </Card.Header>
            {/* ADD FILE UPDLOADS FOR EACH TYPE OF MEDICAL RECORD */}
            <Card.Body>
              <p>
                <strong>Self Declaration </strong>self{' '}
              </p>
              <p>
                <strong>Physical </strong>physical{' '}
              </p>
              <p>
                <strong>Eye Exam </strong>eye{' '}
              </p>
              <p>
                <strong>Blood Test </strong>blood{' '}
              </p>
              <p>
                <strong>Pregnancy Test </strong>pregnancy
              </p>
              <p>
                <strong>Stress Test </strong>stress{' '}
              </p>
            </Card.Body>
          </Card>
          <Card statusColor="">
            <Card.Header>
              <Card.Title>Accomplishments</Card.Title>
            </Card.Header>
            <Card.Body>
              <textarea
                className={'form-control'}
                name={`accomplishments`}
                placeholder={'Type your answer...'}
              />
            </Card.Body>
          </Card>
          <Card statusColor="">
            <Card.Header>
              <Card.Title>Disciplines of experience</Card.Title>
            </Card.Header>
            <Card.Body>
              <textarea
                className={'form-control'}
                name={`accomplishments`}
                placeholder={'Type your answer...'}
              />
            </Card.Body>
          </Card>
          <UserActivitiesList studentId={student.id} />
        </Grid.Col>
        <Grid.Col lg={8} sm={12} xs={12}>
          <Card>
            <>
              <Card.Body>
                <div
                  className="card-body-click"
                  onClick={() => history.push('/fighter-details')}
                >
                  <Grid.Row>
                    <Grid.Col sm={6} md={6}>
                      {student?.address1 && student?.address1 + ' '}
                      {student?.address2 && student?.address2}
                    </Grid.Col>
                    <Grid.Col sm={6} md={6}></Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col sm={6} md={6}>
                      {student.city ? (
                        student.city + ', '
                      ) : (
                        <span className="text-warning"></span>
                      )}
                      {student.province} {student.postal_code}
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col sm={6} md={6}>
                      {student.country ? (
                        student.country
                      ) : (
                        <span className="text-warning"></span>
                      )}
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mt-5">
                    <Grid.Col width={6} xs={6} sm={6} md={3} lg={3}>
                      {student.dob && (
                        <div>
                          Age <h4>{getAge(student.dob)}</h4>
                        </div>
                      )}
                    </Grid.Col>
                    <Grid.Col width={6} xs={6} sm={6} md={3} lg={3}>
                      {student.height && student.height !== 0 && (
                        <div>
                          Height{' '}
                          <h4>
                            {student.height
                              ? formatHeight(student?.height)
                              : ' '}
                          </h4>
                        </div>
                      )}
                    </Grid.Col>
                    <Grid.Col width={6} xs={6} sm={6} md={3} lg={3}>
                      {student.weight && student.weight !== 0 && (
                        <div>
                          Weight{' '}
                          <h4>
                            {student.weight ? student.weight : ' '}
                            {' lbs'}
                          </h4>
                        </div>
                      )}
                    </Grid.Col>
                    {student.jump_weight !== 0 &&
                      [
                        EUserTypes.dzm.toString(),
                        EUserTypes.rigger.toString(),
                        EUserTypes.admin.toString()
                      ].includes(currentUser.type) && (
                        <Grid.Col width={6} xs={6} sm={6} md={3} lg={3}>
                          Exit Weight{' '}
                          <h4>
                            {student.jump_weight ? (
                              student.jump_weight + ' lbs'
                            ) : student.weight ? (
                              <Text className="font-italic">
                                {student.weight + 25} lbs (est)
                              </Text>
                            ) : (
                              ' '
                            )}
                          </h4>
                        </Grid.Col>
                      )}
                    {student?.reach && (
                      <Grid.Col width={6} xs={6} sm={6} md={3} lg={3}>
                        <div>
                          Reach <h4>{student.reach} cm</h4>
                        </div>
                      </Grid.Col>
                    )}
                    {student?.stance && (
                      <Grid.Col width={6} xs={6} sm={6} md={3} lg={3}>
                        <div>
                          Stance <h4>{student.stance}</h4>
                        </div>
                      </Grid.Col>
                    )}
                  </Grid.Row>
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="float-left">
                  <UserVerify userId={user.id} />
                </div>
                <UserRoleList />
              </Card.Footer>
            </>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </>
  )
}

export default FighterDetails
