import { useQuery } from '@apollo/client'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar, {
  DateSelectArg,
  EventChangeArg,
  EventClickArg,
  EventContentArg
} from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import { observer } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import moment from 'moment'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Card, Dimmer, Grid, Icon } from 'tabler-react'
import useReactRouter from 'use-react-router'
import { EventStoreContext } from '../../contexts/eventStoreContext'
import { GET_CAMPS } from '../../graphql/GET_CAMPS'
import { useRootStore } from '../../hooks/useRootStore'
import {
  useGetUserIsCoachSuperAdmin,
  useGetUserIsStudent
} from '../../modules/common/hooks/useGetCurrentUserType'
import TeamListLogo from '../../modules/team/components/TeamListLogo'
import { Types } from '../../types/graphql'
import UserCalendarSportsFilter from '../../modules/user/sports/components/UserCalendarSportsFilter'
import UserCalendarEventTypeFilter from '../../modules/user/sports/components/UserCalendarEventTypeFilter'
import CalendarLocationFilter from '../../modules/user/calendar/CalendarLocationFilter'

interface ICalendarViewProps {
  toggleModal: () => void
}

const CalendarView = ({ toggleModal }: ICalendarViewProps) => {
  const eventStore = useContext(EventStoreContext)
  const { events, eventTypes } = eventStore
  const rootStore = useRootStore()
  const {
    currentCoachTeam,
    calendarSportsTypeFilter,
    currentUser,
    calendarTeamFilter,
    calendarLocationFilter
  } = rootStore
  const { history } = useReactRouter()
  const isStudent = useGetUserIsStudent()
  const isCoachSuperAdmin = useGetUserIsCoachSuperAdmin()
  const [sportsTypeFilter, setSportsTypeFilter] = useState([])
  const [eventTypeFilter, setEventTypeFilter] = useState([])
  const [teamFilter, setTeamFilter] = useState<number>(null)
  const [locationFilter, setLocationFilter] = useState<number>(null)
  const [showFilter, setShowFilter] = useState(
    isStudent || !!currentUser.student_id
  )
  useEffect(() => {
    setSportsTypeFilter(
      calendarSportsTypeFilter ? [calendarSportsTypeFilter] : []
    )
    setTeamFilter(calendarTeamFilter || null)
    setLocationFilter(calendarLocationFilter || null)

    rootStore.setFilter('calendarSportsTypeFilter', null)
    rootStore.setFilter('calendarTeamFilter', null)
    rootStore.setFilter('calendarLocationFilter', null)
  }, [])

  // change this to only allow teams with feature_camps to Create Events
  const canAddEvent: boolean = useMemo(() => {
    return currentCoachTeam && currentCoachTeam.feature_camps
  }, [currentCoachTeam])

  let filterOptions: object = { isDateAgnostic: true }
  let coachFilterOptions: object = { ...filterOptions }

  // Coach Accounts can see only events for their Team
  if (currentCoachTeam) {
    coachFilterOptions = {
      ...coachFilterOptions,
      team_id: currentCoachTeam?.id.toString()
    }
  }

  // students can see all events that are published and public
  filterOptions = {
    ...filterOptions,
    isPublic: true,
    isPublished: true
  }

  const {
    loading,
    error,
    data: publicCampsData
  } = useQuery(GET_CAMPS, {
    variables: { filter: { ...filterOptions } }
  })

  const {
    loading: coachCampLoading,
    error: coachCampError,
    data: coachCampsData
  } = useQuery(GET_CAMPS, {
    variables: { filter: { ...coachFilterOptions } },
    skip: !currentCoachTeam
  })

  useEffect(() => {
    eventStore.resetEventEntry()
    let modifiedEvents = []
    let coachEvents = []
    if (!loading && publicCampsData) {
      modifiedEvents = publicCampsData.getCamps
        .filter((event) => {
          if (currentCoachTeam?.id) {
            return Number(event.team_id) !== currentCoachTeam.id
          }
          return true
        })
        .map((camp) =>
          Object.assign(
            {},
            {
              ...camp,
              title: camp.camp_name,
              allDay: camp.all_day,
              end: camp.all_day
                ? moment(camp.end).add(1, 'd').format()
                : camp.end
            }
          )
        )
    }
    if (!coachCampLoading && coachCampsData) {
      coachEvents = publicCampsData.getCamps
        .filter((event) => {
          if (currentCoachTeam) {
            return Number(event.team_id) === currentCoachTeam.id
          }
          return true
        })
        .map((camp) =>
          Object.assign(
            {},
            {
              ...camp,
              title: camp.camp_name,
              allDay: camp.all_day,
              end: camp.all_day
                ? moment(camp.end).add(1, 'd').format()
                : camp.end
            }
          )
        )
    }

    const mergedEvents = [...modifiedEvents, ...coachEvents]
      .filter(
        (e: Types.Camp) =>
          !sportsTypeFilter.length ||
          sportsTypeFilter.includes(Number(e.sport_type_id))
      )
      .filter(
        (e: Types.Camp) =>
          !eventTypeFilter.length ||
          eventTypeFilter.includes(Number(e.event_type_id))
      )
      .filter(
        (e: Types.Camp) => !teamFilter || Number(e.team_id) === teamFilter
      )
      .filter(
        (e: Types.Camp) =>
          !locationFilter || e.location_id === Number(locationFilter)
      )
    applySnapshot(events, mergedEvents)
  }, [
    publicCampsData,
    coachCampsData,
    sportsTypeFilter,
    eventTypeFilter,
    teamFilter,
    locationFilter
  ])

  const handleChangeSportsType = (id: number) => {
    if (sportsTypeFilter.indexOf(Number(id)) >= 0) {
      setSportsTypeFilter(sportsTypeFilter.filter((num) => num != Number(id)))
    } else {
      setSportsTypeFilter([...sportsTypeFilter, id])
    }
  }

  const handleChangeEventType = (ids: number[]) => {
    setEventTypeFilter(ids)
  }

  if (error || coachCampError) {
    return <p>{`Error: ${error.message}`}</p>
  }

  function handleDateSelect(selectInfo: DateSelectArg) {
    if (!canAddEvent) {
      return null
    }

    eventStore.initEventEntry(selectInfo)
    toggleModal()
  }

  const handleEventClick = (clickInfo: EventClickArg) => {
    history.push(`/calendar/${clickInfo.event.id}`)
  }

  const handleEventChange = (changeInfo: EventChangeArg) => {
    eventStore.changeEvent(changeInfo)
  }

  const renderEventContent = (eventContent: EventContentArg) => {
    const { event } = eventContent

    return (
      <div
        className={`${
          event.extendedProps.is_published
            ? 'cursor-pointer pl-1 text-white'
            : 'cursor-pointer pl-1 bg-light text-primary'
        } `}
      >
        {event.title}
        <Icon
          className="ml-2"
          name={`${event.extendedProps.is_public ? 'calendar' : 'users'} `}
        />
      </div>
    )
  }
  useEffect(() => {
    const hasFilter =
      sportsTypeFilter.length > 0 ||
      eventTypeFilter.length > 0 ||
      teamFilter ||
      locationFilter
    // Modify the button after rendering to insert an icon
    const button = document.querySelector('.fc-myCalendarFilter-button')
    if (button instanceof HTMLElement) {
      button.style.opacity = hasFilter ? '1' : '0.65'
      button.innerHTML = `<i class="fe fe-filter"></i>`
    }
  }, [sportsTypeFilter, eventTypeFilter, teamFilter, locationFilter])
  return (
    <>
      {isCoachSuperAdmin && (
        <Grid.Row className={`${showFilter ? '' : 'd-none'}`}>
          <Card title="Filters">
            <Card.Body>
              <Grid.Row>
                <Grid.Col sm={12} md={6} lg={3}>
                  {(isStudent || currentUser.student_id) && (
                    <>
                      <UserCalendarSportsFilter
                        studentId={
                          isStudent
                            ? Number(currentUser.id)
                            : Number(currentUser.student_id)
                        }
                        onClickProps={handleChangeSportsType}
                        activeFilter={sportsTypeFilter}
                      />
                    </>
                  )}
                </Grid.Col>
                <Grid.Col sm={12} md={6} lg={3}>
                  <UserCalendarEventTypeFilter
                    activeFilter={eventTypeFilter}
                    eventTypes={eventTypes}
                    onChangeProp={handleChangeEventType}
                  />
                </Grid.Col>
                <Grid.Col sm={12} md={6} lg={3}>
                  <CalendarLocationFilter
                    locationFilter={locationFilter}
                    onChangeProp={setLocationFilter}
                  />
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
          </Card>
        </Grid.Row>
      )}
      <Grid.Row>
        {isCoachSuperAdmin && (
          <Grid.Col
            sm={12}
            md={12}
            lg={4}
            className={`${sportsTypeFilter.length > 0 ? '' : 'd-none'}`}
          >
            {(isStudent || currentUser.student_id) && (
              <>
                <TeamListLogo
                  sportsList={sportsTypeFilter}
                  onClickProp={setTeamFilter}
                  selectedTeam={teamFilter}
                />
              </>
            )}
          </Grid.Col>
        )}

        <Grid.Col
          sm={12}
          md={12}
          lg={sportsTypeFilter.length > 0 && isCoachSuperAdmin ? 8 : 12}
        >
          <Dimmer active={loading} loader={loading}>
            <FullCalendar
              dayMaxEvents={true}
              events={events.slice()}
              customButtons={{
                myCalendarFilter: {
                  text: ' ',
                  click: () => setShowFilter(!showFilter)
                }
              }}
              headerToolbar={{
                // keep for reference, not currently needed
                right: isCoachSuperAdmin
                  ? 'myCalendarFilter today prev,next'
                  : 'today prev,next'
                // cnter: 'title',
                // right: 'dayGridMonth,timeGridWeek,timeGridDay'
              }}
              initialView="dayGridMonth"
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              selectable={true}
              selectMirror={true}
              select={handleDateSelect}
              eventContent={renderEventContent}
              eventClick={handleEventClick}
              eventChange={handleEventChange}
            />
          </Dimmer>
        </Grid.Col>
      </Grid.Row>
    </>
  )
}

export default observer(CalendarView)
