import React, { useMemo } from 'react'
import { useRootStore } from '../../../../hooks'
import useGetRegisteredStudents from '../../../user/registrations/hooks/useGetRegisteredStudents'
import { useQuery } from '@apollo/client'
import { GET_CAMP_REGISTRATION_REQUIREMENTS } from '../../../../graphql/GET_CAMP_REGISTRATION_REQUIREMENTS'
import {
  formatMoney,
  getWingLoad,
  inchToCm
} from '../../../../utils/numberFormat'
import moment from 'moment/moment'
import { EHostnames } from '../../../../components/constants'
import { CSVLink } from 'react-csv'

const RegistrationListCSVButton = ({ campId, teamId }) => {
  const { domainData } = useRootStore()
  const { data, loading } = useGetRegisteredStudents({ campId })
  const { data: campRequirementsData, loading: campRequirementsLoading } =
    useQuery(GET_CAMP_REGISTRATION_REQUIREMENTS, {
      variables: {
        team_id: Number(teamId),
        camp_id: Number(campId)
      },
      skip: !teamId
    })
  const campRequirements = useMemo(() => {
    if (campRequirementsLoading) return []

    return campRequirementsData?.campRegistrationRequirements
      .filter((r) => r.is_exist)
      .map((r) => r.requirement_type_slug)
  }, [campRequirementsData, campRequirementsLoading])
  const registrationList = useMemo(
    () =>
      data.map((regStudent) => {
        const {
          id,
          email,
          first_name,
          last_name,
          phone_country_code,
          phone_number,
          registrationQuestions,
          registration_code,
          rating,
          status,
          uspa,
          skycruStudentRegistrationDetails
        } = regStudent

        // Create object to return
        const ignoreList = [
          'name',
          'email',
          'phone',
          'merit_sync',
          'profile_photo',
          'profile_avatar',
          'min_time'
        ]
        const reqVals = {}

        campRequirements
          .filter((req) => !ignoreList.includes(req))
          .forEach((req) => {
            const keys = Object.keys(regStudent)

            if (keys.includes(req) || req === 'wing_load' || req === 'burble') {
              switch (req) {
                case 'wing_load':
                  reqVals[req] =
                    skycruStudentRegistrationDetails?.main_gear_canopy_size &&
                    regStudent.weight
                      ? getWingLoad(
                          skycruStudentRegistrationDetails?.main_gear_canopy_size,
                          regStudent.weight
                        )
                      : ''
                  break
                case 'aad':
                  reqVals[req] =
                    skycruStudentRegistrationDetails?.aad_gear_model_name ?? ''
                  break
                case 'home_dz':
                  reqVals[req] =
                    skycruStudentRegistrationDetails?.home_dz_name ?? ''
                  break
                case 'home_tunnel':
                  reqVals[req] =
                    skycruStudentRegistrationDetails?.home_tunnel_name ?? ''
                  break
                case 'dob':
                  reqVals[req] = regStudent[req]
                    ? moment(regStudent[req]).format('MM/DD/YYYY')
                    : ''
                  break
                case 'location':
                  reqVals[req] = regStudent?.registrationLocation ?? ''
                  break
                case 'reach':
                  reqVals[req] = inchToCm(regStudent[req] ?? 0) ?? ''
                  break
                case 'burble':
                  reqVals[req] = uspa?.burbleLicense?.merits_id || ''
                  break
                default:
                  reqVals[req] = regStudent[req]
                  break
              }
            }
          })

        const uspaData =
          domainData.key === EHostnames.SKYCRU ? { uspa: uspa.uspaStatus } : {}

        const questions = registrationQuestions
          ? registrationQuestions
              .filter((q) => q.is_exist)
              .reduce(
                (acc, { name, user_answer }) => ({
                  ...acc,
                  [name]: user_answer
                }),
                {}
              )
          : {}

        return {
          id,
          first_name,
          last_name,
          status,
          email,
          phone_country_code,
          phone_number,
          registration_code,
          rating,
          paid_status:
            regStudent.paymentDetail.outstanding > 0 ? 'Pending' : 'Paid',
          totalFees: formatMoney(
            regStudent.paymentDetail.totalFees / 100,
            regStudent.registration_currency
          ),
          totalPaid: formatMoney(
            regStudent.paymentDetail.totalPaid / 100,
            regStudent.registration_currency
          ),
          totalComp: formatMoney(
            regStudent.paymentDetail.totalComp / 100,
            regStudent.registration_currency
          ),
          remainingBalance: formatMoney(
            regStudent.paymentDetail.outstanding / 100,
            regStudent.registration_currency
          ),
          ...uspaData,
          ...reqVals,
          ...questions
        }
      }),
    [data, loading]
  )
  return (
    <CSVLink
      data={registrationList}
      filename="registration-list.csv"
      className="ml-0 text-secondary"
      onClick={(event) => {
        if (loading) event.preventDefault()
      }} // Prevent download
    >
      <i className={'mr-2 fe fe-' + (loading ? 'loader' : 'download')} />
      Registration List
    </CSVLink>
  )
}

export default RegistrationListCSVButton
