import React, { FC } from 'react';
import { Container, Icon } from 'tabler-react';
import AddStudentCoachModal from '../../components/Students/Modal/AddStudentCoachModal'
import FeatureAccessGate from 'modules/common/components/FeatureAccessGate'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'


const MobileNav = () => {
  const { isCoachAdmin, isCoachSuperAdmin } =
      useGetCurrentUserType()
  const [isAddStudentModalOpen, setIsAddStudentModalOpen] =
      React.useState(false)
  const handleToggleAddStudentModal = () =>
      setIsAddStudentModalOpen(!isAddStudentModalOpen)

  return (
    <>
          <div>
            <Container>
              <AddStudentCoachModal
                isOpen={isAddStudentModalOpen}
                handleClose={handleToggleAddStudentModal}
              />
              <nav className="navbar fixed-bottom navbar-light bg-light text-center pt-4 pb-4">
                  <li className="nav-item">
                    <a className="active d-inline-block" href="/">
                      <Icon className="d-block" name={"home"} />
                      <span className="d-block small">Home</span>
                    </a>
                  </li>
                  <FeatureAccessGate feature="feature_camps">
                    <li className="nav-item">
                      <a
                        className="active d-inline-block"
                        href="/events"
                      >
                        <Icon className="d-block" name={"calendar"} />
                        <span className="d-block small">Events</span>
                      </a>
                    </li>
                  </FeatureAccessGate>
                  <FeatureAccessGate feature="feature_open_add_students">
                    {(isCoachAdmin || isCoachSuperAdmin) && (
                      <li className="nav-item" onClick={handleToggleAddStudentModal}>
                        <a className="active d-inline-block" href="#">
                          <Icon className="d-block" name={"user-plus"} />
                          <span className="d-block small">Student</span>
                        </a>
                      </li>
                    )}
                  </FeatureAccessGate>
                  <FeatureAccessGate feature="feature_accounting">
                    <li className="nav-item">
                      <a
                        className="active d-inline-block"
                        href="/transactions"
                      >
                        <Icon className="d-block" name={"list"} />
                        <span className="d-block small">Transactions</span>
                      </a>
                    </li>
                  </FeatureAccessGate>
                <li className="nav-item">
                    <a className="active d-inline-block" href="/payments">
                      <Icon className="d-block" name={"credit-card"} />
                      <span className="d-block small">Payments</span>
                    </a>
                  </li>
              </nav>
            </Container>
          </div>
        </>
  );
};

export default MobileNav;
