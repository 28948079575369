import React, { useMemo, useState } from 'react'
import { Types } from '../../../types/graphql'
import useRouter from 'use-react-router'
import { useMutation } from '@apollo/client'
import { DELETE_GROUP } from '../../../graphql/DELETE_GROUP'
import { toast } from 'react-toastify'
import { EDIT_GROUP } from '../../../graphql/EDIT_GROUP'
import { GET_GROUPS } from '../../../graphql/GET_GROUPS'
import { loadStripe } from '@stripe/stripe-js'
import EventGroupsListItemEditName from '../../../components/Calendar/Forms/EventGroupsListItemEditName'
import { Elements } from '@stripe/react-stripe-js'
import GroupPaymentModal from '../../user/registrations/components/modals/GroupPaymentModal'
import { CSVLink } from 'react-csv'
import { Button, Card, Dropdown, Grid, Icon, Tag, Text } from 'tabler-react'
import { formatMoney } from '../../../utils/numberFormat'
import useGetCurrentUserType from '../../common/hooks/useGetCurrentUserType'
import RegistrationGroupFormModal from '../../../components/Registration/Modals/RegistrationGroupFormModal'
import GroupEmailModalForm from '../../group/components/forms/GroupEmailModalForm'

interface GroupFinanceItemProps {
  group: Types.Group
  stripeKey: string
  paymentFee: number
}

const GroupFinanceItem = ({
                            group,
                            stripeKey,
                            paymentFee
                          }:GroupFinanceItemProps) => {

  const { history } = useRouter()
  const [isEditting, setIsEditting] = React.useState(false)
  const {isAdmin, isCoachSuperAdmin} = useGetCurrentUserType();
  const [sendGroupEmailOpen, setSendGroupEmailOpen] = useState(false)
  const [deleteGroup] = useMutation(DELETE_GROUP, {
    onCompleted: () => {
      toast.success('Group deleted')
    },
    refetchQueries: ['GET_GROUPS']
  })
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false)
  const [removeGroup] = useMutation(EDIT_GROUP, {
    onCompleted: () => {
      toast.success('Group updated')
    },
    refetchQueries: ['GET_GROUPS']
  })

  const groupMembersLength = useMemo(() => {
    return group.members.length
  }, [group.members])

  const handleDelete = () => {
    if (groupMembersLength > 0) {
      return
    }
    if (window.confirm('Are you sure you want to delete this group?')) {
      deleteGroup({
        variables: {
          id: group.id
        }
      })
    }
  }

  const handleRemove = () => {
    if (
      window.confirm(
        'Are you sure you want to move this group to the dashboard?'
      )
    ) {
      removeGroup({
        variables: {
          updateGroupInput: {
            id: group.id,
            camp_id: null
          }
        },
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              filter: {
                camp_id: Number(group.camp_id),
                team_id: Number(group.team_id),
                status: 'Active'
              }
            }
          }
        ]
      })
    }
  }

  const handleArchive = () => {
    if (window.confirm('Are you sure you want to archive this group?')) {
      removeGroup({
        variables: {
          updateGroupInput: {
            id: group.id,
            status: 'Archived'
          }
        },
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              filter: {
                camp_id: Number(group.camp_id),
                team_id: Number(group.team_id),
                status: 'Active'
              }
            }
          }
        ]
      })
    }
  }

  const groupMembers = useMemo(
    () =>
      group.members.map((member) => ({
        id: member.id,
        first_name: member.first_name,
        last_name: member.last_name,
        phone_country_code: member.phone_country_code,
        phone_number: member.phone_number,
        email: member.email
      })),
    [group.members]
  )
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(stripeKey)
  )
  const [groupPaymentModalOpen, setGroupPaymentModalOpen] = useState(false)
  return (
    <>
      <Card className="group-card">
        <Card.Header
        //  backgroundURL={group.image && `/api/s3/uploads/${group.image}`}
          className={
          //  !group.image &&
            group.color
              ? 'px-3 py-1 text-white bg-' + group.color
              : 'px-3 py-1 text-white opacity bg-gray-dark'
          }
          style={{ maxHeight: '100%', maxWidth: '10px' }}
        >
          <Card.Title
            className={
              group.color
                ? 'cursor-pointer px-3 py-1 text-white bg-' + group.color
                : ''
            }
          >
            {isEditting && (
              <EventGroupsListItemEditName
                group={group}
                toggleEdit={setIsEditting}
              />
            )}
            {!isEditting && (
              <>
                <Text onClick={() => setIsEditting(true)}>
                  {group.name}
                  <Tag color={group.color} className="ml-2">
                    {groupMembersLength}
                  </Tag>
                </Text>
                <Text.Small className={'text-white mb-0 mt-1'}>
                  {group.discipline}
                </Text.Small>
              </>
            )}
          </Card.Title>
          <Card.Options>
            <Dropdown
              className={
                'float-right cursor-pointer ' + (group.color && 'text-white')
              }
              toggle={false}
              position="bottom-end"
              arrow={true}
              arrowPosition="right"
              class="cursor-pointer"
              triggerContent={<Icon name="more-vertical" />}
              items={[
                <>
                  <Dropdown.Item icon="download-cloud" as="Button">
                    <CSVLink
                      data={groupMembers}
                      filename="event_group_members.csv"
                      className="ml-0 text-dark"
                    >
                      CSV
                    </CSVLink>
                  </Dropdown.Item>
                  <Dropdown.Item
                    icon="edit"
                    onClick={() => setIsEditModalOpen(true)}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    icon="credit-card"
                    onClick={() => setGroupPaymentModalOpen(true)}
                  >
                    Payment
                  </Dropdown.Item>
                  <Dropdown.Item
                    icon="mail"
                    onClick={() => setSendGroupEmailOpen(true)}
                  >
                    Email
                  </Dropdown.Item>
                  {/* hide temporarily, may use this later
                  <Dropdown.Item icon="home" onClick={handleRemove}>
                    Move
                  </Dropdown.Item>
                  <Dropdown.ItemDivider />
                  <Dropdown.Item icon="minus-circle" onClick={handleArchive}>
                    Archive
                  </Dropdown.Item>
                  <Dropdown.Item icon="x-circle" onClick={handleDelete}>
                    Delete
                  </Dropdown.Item>
                  */}
                </>
              ]}
            />
          </Card.Options>
        </Card.Header>
        <Card.Body>
          {group.members.map((member) => {
            return (
              <div key={member.id}>
                <Button
                  block
                  color="primary"
                  className="registered-student mb-1 p-1 text-left d-flex align-items-center w-100"
                  size="sm"
                  title={`${member.first_name} ${member.last_name}`}
                  onClick={() => {
                    history.push(
                      `/events/registrations/${member.registration_id}/${member.id}`
                    )
                  }}
                >
                  <div className="d-block w-100">
                    <span>
                      <Icon name="user" className="mr-1" />
                      {`${member.first_name} ${member.last_name}`}
                    </span>
                  </div>
                </Button>
              </div>
            )
          })}
        </Card.Body>
        {group.group_options.length > 0 && (
          <Card.Footer>
            {group.group_options.map((option) => {
              return (
                <Grid.Row key={option.id}>
                  <Grid.Col>
                    <>
                      <p className="mb-0">
                        <strong>{option.name}</strong>
                      </p>
                      <p>
                        {formatMoney(Number(option.option_value))}{' '}
                        <span className="text-muted">
                          {option.fee_type.toLowerCase()}
                        </span>
                        {/*<Tag className="ml-3">*/}
                        {/*  {option.option_type_name.toUpperCase()}*/}
                        {/*</Tag>*/}
                      </p>
                    </>
                  </Grid.Col>
                </Grid.Row>
              )
            })}
          </Card.Footer>
        )}

        <Card.Footer>
          {isAdmin && (
            <Grid.Row>
              <Grid.Col>
                <Text className={'font-weight-bold float-right text-muted'}>
                  {formatMoney(
                    group.paymentDetails.total / 100,
                    group.paymentDetails.currency
                  )}
                </Text>
                <Text className="font-weight-bold">Group Total:</Text>
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Col>
              <Text
                className={
                  'font-weight-bold float-right ' +
                  (group.paymentDetails.totalPaid === 0 ? '' : 'text-success')
                }
              >
                {formatMoney(
                  (group.paymentDetails.totalPaid +
                    group.paymentDetails.totalComp) /
                    100,
                  group.paymentDetails.currency
                )}
              </Text>
              <Text className="font-weight-bold">Paid:</Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Text
                className={
                  'font-weight-bold float-right ' +
                  (group.paymentDetails.outstanding === 0
                    ? ''
                    : 'text-danger cursor-pointer')
                }
                onClick={() => {
                  if (isAdmin || isCoachSuperAdmin) {
                    setGroupPaymentModalOpen(true)
                  }
                }}
              >
                {formatMoney(
                  group.paymentDetails.outstanding / 100,
                  group.paymentDetails.currency
                )}
              </Text>
              <Text className="font-weight-bold">Balance:</Text>
            </Grid.Col>
          </Grid.Row>
        </Card.Footer>
      </Card>
      <RegistrationGroupFormModal
        isOpen={isEditModalOpen}
        toggle={setIsEditModalOpen}
        group={group}
        campId={group.camp_id}
      />
      <GroupEmailModalForm
        isOpen={sendGroupEmailOpen}
        toggle={() => setSendGroupEmailOpen(false)}
        group={group}
      />
      {stripeKey && (
        <Elements stripe={stripePromise}>
          <GroupPaymentModal
            amount={group.paymentDetails.outstanding / 100} // sum of per group option fees
            currency={group.paymentDetails.currency} //registration_currency
            isModalOpen={groupPaymentModalOpen}
            name={group.name}
            paymentFee={paymentFee}
            toggleModal={() => setGroupPaymentModalOpen(!groupPaymentModalOpen)}
            group={group}
            paymentDescription={`${group.team_name} ${group.camp_name}`} //Team name - Camp name
          />
        </Elements>
      )}
    </>
  )
}

export default GroupFinanceItem
