import React from 'react'
import Wrapper from '../layout'
import UserSettings from '../../components/User/UserSettings'
import { useQuery } from '@apollo/client'
import { GET_COACH_BY_ID } from '../../graphql/GET_COACH_BY_ID'
import { useRootStore } from '../../hooks'
import { EUserTypes } from '../../components/User/userTypes'

const CoachSettings = (props) => {
  const { currentUser } = useRootStore()
  const { data: coachData, loading: coachLoading } = useQuery(GET_COACH_BY_ID, {
    variables: {
      coach_id: currentUser?.id
    }
  })
  return (
    <Wrapper {...props} title="Settings">
      <div className="container">
        <UserSettings user={currentUser} type={EUserTypes.coach} />
      </div>
    </Wrapper>
  )
}

export default CoachSettings
