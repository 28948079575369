import EventRegistrationStatusCount from 'components/Calendar/EventRegistrationStatusCount'
import CalendarDuplicate from 'components/Calendar/Forms/CalendarDuplicate'
import { useRootStore } from 'hooks'
import { EEventStatus } from 'models/EventStore'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Avatar, Button, Card, Grid, Icon, Text } from 'tabler-react'
import useRouter from 'use-react-router'
import { formatMoney } from 'utils/numberFormat'
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType'
import useGetEventOrganizers from '../../modules/user/roles/hooks/useGetEventOrganizers'
import { Types } from '../../types/graphql'
import { useGetCoachById } from '../../modules/coach/hooks/useGetCoachById'

type CalendarEventSidebarProps = {
  isEditor: boolean
  event: Types.Camp
  isDuplicateModalOpen: boolean
  isRegistrationStatusConfirmed: boolean
  registrationOptions: Types.CampRegistrationOption[]
  handleSetIsModalOpen: (isModalOpen: boolean) => void
  toggleIsDuplicateModalOpen: () => void
}

const CalendarEventSidebar = ({
  isEditor,
  event,
  isDuplicateModalOpen,
  isRegistrationStatusConfirmed,
  registrationOptions,
  handleSetIsModalOpen,
  toggleIsDuplicateModalOpen
}: CalendarEventSidebarProps) => {
  const { history } = useRouter()
  const { isAdmin, isCoachSuperAdmin, isStudent } = useGetCurrentUserType()
  const { currentUser, currentCoachTeam } = useRootStore()
  const { coach } = useGetCoachById(currentUser.id)

  const isRegistered = useMemo(
    () =>
      event.registered_students.find(
        (reg) =>
          Number(reg.student_id) === currentUser?.id ||
          reg.student_id === Number(coach?.student_id)
      ),
    [event.registered_students, currentUser]
  )

  const { isOrganizer } = useGetEventOrganizers({ eventId: Number(event.id) })

  const showRegistrations = useMemo(() => {
    return (
      (isRegistrationStatusConfirmed || isAdmin || isCoachSuperAdmin) &&
      event.registration_list
    )
  }, [
    isRegistrationStatusConfirmed,
    event.registration_list,
    isAdmin,
    isCoachSuperAdmin
  ])

  const isRegistrationFull = useMemo(() => {
    return (
      event.registrations_max &&
      event.registered_students_count >= Number(event.registrations_max)
    )
  }, [event.registrations_max, event.registered_students_count])

  const isRegistrationEnabled = useMemo(() => {
    if (event.registration_status !== 'open') return false

    if (isRegistrationFull && !event.registration_waitlist) return false

    return true
  }, [
    event.registration_status,
    isRegistrationFull,
    event.registration_waitlist
  ])

  return (
    <Grid.Col lg={4} sm={12} xs="auto">
      <CalendarDuplicate
        event={event}
        toggleModal={toggleIsDuplicateModalOpen}
        isModalOpen={isDuplicateModalOpen}
        eventOptions={registrationOptions}
      />
      {event.status === EEventStatus.registrations && (
        <>
          <Card>
            <Card.Body>
              {event.camp_fees > 0 && (
                <Grid.Row className="mb-2">
                  <Grid.Col className="text-center">
                    <div className="h6">Event Fee</div>
                    <Button size="lg" color="white" className="text-success">
                      <strong>
                        {formatMoney(
                          event.camp_fees,
                          event.registration_currency ?? event.teamCurrency
                        )}
                      </strong>
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              )}
              {(event.default_jump_count || event.default_jump_price) && (
                <>
                  <Text className="text-secondary text-center mb-3">
                    {event.default_jump_count && (
                      <>
                        Expected Jumps:
                        <strong>{' ' + event.default_jump_count}</strong>
                      </>
                    )}
                    {event.default_jump_price &&
                      event.default_jump_count &&
                      ' | '}
                    {event.default_jump_price > 0 && 'Jump Tickets: '}
                    <strong>
                      {event.default_jump_price > 0 &&
                        formatMoney(
                          event.default_jump_price,
                          event.registration_currency ?? event.teamCurrency
                        )}
                    </strong>
                  </Text>
                </>
              )}
              {event.registration_type === 'slots' && (
                <>
                  <p className="text-secondary text-center mb-3">
                    <strong>{event.slot_duration} minute</strong> slots / Flyers
                    per slot: <strong>{event.students_per_slot}</strong>
                  </p>
                </>
              )}
              <Grid.Row>
                {!isStudent &&
                (moment(event.end).isBefore() ||
                  event.registration_status === 'closed') ? (
                  <Grid.Col>
                    <Button
                      block
                      color="gray"
                      icon="user-x"
                      className="mb-3"
                      disabled
                    >
                      Registration Closed
                    </Button>
                  </Grid.Col>
                ) : (
                  <>
                    {(isStudent || (coach && coach?.student_id)) && (
                      <>
                        {isRegistered ? (
                          <Grid.Col width={12}>
                            <Text className="text-center mt-3 mb-2">
                              Your Registration status:
                              <span
                                className={`ml-2 font-weight-bold${
                                  isRegistered
                                    ? ' text-' + isRegistered.status_color
                                    : ' text-danger'
                                }`}
                              >
                                {isRegistered.status}
                              </span>
                            </Text>
                            <Link
                              to={`/events/registrations/${isRegistered.user_registrations_id}/${isRegistered.student_id}`}
                            >
                              <Button
                                block
                                color={isRegistered.status_color ?? 'danger'}
                                className="mb-3"
                              >
                                View Registration
                              </Button>
                            </Link>
                          </Grid.Col>
                        ) : (
                          <>
                            {(isStudent || coach.student_id) && (
                              <Grid.Col width={12}>
                                {event.student_registration ? (
                                  <>
                                    {isRegistrationFull &&
                                      !event.registration_waitlist && (
                                        <Button
                                          block
                                          color="gray"
                                          icon="user-x"
                                          className="mb-3"
                                          disabled
                                        >
                                          Event Full
                                        </Button>
                                      )}
                                    {isRegistrationEnabled && (
                                      <Button
                                        block
                                        color="primary"
                                        icon="user-plus"
                                        className="mb-3"
                                        onClick={() => {
                                          if (isStudent || coach.student_id) {
                                            if (
                                              event.registration_status ===
                                              'open'
                                            )
                                              handleSetIsModalOpen(true)
                                          }
                                        }}
                                      >
                                        Register Now
                                      </Button>
                                    )}
                                  </>
                                ) : (
                                  <Button
                                    block
                                    color="primary"
                                    icon="user-x"
                                    className="mb-3"
                                    disabled
                                  >
                                    Registration Closed
                                  </Button>
                                )}
                              </Grid.Col>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {(isAdmin || currentCoachTeam || isOrganizer) &&
                      !coach?.student_id && (
                        <Grid.Col width={12}>
                          <Button
                            block
                            color="primary"
                            icon="user-plus"
                            className="mb-3 mt-2"
                            onClick={() => handleSetIsModalOpen(true)}
                            // enable always for isAdmin and isCoachSuperAdmin, disable always if NoTeam, disable for everyone else 1 day after event ends
                            disabled={
                              //  !currentCoachTeam ||
                              !isCoachSuperAdmin &&
                              moment().diff(moment(event.end), 'days') > 1
                            }
                          >
                            Register
                          </Button>
                        </Grid.Col>
                      )}
                  </>
                )}
              </Grid.Row>
              {event.status === 'Registrations' && !isStudent && isEditor && (
                <Grid.Row className="mt-3 border m-0 p-2 bg-light">
                  <Grid.Col>
                    <div className="text-secondary">
                      <Text className="float-right">
                        <Icon
                          name={
                            event.registration_status === 'open' &&
                            moment().diff(moment(event.end), 'days') < 1
                              ? 'check-circle'
                              : 'x-circle'
                          }
                          className={
                            event.registration_status === 'open' &&
                            moment().diff(moment(event.end), 'days') < 1
                              ? 'text-success'
                              : 'text-danger'
                          }
                        />
                      </Text>
                      Registration{' '}
                      <strong>
                        <span className="text-uppercase">
                          {event.registration_status ?? 'Closed'}
                        </span>
                      </strong>
                    </div>
                    {/* */}
                    <div className="text-secondary">
                      <Text className="float-right">
                        <Icon
                          name={
                            event.student_registration &&
                            moment().diff(moment(event.end), 'days') < 1
                              ? 'check-circle'
                              : 'x-circle'
                          }
                          className={
                            event.student_registration &&
                            moment().diff(moment(event.end), 'days') < 1
                              ? 'text-success'
                              : 'text-danger'
                          }
                        />
                      </Text>
                      Participants{' '}
                      <strong>
                        CAN{!event.student_registration && 'NOT'}
                      </strong>{' '}
                      Register
                    </div>

                    {event.registrations_max && (
                      <div className="text-secondary">
                        <Text className="float-right">
                          <Icon
                            name={
                              event.registration_waitlist
                                ? 'check-circle'
                                : 'x-circle'
                            }
                            className={
                              event.registration_waitlist
                                ? 'text-success'
                                : 'text-danger'
                            }
                          />
                        </Text>
                        Waitlist{' '}
                        <strong>
                          {event.registration_waitlist
                            ? 'enabled'
                            : 'disabled'}
                        </strong>
                      </div>
                    )}
                    <div className="text-secondary">
                      <Text className="float-right">
                        <Icon
                          name={
                            event.auto_approval ? 'check-circle' : 'x-circle'
                          }
                          className={
                            event.auto_approval
                              ? 'text-success'
                              : 'text-danger'
                          }
                        />
                      </Text>
                      Auto Approval{' '}
                      <strong>
                        {event.auto_approval ? 'enabled' : ' disabled'}
                      </strong>
                    </div>
                    {event.registration_type === 'slots' && (
                      <div className="text-secondary">
                        <Text className="float-right">
                          <Icon
                            name={
                              event.student_slots
                                ? 'check-circle'
                                : 'x-circle'
                            }
                            className={
                              event.student_slots
                                ? 'text-success'
                                : 'text-danger'
                            }
                          />
                        </Text>
                        Open Slot Selection
                      </div>
                    )}
                  </Grid.Col>
                </Grid.Row>
              )}
            </Card.Body>
          </Card>
          <Avatar.List className="mb-3 ml-1" stacked>
            {event.registered_students
              .filter((rs) => !!rs.profile_avatar)
              .slice(0, 15)
              .map((registeredStudent) => (
                <Avatar
                  key={registeredStudent.id}
                  imageURL={`/api/s3/uploads/${registeredStudent.profile_avatar}`}
                />
              ))}
            {event.registered_students_count > 15 && (
              <Avatar className>
                <Text.Small>+{event.registered_students_count - 15}</Text.Small>
              </Avatar>
            )}
          </Avatar.List>
          {isEditor && (
            <Card>
              <Card.Header>
                <Card.Title>
                  <span
                    className="cursor-pointer"
                    onClick={() =>
                      history.push(`/event/registration-list/${event.id}`)
                    }
                  >
                    Registrations
                  </span>
                </Card.Title>
                <Card.Options>
                  {(isAdmin || currentCoachTeam || isOrganizer) && (
                    <Button.List align="right">
                      <Button
                        icon="user-plus"
                        size="sm"
                        color="white"
                        className="text-success"
                        onClick={() => handleSetIsModalOpen(true)}
                        // enable always for isAdmin and isCoachSuperAdmin, disable always if NoTeam, disable for everyone else 1 week after event ends
                        disabled={
                          !currentCoachTeam ||
                          (!isCoachSuperAdmin &&
                            moment().diff(moment(event.end), 'days') > 7)
                        }
                      />
                      <Button
                        icon="users"
                        size="sm"
                        color="white"
                        className="text-info"
                        onClick={() =>
                          //  history.push(`/event/groups/${event.id}`)
                          history.push(`/event/registration-list/${event.id}`)
                        }
                      />
                      {showRegistrations && (
                        <Button
                          icon="user-check"
                          size="sm"
                          color="white"
                          className="text-primary"
                          onClick={() => {
                            history.push(`/event/participants/${event.id}`)
                          }}
                        />
                      )}
                    </Button.List>
                  )}
                </Card.Options>
              </Card.Header>
              <EventRegistrationStatusCount event={event} />
            </Card>
          )}
          {isAdmin && (
            <>
              <Card>
                <Card.Body>
                  {event.registration_type && (
                    <p className="text-secondary">
                      Registration Type: {event.registration_type}
                    </p>
                  )}
                  <p className="text-secondary">
                    {'Deposit Fee: ' +
                      formatMoney(
                        event.deposit_fee,
                        event.registration_currency ?? event.teamCurrency
                      )}
                  </p>
                  <p className="text-secondary">
                    {'Registration Fee: ' +
                      formatMoney(
                        event.registration_fee,
                        event.registration_currency ?? event.teamCurrency
                      )}
                  </p>
                  <p className="text-secondary">
                    {'Event Fee: ' +
                      formatMoney(
                        event.camp_fees,
                        event.registration_currency ?? event.teamCurrency
                      )}
                  </p>
                </Card.Body>
              </Card>
            </>
          )}
        </>
      )}
    </Grid.Col>
  )
}

export default CalendarEventSidebar
