import React, { useContext, useEffect, useState } from 'react'
import Modal from '../../../components/Modal'
import { Button } from 'tabler-react'
import { useQuery } from '@apollo/client'
import { GET_STUDENT_BY_ID } from '../../../graphql/GET_STUDENT_BY_ID'
import { UserStoreContext } from '../../../contexts/userStoreContext'
import UserDetails from '../../../components/User/UserDetails'
import UserRegistration from '../../../components/User/UserRegistration'

interface RegistrationDetailsModalProps {
  open: boolean
  toggle: () => void
  studentId: number
  registrationId: number
}

const RegistrationDetailsModal = ({
  open,
  toggle,
  studentId,
  registrationId
}: RegistrationDetailsModalProps) => {
  const userStore = useContext(UserStoreContext)
  const [display, setDisplay] = useState('registration')
  const { user } = userStore
  const { data, loading } = useQuery(GET_STUDENT_BY_ID, {
    variables: {
      student_id: studentId
    }
  })

  useEffect(() => {
    if (data && !loading) {
      userStore.detach(user)
      userStore.loadUser(data.getStudentById)
    }
  }, [data, loading])
  return (
    <Modal
      maxWidth={'xl'}
      title={`${display === 'registration' ? 'Registration' : 'User'} Details`}
      content={
        <>
          {display === 'registration' && (
            <>
              <UserRegistration
                userRegIdProp={registrationId}
                studentIdProp={studentId}
              />
            </>
          )}
          {display === 'user' && (
            <>
              <UserDetails />
            </>
          )}
        </>
      }
      open={open}
      onClose={toggle}
      actions={
        <>
          <Button size={'sm'} color={'white'} pill onClick={toggle}>
            Close
          </Button>
          <Button
            size={'sm'}
            color={`${display === 'user' ? 'primary' : 'secondary'}`}
            pill
            onClick={() => setDisplay('user')}
          >
            User Details
          </Button>
          <Button
            size={'sm'}
            color={`${display === 'registration' ? 'primary' : 'secondary'}`}
            pill
            onClick={() => setDisplay('registration')}
          >
            Registration
          </Button>
        </>
      }
    />
  )
}

export default RegistrationDetailsModal
