import { useMutation, useQuery } from '@apollo/client'
import GearJobModalForm from 'components/Gear/Modal/GearJobModalForm'
import InvoiceDetailJobItem from 'components/Invoices/InvoiceDetailJobItem'
import InvoiceDetailProductItem from 'components/Invoices/InvoiceDetailProductItem'
import AddInvoiceItemModalForm from 'components/Invoices/Modals/AddInvoiceItemModalForm'
import { Loading } from 'components/Loading'
import { EUserTypes } from 'components/User/userTypes'
import { UserStoreContext } from 'contexts/userStoreContext'
import { DELETE_INVOICE_ITEM } from 'graphql/DELETE_INVOICE_ITEM'
import { GET_INVOICE } from 'graphql/GET_INVOICE'
import { GET_INVOICE_ITEMS } from 'graphql/GET_INVOICE_ITEMS'
import { GET_JOB_LIST } from 'graphql/GET_JOB_LIST'
import { GET_INVOICE_ITEMS as GET_INVOICE_ITEMS_TYPE } from 'graphql/types/GET_INVOICE_ITEMS'
import { GET_JOB_LIST as GET_JOB_LIST_TYPE } from 'graphql/types/GET_JOB_LIST'
import { UPDATE_INVOICE } from 'graphql/UPDATE_INVOICE'
import { UPDATE_JOB } from 'graphql/UPDATE_JOB'
import { useRootStore } from 'hooks'
import { observer } from 'mobx-react'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import InvoicePaymentButton from 'modules/invoice/components/buttons/InvoicePaymentButton'
import InvoiceNotes from 'modules/invoice/components/cards/InvoiceNotes'
import PrintInvoiceButton from 'modules/invoice/components/PrintInvoiceButton'
import InvoicePaymentsTable from 'modules/invoice/components/tables/InvoicePaymentsTable'
import useGetInvoice from 'modules/invoice/hooks/useGetInvoice'
import moment from 'moment'
import { useContext, useState } from 'react'
import {
  Button,
  Card,
  GalleryCard,
  Grid,
  Icon,
  Table,
  Text
} from 'tabler-react'
import useReactRouter from 'use-react-router'
import { formatMoney } from 'utils/numberFormat'
import { formatPhoneNumber, stripPhoneNumber } from 'utils/phoneFormat'
import SendEmailInvoiceButton from '../../modules/invoice/components/buttons/SendEmailInvoiceButton'
import { Types } from '../../types/graphql'
import InvoiceActivityListModal from './Modals/InvoiceActivityListModal'
import VenmoButton from '../../modules/common/components/buttons/VenmoButton'
import PaypalButton from '../../modules/common/components/buttons/PaypalButton'

type InvoiceDetailsProps = {
  invoiceId: number
}

const InvoiceDetails = ({ invoiceId }: InvoiceDetailsProps) => {
  const { data, loading }: { data: any; loading: boolean } =
    useGetInvoice(invoiceId)

  if (loading || !data) {
    return <Loading />
  }

  const invoice = data.invoice
  const pdfDetails = {
    company_name: invoice.company_name,
    company_phone: invoice.company_phone,
    company_email: invoice.company_email,
    customer_name: invoice.customer_name,
    customer_phone: invoice.customer_phone,
    customer_email: invoice.customer_email,
    created_on: invoice.created_on,
    invoice_type: invoice.invoice_type_id,
    invoice_terms: invoice.team.invoice_terms
  }

  return (
    <InvoiceDetailsInner
      invoiceId={invoiceId}
      invoice={invoice}
      pdfDetails={pdfDetails}
    />
  )
}

const InvoiceDetailsInner = ({
  invoiceId,
  invoice,
  pdfDetails
}: {
  invoiceId: number
  invoice: Types.Invoice
  pdfDetails: any
}) => {
  const { currentUser } = useRootStore()

  const { isStudent, isAdmin } = useGetCurrentUserType()
  const { location } = useReactRouter()
  const userStore = useContext(UserStoreContext)
  const { user } = userStore

  const { history } = useReactRouter()

  const [isOpen, setIsopen] = useState(false)
  const [isAddItemOpen, setIsAddItemOpen] = useState(false)

  const { data: jobsData, refetch: refetchJobsList } =
    useQuery<GET_JOB_LIST_TYPE>(GET_JOB_LIST, {
      variables: {
        jobListFilter: {
          invoice_id: Number(invoiceId)
        }
      }
    })

  const { data: items, refetch: refetchInvoiceItems } =
    useQuery<GET_INVOICE_ITEMS_TYPE>(GET_INVOICE_ITEMS, {
      variables: {
        invoice_id: Number(invoiceId)
      }
    })

  const total = invoice.paymentDetail.total / 100
  const outstanding = invoice.paymentDetail.outstanding / 100
  const isInvoicePayable = invoice.invoice_type_id === 1

  const routeToStudent = (student) => {
    userStore.detach(user)
    userStore.loadUser(student)
    history.push('/user-details')
  }

  const [updateInvoice] = useMutation(UPDATE_INVOICE)

  const handleClick = async () => {
    if (invoice.status === 'Archived') {
      return false
    }

    if (window.confirm('Archive this invoice?'))
      await updateInvoice({
        variables: {
          updateInvoiceInput: {
            id: invoiceId,
            status: 'Archived'
          }
        },
        refetchQueries: [
          {
            query: GET_INVOICE,
            variables: {
              id: invoiceId
            }
          }
        ]
      })
  }

  const [deleteInvoiceItem] = useMutation(DELETE_INVOICE_ITEM)
  const [updateJob] = useMutation(UPDATE_JOB)
  const serviceOrderClickHandle = async () => {
    jobsData?.jobsList.map(async (item) => {
      if (
        await deleteInvoiceItem({
          variables: {
            itemId: item.id
          }
        })
      ) {
        let status = ''
        if (item.rigger_id) {
          status = 'Active'
        } else {
          status = 'Open'
        }

        await updateJob({
          variables: {
            updateJobInput: {
              id: item.id,
              status: status
            }
          }
        })
      }
    })
    refetchJobsList()
    refetchInvoiceItems()
  }

  const isModal = location.pathname.includes('/rigger/job-gear-storage')
  const [isInvoiceEventsOpen, setIsInvoiceEventsOpen] = useState(false)

  const invoiceStatus = outstanding <= 0 ? 'Paid' : invoice.status
  return (
    <>
      {!isAdmin && (
        <>
          {isOpen && (
            <GearJobModalForm
              isModalOpen={isOpen}
              toggleModal={setIsopen}
              userGearJob={{
                student_id: invoice.student_id,
                student: invoice.student,
                status: 'Invoice',
                invoice_id: invoiceId,
                invoice_total: total
              }}
            />
          )}
          {isAddItemOpen && (
            <AddInvoiceItemModalForm
              invoice={invoice}
              toggleModal={setIsAddItemOpen}
              isModalOpen={isAddItemOpen}
            />
          )}
        </>
      )}

      <InvoiceActivityListModal
        invoiceId={Number(invoiceId)}
        isOpen={isInvoiceEventsOpen}
        setIsOpen={setIsInvoiceEventsOpen}
      />

      <div className="d-flex flex-column">
        <div className="d-flex flex-column gap-4">
          <div className="d-flex justify-content-between noPrint">
            <h2 className="mb-0">
              {currentUser.type === EUserTypes.admin &&
                invoice.invoice_type_id === 2 && (
                  <>
                    <Button
                      icon="chevron-down"
                      color="info"
                      size="md"
                      className="mr-5"
                      disabled
                    >
                      Make Invoice
                    </Button>
                    <Button
                      icon="chevron-right"
                      color="info"
                      size="md"
                      className="mr-5"
                      disabled={jobsData?.jobsList.length === 0}
                      onClick={() => serviceOrderClickHandle()}
                    >
                      Service Orders
                    </Button>
                  </>
                )}
              {invoice.invoice_type_id === 2 &&
                jobsData?.jobsList.length === 0 &&
                currentUser.type !== EUserTypes.student && (
                  <Button
                    icon="x-circle"
                    color="secondary"
                    size="md"
                    className="ml-2 text-danger"
                    onClick={() => handleClick()}
                  >
                    {invoice.status}
                  </Button>
                )}
              {jobsData?.jobsList.length === 0 &&
                invoice.invoice_type_id === 1 &&
                currentUser.type !== EUserTypes.student && (
                  <Button
                    color={
                      invoice.status === 'Archived' ? 'danger' : 'secondary'
                    }
                    size="md"
                    className=""
                    onClick={() => handleClick()}
                    disabled
                  >
                    {invoice.status}
                  </Button>
                )}
              {jobsData?.jobsList.length === 0 &&
                invoice.invoice_type_id === 1 &&
                currentUser.type !== EUserTypes.student && (
                  <Button
                    icon="x-circle"
                    color="secondary"
                    size="md"
                    className="ml-2 text-danger"
                    onClick={() => handleClick()}
                  />
                )}
              {jobsData?.jobsList.length !== 0 &&
                invoice.invoice_type_id === 1 && (
                  <Button
                    color={invoiceStatus === 'Paid' ? 'success' : 'secondary'}
                    size="md"
                    className="d-print-none"
                    disabled
                  >
                    {invoiceStatus}
                  </Button>
                )}
            </h2>

            <Button.List className="d-flex gap-2">
              {isAdmin && (
                <Button
                  icon="activity"
                  size="md"
                  color="secondary"
                  className="d-print-none"
                  onClick={() => setIsInvoiceEventsOpen(true)}
                />
              )}
              {pdfDetails && items?.invoiceItems && (
                <PrintInvoiceButton
                  invoiceId={invoice.id}
                  invoiceItems={items?.invoiceItems}
                  pdfDetails={pdfDetails}
                  total={total}
                />
              )}

              {currentUser.type !== EUserTypes.student && (
                <>
                  <SendEmailInvoiceButton
                    invoiceId={invoiceId}
                    invoice={invoice}
                  />
                </>
              )}
            </Button.List>
          </div>
          <Card>
            <Card.Body>
              <Grid.Row>
                <Grid.Col width={12}>
                  {invoice.team.team_logo_wide_image && (
                    <GalleryCard className="border-0">
                      <GalleryCard.Image
                        className="product-image invoice-image"
                        src={`/api/s3/uploads/${invoice.team.team_logo_wide_image}`}
                      />
                    </GalleryCard>
                  )}
                  <Text>
                    <h4 className="ml-2">{invoice?.company_name}</h4>
                    <Button
                      icon="phone"
                      color="white"
                      size="sm"
                      className="d-block"
                      disabled
                    >
                      {formatPhoneNumber(
                        stripPhoneNumber(invoice?.company_phone ?? '')
                      )}
                    </Button>
                    <Button
                      icon="mail"
                      color="white"
                      size="sm"
                      className="d-block"
                      disabled
                    >
                      {invoice?.company_email}
                    </Button>
                  </Text>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row className="ml-0 mb-7 mt-7">
                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                  {invoice.invoice_type_id === 1 && <h3>INV: {invoice.id}</h3>}
                  {invoice.invoice_type_id === 2 && <h3>ESTIMATE</h3>}
                  <Text>
                    {moment(invoice?.created_on).format('D MMMM, YYYY')}
                  </Text>
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={6} lg={6}>
                  <Card className="bg-light border-0">
                    <Card.Body>
                      <h4
                        className={'ml-2 cursor-pointer'}
                        onClick={() => {
                          if (isStudent) {
                            history.push('/user/settings')
                            return
                          } else {
                            routeToStudent(invoice.student)
                          }
                        }}
                      >
                        {invoice?.customer_name}
                      </h4>
                      <Text className="d-block">
                        {invoice?.customer_phone && (
                          <Button
                            icon="phone"
                            color="light"
                            size="sm"
                            className="d-block"
                            disabled
                          >
                            {invoice?.customer_phone}
                          </Button>
                        )}
                      </Text>
                      <Text className="d-block">
                        {invoice?.customer_email && (
                          <Button
                            icon="mail"
                            color="light"
                            size="sm"
                            className="d-block"
                            disabled
                          >
                            {invoice?.customer_email}
                          </Button>
                        )}
                      </Text>
                    </Card.Body>
                  </Card>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col xl={12}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.ColHeader>PRODUCT/SERVICE</Table.ColHeader>
                        <Table.ColHeader></Table.ColHeader>
                        <Table.ColHeader className="text-center">
                          UNIT
                        </Table.ColHeader>
                        <Table.ColHeader className="text-center">
                          QTY
                        </Table.ColHeader>
                        <Table.ColHeader className="text-right pr-7">
                          AMOUNT
                        </Table.ColHeader>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {items?.invoiceItems.map((item: Types.InvoiceItem) => {
                        if (item?.job_id) {
                          return (
                            <InvoiceDetailJobItem
                              key={item.job.id}
                              job={item.job}
                              invoice={invoice}
                            />
                          )
                        } else {
                          return (
                            <InvoiceDetailProductItem
                              key={item.product_id}
                              product={item.product}
                              invoice_amount={total}
                              invoice_id={invoiceId}
                            />
                          )
                        }
                      })}
                      {currentUser.type === EUserTypes.rigger && (
                        <Table.Row>
                          <Table.Col>
                            {!isModal && (
                              <Button
                                className="m-2 d-print-none"
                                color="secondary"
                                size="sm"
                                icon="plus"
                                onClick={() => setIsopen(true)}
                              >
                                JOB
                              </Button>
                            )}
                          </Table.Col>
                          <Table.Col></Table.Col>
                          <Table.Col></Table.Col>
                          <Table.Col></Table.Col>
                          <Table.Col></Table.Col>
                        </Table.Row>
                      )}
                      <Table.Row>
                        <Table.Col></Table.Col>
                        <Table.Col></Table.Col>
                        <Table.Col></Table.Col>
                        <Table.Col className={'text-right'}>Subtotal</Table.Col>
                        <Table.Col className={'text-right'}>
                          {formatMoney(invoice.invoice_amount)}
                          <Button
                            color="white"
                            size="sm"
                            className={`ml-3 pt-0 text-muted`}
                            disabled
                          />
                        </Table.Col>
                      </Table.Row>

                      {!isStudent && !isAdmin && (
                        <Table.Row>
                          <Table.Col>
                            {!isModal && (
                              <Button
                                className="m-2 d-print-none"
                                color="secondary"
                                size="sm"
                                icon="plus"
                                onClick={() => setIsAddItemOpen(true)}
                              >
                                ITEM
                              </Button>
                            )}
                          </Table.Col>
                          <Table.Col></Table.Col>
                          <Table.Col></Table.Col>
                          <Table.Col></Table.Col>
                          <Table.Col></Table.Col>
                        </Table.Row>
                      )}

                      <Table.Row>
                        <Table.Col></Table.Col>
                        <Table.Col></Table.Col>
                        <Table.Col></Table.Col>
                        <Table.Col className={'text-right'}>
                          <h4>TOTAL</h4>
                        </Table.Col>
                        <Table.Col className={'text-right pr-7'}>
                          <h4>{formatMoney(total)}</h4>
                        </Table.Col>
                      </Table.Row>

                      <Table.Row>
                        <Table.Col></Table.Col>
                        <Table.Col></Table.Col>
                        <Table.Col></Table.Col>
                        <Table.Col className={'text-right'}>
                          <h4>Outstanding</h4>
                        </Table.Col>
                        <Table.Col className={'text-right pr-7'}>
                          <h4>{formatMoney(outstanding)}</h4>
                        </Table.Col>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
          </Card>
        </div>
        {isInvoicePayable && (
          <>
            <Card>
              <Card.Header>
                <Card.Title>
                  <Icon name="credit-card" className="mr-2 ml-0 text-blue" />
                  Payments
                </Card.Title>
                {!isStudent && ''}
                <Card.Options>
                  <InvoicePaymentButton
                    invoice={invoice}
                    jobIds={jobsData?.jobsList.map((job) => job.id)}
                    total={outstanding}
                  />
                  {invoice.team?.venmo && (
                    <VenmoButton
                      id={invoice.id}
                      venmo={invoice.team.venmo}
                      outstanding={outstanding}
                    />
                  )}
                  {invoice.team?.paypal && (
                    <PaypalButton
                      paypal={invoice.team.paypal}
                      outstanding={outstanding}
                    />
                  )}
                </Card.Options>
              </Card.Header>
              <Card.Body className="">
                <InvoicePaymentsTable invoiceId={invoiceId} />
              </Card.Body>
            </Card>
          </>
        )}

        {!isStudent && invoice && <InvoiceNotes invoice={invoice} />}
      </div>
    </>
  )
}

export default observer(InvoiceDetails)
