import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { GET_TEAMS } from '../../../graphql/GET_TEAMS'
import { Card, Dimmer, Grid, List } from 'tabler-react'
import { Types } from '../../../types/graphql'

const TeamListLogo = ({ sportsList, onClickProp, selectedTeam }) => {
  // const { history } = useRouter()
  const { data, loading } = useQuery(GET_TEAMS, {
    variables: {
      filter: {
        isPublic: true,
        isPublished: true,
        sportsTypes: JSON.stringify(sportsList)
      }
    }
  })

  const orderTeamByBusinessType = (teams: Types.Team[]) => {
    return teams.reduce((acc, team) => {
      const keys = Object.keys(acc)
      if (!keys.includes(team.coach_business_type)) {
        acc[team.coach_business_type] = []
      }
      acc[team.coach_business_type].push(team)
      return acc
    }, {})
  }
  const coachBusinessTypeLabels: Record<string, string> = {
    coach: 'Coaching',
    dzm: 'Dropzones',
    events: 'Event Organizers',
    rigger: 'Riggers',
    wtm: 'Wind Tunnel'
    // Add other keys here...
  }
  const teamsByCoachBusiness = useMemo(() => {
    if (data && !loading) {
      return orderTeamByBusinessType(data.teams || [])
    }
    return []
  }, [data, loading])
  return (
    <Dimmer loader={loading} active={loading}>
      {Object.keys(teamsByCoachBusiness).length > 0 && (
        <Card title={'Teams'}>
          <Card.Body>
            <Grid.Row>
              <Grid.Col width={12}>
                {Object.keys(teamsByCoachBusiness).map((key) => (
                  <React.Fragment key={key}>
                    <strong style={{ display: 'block', marginTop: '1rem' }}>
                      {coachBusinessTypeLabels[key] || key}
                    </strong>
                    <List unstyled>
                      {teamsByCoachBusiness[key].map((team: Types.Team) => (
                        <List.Item
                          key={team.id}
                          className={`${
                            selectedTeam === team.id ? 'bg-azure' : ''
                          }`}
                        >
                          <span
                            onClick={() => {
                              if (selectedTeam === team.id) onClickProp(null)
                              else onClickProp(team.id)
                            }}
                          >
                            {team.name}
                          </span>
                        </List.Item>
                      ))}
                    </List>
                  </React.Fragment>
                ))}
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
        </Card>
      )}
    </Dimmer>
  )
}

export default TeamListLogo
