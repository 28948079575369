import React from 'react'
import useReactRouter from 'use-react-router'
import AsyncSelect from 'react-select/async'
import { Tag, Text } from 'tabler-react'
import { GET_TEAMS_BY_FILTER_QUERY } from '../../../../graphql/GET_TEAMS_BY_FILTER_QUERY'
import { useAutocomplete } from '../../../../hooks/useAutocomplete'
import { Types } from 'types/graphql'
import autoCompleteStyles from '../../../../components/Students/Forms/autocompleteStyles'
import { applySnapshot } from 'mobx-state-tree'
import { useLazyQuery } from '@apollo/client'
import { GET_COACH_TEAM_BY_ID } from '../../../../graphql/GET_COACH_TEAM_BY_ID'
import { useRootStore } from '../../../../hooks'

const TeamSearchMenu = ({ toggle }: { toggle?: () => void }) => {
  const { history } = useReactRouter()
  const rootStore = useRootStore()
  const { currentUser } = rootStore
  const { loadOptions } = useAutocomplete({
    query: GET_TEAMS_BY_FILTER_QUERY,
    minInputLength: 1
  })

  const [getCoachTeam, { data, loading }] = useLazyQuery(GET_COACH_TEAM_BY_ID)
  
  return (
    <>
      <AsyncSelect
        backspaceRemovesValue={true}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null
        }}
        autoFocus={true}
        escapeClearsValue={true}
        getOptionLabel={(option: Types.TeamSearch) => {
          return (
            <Text className="cursor-pointer">
              {option?.id ? (
                <>
                  <span
                    className={
                      option.is_published ? 'text-primary' : 'text-secondary'
                    }
                  >
                    {option?.name}
                  </span>
                  <Tag
                    color={option.is_public ? 'primary' : 'secondary'}
                    className="float-right"
                  >
                    {option?.id}
                  </Tag>
                  <Text.Small className="d-block text-muted">
                    {option.slug}
                  </Text.Small>
                </>
              ) : (
                ''
              )}
            </Text>
          )
        }}
        getOptionValue={(option: Types.TeamSearch) => option.id}
        isClearable={true}
        loadOptions={loadOptions}
        onChange={(e) => {
          if (e) {
            getCoachTeam({ variables: { teamId: Number(e.id) } }).then(
              ({ data }) => {
                applySnapshot(rootStore, {
                  ...rootStore,
                  currentCoachTeam: { ...data.getCoachTeamById },
                  currentUser: {
                    ...currentUser,
                    type:
                      data.getCoachTeamById?.coach_business_type ??
                      currentUser?.type
                  }
                })
                history.push('/')
              }
            )
          }
        }}
        onMenuClose={toggle}
        placeholder="Search Teams"
        styles={autoCompleteStyles}
      />
    </>
  )
}

export default TeamSearchMenu
