import { useMutation } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { Dropdown } from 'tabler-react'
import UserCardDropdown from '../../../../../components/User/UserCardDropdown'
import { UPDATE_STUDENT_MUTATION } from '../../../../../graphql/UPDATE_STUDENT_MUTATION'
import { useRootStore } from '../../../../../hooks'

type AvatarUploadProps = {
  profileAvatar?: string
  studentId?: number
  className?: string
  refetchQueries?: string[]
}

/**
 * Only use this to update student Avatar
 * @param profileAvatar
 * @param studentId
 * @param className
 * @param refetchQueries
 * @constructor
 */
const AvatarUpload = ({
  profileAvatar,
  studentId,
  className,
  refetchQueries
}: AvatarUploadProps) => {
  const rootStore = useRootStore()

  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION, {
    onCompleted: () => toast.success('Avatar Updated.')
  })

  const onError = (error) => {
    toast.error(error)
    rootStore.setLoading(false)
  }

  const onFinished = (response: { fileKey: string }) => {
    updateAvatar(response.fileKey)
    rootStore.setLoading(false)
  }

  const updateAvatar = async (fileKey: string | null) => {
    await updateStudent({
      variables: {
        student: {
          id: studentId,
          profile_avatar: fileKey
        }
      },
      refetchQueries: refetchQueries
    })
  }
  return (
    <Dropdown
      className={`cursor-pointer avatar-dropdown ${className}`}
      icon="camera"
      items={
        <UserCardDropdown
          rootStore={rootStore}
          imageName={profileAvatar}
          onErrorHandler={onError}
          onFinishedHandler={onFinished}
          callBack={updateAvatar}
        />
      }
      toggle={false}
    />
  )
}

export default AvatarUpload
