import React from 'react';
import { Dropdown, Nav } from 'tabler-react';
import { Link } from 'react-router-dom';
import StudentSearchForm from 'components/Students/Forms/StudentSearchForm';
import FeatureAccessGate from 'modules/common/components/FeatureAccessGate';


const PromoterNav = () => {
  return (
    <>
      <Nav.Item icon='home' to='/' value='/' />
      <Nav.Item icon="users" to="/fighter/search" value="Fighter Search" />
      <FeatureAccessGate feature="feature_products">
        <Nav.Item icon="shopping-bag" to="/products" value="Products" />
      </FeatureAccessGate>
      <li className="nav-item ml-lg-auto mb-4 mb-lg-0">
        <Dropdown
          toggle={false}
          icon="calendar"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/calendar" className="dropdown-item d-none d-md-block">
                Calendar
              </Link>
              <Link to="/events" className="dropdown-item">
                Events
              </Link>
            </>
          }
        />
        <Dropdown
          toggle={false}
          icon="pie-chart"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <FeatureAccessGate feature="feature_invoices">
                <Link to="/invoices" className="dropdown-item">
                  Invoices
                </Link>
              </FeatureAccessGate>
                <Link to="/payments" className="dropdown-item">
                  Payments
                </Link>
              <FeatureAccessGate feature="feature_app_reports">
                  <Link to="/dzm/reports" className="dropdown-item">
                    Reports
                  </Link>
              </FeatureAccessGate>
              <FeatureAccessGate feature="feature_accounting">
                <Link to="/transactions" className="dropdown-item">
                  Transactions
                </Link>
              </FeatureAccessGate>
            </>
          }
        />
        <Dropdown
          toggle={false}
          icon="users"
          position="bottom-end"
          flex
          className="mr-4 ml-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/fighters" className="dropdown-item">
                Fighters
              </Link>
              <Link to="/fighter/search" className="dropdown-item">
                Fighter Search
              </Link>
              <Link to="/staff" className="dropdown-item">
                TEAM Accounts
              </Link>
            </>
          }
        />
        <StudentSearchForm />
      </li>
    </>
  );
};

export default PromoterNav;
