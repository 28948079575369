export const teamFeatureOpts = [
  {
    label: "Coach Account",
    value: "feature_coach_account",
  },

  {
    label: "DZM Account",
    value: "feature_dzm_account",
  },
];

export const teamAccessOpts = [
  {
    label: "Accounting",
    value: "feature_accounting",
  },
  {
    label: "Alpha (Test Features)",
    value: "feature_alpha",
  },
  {
    label: "Booking Calendar",
    value: "feature_booking_calendar",
  },
  {
    label: "Camps/Events",
    value: "feature_camps",
  },
  {
    label: "Event Registrations",
    value: "feature_registrations",
  },
  {
    label: "CRM",
    value: "feature_crm",
  },
  {
    label: "Invoicing",
    value: "feature_invoices",
  },
  {
    label: "Manifest",
    value: "feature_manifest",
  },
  {
    label: "Manually Add Students",
    value: "feature_open_add_students",
  },
  {
    label: "Reports",
    value: "feature_app_reports",
  },
  {
    label: "Slot Registrations",
    value: "feature_slot_registrations",
  },
  {
    label: "Waivers",
    value: "feature_waivers",
  },
];

export const teamPermissionOpts = [
  {
    label: "Account Transfers",
    value: "feature_transfer_time",
  },
  {
    label: "Cancel Transactions",
    value: "feature_cancel_transactions",
  },
  {
    label: "Dashboard",
    value: "feature_dashboard",
  },
  {
    label: "Dashboard Groups",
    value: "feature_dashboard_groups",
  },
  {
    label: "Disable Students",
    value: "feature_disable_students",
  },
  {
    label: "Edit Transactions",
    value: "feature_edit_transactions",
  },
  {
    label: "Lock Account Actions",
    value: "feature_locked_account_actions",
  },
  {
    label: "Open Scheduling",
    value: "feature_schedule_open",
  },
  {
    label: "Paid Status (App Only/Depreciated)",
    value: "feature_paid_status",
  },
  {
    label: "Payment Proccessing",
    value: "feature_payments",
  },
  {
    label: "Product Management",
    value: "feature_products",
  },
  {
    label: "Recurring Transactions",
    value: "feature_recurring_transactions",
  },
  {
    label: "Skills Progression",
    value: "feature_student_progression",
  },
  {
    label: "Student Checkin",
    value: "feature_student_checkin",
  },
  {
    label: "Student Account Payments",
    value: "feature_student_account_payments",
  },
];

export const teamRegistrationOpts = [
  {
    label: "Require Merits (USPA)",
    value: "feature_require_uspa",
  },
  {
    label: "Require User Contact Information",
    value: "student_contact_required",
  },
];
