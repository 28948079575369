import { useMutation, useQuery } from '@apollo/client'
import { observer } from 'mobx-react'
import useGetCurrentUserType, {
  useGetUserIsStudent
} from 'modules/common/hooks/useGetCurrentUserType'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, Icon, Text } from 'tabler-react'
import { ADD_STUDENT_TEAM_PROGRESSIONS } from '../../graphql/ADD_STUDENT_TEAM_PROGRESSIONS'
import { GET_STUDENT_TEAM_PROGRESSION } from '../../graphql/GET_STUDENT_TEAM_PROGRESSION'
import { useRootStore } from '../../hooks'
import ToggleUserRegistrationScorecard from '../../modules/user/registrations/components/switch/ToggleUserRegistrationScorecard'
import { Types } from '../../types/graphql'
import PrintStudentSkillsButton from './Pdf/PrintStudentSkillsButton'
import StudentTeamProgressionsHeading from './StudentTeamProgressionHeading'

interface IStudentTeamProgressionsProps {
  studentId: number
  teamId: number
  isModal: boolean
  userRegistrationDetails?: any
}

// Function to convert URL to base64
const TeamLogoToData = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );

const StudentTeamProgressions = ({
  studentId,
  teamId,
  isModal,
  userRegistrationDetails,
}: IStudentTeamProgressionsProps) => {
  const { currentCoachTeam } = useRootStore();
  const { isAdmin, isCoachSuperAdmin } = useGetCurrentUserType();
  const isStudent = useGetUserIsStudent();
  const [activeSkills, setActiveSkills] = useState<string>("");
  const [logoBase64, setLogoBase64] = React.useState(null);
  const studentProgressionData = {
    team_id: userRegistrationDetails?.camp_id ? undefined : Number(teamId),
    student_id: Number(studentId),
    camp_id: userRegistrationDetails?.camp_id
      ? Number(userRegistrationDetails?.camp_id)
      : undefined,
  };
  const { data: studentTeamProgressionsData, loading } = useQuery(
    GET_STUDENT_TEAM_PROGRESSION,
    {
      variables: {
        studentProgressionInput: {
          teamId: Number(teamId),
          studentId: Number(studentId),
          campId: userRegistrationDetails?.camp_id
            ? Number(userRegistrationDetails?.camp_id)
            : undefined,
        },
      },
    },
  );

  React.useEffect(() => {
    if (!isStudent || !isAdmin)
      TeamLogoToData(
        `/api/s3/uploads/${currentCoachTeam?.team_logo_header}`,
      ).then((dataUrl) => {
        setLogoBase64(dataUrl);
      });
  }, [currentCoachTeam?.team_logo_header]);

  const studentDetails = useMemo(() => {
    if (userRegistrationDetails) {
      return {
        studentName: `${userRegistrationDetails.first_name} ${userRegistrationDetails.last_name}`,
        teamLogo: logoBase64,
        teamName: userRegistrationDetails?.team_name,
        campName: userRegistrationDetails.camp_name,
        campLocation: userRegistrationDetails.campLocation,
        campDuration: `${moment
          .utc(userRegistrationDetails.start)
          .format("MMM-DD")} - ${moment
          .utc(userRegistrationDetails.end)
          .format("MMM-DD")}`,
      };
    }
    return null;
  }, [userRegistrationDetails]);

  const [addStudentTeamProgressions] = useMutation(
    ADD_STUDENT_TEAM_PROGRESSIONS,
  );

  const progressions: { string: Types.GET_STUDENT_TEAM_PROGRESSIONQuery } =
    useMemo(() => {
      if (!loading && studentTeamProgressionsData?.getStudentTeamProgressions) {
        return studentTeamProgressionsData?.getStudentTeamProgressions.reduce(
          function (storage, item) {
            const key = item?.heading;
            storage[key] = storage[key] || [];
            storage[key].push(item);
            return storage;
          },
          {},
        );
      }

      return [];
    }, [studentTeamProgressionsData, loading]);

  useEffect(() => {
    if (Object.keys(progressions).length === 1 && !activeSkills) {
      setActiveSkills(Object.keys(progressions)[0]);
    }
  }, [progressions]);

  const handleCreateProgressions = () => {
    const ids = progressions[activeSkills]
      .map((p: Types.StudentTeamProgression) => {
        if (!p.is_exist) return p.id;

        return null;
      })
      .filter((n) => n);

    if (window.confirm("Add skill set for this student?")) {
      addStudentTeamProgressions({
        variables: {
          addStudentProgressionsInput: {
            progression_ids: ids,
            status: 0,
            ...studentProgressionData,
          },
        },
        refetchQueries: ["GET_STUDENT_TEAM_PROGRESSION"],
      });
    }
  };

  return (
    <Card statusColor="blue">
      <Card.Header>
        <Card.Title>
          <Icon name="check-circle" className="mr-2 ml-0 text-primary" />
          {isModal ? 'Score Card' : 'Skills Progression'}
        </Card.Title>
        {(
          <Card.Options>
            <Button.List>
              {Object.keys(progressions).map((key) => (
                <Button
                  outline={key !== activeSkills}
                  key={'header-' + key}
                  icon={
                    key === activeSkills &&
                    progressions[key].filter(
                      (p: Types.StudentTeamProgression) => !p.is_exist
                    ).length > 0
                      ? 'plus'
                      : null
                  }
                  color={
                    key === activeSkills
                      ? 'primary'
                      : 'secondary'
                  }
                  size="sm"
                  className={"text-uppercase"}
                  onClick={() => {
                    if (key === activeSkills) {
                      if (
                        progressions[key].filter(
                          (p: Types.StudentTeamProgression) => !p.is_exist
                        ).length > 0
                      )
                        handleCreateProgressions()
                    } else setActiveSkills(key)
                  }}
                >
                  {key === activeSkills &&
                  progressions[key].filter(
                    (p: Types.StudentTeamProgression) => !p.is_exist
                  ).length > 0
                    ? 'SKILL SET'
                    : key}{' '}
                  {progressions[key].filter(
                    (p: Types.StudentTeamProgression) => p.is_exist
                  ).length > 0 && (
                    <span
                      className={key === activeSkills ? null : 'text-success'}
                    >
                      {(
                        (progressions[key].filter(
                          (p: Types.StudentTeamProgression) =>
                            p.is_exist && p.studentProgressionStatus === 1
                        ).length /
                          progressions[key].length) *
                        100
                      ).toFixed(0)}
                      %
                    </span>
                  )}
                </Button>
              ))}
              {activeSkills && (
                <Button
                  icon="x"
                  color="white"
                  size="sm"
                  onClick={() => {
                    setActiveSkills('')
                  }}
                />
              )}
            </Button.List>
          </Card.Options>
        )}
      </Card.Header>
      {Object.keys(progressions).map(
        (key) =>
          activeSkills === key &&
          !isStudent && (
            <StudentTeamProgressionsHeading
              campId={userRegistrationDetails?.camp_id}
              heading={key}
              studentProgressHeading={progressions[key]}
              key={key}
              studentId={studentId}
            />
          )
      )}
      <Text className="text-center">
        {activeSkills &&
          isModal &&
          progressions[activeSkills].filter(
            (p: Types.StudentTeamProgression) => p.is_exist
          ).length > 0 && (
            <>
              <PrintStudentSkillsButton
                heading={activeSkills}
                studentId={studentId}
                progression={progressions[activeSkills]}
                studentDetails={studentDetails}
              />
              {!isStudent && (
                <ToggleUserRegistrationScorecard
                  userRegistrationDetails={userRegistrationDetails}
                />
              )}
            </>
          )}
      </Text>
    </Card>
  )
};

export default observer(StudentTeamProgressions);
