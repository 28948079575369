import gql from 'graphql-tag'

export const GET_TEAM_REGISTRATION_HISTORY = gql`
  query GET_TEAM_REGISTRATION_HISTORY(
    $teamId: ID!
    $campId: ID!
    $start: String
  ) {
    getCampRegitrationHistory(teamId: $teamId, campId: $campId, start: $start) {
      csvData
    }
  }
`
