import gql from 'graphql-tag'

export const GET_CAMP_GROUP_PAYMENT_SUMMARY = gql`
  query GET_CAMP_GROUP_PAYMENT_SUMMARY($camp_id: Int!) {
    getCampGroupsPaymentSummary(camp_id: $camp_id) {
      outstanding
      total
      totalPaid
      totalFees
      totalComp
      currency
      haveBalance
      payments {
        id
        amount
        application_fee
        fees
        total
        camp_name
        card_brand
        card_country
        card_customer_name
        group_name
        group_id
        manual_payment
        processed_by
        status
        team_name
      }
    }
  }
`
