import { useMutation } from '@apollo/client'
import { EDIT_USER_REGISTRATION_QUESTION } from 'graphql/EDIT_USER_REGISTRATION_QUESTION'
import { GET_REGISTRATION_QUESTIONS } from 'graphql/GET_REGISTRATION_QUESTIONS'
import React, { useMemo, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { Button, Form, Text } from 'tabler-react'
import { ADD_USER_EVENT } from '../../../graphql/ADD_USER_EVENT'
import { GET_ALL_USER_REGISTRATION_EVENTS } from '../../../graphql/GET_ALL_USER_REGISTRATION_EVENTS'
import { Types } from '../../../types/graphql'
import { validateQuestionResponse } from '../../../utils/registrationQuestionUtil'
import { EUserEventsId } from '../UserEvents'
import { useGetStudentOverride } from '../../../modules/user/hooks/user'
import { toast } from 'react-toastify'

interface IUserRegistrationQuestionItemProp {
  question: Types.RegistrationQuestion
  userRegId: number
  studentId: number
  teamId: number
}

const UserRegistrationQuestionItem = ({
  question,
  userRegId,
  studentId,
  teamId
}: IUserRegistrationQuestionItemProp) => {
  const isStudentOverride = useGetStudentOverride(studentId)

  const [isEditting, setIsEditting] = useState(false)
  const [userAnswer, setUserAnswer] = useState(question.user_answer)

  const questionError = useMemo(() => {
    if (question.registration_question_type_id === 4)
      return `Answer must be at least ${question.answer_restriction}`

    if (question.registration_question_type_id === 5)
      return `Answer should not be more than ${question.answer_restriction}`

    if (question.registration_question_type_id === 6)
      return `Answer must be at least ${question.answer_restriction} characters`
    if (question.registration_question_type_id === 7)
      return `Answer should not be more than ${question.answer_restriction} characters`
  }, [])

  const [userEvent] = useMutation(ADD_USER_EVENT)
  const [updateUserRegistrationQuestion] = useMutation(
    EDIT_USER_REGISTRATION_QUESTION,
    {
      onCompleted: (result) => {
        toast.success('Question response updated successfully')
        if (result.updateUserRegistrationQuestion) {
          userEvent({
            variables: {
              userEvent: {
                user_event_type_id: EUserEventsId.reg_ques_updated,
                student_id: studentId,
                status: 'Updated',
                team_id: teamId,
                user_registration_id: userRegId
              }
            },
            refetchQueries: [
              {
                query: GET_ALL_USER_REGISTRATION_EVENTS,
                variables: {
                  filter: {
                    userRegistrationId: userRegId
                  },
                  limit: 20,
                  page: 1
                }
              }
            ]
          })
        }
      },
      refetchQueries: ['GET_REGISTRATION_QUESTIONS']
    }
  )
  const updateUserAnswer = () => {
    updateUserRegistrationQuestion({
      variables: {
        option: {
          id: question?.user_registration_question_id || null,
          registration_id: userRegId,
          registration_questions_id: question.id,
          user_answer: userAnswer
        }
      }
    })
    setIsEditting(false)
  }
  return (
    <div>
      <Text className="mb-0 text-muted">{question.description}</Text>
      {!isEditting ? (
        <Text
          className="m-3 cursor-pointer"
          onClick={() => {
            if (isStudentOverride) setIsEditting(true)
          }}
        >
          <Text
            className={`font-weight-bold ws-prewrap ${
              !question.user_answer && question.is_required && 'text-danger'
            }`}
          >
            {question.user_answer
              ? question.registration_question_type_id === 8
                ? question.user_answer.split(';').join(',')
                : question.user_answer
              : 'No response'}
          </Text>
        </Text>
      ) : (
        <div>
          <div>
            {!question.registration_question_type_id && (
              <textarea
                className={'form-control'}
                name={`question${question.id}`}
                placeholder={'Type your answer...'}
                onChange={(e) => setUserAnswer(e.target.value)}
                value={userAnswer}
              />
            )}
            {[1, 6, 7].includes(question.registration_question_type_id) && (
              <textarea
                className={'form-control'}
                name={`question${question.id}`}
                placeholder={'Type your answer...'}
                onChange={(e) => setUserAnswer(e.target.value)}
                value={userAnswer}
              />
            )}
            {[3, 4, 5].includes(question.registration_question_type_id) && (
              <NumericFormat
                className={'mb-0'}
                name={`question${question.id}`}
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
              />
            )}
            {question.registration_question_type_id === 2 && (
              <>
                <Form.Radio
                  className="cursor-pointer mr-2"
                  label={'Yes'}
                  name={`question${question.id}`}
                  value={'Yes'}
                  checked={userAnswer === 'Yes'}
                  onChange={() => {
                    setUserAnswer('Yes')
                  }}
                />
                <Form.Radio
                  className="cursor-pointer"
                  label={'No'}
                  name={`question${question.id}`}
                  value={'No'}
                  checked={userAnswer === 'No'}
                  onChange={() => {
                    setUserAnswer('No')
                  }}
                />
              </>
            )}

            {question.registration_question_type_id === 8 && (
              <>
                {question.question_options?.map((option) => {
                  const answerSet = userAnswer.split(';')
                  return (
                    <Form.Checkbox
                      className="d-block cursor-pointer ml-2"
                      label={option.name}
                      name={`question${question.id}`}
                      value={option.name}
                      checked={answerSet.includes(option.name)}
                      onChange={() => {
                        if (answerSet.includes(option.name)) {
                          const newAnswer = answerSet.filter(
                            (item) => item !== option.name
                          )
                          setUserAnswer(newAnswer.join(';'))
                        } else {
                          const newAnswer = [...answerSet, option.name]
                          setUserAnswer(newAnswer.join(';'))
                        }
                      }}
                    />
                  )
                })}
              </>
            )}

            {question.registration_question_type_id === 10 && (
              <Form.Checkbox
                className="d-block cursor-pointer ml-2"
                label={'Confirm'}
                name={`question${question.id}`}
                value={'Confirm'}
                checked={userAnswer === 'Confirm'}
                onChange={(e) => {
                  const isChecked = e.target.checked
                  if (isChecked) setUserAnswer('Confirm')
                  else setUserAnswer('')
                }}
              />
            )}

            {question.registration_question_type_id === 9 && (
              <>
                {question.question_options?.map((option) => (
                  <Form.Radio
                    key={option.id}
                    className="d-block cursor-pointer ml-2"
                    label={option.name}
                    name={`question${question.id}`}
                    value={option.name}
                    checked={userAnswer === option.name}
                    onChange={() => {
                      setUserAnswer(option.name)
                    }}
                  />
                ))}
              </>
            )}
          </div>
          <div>
            <span className="field-error text-danger">
              {!validateQuestionResponse(question, userAnswer) && questionError}
            </span>
          </div>
          <div className={'text-right'}>
            <Button
              pill
              color="white"
              className="btn-sm mr-2"
              onClick={(e) => {
                setIsEditting(false)
              }}
            >
              CANCEL
            </Button>

            <Button
              className={'my-3'}
              pill
              color="primary"
              size="sm"
              type="submit"
              disabled={!validateQuestionResponse(question, userAnswer)}
              onClick={(e) => {
                updateUserAnswer()
              }}
            >
              UPDATE
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default UserRegistrationQuestionItem
