import { useMutation } from '@apollo/client'
import { observer } from 'mobx-react'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Avatar, Button, Card, Dropdown, Grid, Icon, List, Text } from 'tabler-react'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import { useRootStore } from 'hooks'
import { DELETE_USER_POST } from 'graphql/DELETE_USER_POST'
import { GET_USER_POSTS } from 'graphql/GET_USER_POSTS'
import { DEFAULT_LIMIT } from 'components/constants'
import UserPostEditForm from 'components/User/Forms/UserPostEditForm'
import { EUserTypes } from 'components/User/userTypes'
import {
  IconBrandWhatsapp,
  IconMail,
  IconMessage,
  IconPhone
} from '@tabler/icons-react'
import ReactHtmlParser from 'react-html-parser'
import * as DOMPurify from 'dompurify'

interface IUserPostListItemProps {
  userPost: any
}

const UserPostListItem = ({ userPost }: IUserPostListItemProps) => {
  const { currentUser } = useRootStore();
  const userPostClone = { ...userPost };
  const [editting, setEditting] = useState(false);

  const isAuthorPost = currentUser.id === userPostClone.coach_id;
  const { isAdmin, isCoachAdmin, isCoachSuperAdmin } = useGetCurrentUserType();

  const handleEditLink = (param) => {
    setEditting(param);
  };

  const author = userPostClone?.coach
    ? userPostClone?.coach.first_name + ' ' + userPostClone?.coach.last_name
    : userPostClone?.student.first_name +
      ' ' +
      userPostClone?.student.last_name;

  const [deleteUserPost] = useMutation(DELETE_USER_POST, {
    onCompleted: (result) => {
      if (result.deleteUserPost) {
        toast.success('Post deleted!');
      }
    }
  });

  const deletePost = () => {
    if (window.confirm('Delete post?')) {
      deleteUserPost({
        variables: {
          id: userPost.id
        },
        refetchQueries: [
          {
            query: GET_USER_POSTS,
            variables: {
              userPostOptions: {
                camp_id: userPost.camp_id,
                limit: DEFAULT_LIMIT,
                offset: 0
              }
            }
          }
        ]
      });
    }
  };

  return (
    <>
      <div>
        <Card className="p-0 mb-0">
          <Card.Body className="p-3">
            {editting ? (
              <UserPostEditForm
                userPost={userPostClone}
                setEditting={setEditting}
              />
            ) : (
              <>
                {currentUser.type !== EUserTypes.student && (
                  <Dropdown
                    className="text-muted pl-0 pr-0 float-right"
                    toggle={false}
                    icon="more-horizontal"
                    isNavLink={true}
                    position="bottom-end"
                    arrow={true}
                    arrowPosition="right"
                    items={
                      <>
                        {(isAdmin || isAuthorPost || isCoachSuperAdmin ||
                        [userPostClone.coach_id, userPostClone.student_id
                        ].includes(currentUser.id)) && (
                          <Dropdown.Item
                            className="cursor-pointer"
                            onClick={() => handleEditLink(true)}
                          >
                            <Icon name="edit" /> Edit
                          </Dropdown.Item>
                        )}
                        {(isAdmin || isAuthorPost || isCoachAdmin || isCoachSuperAdmin) && (
                          <Dropdown.Item
                            className="cursor-pointer"
                            onClick={() => deletePost()}
                          >
                            <Icon name="trash" /> Delete
                          </Dropdown.Item>
                        )}
                        {(isAdmin || isCoachSuperAdmin) && (
                          <Dropdown.Item
                            className="text-muted cursor-pointer"
                          //  onClick={() => deletePost()}
                          >
                            <Icon name="alert-triangle" /> Report Post
                          </Dropdown.Item>
                        )}
                      </>
                    }
                  />
                )}
                <Grid.Row>
                  <Avatar
                    size="md"
                    className="mr-3 float-left mb-2 d-block"
                    imageURL={
                        (userPostClone.profile_avatar &&
                          `/api/s3/uploads/${userPostClone.profile_avatar}`) ||
                        '//www.gravatar.com/avatar?d=mp'
                      }
                  />
                  <Text><strong>{author}</strong><br />
                  <Text.Small className="text-muted">
                    {moment(userPost.created_on).format('MMM DD, YYYY - h:mm')}
                    {userPost.updated_on && ' Edited'}
                  </Text.Small></Text>
                </Grid.Row>
                <Text
                  className="ws-prewrap textEditor-display d-block"
                //  onClick={() => handleEditLink(true)}
                >
                  {ReactHtmlParser(DOMPurify.sanitize(userPost.content))}
                </Text>
              </>
            )}
            {isAdmin && (
              <Button.List align="right" className="mr-3">
                {userPostClone.email && ''}
                <IconMail
                  size={20}
                  className={
                    userPostClone.email
                      ? 'text-info cursor-pointer'
                      : ' text-muted ml-4'
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    window.location.href = 'mailto:' + userPostClone.email
                  }}
                />
                <IconPhone
                  size={20}
                  className={
                    userPostClone.phone_number
                      ? 'text-info cursor-pointer'
                      : ' text-muted ml-4'
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    window.location.href = 'tel:' + userPostClone.phone_number
                  }}
                />
                <IconMessage
                  size={20}
                  className={
                    userPostClone.phone_number
                      ? 'text-info cursor-pointer'
                      : ' text-muted ml-4'
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    window.location.href = 'sms:' + userPostClone.phone_number
                  }}
                />
                <IconBrandWhatsapp
                  size={20}
                  className={
                    userPostClone.whatsapp_number
                      ? 'text-info cursor-pointer'
                      : ' text-muted  ml-4'
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    window.location.href = 'https://wa.me/18588881152'
                  }}
                />
              </Button.List>
            )}
          </Card.Body>
        </Card>

        <List inline className="float-right list-inline-dots mb-0">
          <List.Item inline className="text-muted m-1 pb-3 cursor-pointer">
            <i className="fe fe-thumbs-up mr-2 text-muted" />
            Like
          </List.Item>
          <List.Item inline className="text-muted m-1 pb-3 cursor-pointer">
            <i className="fe fe-message-square mr-2 text-muted" />
            Comment
          </List.Item>
        </List>
        <Text.Small className="float-left text-muted m-1 pb-3">
{/*
          <i className="fe fe-calendar mr-6 text-muted" />
          {moment(userPost.created_on).format('MMM DD, YYYY - h:mm')}
          {userPost.updated_on && ' Edited'}
*/}
          <span className="cursor-pointer ml-2 mr-3"><i className="fe fe-thumbs-up mr-1" />0</span>
          <span className="cursor-pointer mr-3"><i className="fe fe-heart mr-1" />0</span>
          <span className="cursor-pointer"><i className="fe fe-message-square mr-1" />0</span>
        </Text.Small>

      </div>
    </>
  )
};

export default observer(UserPostListItem);
