import { STATUS_ICON_COLOR_MAPPING } from 'modules/user/registrations/constants/registrationStatusMapping'
import moment from 'moment/moment'
import React from 'react'
import { Link } from 'react-router-dom'
import {
  Alert,
  Button,
  Card,
  Dimmer,
  Grid,
  Icon,
  Tag,
  Text
} from 'tabler-react'
import { Types } from '../../types/graphql'

interface IStudentCamps {
  loading: boolean
  camps: Types.StudentCamps[]
  studentId: number
}

const StudentEvents = ({ loading, camps, studentId }: IStudentCamps) => {
  if (!loading && camps?.length <= 0) {
    return (
      <Card statusColor="gray-dark">
        <Card.Header>
          <Card.Title>
            <i className="fe fe-calendar text-primary mr-2" />
            Event Registrations
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Alert type="info text-center">
            <strong>No Events</strong>
          </Alert>
        </Card.Body>
      </Card>
    );
  }
  return (
    <Dimmer active={loading} loader={loading}>
      <Card statusColor="gray-dark">
        <Card.Header>
          <Card.Title>
            <i className="fe fe-calendar text-primary mr-2" />
            Event Registrations
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {camps?.filter((event) => moment(event.end) > moment()).length >
            0 && (
            <>
              <Grid.Row>
                {!loading &&
                  camps
                    .filter((event) => moment(event.end) > moment())
                    .reverse()
                    .map((camp) => {
                      return (
                        <Grid.Col sm="12" md="6" lg="4">
                          <Card key={camp.camp_id} className="card-profile">
                            <Card.Header
                              backgroundURL={
                                (camp.camp_image &&
                                  `/api/s3/uploads/${camp.camp_image}`) ||
                                '//via.placeholder.com/374x144.png?text=+'
                              }
                            ></Card.Header>
                            <Card.Body>
                              <span className="ml-3 text-muted float-right">
                                {camp.user_registrations_id && (
                                  <Button
                                    size="sm"
                                    color="white"
                                    className="text-success"
                                  >
                                    <Link
                                      to={`/events/registrations/${camp.user_registrations_id}/${studentId}`}
                                    >
                                      <Icon
                                        name={
                                          STATUS_ICON_COLOR_MAPPING.get(
                                            camp?.status
                                          )?.icon ?? 'x-circle'
                                        }
                                        className={
                                          'ml-0 ' +
                                            STATUS_ICON_COLOR_MAPPING.get(
                                              camp?.status
                                            )?.className ?? 'text-danger'
                                        }
                                      />
                                    </Link>
                                  </Button>
                                )}
                              </span>
                              <p>
                                <span className="">
                                  <Link to={'/user/calendar/' + camp.camp_id}>
                                    {camp.camp_name}
                                  </Link>
                                </span>
                                <Text.Small className="d-block text-muted">
                                  {moment(camp.start).format('MMM D, YYYY')}
                                  {' - '}
                                  {camp.team_name}
                                </Text.Small>
                              </p>
                              <p>
                                <Tag>{camp.location.name}</Tag>
                              </p>
                            </Card.Body>
                          </Card>
                        </Grid.Col>
                      )
                    })}
              </Grid.Row>
            </>
          )}
          {camps?.filter((event) => moment(event.end) < moment()).length >
            0 && (
            <>
              <h4>Past</h4>
              <Text>
                {!loading &&
                  camps
                    .filter((event) => moment(event.end) < moment())
                    .map((camp) => {
                      return (
                        <div key={camp.camp_id}>
                          <span className="ml-3 text-muted float-right">
                            {camp.user_registrations_id && (
                              <Button size="sm" color="white">
                                <Link
                                  to={`/events/registrations/${camp.user_registrations_id}/${studentId}`}
                                >
                                  <Icon
                                    name={
                                      STATUS_ICON_COLOR_MAPPING.get(
                                        camp?.status
                                      )?.icon ?? 'x-circle'
                                    }
                                    className={
                                      'ml-0 ' +
                                        STATUS_ICON_COLOR_MAPPING.get(
                                          camp?.status
                                        )?.className ?? 'text-danger'
                                    }
                                  />
                                </Link>
                              </Button>
                            )}
                          </span>
                          <p>
                            <span className="">
                              <Link to={'/user/calendar/' + camp.camp_id}>
                                {camp.camp_name}
                              </Link>
                            </span>
                            <Text.Small className="d-block text-muted">
                              <strong>
                                {moment(camp.start).format('MMM D, YYYY')}
                              </strong>{' '}
                              - {camp.location.name}
                            </Text.Small>
                            <Text.Small className="d-block text-muted">
                              {camp.team_name}
                            </Text.Small>
                          </p>
                        </div>
                      )
                    })}
              </Text>
            </>
          )}
        </Card.Body>
      </Card>
    </Dimmer>
  )
};
export default StudentEvents;
