import React, { useMemo } from 'react'
import EmailMeritsInstructionsButton from '../../../auth-merit/components/buttons/EmailMeritInstructionsButton'
import { Types } from '../../../../types/graphql'

import { BASE_ORG_ID } from '../../../../components/constants'
import UserCredentialsForm from '../../../../components/User/Forms/UserCredentialsForm'
import UserMeritsAndCredentials from '../../profile/components/UserMeritsAndCredentials'
import { Button, Card, Dimmer, Icon } from 'tabler-react'
import {
  useGetStudentOrgCredentials,
  useGetUserMerits
} from '../../hooks/merits'
import { useQuery } from '@apollo/client'
import { GET_SPORTS_BY_STUDENT_ID as GET_SPORTS_BY_STUDENT_ID_TYPE } from '../../../../graphql/types/GET_SPORTS_BY_STUDENT_ID'
import { GET_SPORTS_BY_STUDENT_ID } from '../../../../graphql/GET_SPORTS_BY_STUDENT_ID'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import { useRootStore } from '../../../../hooks'
import useReactRouter from 'use-react-router'
import { useGetStudentOverride } from '../../hooks/user'

const UserCredentials = ({ studentId }) => {
  const { location } = useReactRouter()
  const { isAdmin, isCoachSuperAdmin, isStudent } = useGetCurrentUserType()
  const isPageSettings = location.pathname === '/user/settings'
  const [isEditing, setIsEditing] = React.useState(isPageSettings)
  const { currentUser } = useRootStore()
  const {
    memberLicense,
    isMemberLicenseExpired,
    sortedABCDLicenses,
    memberUpdated,
    memberEmail,
    memberName,
    hasLifetimeMembership,
    meritsLoading,
    merits,
    coachLicense,
    otherMeritsWithExpiration,
    isCoachLicenseExpired,
    sortedOtherLicenses,
    burble
  } = useGetUserMerits({ studentId: studentId })

  const isStudentOverride = useGetStudentOverride(studentId)

  const { studentManualCredentials, teamOrgs, credentialsLoading } =
    useGetStudentOrgCredentials(isStudent ? currentUser?.id : studentId)

  const { data: studentSports, loading: studentSportsLoading } =
    useQuery<GET_SPORTS_BY_STUDENT_ID_TYPE>(GET_SPORTS_BY_STUDENT_ID, {
      variables: {
        student_id: isStudent ? currentUser?.id : studentId
      },
      skip: !isStudent
    })

  const hasBaseSport = useMemo(() => {
    if (!studentSportsLoading && studentSports) {
      return !!studentSports?.getSportsByStudentId.find(
        (sport) => sport.is_active && sport.name === 'BASE'
      )
    }

    return false
  }, [studentSports, studentSportsLoading])

  const orgs = useMemo(
    () => {
      let orgFiltered
      const defaultOrg = {
        __typename: 'TeamOrg',
        org_id: null,
        slug: 'USPA',
        name: 'USPA (Merits)',
        image: '',
        exist: true,
        team_id: null,
        is_primary: false
      }
      if (!credentialsLoading && teamOrgs) {
        if (!isAdmin && !isStudentOverride) {
          orgFiltered = teamOrgs.filter((teamOrg: Types.TeamOrg) => {
            if (memberLicense && Number(teamOrg.org_id) === 1) {
              return false
            }

            if (Number(teamOrg.org_id) === BASE_ORG_ID) {
              return hasBaseSport
            }

            if (
              studentManualCredentials &&
              studentManualCredentials.length > 0
            ) {
              if (
                studentManualCredentials.some(
                  (oc) => oc.org_id === teamOrg.org_id
                ) ||
                teamOrg.exist
              ) {
                return true
              }
            }

            return teamOrg.exist
          })

          if (memberLicense) orgFiltered.unshift(defaultOrg)

          return orgFiltered
        }

        orgFiltered = teamOrgs.filter((teamOrg: Types.TeamOrg) => {
          if (memberLicense && Number(teamOrg.org_id) === 1 && !isAdmin) {
            return false
          }

          if (isAdmin || isStudentOverride) {
            return true
          }

          return teamOrg.exist
        })

        if (memberLicense) orgFiltered.unshift(defaultOrg)

        return orgFiltered
      }

      return []
    },
    // Eslint-disable-next-line
    [
      credentialsLoading,
      studentManualCredentials,
      isAdmin,
      isStudent,
      isStudentOverride,
      memberLicense
    ]
  )

  const displayEmailMerits = useMemo(() => {
    if (!studentManualCredentials) return false

    const hasManualUSPA = studentManualCredentials.some(
      (oc) => Number(oc.org_id) === 1 && oc.membership
    )
    return (
      (isAdmin || isCoachSuperAdmin) &&
      orgs.find((o) => Number(o.org_id) === 1) &&
      hasManualUSPA &&
      !memberLicense
    )
  }, [
    studentManualCredentials,
    memberLicense,
    orgs,
    isAdmin,
    isCoachSuperAdmin
  ])

  const params = {
    memberLicense,
    isMemberLicenseExpired,
    sortedABCDLicenses,
    memberUpdated,
    memberEmail,
    memberName,
    hasLifetimeMembership,
    meritsLoading,
    merits,
    coachLicense,
    otherMeritsWithExpiration,
    isCoachLicenseExpired,
    sortedOtherLicenses,
    studentManualCredentials,
    orgs,
    credentialsLoading
  }
  return (
    <Dimmer active={credentialsLoading} loader={credentialsLoading}>
      <Card statusColor="blue">
        <Card.Header className="d-block d-md-flex pt-3 pl-3">
          <Card.Title className="mb-3 mb-md-0">
            <Icon name="award" className="mr-2 ml-0 text-success" />
            Credentials
          </Card.Title>
          <Card.Options>
            <Button.List>
              {isStudent && memberUpdated && (
                <>
                  <a href="/auth/merit/sync?redirect=/user/settings">
                    <Button
                      icon={'refresh-cw'}
                      color={'white'}
                      className="mb-2 text-success"
                      loading={credentialsLoading}
                      disabled={credentialsLoading}
                    >
                      REFRESH
                    </Button>
                  </a>
                  <a href="/auth/merit/unlink?redirect=/user/settings">
                    <Button
                      icon="x-circle"
                      color="white"
                      className="mb-2 text-muted"
                      disabled={credentialsLoading}
                    >
                      UNLINK MERIT
                    </Button>
                  </a>
                </>
              )}
              {(isAdmin || isCoachSuperAdmin) && memberLicense && (
                <Button
                  icon={'refresh-cw'}
                  color={'white'}
                  className="text-success"
                  loading={meritsLoading}
                  // we should disable if no refresh token
                  disabled={true}
                >
                  UPDATE
                </Button>
              )}
              {!isAdmin && displayEmailMerits && (
                <EmailMeritsInstructionsButton />
              )}
              {isAdmin && (
                <>
                  {/*
                  <Button icon={'award'} color={'cyan'} />
                  <Button icon={'shield'} color={'primary'} />
                  <Button icon={'award'} color={'secondary'} />
                */}
                  {burble.length > 0 && (
                    <Button
                      icon={'bold'}
                      color={'white'}
                      className={'text-gray-dark'}
                    />
                  )}
                </>
              )}
              <Button
                icon={isEditing ? 'x' : 'more-vertical'}
                color={'white'}
                onClick={() => setIsEditing(!isEditing)}
              />
            </Button.List>
          </Card.Options>
        </Card.Header>
        {isEditing && (
          <>
            <UserCredentialsForm params={params} />
          </>
        )}
        {!isEditing && (
          <>
            <UserMeritsAndCredentials params={params} />
          </>
        )}
      </Card>
    </Dimmer>
  )
}

export default UserCredentials
