import React from 'react'
import { Button } from 'tabler-react'

const VenmoButton = ({ id, venmo, outstanding }) => {
  const venmoLink = () => {
    window.open(
      `https://venmo.com/${venmo}?txn=pay&amount=${outstanding}&note=INV:${id}`,
      '_blank'
    )
  }

  return (
    <Button
      icon="dollar-sign"
      color="primary"
      onClick={venmoLink}
      className="mr-2"
      disabled={outstanding === 0}
    >
      VENMO
    </Button>
  )
}

export default VenmoButton

