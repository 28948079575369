import React from 'react'
import { Button } from 'tabler-react'
import { applySnapshot } from 'mobx-state-tree'
import { useRootStore } from '../../../../hooks'
import useRouter from 'use-react-router'
import { useQuery } from '@apollo/client'
import { GET_COACH_TEAM_BY_ID } from '../../../../graphql/GET_COACH_TEAM_BY_ID'

const SwitchTeamButton = ({ teamId }) => {
  const { history } = useRouter()
  const rootStore = useRootStore()
  const { currentUser, currentCoachTeam } = rootStore
  const { data, loading } = useQuery(GET_COACH_TEAM_BY_ID, {
    variables: {
      teamId
    }
  })

  const handleClick = () => {
    applySnapshot(rootStore, {
      ...rootStore,
      currentCoachTeam: { ...data.getCoachTeamById },
      currentUser: {
        ...currentUser,
        type: data.getCoachTeamById?.coach_business_type ?? currentUser?.type
      }
    })
    history.go(0)
  }
  return (
    <Button
      outline
      icon="repeat"
      color="success"
      onClick={handleClick}
    >
      SWITCH to <strong>{data?.getCoachTeamById.name}</strong>
    </Button>
  )
}

export default SwitchTeamButton
