import { useQuery } from '@apollo/client'
import { GET_SPORTS_BY_STUDENT_ID } from '../../../../graphql/GET_SPORTS_BY_STUDENT_ID'
import { GET_SPORTS_BY_STUDENT_ID as GET_SPORTS_BY_STUDENT_ID_TYPE } from '../../../../graphql/types/GET_SPORTS_BY_STUDENT_ID'
import { useRootStore } from '../../../../hooks'
import React, { useMemo } from 'react'
import { Button, Card, Dimmer } from 'tabler-react'
import useRouter from 'use-react-router'
import { Types } from '../../../../types/graphql'

interface UserSportsListProps {
  studentId: number
  onClickProps?: (id: number) => void
  activeFilter?: number[]
}

const UserSportsList = ({
  studentId,
  onClickProps,
  activeFilter
}: UserSportsListProps) => {
  const rootStore = useRootStore()
  const { history } = useRouter()

  const { data: studentSports, loading: studentSportsLoading } =
    useQuery<GET_SPORTS_BY_STUDENT_ID_TYPE>(GET_SPORTS_BY_STUDENT_ID, {
      variables: {
        student_id: studentId
      }
    })

  const studentSportRows: Types.SportTypes[] = useMemo(
    (): Types.SportTypes[] =>
      studentSports?.getSportsByStudentId
        .filter((sport) => sport.is_active)
        .map((item) => item) ?? [],
    [studentSports]
  )

  const handleSportsClick = (sport: Types.SportTypes) => {
    const id = sport.sub ? Number(sport.sub) : Number(sport.id)
    if (onClickProps) {
      onClickProps(id)
    } else {
      rootStore.setFilter('calendarSportsTypeFilter', id)
      history.push('/user/calendar')
    }
  }

  return (
    <Card statusColor="blue" title="Activities">
      <Card.Body className="py-2">
        <Dimmer active={studentSportsLoading} loader={studentSportsLoading}>
          {studentSportRows.length <= 0 && (
            <>
              <Button.List align="center mt-3">
                <Button
                  color="primary"
                  size="sm"
                  className=""
                  onClick={(): void => history.push('/user/settings')}
                >
                  Add Activities
                </Button>
              </Button.List>
            </>
          )}
          <Button.List className="my-3" align="center">
            {studentSportRows.map(
              (sportType: Types.SportTypes): JSX.Element => (
                <Button
                  pill
                  color={
                    activeFilter && activeFilter.includes(sportType.id)
                      ? 'primary'
                      : 'secondary'
                  }
                  size="sm"
                  key={sportType.id}
                  onClick={() => handleSportsClick(sportType)}
                >
                  {sportType.name}
                </Button>
              )
            )}
          </Button.List>
        </Dimmer>
      </Card.Body>
    </Card>
  )
}

export default UserSportsList;
