import { useQuery } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { GET_STRIPE_PUBLIC_KEY } from 'graphql/GET_STRIPE_PUBLIC_KEY'
import { GET_STRIPE_PUBLIC_KEY as GET_STRIPE_PUBLIC_KEY_TYPE } from 'graphql/types/GET_STRIPE_PUBLIC_KEY'
import { observer } from 'mobx-react'
import InvoicePaymentModal from 'modules/invoice/components/modals/InvoicePaymentModal'
import React, { useMemo, useState } from 'react'
import { Button } from 'tabler-react'
import { Types } from '../../../../types/graphql'

type InvoicePaymentButtonProps = {
  invoice: Types.Invoice
  jobIds: number[]
  total: number
}

const InvoicePaymentButton = ({
  invoice,
  jobIds,
  total
}: InvoicePaymentButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const { data: stripeData } = useQuery<GET_STRIPE_PUBLIC_KEY_TYPE>(
    GET_STRIPE_PUBLIC_KEY
  )

  const stripeKey = useMemo(
    () => stripeData?.getStripePublicKey,

    [stripeData?.getStripePublicKey]
  )

  const stripeAccount = invoice.team?.stripe_account_id

  return (
    <>
      <Button
        icon="credit-card"
        color="success"
        className="mr-2"
        // disabled={
        //   total === 0 ||
        //   invoice?.status === 'Paid' ||
        //   invoice?.status === 'Archived'
        // }
        onClick={toggleModal}
      >
        PAYMENT
      </Button>

      {isModalOpen && stripeKey && (
        <Elements
          stripe={loadStripe(stripeKey, {
            apiVersion: '2023-08-16',
            stripeAccount
          })}
        >
          <InvoicePaymentModal
            invoice={invoice}
            amount={total}
            currency={invoice.team.default_currency ?? 'USD'}
            email={invoice?.customer_email}
            invoiceId={invoice?.id}
            isModalOpen={isModalOpen}
            jobIds={jobIds}
            name={invoice?.customer_name}
            paymentFee={invoice.team.payment_fee_customer / 100}
            stripeAccountId={stripeAccount}
            studentId={Number(invoice?.student.id)}
            teamId={Number(invoice.team.id)}
            toggleModal={toggleModal}
          />
        </Elements>
      )}
    </>
  )
}

export default observer(InvoicePaymentButton)
