import React from 'react'
import Wrapper from '../layout'
import UserSettings from '../../components/User/UserSettings'
import { useRootStore } from '../../hooks'
import { EUserTypes } from '../../components/User/userTypes'

const AdminSettings = (props) => {
  const { currentUser } = useRootStore()
  return (
    <Wrapper {...props} title="Settings">
      <div className="container">
        <UserSettings user={currentUser} type={EUserTypes.admin} />
      </div>
    </Wrapper>
  )
}

export default AdminSettings
