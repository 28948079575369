import React, { useEffect } from 'react'
import { Types } from '../../../types/graphql'
import autoCompleteStyles from '../../../components/Students/Forms/autocompleteStyles'
import { useAutocomplete } from '../../../hooks/useAutocomplete'
import { GET_LOCATIONS_BY_FILTER_QUERY } from '../../../graphql/GET_LOCATIONS_BY_FILTER_QUERY'
import { useLazyQuery } from '@apollo/client'
import { GET_LOCATION_BY_ID } from '../../../graphql/GET_LOCATION_BY_ID'
import AsyncSelect from 'react-select/async'
import { Header } from 'tabler-react'

const CalendarLocationFilter = ({ onChangeProp, locationFilter }) => {
  const { loadOptions } = useAutocomplete({
    query: GET_LOCATIONS_BY_FILTER_QUERY
  })

  const [getLocation, { data: locationData }] = useLazyQuery(GET_LOCATION_BY_ID)

  useEffect(() => {
    if (locationFilter) {
      async function fetchData() {
        await getLocation({
          variables: {
            id: locationFilter.toString()
          }
        })
      }

      fetchData()
    }
  }, [locationFilter])

  return (
    <>
      <Header.H4>Location</Header.H4>
      <AsyncSelect
        backspaceRemovesValue={true}
        className="input-group"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null
        }}
        escapeClearsValue={true}
        isClearable={true}
        getOptionLabel={(option: Types.Location) => option.name}
        getOptionValue={(option: Types.Location) => option.id}
        loadOptions={loadOptions}
        onChange={(e, change) => {
          if (change.action === 'clear') {
            onChangeProp(null)
          } else {
            onChangeProp(e.id)
          }
        }}
        value={
          locationFilter && locationData?.location
            ? {
                id: locationData.location.id,
                name: locationData.location.name
              }
            : null
        }
        placeholder="Search Location"
        styles={autoCompleteStyles}
      />
    </>
  )
}

export default CalendarLocationFilter
