import React, { FC } from 'react'
import { Container, Nav } from 'tabler-react'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import { UseIsMobile } from '../../utils/windowListener'
import AdminMobileNav from './AdminMobileNav'
import MobileNav from './MobileNav'

interface NavbarProps {
  children: React.ReactNode
  collpase: boolean
}

const Navbar: FC<NavbarProps> = ({ children, collpase }) => {
  const { isAdmin, isCoach, isEventOrganizer } = useGetCurrentUserType();
  const isMobile = UseIsMobile()
  return (
    <>
      {isAdmin && isMobile && <AdminMobileNav />}
      {(isCoach || isEventOrganizer) && isMobile && <MobileNav />}
      <div
        className={`header d-print-none d-lg-flex p-0 ${
          collpase && 'collapse'
        }`}
      >
        <Container>
          <Nav className="border-0 flex-column flex-lg-row">{children}</Nav>
        </Container>
      </div>
    </>
  )
};

export default Navbar;
