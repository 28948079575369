import { useQuery } from '@apollo/client'
import { Loading } from 'components/Loading'
import * as DOMPurify from 'dompurify'
import { GET_CAMPS } from 'graphql/GET_CAMPS'
import { GET_REGISTRATION_OPTIONS } from 'graphql/GET_REGISTRATION_OPTIONS'
import { GET_CAMPS as GET_CAMPS_TYPE } from 'graphql/types/GET_CAMPS'
import { GET_REGISTRATION_OPTIONS as GET_REGISTRATION_OPTIONS_TYPE } from 'graphql/types/GET_REGISTRATION_OPTIONS'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import moment from 'moment'
import React, { useEffect, useMemo } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Alert, Button, Card, Grid, Icon, Tag, Text } from 'tabler-react'

import { GET_TEAM_BY_SLUG } from '../../graphql/GET_TEAM_BY_SLUG'
import { useRootStore } from '../../hooks'
import CheckInOutButton from '../../modules/student/components/buttons/CheckInOutButton'
import { Types } from '../../types/graphql'
// import { formatPhoneNumberWithCountryCode } from "../../utils/phoneFormat";
import { Helmet } from 'react-helmet'
import EventListItemCard from '../Event/EventListItemCard'

const TeamPublicDetails = () => {
  const { teamSlug } = useParams<{
    teamSlug: string
  }>()
  const rootStore = useRootStore()
  const { currentCoachTeam } = rootStore
  const {
    loading: teamLoading,
    error: teamError,
    data: teamData
  } = useQuery(GET_TEAM_BY_SLUG, {
    variables: {
      teamSlug: teamSlug ?? currentCoachTeam?.slug
    }
  })
  const team = useMemo(() => teamData?.teamBySlug ?? null, [teamData])

  const {
    loading: regLoading,
    error: regError,
    data: regData
  } = useQuery<GET_REGISTRATION_OPTIONS_TYPE>(GET_REGISTRATION_OPTIONS, {
    variables: {
      teamId: team?.id
    },
    skip: !team?.id
  })

  const {
    loading: campLoading,
    error: campError,
    data: campData
  } = useQuery<GET_CAMPS_TYPE>(GET_CAMPS, {
    skip: !team?.id,
    variables: {
      filter: {
        isDateAgnostic: false,
        isPresent: true,
        isPublished: true,
        team_id: Number(team?.id),
        relatedCamps: true
      }
    }
  })

  if (!teamLoading && !team) {
    return <Alert type="danger">Unknown Error</Alert>
  }
  if (teamLoading || teamError) {
    return <Loading />
  }

  if (regLoading || regError || !regData) {
    return <Loading />
  }

  if (campLoading || campError || !campData) {
    return <Loading />
  }

  return (
    <TeamPublicDetailsInner
      teamData={team}
      regData={regData}
      campData={campData}
    />
  )
}

const TeamPublicDetailsInner = ({ teamData, regData, campData }) => {
  const history = useHistory()
  const { isStudent } = useGetCurrentUserType()
  const rootStore = useRootStore()
  const { currentUser } = rootStore

  const camps = useMemo(() => {
    if (campData?.getCamps.length > 0) {
      return campData?.getCamps
        .filter((event: Types.Camp) => moment(event.end) > moment())
    }

    return []
  }, [
    // Eslint-disable-next-line
    teamData?.id,
    campData
  ])

  useEffect(() => {
    if (regData) {
      const opts = []

      regData.getRegistrationOptions.forEach((regOpt) => {
        if (!opts[regOpt.fee_type_name]) {
          opts[regOpt.fee_type_name] = []
        }

        opts[regOpt.fee_type_name].push(regOpt)
      })

      Object.entries(opts).forEach(([key, value]) => {
        const data = { key: '', value: '' }
        data.key = key
        data.value = value
        opts.push(data)
      })
    }
  }, [regData]) // Eslint-disable-line

  // After
  const hrefTeamCall = () => {
    window.location.href =
      'tel:+' + teamData.team_phone_country_code + teamData.team_phone
  }
  const hrefTeamEmail = () => {
    window.location.href = 'mailto:' + teamData.team_email
  }
  const hrefTeamWebsite = () => {
    let url = teamData.team_url;
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url; // Default to HTTPS if no protocol is specified
    }
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  const viewCalendarClick = () => {
    rootStore.setFilter('calendarTeamFilter', Number(teamData.id))
    history.push('/user/calendar')
  }

  const noData: JSX.Element = (
    <Alert type="info">
      <strong>No Data</strong>
    </Alert>
  )

  if (!teamData) {
    return noData
  }

  return (
    <>
      <Helmet>
        <title>{teamData.name}</title>
      </Helmet>
      <Grid.Row>
        <Grid.Col lg={4} sm={12} xs={12} className="grid-col">
          <Card className="card-profile">
            <Card.Header
              backgroundURL={
                (teamData?.team_logo_wide_image &&
                  `/api/s3/uploads/${teamData?.team_logo_wide_image}`) ||
                '//via.placeholder.com/374x144.png?text=+'
              }
              className="p-0 m-0"
              style={{ maxHeight: '100%', maxWidth: '10px' }}
            ></Card.Header>
            <Card.Body>
              <Text className="text-center mb-3">
                <h3>{teamData.name || 'ID Not Found'}</h3>
              </Text>
              {teamData.default_location_id && (
                <Text className="text-center mb-5">
                  <Tag>
                    <Link
                      to={`/locations/${teamData.default_location_id}`}
                      className="text-secondary"
                    >
                      <i className="fe fe-map-pin mr-2" />
                      {teamData.default_location_name}
                    </Link>
                  </Tag>
                </Text>
              )}
            </Card.Body>
            <Card.Footer className="p-0">
              {(teamData.team_phone || teamData.team_email) && (
                <Grid.Row className="text-center p-0 m-0 border-bottom">
                  <Grid.Col width={6} className="p-3">
                    {teamData.team_phone && teamData.team_phone_country_code && (
                      <Button
                        icon="phone"
                        color="white"
                        className="text-primary"
                        onClick={hrefTeamCall}
                      >
                        CALL
                      </Button>
                    )}
                  </Grid.Col>
                  <Grid.Col width={6} className="border-left p-3">
                    {teamData.team_email && (
                      <Button
                        icon="mail"
                        color="white"
                        className="text-primary"
                        onClick={hrefTeamEmail}
                      >
                        EMAIL
                      </Button>
                    )}
                  </Grid.Col>
                </Grid.Row>
              )}

              <Grid.Row className={'text-center'}>
                {teamData.team_url && (
                  <Grid.Col className="p-3">
                    <Button
                      icon="globe"
                      color="white"
                      className="text-primary"
                      onClick={hrefTeamWebsite}
                    >
                      WEBSITE
                    </Button>
                  </Grid.Col>
                )}
              </Grid.Row>
            </Card.Footer>
          </Card>
          <Card>
            {/* hide temporarily until ready, students need to be able to click the connect button to create a student_teams record with the team. display confirmation modal.
            <Card.Header>
              <Card.Options>
                <Button.List>
                  <Button
                    icon="star"
                    color="white"
                    size="sm"
                  >
                    CONNECT
                  </Button>
                </Button.List>
              </Card.Options>
            </Card.Header>
            */}
            <Card.Body>
              <Grid.Row>
                <Grid.Col>
                  {ReactHtmlParser(DOMPurify.sanitize(teamData.team_bio))}
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
          </Card>
          {isStudent && teamData.feature_student_checkin && (
            <CheckInOutButton
              studentId={currentUser.id}
              teamId={teamData.id}
              teamName={teamData.name}
            />
          )}
        </Grid.Col>
        <Grid.Col lg={8} sm={12} xs={12} className="grid-col">
          {camps.length > 0 ? (
            <>
            <Card>
              <Card.Header>
                <Card.Title>
                  <Icon name="calendar" className="mr-2 ml-0 text-primary" />
                  Upcoming Events
                </Card.Title>
                {/*<Card.Options>{canAddEvent && newEventButton()}</Card.Options>*/}
                <Card.Options>
                  <Button
                  icon="calendar"
                  color="white"
                  className="text-primary"
                  onClick={viewCalendarClick}
                >
                  EVENTS
                </Button>
              </Card.Options>
              </Card.Header>
            </Card>
            <Grid.Row className="my-3">
              {camps.map((event) => (
                <Grid.Col sm="12" md="6" lg="6" key={event.id}>
                  <EventListItemCard event={event} key={event.id} />
                </Grid.Col>
              ))}
            </Grid.Row>
          </>
          ) : (
            <Alert type="info" className="text-center">
              <strong>No Upcoming Events</strong>
            </Alert>
          )}
          <Grid.Row>
            {teamData.coaches
              .filter((c) => c.is_active && c.id !== 16)
              .filter((c) => {
                return c.userRoles.find((r) => {
                  return (
                    (r.key === 'coach' || r.key === 'org') &&
                    r.team_id === teamData.id
                  )
                })
              })
              .map((c) => (
                <Grid.Col key={c.id} xs={12} sm={12} md={6} lg={4}>
                  <Card className="user-profile">
                    <Card.Header
                      className="card-header"
                      backgroundURL={
                        (c.profile_avatar &&
                          `/api/s3/uploads/${c.profile_avatar}`) ||
                        ''
                      }
                    ></Card.Header>
                    <Card.Body className="pl-3 pr-3 pb-0">
                      <Grid.Row>
                        <Grid.Col>
                          <h3 className="text-center">
                            {c.first_name} {c.last_name}
                          </h3>
                        </Grid.Col>
                      </Grid.Row>
                      {c?.email_private !== 1 && c?.phone_private !== 1 && (
                        <Grid.Row className="border-top">
                          <Grid.Col
                            lg={6}
                            md={6}
                            sm={6}
                            className="text-center my-4"
                          >
                            {c?.email && c?.email_private === 0 ? (
                              <a
                                className="text-primary"
                                href={'mailto:' + c?.email}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fe fe-mail mr-2" />
                                EMAIL
                              </a>
                            ) : (
                              <></>
                            )}
                          </Grid.Col>
                          <Grid.Col
                            lg={6}
                            md={6}
                            sm={6}
                            className="text-center my-4"
                          >
                            {c?.phone_number && c?.phone_private === 0 ? (
                              <a
                                className="text-primary"
                                href={
                                  'sms:' +
                                  (c?.phone_country_code
                                    ? c?.phone_country_code
                                    : '1') +
                                  c?.phone_number
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fe fe-message-circle mr-2" />
                                SMS
                              </a>
                            ) : (
                              <></>
                            )}
                          </Grid.Col>
                        </Grid.Row>
                      )}
                    </Card.Body>
                  </Card>
                </Grid.Col>
              ))}
          </Grid.Row>
          {/* hide temporarily - display team sponsors here
          <Card>
            <Card.Body>

            </Card.Body>
          </Card>
*/}
        </Grid.Col>
      </Grid.Row>
    </>
  )
}

export default TeamPublicDetails
