export enum EUserRoles {
  admin = 1,
  student = 2,
  coach = 3,
  dzo = 4,
  staff = 5,
  pilot = 6,
  customer = 7,
  tandem = 8,
  rigger = 9,
  packer = 10,
  camera = 11,
  lo = 12,
  go = 13,
  riggermast = 14,
  athlete = 15,
  org = 16,
  ctand = 17,
  ftf = 18,
  competitor = 19,
  delegate = 20,
  manager = 21,
  extra = 22,
  interpret = 23,
  headdel = 24,
  medic = 25,
  nac = 26,
  finance = 27,
  controller = 28,
  cjudge = 29,
  judge = 30,
  host = 31,
  alternate = 32,
  jumper = 33,
  flyer = 34
}
