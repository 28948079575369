import React, { useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_USER_LOCATION } from '../../../graphql/UPDATE_USER_LOCATION'
import { GET_USER_LOCATION } from '../../../graphql/GET_USER_LOCATION'
import { useAutocomplete } from '../../../hooks/useAutocomplete'
import { GET_LOCATIONS_BY_FILTER_QUERY } from '../../../graphql/GET_LOCATIONS_BY_FILTER_QUERY'
import { toast } from 'react-toastify'
import { Types } from '../../../types/graphql'
import autoCompleteStyles from '../../Students/Forms/autocompleteStyles'
import { Formik } from 'formik'
import { Button, Card, Form, Grid } from 'tabler-react'
import Modal from '../../Modal'
import AsyncSelect from 'react-select/async'
import { GET_USER_REGISTRATIONS_BY_IDS } from '../../../graphql/GET_USER_REGISTRATIONS_BY_IDS'
import { DELETE_USER_LOCATION } from '../../../graphql/DELETE_USER_LOCATION'

interface RegistrationStudentLocationProps {
  isModalOpen: boolean
  toggleModal: any
  student: any
  locationType: string
}

const RegistrationStudentLocationUpdateModal = ({
  isModalOpen,
  toggleModal,
  student,
  locationType
}: RegistrationStudentLocationProps) => {
  const [submitStudentLocation] = useMutation(UPDATE_USER_LOCATION)
  const [deleteUserLocation] = useMutation(DELETE_USER_LOCATION, {
    onCompleted: (result) => {
      if (result.deleteUserLocation) {
        toast.success('User location removed')
      }
    }
  })
  const { data: userLocation, loading: userLocationLoading } = useQuery(
    GET_USER_LOCATION,
    {
      variables: {
        student_id: Number(student.student_id),
        flag_type_id: 6,
        location_type: 'dropzone'
      }
    }
  )

  const defaultHomeLocation = useMemo(() => {
    if (!userLocationLoading && !!userLocation?.getUserLocation)
      return userLocation.getUserLocation
    return null
  }, [userLocation, userLocationLoading])

  const { loadOptions } = useAutocomplete({
    query: GET_LOCATIONS_BY_FILTER_QUERY,
    options: { filter: { field: 'type', value: 'dropzone' } }
  })

  // TUNNEL
  const { data: dataTunnelLocation, loading: tunnelLocationLoading } = useQuery(
    GET_USER_LOCATION,
    {
      variables: {
        student_id: Number(student.student_id),
        flag_type_id: 6,
        location_type: 'tunnel'
      }
    }
  )

  const defaultTunnelLocation = useMemo(() => {
    if (!tunnelLocationLoading && !!dataTunnelLocation?.getUserLocation)
      return dataTunnelLocation.getUserLocation
    return null
  }, [dataTunnelLocation, tunnelLocationLoading])

  const { loadOptions: tunnelOptions } = useAutocomplete({
    query: GET_LOCATIONS_BY_FILTER_QUERY,
    options: { filter: { field: 'type', value: 'tunnel' } }
  })
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        dz_id: defaultHomeLocation?.user_location_id ?? undefined,
        dz_location_id: Number(defaultHomeLocation?.id),
        dz_location_name: defaultHomeLocation?.name ?? '',
        student_id: Number(student.student_id),
        flag_type_id: 6,
        t_id: defaultTunnelLocation?.user_location_id ?? undefined,
        t_location_id: Number(defaultTunnelLocation?.id),
        t_location_name: defaultTunnelLocation?.name ?? ''
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        if (values.dz_location_id) {
          submitStudentLocation({
            variables: {
              options: {
                id: values.dz_id,
                student_id: values.student_id,
                location_id: values.dz_location_id,
                flag_type_id: 6
              }
            },
            refetchQueries: [
              {
                query: GET_USER_LOCATION,
                variables: {
                  student_id: Number(student.student_id),
                  flag_type_id: 6,
                  location_type: 'dropzone'
                }
              },
              {
                query: GET_USER_REGISTRATIONS_BY_IDS,
                variables: {
                  userRegistrationsId: Number(student?.user_registrations_id)
                }
              }
            ]
          })
        }

        if (values.t_location_id) {
          submitStudentLocation({
            variables: {
              options: {
                id: values.t_id,
                student_id: values.student_id,
                location_id: values.t_location_id,
                flag_type_id: 6
              }
            },
            refetchQueries: [
              {
                query: GET_USER_LOCATION,
                variables: {
                  student_id: Number(student.student_id),
                  flag_type_id: 6,
                  location_type: 'tunnel'
                }
              },
              {
                query: GET_USER_REGISTRATIONS_BY_IDS,
                variables: {
                  userRegistrationsId: Number(student?.user_registrations_id)
                }
              }
            ]
          })
        }

        toast.success('User locations updated')
        resetForm()
        setSubmitting(false)
        toggleModal()
      }}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        isSubmitting,
        setFieldValue,
        errors,
        touched
      }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            content={
              <Grid.Col md={12}>
                {locationType === 'dropzone' && (
                  <Form.Group label="Home DZ:">
                    {!userLocationLoading && (
                      <>
                        <AsyncSelect
                          backspaceRemovesValue={true}
                          className="input-group"
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            LoadingIndicator: () => null
                          }}
                          escapeClearsValue={true}
                          getOptionLabel={(option: Types.Location) =>
                            option.name
                          }
                          getOptionValue={(option: Types.Location) =>
                            option.id
                          }
                          isClearable={true}
                          loadOptions={loadOptions}
                          name="dz_location_id"
                          onChange={(e, change) => {
                            if (change.action === 'clear') {
                              if (defaultHomeLocation?.user_location_id)
                                deleteUserLocation({
                                  variables: {
                                    id: defaultHomeLocation?.user_location_id
                                  },
                                  refetchQueries: [
                                    {
                                      query: GET_USER_LOCATION,
                                      variables: {
                                        student_id: Number(
                                          student?.student_id
                                        ),
                                        flag_type_id: 6,
                                        location_type: 'dropzone'
                                      }
                                    },
                                    {
                                      query: GET_USER_REGISTRATIONS_BY_IDS,
                                      variables: {
                                        userRegistrationsId: Number(
                                          student?.user_registrations_id
                                        )
                                      }
                                    }
                                  ]
                                })
                            } else {
                              if (e) {
                                setFieldValue('dz_location_id', Number(e.id))
                                setFieldValue('dz_location_name', e.name)
                              } else setFieldValue('dz_location_id', null)
                            }
                          }}
                          value={
                            values.dz_location_id
                              ? {
                                  id: values.dz_location_id,
                                  name: values.dz_location_name
                                }
                              : null
                          }
                          placeholder="Select your home DZ (optional)"
                          styles={autoCompleteStyles}
                        />
                        <span className="field-error text-danger">
                          {errors.dz_location_id &&
                            touched.dz_location_id &&
                            errors.dz_location_id}
                        </span>
                      </>
                    )}
                  </Form.Group>
                )}

                {locationType === 'tunnel' && (
                  <Form.Group label="Home Tunnel:">
                    {!tunnelLocationLoading && (
                      <>
                        <AsyncSelect
                          backspaceRemovesValue={true}
                          className="input-group"
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            LoadingIndicator: () => null
                          }}
                          escapeClearsValue={true}
                          getOptionLabel={(option: Types.Location) =>
                            option.name
                          }
                          getOptionValue={(option: Types.Location) =>
                            option.id
                          }
                          isClearable={true}
                          loadOptions={tunnelOptions}
                          name="t_location_id"
                          onChange={(e, change) => {
                            if (change.action === 'clear') {
                              if (defaultTunnelLocation?.user_location_id)
                                deleteUserLocation({
                                  variables: {
                                    id: defaultTunnelLocation?.user_location_id
                                  },
                                  refetchQueries: [
                                    {
                                      query: GET_USER_LOCATION,
                                      variables: {
                                        student_id: Number(
                                          student.student_id
                                        ),
                                        flag_type_id: 6,
                                        location_type: 'tunnel'
                                      }
                                    },
                                    {
                                      query: GET_USER_REGISTRATIONS_BY_IDS,
                                      variables: {
                                        userRegistrationsId: Number(
                                          student?.user_registrations_id
                                        )
                                      }
                                    }
                                  ]
                                })
                            } else {
                              if (e) {
                                setFieldValue('t_location_id', Number(e.id))
                                setFieldValue('t_location_name', e.name)
                              } else setFieldValue('t_location_id', null)
                            }
                          }}
                          value={
                            values.t_location_id
                              ? {
                                  id: values.t_location_id,
                                  name: values.t_location_name
                                }
                              : null
                          }
                          placeholder="Set home tunnel (optional)"
                          styles={autoCompleteStyles}
                        />
                        <span className="field-error text-danger">
                          {errors.t_location_id &&
                            touched.t_location_id &&
                            errors.t_location_id}
                        </span>
                      </>
                    )}
                  </Form.Group>
                )}
              </Grid.Col>
            }
            actions={
              <>
                <Button
                  onClick={toggleModal}
                  pill
                  color={'white'}
                  className="float-right btn-sm"
                >
                  CANCEL
                </Button>

                <Button
                  onClick={(e) => {
                    e.preventDefault()
                    setFieldValue('action', 'add')
                    handleSubmit()
                  }}
                  type="submit"
                  disabled={isSubmitting}
                  pill
                  className="btn-gray-dark float-right btn-sm"
                >
                  {isSubmitting ? 'Processing...' : 'SAVE'}
                </Button>
              </>
            }
            open={isModalOpen}
            title={`${student.first_name} ${student.last_name}`}
            onClose={toggleModal}
          />
        </form>
      )}
    </Formik>
  )
}

export default RegistrationStudentLocationUpdateModal
