import { observer } from 'mobx-react';
import { Button, Card, Form, Grid, Icon, Table } from 'tabler-react';
import AdminListItem from './AdminListItem';
import { Types } from '../../types/graphql'

const AdminList = ({ admins }) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>
            <Icon name="users" className="mr-2 ml-0 text-primary" /> Admins
          </Card.Title>
          <Card.Options>
            <Button icon="plus" color="primary" disabled>ADD</Button>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Username</Table.ColHeader>
                    <Table.ColHeader>Status</Table.ColHeader>
                    <Table.ColHeader>Action</Table.ColHeader>
                    <Table.ColHeader>Edit</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {admins.map((admin: Types.Admin) => (
                    <Table.Row key={admin.id}>
                      <AdminListItem admin={admin} />
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title>
            <Icon name="users" className="mr-2 ml-0 text-success" /> CoachSuperAdmins
          </Card.Title>
          <Card.Options>
            <Button icon="plus" color="primary" disabled>ADD</Button>
          </Card.Options>
        </Card.Header>
        <Card.Body>
            
        </Card.Body>
      </Card>
    </>
  );
};

export default observer(AdminList);
