import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Formik } from 'formik'
import moment from 'moment/moment'
import { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'
import { Button, Form, Grid } from 'tabler-react'
import useReactRouter from 'use-react-router'
import { ADD_CAMP_STUDENT } from '../../../graphql/ADD_CAMP_STUDENT'
import { ADD_STUDENT_TO_TEAM } from '../../../graphql/ADD_STUDENT_TO_TEAM'
import { ADD_USER_EVENT } from '../../../graphql/ADD_USER_EVENT'
import { ADD_USER_REGISTRATION_OPTIONS } from '../../../graphql/ADD_USER_REGISTRATION_OPTIONS'
import { CREATE_USER_REGISTRATION_QUESTION } from '../../../graphql/CREATE_USER_REGISTRATION_QUESTION'
import { GET_CAMP_ACTIVE_REGISTRATION_OPTIONS } from '../../../graphql/GET_CAMP_ACTIVE_REGISTRATION_OPTIONS'
import { GET_CAMP_DISCIPLINES } from '../../../graphql/GET_CAMP_DISCIPLINES'
import { GET_CAMP_REGISTRATION_REQUIREMENTS } from '../../../graphql/GET_CAMP_REGISTRATION_REQUIREMENTS'
import { GET_CAMP_ROLES } from '../../../graphql/GET_CAMP_ROLES'
import { GET_LOCATIONS_BY_FILTER_QUERY } from '../../../graphql/GET_LOCATIONS_BY_FILTER_QUERY'
import { GET_REGISTRATION_QUESTIONS } from '../../../graphql/GET_REGISTRATION_QUESTIONS'
import { GET_STUDENT_BY_ID } from '../../../graphql/GET_STUDENT_BY_ID'
import { GET_STUDENT_TEAM } from '../../../graphql/GET_STUDENT_TEAM'
import { GET_USER_LOCATION } from '../../../graphql/GET_USER_LOCATION'
import { UPDATE_STUDENT_MUTATION } from '../../../graphql/UPDATE_STUDENT_MUTATION'
import { useRootStore } from '../../../hooks'
import { useAutocomplete } from '../../../hooks/useAutocomplete'
import useGetCurrentUserType from '../../../modules/common/hooks/useGetCurrentUserType'
import useGetRegistrationStatus from '../../../modules/team-registration-status/hooks/useGetRegistrationStatus'
import { Types } from '../../../types/graphql'
import { validateQuestionResponse } from '../../../utils/registrationQuestionUtil'
import {
  OTHER_REQUIREMENTS,
  OTHER_REQUIREMENTS_OBJECT_INIT,
  REQUIREMENT_REGISTRATION_DATA,
  REQUIREMENT_REGISTRATION_DATA_OBJECT_INIT,
  REQUIREMENT_STUDENT_PROFILE,
  REQUIREMENT_STUDENT_PROFILE_OBJECT_INIT,
  REQUIREMENT_TYPES_MAP
} from '../../constants'
import Modal from '../../Modal'
import { EUserEvents, EUserEventsId } from '../../User/UserEvents'
import { EUserTypes } from '../../User/userTypes'
import autoCompleteStyles from './autocompleteStyles'
import StudentRegistrationDetailsForm from './StudentRegistrationDetailsForm'
import StudentRegistrationMeritsLocationForm from './StudentRegistrationMeritsLocationForm'
import StudentRegistrationOptionsForm from './StudentRegistrationOptionsForm'
import StudentRegistrationQuestionsForm from './StudentRegistrationQuestionsForm'
import StudentSearchField from './StudentSearchField'

export const SACTION_FEE_TYPE_ID = '10'
export const ADDITIONAL_EVENT_TYPE_ID = '7'

interface IStudentRegistrationFormProps {
  isModalOpen: boolean
  toggleModal: any
  camp: Types.Camp
  defaultJumpCount: number
  studentIdProp?: number
  coach?: Types.Coach
}

type QuestionResponse = {
  [key: number]: string
}

export const roleDisciplineList = [
  '19', //Competitor
  '32' // Alternate
]
const StudentRegistrationForm = ({
  isModalOpen,
  toggleModal,
  camp,
  defaultJumpCount,
  studentIdProp,
  coach
}: IStudentRegistrationFormProps) => {
  const rootStore = useRootStore()
  const { currentUser, currentCoachTeam } = rootStore
  const { history } = useReactRouter()
  const { isStudent, isEventOrganizer } = useGetCurrentUserType()

  const [selectedOptions, setSelectedOptions] = useState([])
  const [questionResponse, setQuestionResponse] =
    useState<QuestionResponse>(null)
  const [validateQuestion, setValidateQuestion] = useState(false)
  const [questions, setQuestions] = useState([])
  const [studentId, setStudentId] = useState(studentIdProp ?? null)
  //const [student, setStudent] = useState(null);
  const MAX_STEP = 7
  //const MIN_STEP = currentUser.type === EUserTypes.student ? 2 : 1;
  const MIN_STEP = 1
  const [page, setPage] = useState(MIN_STEP)

  const [addUserRegistrationOptions] = useMutation(
    ADD_USER_REGISTRATION_OPTIONS
  )
  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION)
  const [addUserEvent] = useMutation(ADD_USER_EVENT)
  const [addUserRegistrationQuestion] = useMutation(
    CREATE_USER_REGISTRATION_QUESTION
  )
  const [addStudentToTeam] = useMutation(ADD_STUDENT_TO_TEAM)

  const { data, loading } = useQuery(GET_CAMP_ACTIVE_REGISTRATION_OPTIONS, {
    variables: {
      campId: Number(camp.id)
    },
    fetchPolicy: 'network-only'
  })

  const { data: campRequirementsData, loading: campRequirementsLoading } =
    useQuery(GET_CAMP_REGISTRATION_REQUIREMENTS, {
      variables: {
        team_id: Number(camp.team_id),
        camp_id: Number(camp.id)
      },
      fetchPolicy: 'network-only'
    })

  const isCoachRegistered = useMemo(() => {
    return !!camp.registered_students.find(
      (student) => student.student_id === Number(coach?.student_id)
    )
  }, [camp, coach])

  const [
    getStudentById,
    { data: studentData, loading: studentLoading, refetch }
  ] = useLazyQuery(GET_STUDENT_BY_ID)
  const [getStudentTeam, { data: studentTeamData }] =
    useLazyQuery(GET_STUDENT_TEAM)

  useEffect(() => {
    if (studentId) {
      getStudentById({
        variables: {
          student_id: studentId
        }
      })

      getStudentTeam({
        variables: {
          student_id: studentId,
          team_id: Number(camp.team_id)
        }
      })
    }
  }, [studentId])

  const { data: questionData, loading: questionLoading } = useQuery(
    GET_REGISTRATION_QUESTIONS,
    {
      variables: {
        filter: {
          campId: Number(camp.id),
          teamId: Number(camp.team_id)
        }
      }
    }
  )

  const requirements = useMemo(() => {
    if (
      !campRequirementsLoading &&
      campRequirementsData?.campRegistrationRequirements.length > 0
    )
      return campRequirementsData.campRegistrationRequirements.filter(
        (r) => r.is_exist
      )

    return []
  }, [campRequirementsData, campRequirementsLoading])

  //USER LOCATIONS DZ and TUNNEL
  const { data: userDzLocation } = useQuery(GET_USER_LOCATION, {
    variables: {
      student_id: studentId,
      flag_type_id: 6,
      location_type: 'dropzone'
    },
    skip: !studentId
  })

  const { data: userTunnelLocation } = useQuery(GET_USER_LOCATION, {
    variables: {
      student_id: studentId,
      flag_type_id: 6,
      location_type: 'tunnel'
    },
    skip: !studentId
  })

  //Gather student profile requirements
  const studentProfileRequirement = useMemo(() => {
    if (campRequirementsLoading) return null
    const requirementObject = { ...REQUIREMENT_STUDENT_PROFILE_OBJECT_INIT }

    const requirementsKeyArray = []
    const profileRequirements =
      campRequirementsData?.campRegistrationRequirements.filter(
        (r) =>
          r.requirement_type_slug === 'email' ||
          (REQUIREMENT_STUDENT_PROFILE.includes(r.requirement_type_slug) &&
            r.is_exist)
      )

    if (!studentLoading && studentData?.getStudentById) {
      if (profileRequirements?.length > 0) {
        profileRequirements.map((r) =>
          requirementsKeyArray.push(r.requirement_type_slug)
        )

        Object.keys(requirementObject).map((k) => {
          let skip = false
          if (!requirementsKeyArray.includes(k)) {
            if (
              (['weight'].includes(k) &&
                requirementsKeyArray.includes('wing_load')) ||
              k === 'email'
            ) {
              skip = false
            } else {
              delete requirementObject[k]
              skip = true
            }
          }

          if (!skip) {
            let value =
              studentData.getStudentById[REQUIREMENT_TYPES_MAP[k].field] ?? null
            if (k === 'phone')
              value =
                studentData.getStudentById.phone_country_code +
                ' ' +
                studentData.getStudentById[REQUIREMENT_TYPES_MAP[k].field]

            if (k === 'wing_load') value = true
            switch (REQUIREMENT_TYPES_MAP[k].type) {
              case 'number':
                requirementObject[k] = value ? Number(value) : null
                break
              case 'date':
                requirementObject[k] = value
                  ? moment(value).format('MM/DD/YYYY')
                  : null
                break
              default:
                requirementObject[k] = value
            }
          }
        })
        return requirementObject
      }
    }

    return null
  }, [
    campRequirementsData,
    campRequirementsLoading,
    studentData,
    studentLoading
  ])

  const studentRequirements = useMemo(() => {
    if (
      campRequirementsLoading ||
      !campRequirementsData?.campRegistrationRequirements
    )
      return null
    const requirementsKeyArray = []
    const requirements = { ...OTHER_REQUIREMENTS_OBJECT_INIT }

    const noneProfileRequirements =
      campRequirementsData?.campRegistrationRequirements.filter(
        (r) =>
          OTHER_REQUIREMENTS.includes(r.requirement_type_slug) && r.is_exist
      )

    if (noneProfileRequirements?.length > 0) {
      noneProfileRequirements.map((r) =>
        requirementsKeyArray.push(r.requirement_type_slug)
      )

      Object.keys(requirements).map((k) => {
        let skip = false
        if (!requirementsKeyArray.includes(k)) {
          if (
            k === 'main' &&
            studentProfileRequirement &&
            Object.keys(studentProfileRequirement).includes('wing_load')
          ) {
            skip = false
          } else {
            delete requirements[k]
            skip = true
          }
        }
        if (!skip) {
          switch (k) {
            case 'home_dz':
              requirements.home_dz = !!userDzLocation?.getUserLocation
              break
            case 'home_tunnel':
              requirements.home_tunnel = !!userTunnelLocation?.getUserLocation
              break
            case 'burble':
              break
            case 'merits':
              break
            case 'member':
              break
            case 'aad':
              break
          }
        }
      })
      return requirements
    }

    return null
  }, [
    campRequirementsData,
    campRequirementsLoading,
    userDzLocation,
    userTunnelLocation,
    studentProfileRequirement
  ])

  const campRequirements = useMemo(() => {
    if (campRequirementsLoading) return null
    const requirementsKeyArray = []
    const campRequirements = { ...REQUIREMENT_REGISTRATION_DATA_OBJECT_INIT }
    const campRequirementsList =
      campRequirementsData?.campRegistrationRequirements.filter(
        (r) =>
          REQUIREMENT_REGISTRATION_DATA.includes(r.requirement_type_slug) &&
          r.is_exist
      )
    if (campRequirementsList?.length > 0) {
      campRequirementsList.map((r) =>
        requirementsKeyArray.push(r.requirement_type_slug)
      )

      Object.keys(campRequirements).map((k) => {
        if (!requirementsKeyArray.includes(k)) {
          delete campRequirements[k]
        }
      })

      return campRequirements
    }
  }, [campRequirementsLoading, campRequirementsData])

  const campRegistrationOptions = useMemo(() => {
    if (!loading && data?.getCampRegistrationOptions) {
      return data?.getCampRegistrationOptions.filter(
        (option) =>
          option.is_exist && option.reg_form && option.fee_type_id !== '8'
      )
    }
    return []
  }, [data, loading])

  useEffect(() => {
    if (!questionLoading && questionData) {
      const activeQuestions = questionData?.registrationQuestions.filter(
        (question) => {
          const isLocationMatch = question.location_id
            ? Number(question.location_id) === camp.location_id
            : true
          const isSportsTypeMatch = question.sport_type_id
            ? question.sport_type_id === Number(camp.sport_type_id)
            : true
          return question.is_exist && isLocationMatch && isSportsTypeMatch
        }
      )
      setQuestions(activeQuestions)
      const questionaire: QuestionResponse = {}

      activeQuestions.map((question) => {
        questionaire[question.id] = ''
      })
      setQuestionResponse(questionaire)
    }
  }, [questionLoading, questionData, camp])

  const handleOptionsToggle = (target) => {
    const currentSelectedOptions = selectedOptions
    if (target.checked) {
      if (currentSelectedOptions.indexOf(target.value))
        currentSelectedOptions.push(Number(target.value))
    } else {
      const index = currentSelectedOptions.indexOf(target.value)
      if (index > -1) {
        // only splice array when item is found
        currentSelectedOptions.splice(index, 1) // 2nd parameter means remove one item only
      }
    }
    setSelectedOptions(currentSelectedOptions)
  }

  const [addCampStudent, { loading: registrationSubmitting }] = useMutation(
    ADD_CAMP_STUDENT,
    {
      onCompleted: (result) => {
        if (result.addCampStudent > 0) {
          const options = selectedOptions.filter(function (item, pos, self) {
            return self.indexOf(item) === pos
          })

          addUserRegistrationOptions({
            variables: {
              addUserRegistrationOptionInput: {
                user_registration_id: result.addCampStudent,
                registration_option_ids: options,
                quantity: defaultJumpCount
              }
            }
          })

          questions.map((q) =>
            addUserRegistrationQuestion({
              variables: {
                option: {
                  registration_id: result.addCampStudent,
                  registration_questions_id: q.id,
                  user_answer: questionResponse[q.id] ?? ''
                }
              }
            })
          )

          addUserEvent({
            variables: {
              userEvent: {
                location_id: camp.location_id,
                status: EUserEvents.reg_added,
                student_id: studentId,
                team_id: camp.team_id,
                camp_id: Number(camp.id),
                coach_id: studentIdProp ? null : currentUser.id,
                user_event_type_id: EUserEventsId.reg_added,
                user_registration_id: result.addCampStudent
              }
            }
          })

          if (!studentTeamData?.getStudentTeam) {
            addStudentToTeam({
              variables: {
                student_id: studentId,
                team_id: Number(camp.team_id)
              }
            })
          }

          toast.success('Registration Successful!')
          toggleModal(false)
          if (currentUser.type === EUserTypes.student)
            history.push(
              `/events/registrations/${result.addCampStudent}/${studentId}`
            )
        } else {
          toast.error('Registration Error!')
        }
      },
      refetchQueries: ['GET_CAMP_BY_ID', 'GET_REGISTERED_STUDENTS_COUNT']
    }
  )

  const updateStudentMutation = () => {
    const phone = studentProfileRequirement?.phone?.split(' ')
    const countryCode = phone?.shift().replace(/\D/g, '')

    const result = updateStudent({
      variables: {
        student: {
          id: Number(studentId),
          height: studentProfileRequirement.height
            ? Number(studentProfileRequirement.height)
            : undefined,
          weight: studentProfileRequirement.weight
            ? Number(studentProfileRequirement.weight)
            : undefined,
          dob: studentProfileRequirement.dob
            ? moment(studentProfileRequirement.dob, 'MM/DD/YYYY').format(
                'YYYY-MM-DD'
              )
            : undefined,
          email: studentProfileRequirement.email
            ? studentProfileRequirement.email
            : undefined,
          phone_number: studentProfileRequirement.phone
            ? phone.join('').replace(/\D/g, '')
            : undefined,
          phone_country_code: studentProfileRequirement.phone
            ? countryCode.phone_code
            : undefined,
          shirt_size_id: studentProfileRequirement.shirt_size
            ? Number(studentProfileRequirement.shirt_size)
            : undefined,
          nac: studentProfileRequirement.nac
            ? Number(studentProfileRequirement.nac)
            : undefined,
          gender: studentProfileRequirement.gender
            ? studentProfileRequirement.gender
            : undefined,
          jump_count: studentProfileRequirement.jump_count
            ? Number(studentProfileRequirement.jump_count)
            : undefined,
          tunnel_time: studentProfileRequirement.tunnel_time
            ? Number(studentProfileRequirement.tunnel_time)
            : undefined,
          country: studentProfileRequirement.country
            ? studentProfileRequirement.country
            : undefined,
          reach: studentProfileRequirement.reach
            ? Number(studentProfileRequirement.reach)
            : undefined,
          stance: studentProfileRequirement.stance
            ? studentProfileRequirement.stance
            : undefined
        }
      }
    })

    if (result) {
      refetch()
    }
  }

  const steps = useMemo(() => {
    const stepList = {
      1: true,
      2:
        requirements.filter((r) =>
          REQUIREMENT_REGISTRATION_DATA.includes(r.requirement_type_slug)
        ).length > 0,
      3: campRegistrationOptions.length > 0,
      4:
        requirements.filter((r) =>
          REQUIREMENT_STUDENT_PROFILE.includes(r.requirement_type_slug)
        ).length > 0,
      5:
        requirements.filter((r) =>
          OTHER_REQUIREMENTS.includes(r.requirement_type_slug)
        ).length > 0,
      //4: false,
      6: questions.length > 0,
      7: true
    }

    return stepList
  }, [data, questions, requirements])

  const movePage = (move) => {
    let nextPage = move === 'next' || move === 'skip' ? page + 1 : page - 1
    const skip: boolean = move === 'skip'

    while (!steps[nextPage]) {
      nextPage =
        move === 'next' || move === 'skip' ? nextPage + 1 : nextPage - 1
      if (nextPage < MIN_STEP || nextPage > MAX_STEP) return
    }

    if (nextPage < MIN_STEP || nextPage > MAX_STEP) return

    if (move === 'prev') {
      setPage(nextPage)
      return
    }

    switch (page) {
      case 1: //select student
        if (studentId) {
          setPage(nextPage)
        }
        break
      case 2: {
        // select Role and Discipline
        let campRequirementsPass = true
        for (const key in campRequirements) {
          if (
            key === 'discipline' &&
            showDisciplineInput &&
            !campRequirements[key]
          ) {
            campRequirementsPass = false
          } else if (key !== 'discipline') {
            if (!campRequirements[key]) {
              campRequirementsPass = false
            }
          }
        }
        if (campRequirementsPass || skip) {
          setPage(nextPage)
        }
        break
      }
      case 3: // select options
        setPage(nextPage)
        break
      case 4: {
        // student profile requirements
        let pass = true
        for (const key in studentProfileRequirement) {
          if (key === 'profile_avatar' && !isStudent) {
            continue
          }
          if (!studentProfileRequirement[key]) {
            pass = false
          }
        }
        if (pass || skip) {
          updateStudentMutation()
          setPage(nextPage)
        }
        break
      }
      case 5: {
        // other requirements
        let other_pass = true
        for (const i in studentRequirements) {
          if (!studentRequirements[i] && i !== 'burble') {
            other_pass = false
          }
        }
        if (other_pass || skip) {
          setPage(nextPage)
        }
        break
      }
      case 6: // questions
        if (
          questions.every((q: Types.RegistrationQuestion) =>
            validateQuestionResponse(q, questionResponse[q.id])
          ) ||
          skip
        )
          setPage(nextPage)
        break
      default: //submit
        setPage(nextPage)
    }
  }

  const { loadOptions: loadLocationsOptions } = useAutocomplete({
    query: GET_LOCATIONS_BY_FILTER_QUERY,
    options: { filter: { field: 'type', value: ['dropzone', 'tunnel'] } }
  })

  const { data: rolesData } = useQuery(GET_CAMP_ROLES, {
    variables: {
      campId: camp.id
    }
  })

  const { data: disciplinesData, loading: disciplineLoading } = useQuery(
    GET_CAMP_DISCIPLINES,
    {
      variables: {
        campId: camp.id
      }
    }
  )
  const rolesOption = useMemo(() => {
    if (rolesData)
      return rolesData?.campRoles
        .filter((role) => role.isExists)
        .map((role) => ({
          label: role.name,
          value: role.roleId
        }))

    return []
  }, [rolesData])

  const disciplineOptions = useMemo(() => {
    if (disciplineLoading) return []
    return disciplinesData?.campDisciplines
      .filter((discipline) => discipline.isExists)
      .map((discipline) => ({
        label: discipline.name,
        value: discipline.disciplineId
      }))
  }, [disciplinesData, disciplineLoading])

  const [showDisciplineInput, setShowDisciplineInput] = useState(
    campRequirements &&
      !Object.keys(campRequirements).includes('role') &&
      Object.keys(campRequirements).includes('discipline')
  )
  const setShowDisciplineInputCallback = (value, setValue) => {
    if (!campRequirements) {
      setShowDisciplineInput(false)
    } else {
      const isDisciplineRequired =
        Object.keys(campRequirements).includes('discipline')
      const isRoleRequired = Object.keys(campRequirements).includes('role')

      const isRoleRequiringDiscipline =
        isRoleRequired && roleDisciplineList.includes(value)
      const isShow =
        (isDisciplineRequired && isRoleRequiringDiscipline) ||
        (isDisciplineRequired && !isRoleRequired)
      setShowDisciplineInput(isShow)
      if (!isShow) {
        campRequirements.discipline = undefined
        setValue('discipline_id', null)
      }
    }
  }

  const { mappedStatusOptions } = useGetRegistrationStatus(Number(camp.team_id))

  const statusConfirmed = mappedStatusOptions.find(
    (tso) => tso.type === 'confirmed'
  )
  const statusPending = mappedStatusOptions.find(
    (tso) => tso.type === 'pending'
  )

  const renderForm = ({
    handleSubmit,
    isSubmitting,
    errors,
    setFieldValue,
    touched,
    values
  }) => (
    <>
      {/*Page 1 START*/}
      {page === 1 && (
        <Grid.Row>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            {!studentIdProp && currentCoachTeam && (
              <Form.Group label="">
                <StudentSearchField
                  autoFocus={true}
                  onChange={(param) => {
                    if (!param) return

                    if (
                      camp.registered_students.find(
                        (student) => student.id === param.id
                      )
                    ) {
                      toast.error(
                        'Participant is already registered for this event'
                      )
                      return
                    }
                    setStudentId(Number(param.id))
                    setFieldValue('student_id', Number(param.id))
                  }}
                  defaultValue={''}
                />

                <span className="field-error text-danger">
                  {errors.student_id && touched.student_id && errors.student_id}
                </span>
              </Form.Group>
            )}
            {studentIdProp && <>{'START REGISTRATION'}</>}
          </Grid.Col>
          {!isStudent && coach?.student_id && !isCoachRegistered && (
            <Grid.Col xs={12} sm={12} md={6} lg={6} className="text-right">
              <Button
                outline
                icon={studentData?.getStudentById ? 'check-circle' : 'user'}
                color={studentData?.getStudentById ? 'success' : 'primary'}
                onClick={() => {
                  setFieldValue('student_id', Number(currentUser.student_id))
                  setStudentId(Number(currentUser.student_id))
                }}
              >
                {studentData?.getStudentById
                  ? studentData.getStudentById.first_name +
                    ' ' +
                    studentData.getStudentById.last_name +
                    ' (' +
                    studentData.getStudentById.id +
                    ')'
                  : 'Register Myself'}
              </Button>
            </Grid.Col>
          )}
        </Grid.Row>
      )}
      {/*Page 1 END*/}

      {/*Page 2 START*/}
      {page === 2 && (
        <>
          {Object.keys(campRequirements).includes('location') && (
            <Form.Group label={'Location'}>
              <AsyncSelect
                backspaceRemovesValue={true}
                className="input-group"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  LoadingIndicator: () => null
                }}
                escapeClearsValue={true}
                getOptionLabel={(option: Types.Location) => option.name}
                getOptionValue={(option: Types.Location) => option.id}
                isClearable={true}
                loadOptions={loadLocationsOptions}
                name="location_id"
                onChange={(e, change) => {
                  if (
                    change.action === 'clear' &&
                    userTunnelLocation?.user_location_id
                  ) {
                    campRequirements.location = null
                    setFieldValue('location_id', null)
                    setFieldValue('location_name', null)
                  } else {
                    if (e) {
                      setFieldValue('location_id', Number(e.id))
                      setFieldValue('location_name', e.name)
                      campRequirements.location = true
                    } else {
                      setFieldValue('location_id', null)
                      campRequirements.location = null
                    }
                  }
                }}
                value={
                  values.location_id
                    ? {
                        id: values.location_id,
                        name: values.location_name
                      }
                    : null
                }
                placeholder="Search tunnels"
                styles={autoCompleteStyles}
              />
              <span className="field-error text-danger">
                {!values.location_id && 'This field is required'}
              </span>
            </Form.Group>
          )}
          {Object.keys(campRequirements).includes('role') && (
            <Form.Group label={'Role'}>
              <Select
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                options={rolesOption}
                name="role_id"
                onChange={(option) => {
                  setFieldValue('role_id', option.value)
                  campRequirements.role = option.value
                  setShowDisciplineInputCallback(option.value, setFieldValue)
                }}
                value={rolesOption.find((i) => i.value === values.role_id)}
              />
              <span className="field-error text-danger">
                {!values.role_id && 'This field is required'}
              </span>
            </Form.Group>
          )}
          {showDisciplineInput && (
            <Form.Group label={'Primary Discipline'}>
              <Select
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                options={disciplineOptions}
                name={'discipline_id'}
                onChange={(option) => {
                  setFieldValue('discipline_id', Number(option.value))
                  campRequirements.discipline = option.value
                }}
                value={disciplineOptions.find(
                  (i) => Number(i.value) === values.discipline_id
                )}
              />
              <span className="small text-muted">
                You can select additional disciplines later.
              </span>
              <span className="field-error text-danger">
                {!values.discipline_id && 'This field is required'}
              </span>
            </Form.Group>
          )}
        </>
      )}
      {/*Page 2 END*/}

      {/*Page 3 START*/}
      {page === 3 && (
        <>
          {!loading && campRegistrationOptions.length > 0 && (
            <StudentRegistrationOptionsForm
              campRequirements={campRequirements}
              registrationOptions={campRegistrationOptions}
              handleOptionsToggle={handleOptionsToggle}
              loading={loading}
            />
          )}
        </>
      )}

      {/*Page 3 END*/}

      {/*Page 4 START*/}
      {page === 4 && (
        <>
          {requirements.filter((r) =>
            REQUIREMENT_STUDENT_PROFILE.includes(r.requirement_type_slug)
          ).length > 0 && (
            <StudentRegistrationDetailsForm
              studentProfileRequirement={studentProfileRequirement}
              studentId={studentId}
              refetch={refetch}
            />
          )}
        </>
      )}

      {/*Page 4 END*/}

      {/*Page 5 START*/}
      {page === 5 && (
        <>
          {requirements.filter(
            (r) =>
              !REQUIREMENT_STUDENT_PROFILE.includes(r.requirement_type_slug)
          ).length > 0 && (
            <StudentRegistrationMeritsLocationForm
              studentId={studentId}
              studentRequirements={studentRequirements}
              camp={camp}
              userDzLocation={userDzLocation?.getUserLocation}
              userTunnelLocation={userTunnelLocation?.getUserLocation}
              studentData={studentData?.getStudentById}
            />
          )}
        </>
      )}

      {/*Page 5 END*/}

      {/*Page 6 START*/}
      {page === 6 && (
        <>
          {questions.length > 0 && (
            <StudentRegistrationQuestionsForm
              registrationQuestions={questions}
              registrationQuestionsResponse={questionResponse}
              setQuestionResponse={setQuestionResponse}
              validateQuestion={validateQuestion}
              studentId={studentId}
            />
          )}
        </>
      )}
      {/*Page 6 END*/}
      {page === 7 && (
        <Grid.Row>
          <Grid.Col sm={12} lg={6}>
            {!isStudent && Number(currentUser?.student_id) !== studentId && (
              <Form.Group label="">
                <Select
                  name="status"
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                  }}
                  menuPortalTarget={document.body}
                  placeholder={'Status'}
                  options={mappedStatusOptions}
                  onChange={(option) => {
                    setFieldValue('status', option.value)
                    setFieldValue('registration_status_id', option.id)
                  }}
                  value={mappedStatusOptions.filter(
                    (i) => i.id === Number(values.registration_status_id)
                  )}
                />
              </Form.Group>
            )}
          </Grid.Col>
        </Grid.Row>
      )}
      <Button.List className="mt-4 mb-7" align="right">
        {page === MAX_STEP && (
          <>
            <Button
              disabled={isSubmitting}
              pill
              className="btn-gray-dark float-right btn-sm"
              onClick={(e) => {
                e.preventDefault()
                handleSubmit()
              }}
            >
              {isSubmitting ? 'Processing...' : 'SUBMIT'}
            </Button>
            <Button
              pill
              color="white"
              className="float-right btn-sm"
              onClick={(e) => {
                e.preventDefault()
                toggleModal(!isModalOpen)
              }}
            ></Button>
          </>
        )}

        {page < MAX_STEP && (
          <Button
            disabled={isSubmitting}
            pill
            className="btn-primary float-right btn-sm"
            onClick={() => {
              if (page === 5) {
                setValidateQuestion(true)
              }
              movePage('next')
            }}
          >
            NEXT
          </Button>
        )}
        {page > MIN_STEP && (
          <Button
            pill
            icon="chevron-left"
            color="secondary"
            className="float-left btn-sm"
            onClick={(e) => {
              movePage('prev')
            }}
          >
            BACK
          </Button>
        )}
        {page > MIN_STEP &&
          page < MAX_STEP &&
          !isStudent &&
          Number(currentUser?.student_id) !== studentId && (
            <Button
              pill
              icon="chevron-right"
              color="secondary"
              className="float-left btn-sm"
              onClick={(e) => {
                movePage('skip')
              }}
            >
              SKIP
            </Button>
          )}
      </Button.List>
    </>
  )

  return (
    <Modal
      content={
        <Formik
          enableReinitialize={true}
          initialValues={{
            student_id: studentIdProp ?? '',
            camp_id: Number(camp.id),
            total_fees: Number(camp.camp_fees) ?? 0,
            status:
              (studentIdProp ||
                Number(currentUser?.student_id) === studentId) &&
              !camp.auto_approval
                ? statusPending
                  ? statusPending.value
                  : 'Pending'
                : statusConfirmed
                ? statusConfirmed.value
                : 'Confirmed',
            registration_status_id:
              (studentIdProp ||
                Number(currentUser?.student_id) === studentId) &&
              !camp.auto_approval
                ? statusPending
                  ? statusPending.id
                  : 2
                : statusConfirmed
                ? statusConfirmed.id
                : 1,
            created_by: studentIdProp ? null : currentUser.id,
            jump_quantity: defaultJumpCount,
            question: true,
            location_id: null,
            location_name: '',
            role_id: null,
            discipline_id: null
          }}
          onSubmit={async (values) => {
            if (!values.student_id) {
              values.student_id = studentId
            }
            //Set status to wait list if max registration is reached
            const waitListStatus = mappedStatusOptions.find(
              (tso) => tso.type === 'waitlist'
            )
            if (
              camp.registrations_max &&
              camp.registered_students.filter(
                (student) => student.status === statusConfirmed.value
              ).length >= Number(camp.registrations_max)
            ) {
              values.status = waitListStatus.value ?? 'Waitlist'
              values.registration_status_id = waitListStatus.id ?? 4
            }

            addCampStudent({
              variables: {
                addCampStudentInput: {
                  student_id: values.student_id,
                  camp_id: values.camp_id,
                  total_fees: values.total_fees,
                  status: values.status,
                  created_by: values.created_by,
                  jump_quantity: values.jump_quantity,
                  registration_status_id: values.registration_status_id,
                  location_id:
                    campRequirements &&
                    Object.keys(campRequirements).includes('location')
                      ? Number(values.location_id)
                      : undefined,
                  role_id:
                    campRequirements &&
                    Object.keys(campRequirements).includes('role')
                      ? Number(values.role_id)
                      : undefined,
                  discipline_id:
                    campRequirements &&
                    Object.keys(campRequirements).includes('discipline')
                      ? values.discipline_id
                        ? Number(values.discipline_id)
                        : undefined
                      : undefined
                }
              }
            })
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
      open={isModalOpen}
      title={camp.camp_name}
      onClose={() => {
        setPage(MIN_STEP)
        toggleModal(!isModalOpen)
      }}
    />
  )
}
export default StudentRegistrationForm
