import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { Button, Form } from 'tabler-react'
import * as Yup from 'yup'
import { EDIT_STUDENT_TEAM_PROGRESSION } from '../../../graphql/EDIT_STUDENT_TEAM_PROGRESSION'
import { GET_STUDENT_TEAM_PROGRESSION } from '../../../graphql/GET_STUDENT_TEAM_PROGRESSION'
import { Types } from '../../../types/graphql'
import FeatureAccessGate from '../../../modules/common/components/FeatureAccessGate'
import { ADD_STUDENT_TEAM_PROGRESSION } from '../../../graphql/ADD_STUDENT_TEAM_PROGRESSION'

const addUserNoteValidationSchema = Yup.object().shape({
  notes: Yup.string().required('This field is required.')
})

interface IStudentProgressionNotesForm {
  studentTeamProgression: Types.StudentTeamProgression
  setIsEdittingNote: any
  studentId: number
  campId: number
}

const StudentProgressionNotesForm = ({
  studentTeamProgression,
  setIsEdittingNote,
  studentId,
  campId
}: IStudentProgressionNotesForm) => {
  const [editStudentTeamProgression] = useMutation(
    EDIT_STUDENT_TEAM_PROGRESSION
  )
  const [addStudentTeamProgression] = useMutation(ADD_STUDENT_TEAM_PROGRESSION)

  const [rating, setRating] = useState(studentTeamProgression.rating ?? 0)
  const [isEdittingRating, setIsEdittingRating] = useState(false)

  const handleToggleCheck = async (item) => {
    if (!item.is_exist) {
      await addStudentTeamProgression({
        variables: {
          addStudentProgressionInput: {
            student_id: Number(studentId),
            team_id: studentTeamProgression.team_id,
            progression_id: item.id,
            status: 1,
            camp_id: campId
          }
        },
        refetchQueries: ['GET_STUDENT_TEAM_PROGRESSION']
      })
    } else {
      await editStudentTeamProgression({
        variables: {
          editStudentProgressionInput: {
            id: item.studentProgressionId,
            status: item.studentProgressionStatus === 1 ? 0 : 1
          }
        },
        refetchQueries: ['GET_STUDENT_TEAM_PROGRESSION']
      })
    }
  }

  const updateRating = async () => {
    await editStudentTeamProgression({
      variables: {
        editStudentProgressionInput: {
          id: studentTeamProgression.studentProgressionId,
          rating: rating
        }
      },
      refetchQueries: ['GET_STUDENT_TEAM_PROGRESSION']
    })
    setIsEdittingRating(false)
  }

  const initValues = {
    id: studentTeamProgression?.studentProgressionId,
    notes: studentTeamProgression?.studentProgressionNotes ?? ''
  }

  const renderForm = ({
    values,
    handleSubmit,
    handleChange,
    errors,
    setFieldValue
  }) => (
    <>
      <Form.Textarea
        //  className={errors.note && 'is-invalid'}
        className="mt-3"
        name="notes"
        placeholder="Add notes"
        rows={3}
        type="text"
        onChange={handleChange}
        value={values.notes}
      />
      {errors.notes && (
        <div className="field-error text-danger">{errors.notes}</div>
      )}
      {studentTeamProgression.is_exist ? (
        <>
          <Button
            className={`cursor-pointer float-left mt-4 mr-1 ${
              studentTeamProgression.studentProgressionStatus === 1
                ? 'text-success'
                : 'text-muted'
            }`}
            color={'white'}
            icon={'check-circle'}
            size={'sm'}
            onClick={() => handleToggleCheck(studentTeamProgression)}
          />
          {isEdittingRating ? (
            <input
              autoFocus
              className={'float-left mr-2 mt-4'}
              min={1}
              max={10}
              type="number"
              value={rating}
              onChange={(e) => {
                const value = Number(e.target.value)
                if (value > 10) setRating(10)
                if (value < 0) setRating(1)
                if (value >= 0 && value <= 10) setRating(value)
              }}
              onBlur={async () => {
                updateRating()
              }}
              onKeyUp={(event) => {
                switch (event.keyCode) {
                  case 13:
                    updateRating()
                    break
                  case 27:
                    setIsEdittingRating(false)
                    break
                }
              }}
            />
          ) : (
            <Button
              icon={studentTeamProgression.rating > 0 ? '' : 'star'}
              color="white"
              size={'sm'}
              className={`cursor-pointer text-center mt-4 float-left mr-2 ${
                studentTeamProgression.rating === 10
                ? "text-success"
                : studentTeamProgression.rating < 5
                ? "text-danger"
                : "text-primary"
              }`}
              onClick={() => setIsEdittingRating(true)}
            >
              {studentTeamProgression.rating > 0 && (
                <span className="text-success px-1">
                  {studentTeamProgression.rating}
                </span>
              )}
            </Button>
          )}
        </>
      ) : null}
      <Button.List className="mt-4" align="right">
        {values.notes && (
        <Button
          color="secondary"
          size="sm"
          onClick={(e) => {
            e.preventDefault()
            setFieldValue('notes', '')
            handleSubmit()

          }}
          className="text-muted mr-1"
        >DELETE</Button>
        )}
        {/*
        <Button
          color="light"
          size="sm"
          onClick={(e) => {
            e.preventDefault()
            setIsEdittingNote(false)
          }}
          className="mr-1"
        >CLOSE</Button>
        */}
        <Button
          color="primary"
          size="sm"
          type="submit"
          onClick={() => handleSubmit()}
        >
          SAVE
        </Button>
      </Button.List>
    </>
  )
  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={addUserNoteValidationSchema}
        initialValues={initValues}
        onSubmit={async (values) => {
          await editStudentTeamProgression({
            variables: {
              editStudentProgressionInput: {
                ...values
              }
            },
            refetchQueries: ['GET_STUDENT_TEAM_PROGRESSION']
          })
          setIsEdittingNote(false)
        }}
      >
        {(formikData) => renderForm(formikData)}
      </Formik>
    </>
  )
}

export default observer(StudentProgressionNotesForm);
