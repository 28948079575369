import React from 'react'
import Modal from '../../../../components/Modal'
import TextEditor from '../../../../components/text-editor/TextEditor'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { useRootStore } from '../../../../hooks'
import { useFormik } from 'formik'
import { Types } from 'types/graphql'
import { Button, Form, Grid, Tag } from 'tabler-react'
import { SEND_GROUP_EMAIL } from '../../../../graphql/SEND_GROUP_EMAIL'

interface GroupEmailModalFormProps {
  isOpen: boolean
  toggle: () => void
  group: Types.Group
}

const GroupEmailModalForm = ({
  isOpen,
  toggle,
  group
}: GroupEmailModalFormProps) => {
  const [sendGroupEmail] = useMutation(SEND_GROUP_EMAIL, {
    onCompleted: () => {
      toast.success('Emails sent successfully')
    }
  })
  const { currentUser } = useRootStore()
  const formik = useFormik({
    initialValues: {
      subject: '',
      message: '',
      groupId: group.id,
      ids: group.members.map((member) => member.id),
      coachId: currentUser.id
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      await sendGroupEmail({
        variables: {
          ...values
        }
      })
      setSubmitting(false)
      resetForm()
      toggle()
    }
  })
  return (
    <Modal
      title={`Email to: ${group.name}`}
      open={isOpen}
      content={
        <div className="bulk-email-modal">
          <form onSubmit={formik.handleSubmit}>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label="Recipients">
                  <Tag.List>
                    {group.members.map((member) => (
                      <Tag key={member.id}>
                        {member.first_name + ' '}
                        {member.last_name + ' '}
                        {member.email ? '<' + member.email + '>' : <i className='fe fe-alert-circle text-danger' />}
                      </Tag>
                    ))}
                  </Tag.List>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <fieldset disabled={formik.isSubmitting}>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="Subject*">
                    <Form.Input
                      name="subject"
                      type="text"
                      autoFocus
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col>
                  <Form.Group label="Message*">
                    <TextEditor
                      value={formik.values.message}
                      onChange={(value) =>
                        formik.setFieldValue('message', value)
                      }
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Button.List align="right">
                <Button
                  icon="send"
                  color="primary"
                  onClick={formik.handleSubmit}
                >
                  SEND EMAIL
                </Button>
              </Button.List>
            </fieldset>
          </form>
        </div>
      }
      onClose={toggle}
    />
  )
}

export default GroupEmailModalForm
