import { EUserTypes } from 'components/User/userTypes';

export const Role = {
  Admin: EUserTypes.admin,
  Coach: EUserTypes.coach,
  DZM: EUserTypes.dzm,
  Events: EUserTypes.events,
  Manufacturer: EUserTypes.manufacturer,
  Org: EUserTypes.org,
  Rigger: EUserTypes.rigger,
  Services: EUserTypes.services,
  Student: EUserTypes.student,
  WTM: EUserTypes.wtm,
  Academy: EUserTypes.academy,
  Promoter: EUserTypes.promoter
};
