import React, { FC, memo, useEffect } from 'react'
import { Button } from 'tabler-react'
import { usePDF } from '@react-pdf/renderer'
import CompetitionGroupsPdf from '../pdf/CompetitionGroupsPDF'

interface GroupsPDFButtonProps {
  campName: string
  groups: any
  disciplines: { value: number; label: string }[]
  filter?: number
}

const GroupsPDFButton: FC<GroupsPDFButtonProps> = memo(
  ({ campName, groups, disciplines, filter }) => {
    console.log(groups)
    const document = CompetitionGroupsPdf({
      campName,
      groups,
      disciplines,
      filter
    })
    const [instance, updateInstance] = usePDF({ document })

    useEffect(() => {
      updateInstance(document)
    }, [campName, groups])

    return (
      <a
        href={instance.url}
        download={`${campName} Groups.pdf`}
        key={campName}
      >
        <Button 
          icon="download" 
          color="secondary" 
          className="ml-2" 
        />
      </a>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.groups === nextProps.groups
  }
)

export default GroupsPDFButton
