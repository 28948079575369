import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_TEAMS } from '../../../../graphql/GET_TEAMS'
import useReactRouter from 'use-react-router'
import { Dimmer, Grid, List } from 'tabler-react'
import { Types } from '../../../../types/graphql'

const StudentHomeTeamList = ({ type }) => {
  const { history } = useReactRouter()
  const { data, loading } = useQuery(GET_TEAMS, {
    variables: {
      filter: {
        isPublic: true,
        isPublished: true,
        coach_business_type: type
      }
    }
  })
  const coachBusinessTypeLabels: Record<string, string> = {
    coach: 'Coaching',
    dzm: 'Dropzones',
    events: 'Event Organizers',
    rigger: 'Riggers',
    wtm: 'Wind Tunnel'
    // Add other keys here...
  }
  const teams = data?.teams || []
  return (
    <Dimmer loader={loading} active={loading}>
      <Grid.Row>
        <Grid.Col>
          <strong style={{ display: 'block', marginTop: '1rem' }}>
            {coachBusinessTypeLabels[type] || type}
          </strong>
          <List unstyled>
            {teams.map((team: Types.Team) => {
              return (
                <List.Item key={team.id} className={`cursor-pointer`}>
                  <span
                    onClick={() => {
                      if (team?.slug) history.push('/' + team.slug)
                    }}
                  >
                    {team.name}
                  </span>
                </List.Item>
              )
            })}
          </List>
        </Grid.Col>
      </Grid.Row>
    </Dimmer>
  )
}

export default StudentHomeTeamList
