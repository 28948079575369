import { EHostnames } from 'components/constants'
import { EUserTypes } from 'components/User/userTypes'
import { useRootStore } from 'hooks/useRootStore'
import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Grid, List, Text } from 'tabler-react'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'


const FooterNav = () => {
  const rootStore = useRootStore()
  const { domainData, currentUser } = rootStore
  const { isDzm, isEventOrganizer, isRigger } = useGetCurrentUserType()


  const userTypeLinks = {
    [EUserTypes.events]: '/pricing/events',
    [EUserTypes.dzm]: '/dzm/pricing',
    [EUserTypes.rigger]: '/rigger/pricing',
    [EUserTypes.coach]: '/coach/pricing',
  }
  const userTypeKnowledgeBase = {
    [EUserTypes.events]: 'https://www.notion.so/skycru/SKYCRU-Knowledge-Base-Events-Competitions-16600a8c924c8097b037e1eb67b0d2e0?pvs=4',
    [EUserTypes.dzm]: 'https://www.notion.so/skycru/DZM-Knowledge-Base-16600a8c924c8040aadfd20202618da9?pvs=4',
    [EUserTypes.rigger]: 'https://www.notion.so/skycru/Rigger-Knowledge-Base-16400a8c924c8085833ecc9f92a64a79?pvs=4',
    [EUserTypes.coach]: 'https://www.notion.so/skycru/Coaching-Knowledge-Base-16600a8c924c805e8f84d7b8b432a4af?pvs=4',
  }

  return (
    <div className="footer">
      <Container>
        <Grid.Row>
          <Grid.Col lg={8} sm={12} xs={12} className="grid-col mb-sm-4">
            <Grid.Row>
              {userTypeLinks[currentUser?.type] && (
                <Grid.Col className="grid-col" lg={4} sm={12} xs={12}>
                  <List unstyled={true} className="mb-0">
                    <li>
                      <Link to={userTypeLinks[currentUser?.type]}>Upgrade Plan</Link>
                    </li>
                    {(isEventOrganizer || isDzm || isRigger) && (
                    <li>
                      <a href={userTypeKnowledgeBase[currentUser?.type]} target='_blank'>Knowledge Base</a>
                    </li>
                    )}
                    <li>
                      <a href={'https://help.skycru.com/'} target='_blank'>User Knowledge Base</a>
                    </li>
                  </List>
                </Grid.Col>
              )}
            </Grid.Row>
          </Grid.Col>
          <Grid.Col lg={4} sm={12} xs={12} className="grid-col text-right">
            {domainData?.key === EHostnames.FAIRFIGHTS || domainData?.key === EHostnames.COMBAT
              ? 'Combat Sports Digital Credentials Platform'
              : 'AIRSPORTS, OPTIMIZED'}
          </Grid.Col>
        </Grid.Row>
      </Container>
    </div>
  )
}

export default FooterNav
