import { useMutation } from '@apollo/client'
import Modal from 'components/Modal'
import RegistrationOrgCredentialForm from 'components/OrgCredentials/Forms/RegistrationOrgCredentialForm'
import StudentRecentCamps from 'components/Students/StudentRecentCamps'
import { UPDATE_USER_REGISTRATION } from 'graphql/UPDATE_USER_REGISTRATION'
import { useRootStore } from 'hooks'
import { PaymentButtons } from 'modules/payment/components/buttons/PaymentButtons'
import RegistrationPaymentsModal from 'modules/registration/payments/modals/RegistrationPaymentsModal'
import UserRegistrationStatusDropdown from 'modules/user/registrations/components/dropdowns/UserRegistrationStatusDropdown'
import moment from 'moment'
import React, { useContext, useMemo, useState } from 'react'
import { Avatar, Button, Dropdown, Table, Text } from 'tabler-react'
import useReactRouter from 'use-react-router'
import { Types } from '../../types/graphql'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import RegistrationPaymentModal from '../../modules/user/registrations/components/modals/RegistrationPaymentModal'
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType'
import { UserStoreContext } from '../../contexts/userStoreContext'
import RegistrationDetailsModal from '../../modules/registration/modals/RegistrationDetailsModal'

interface IEventRegistrationListItemProps {
  student: Types.CampStudentListItem
  teamId?: number
  handleToggleSelected?: (id: number) => void
  selected?: boolean
  stripeKey?: string
  paymentFee?: number
  scorecard?: boolean
}

const EventRegistrationListItem = ({
  student,
  teamId,
  handleToggleSelected,
  selected: checked,
  stripeKey,
  paymentFee,
  scorecard
}: IEventRegistrationListItemProps) => {
  const { history } = useReactRouter()
  const { currentUser } = useRootStore()
  const userStore = useContext(UserStoreContext)
  const { isAdmin } = useGetCurrentUserType()
  const [isEdittingRating, setIsEdittingRating] = useState<boolean>(false)
  const [rating, setRating] = useState<number>(student.rating ?? 0)
  const [updateUserRegistration] = useMutation(UPDATE_USER_REGISTRATION, {
    refetchQueries: ['GET_USER_REGISTRATIONS_BY_IDS', 'GET_REGISTERED_STUDENTS']
  })

  const { total, outstanding, totalPaid, totalFees, totalComp } =
    student.paymentDetail

  const paymentStatus = useMemo(() => {
    if (totalComp === total && totalComp > 0) {
      return 'Comp'
    }

    if (outstanding === 0) {
      return 'Paid'
    }
    return ''
  }, [totalComp, outstanding, totalFees])
  // Comment out ORG related codes
  // let statusIcon = 'bg-danger'
  // if (student.uspa.uspaStatus === 'USPA Active') {
  //   statusIcon = 'bg-success'
  // }
  //
  // if (student.uspa.isLicenseExpiring) {
  //   statusIcon = 'bg-warning'
  // }
  //
  // let studentOrg = null
  // let studentLicense = null
  // if (student.orgCredentials.length > 0) {
  //   studentOrg = student.orgCredentials[0]
  //   studentLicense = student.orgCredentials.find(
  //     (org) => org.org_id === studentOrg.org_id && (org.license || org.nac_slug)
  //   )
  // }
  //
  // let orgStatusIcon = 'bg-danger'
  //
  // if (moment(student.end).diff(studentOrg?.expires, 'days') > 0) {
  //   orgStatusIcon = 'bg-warning'
  // }
  //
  // if (moment(student.end).diff(studentOrg?.expires, 'days') < 0) {
  //   orgStatusIcon = 'bg-success'
  // }
  //
  // if (moment().diff(studentOrg?.expires, 'days') > 0) {
  //   orgStatusIcon = 'bg-danger'
  // }

  const updateRating = async () => {
    updateUserRegistration({
      variables: {
        userRegistration: {
          id: student.user_registrations_id,
          rating: rating,
          coach_id: currentUser.id
        }
      }
    })
    setIsEdittingRating(false)
  }
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false)
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false)

  const [openStripeModal, setOpenStripeModal] = useState<boolean>(false)
  const toggleOpenStripeModal = () => {
    setOpenStripeModal(!openStripeModal)
  }

  return (
    <>
      <Table.Col>
        <input
          id={`participant-${student.student_id}`}
          name={'selected'}
          type="checkbox"
          checked={checked}
          onClick={() => handleToggleSelected(student.user_registrations_id)}
          onChange={() => {}}
        />
      </Table.Col>
      <Table.Col>
        <Avatar
          size="md"
          className="cursor-pointer"
          status={student.status_color ?? ''}
          imageURL={
            student?.profile_avatar
              ? `/api/s3/uploads/${student.profile_avatar}`
              : '//www.gravatar.com/avatar?d=mp'
          }
          onClick={() =>
            () => setInfoModalOpen(true)
          }
        />
      </Table.Col>
      <Table.Col>
        <strong>
          <Text
            className="cursor-pointer d-block"
            onClick={
              () => setInfoModalOpen(true)
            }
          >
            {student.first_name + ' ' + student.last_name}
          </Text>
        </strong>
        <Text.Small className="text-muted d-block">
          <span style={{ whiteSpace: 'wrap' }}>
            {student.city ? student.city + ' ' : ''}
            {student.province && student.province + ' '}
            {student.country !== 'United States' ? student.country : ''}
          </span>
        </Text.Small>
        {infoModalOpen && (
          <RegistrationDetailsModal
            registrationId={student.user_registrations_id}
            studentId={student.student_id}
            open={infoModalOpen}
            toggle={() => setInfoModalOpen(false)}
          />
        )}
      </Table.Col>
      <Table.Col>
        {(student.status === 'Phase 1 approved' ||
          student.status === 'Phase 2 approved') && (
          <RecentCamps
            studentId={student.student_id}
            campId={student.camp_id}
            campsJoined={student.campsJoined}
          />
        )}
        {/*EDIT STATUS HERE*/}
        {student && (
          <UserRegistrationStatusDropdown
            campId={student?.camp_id}
            registrationStart={student?.start}
            status={student?.status}
            teamId={teamId}
            studentId={student?.student_id.toString()}
            userRegId={Number(student.user_registrations_id)}
            isEditable={moment().diff(moment(student?.end), 'days') < 1}
          />
        )}
        {(student.status === 'Confirmed' ||
          student.status === 'Phase 1 approved' ||
          student.status === 'Phase 2 approved' ||
          student.status === 'Accepted') &&
          scorecard && (
            <>
              {isEdittingRating ? (
                <input
                  autoFocus
                  min={0}
                  max={10}
                  type="number"
                  value={rating}
                  onChange={(e) => {
                    const value = Number(e.target.value)
                    if (value > 10) setRating(10)
                    if (value < 0) setRating(0)
                    if (value >= 0 && value <= 10) setRating(value)
                  }}
                  onBlur={async () => {
                    updateRating()
                  }}
                  onKeyUp={(event) => {
                    switch (event.keyCode) {
                      case 13:
                        updateRating()
                        break
                      case 27:
                        setIsEdittingRating(false)
                        break
                    }
                  }}
                />
              ) : (
                <Button
                  outline
                  icon="star"
                  //  size={'sm'}
                  color={rating > 0 ? 'info' : 'secondary'}
                  className="ml-2"
                  onClick={() => setIsEdittingRating(true)}
                >
                  {rating > 0 && <span className="ml-2">{rating}</span>}
                </Button>
              )}
            </>
          )}
      </Table.Col>
      <Table.Col>
        <PaymentButtons
          totalComp={totalComp}
          totalPaid={totalPaid}
          outstanding={outstanding}
          paymentStatus={paymentStatus}
          onClick={() => setOpenPaymentModal(true)}
          status={student.status}
          currency={student.registration_currency}
          toggleStripeModal={toggleOpenStripeModal}
        />
        {stripeKey && openStripeModal && (
          <Elements stripe={loadStripe(stripeKey)}>
            <RegistrationPaymentModal
              amount={outstanding / 100}
              currency={student.registration_currency}
              isModalOpen={openStripeModal}
              name={`${student?.first_name} ${student?.last_name}`}
              paymentFee={paymentFee}
              toggleModal={toggleOpenStripeModal}
              userRegistrationDetails={student}
            />
          </Elements>
        )}
        {openPaymentModal && (
          <RegistrationPaymentsModal
            outstanding={outstanding / 100}
            totalPaid={totalPaid / 100}
            userRegistrationDetails={student}
            open={openPaymentModal}
            onClose={() => setOpenPaymentModal(false)}
            currency={student.registration_currency}
          />
        )}
      </Table.Col>
      {/* COMMENTED OUT FOR NOW*/}
      {/*<Table.Col>*/}
      {/*  {Number(studentOrg?.org_id) === 1 && student.uspa.memberLicense ? (*/}
      {/*    <>*/}
      {/*      <Text>*/}
      {/*        USPA {student.uspa.memberLicense?.merits_uspa_member_id}*/}
      {/*        <i className="fe fe-check-circle text-primary ml-2" />*/}
      {/*        {isAdmin && student.uspa?.burbleLicense && (*/}
      {/*          <i className="fe fe-bold text-gray-dark ml-2" />*/}
      {/*        )}*/}
      {/*      </Text>*/}
      {/*      <Text.Small className="d-block">*/}
      {/*        <span className={`status-icon ${statusIcon}`} />*/}
      {/*        {student.uspa.memberLicense?.merits_uspa_member_expiration ? (*/}
      {/*          <>{student.uspa.memberLicense?.merits_uspa_member_expiration}</>*/}
      {/*        ) : (*/}
      {/*          'Lifetime Member'*/}
      {/*        )}*/}
      {/*      </Text.Small>*/}
      {/*    </>*/}
      {/*  ) : (*/}
      {/*    <>*/}
      {/*      {studentOrg?.membership ? (*/}
      {/*        <>*/}
      {/*          <Text>*/}
      {/*            {(studentOrg?.org_slug.toUpperCase() ?? '') +*/}
      {/*              ' ' +*/}
      {/*              (studentOrg?.membership ?? '')}*/}
      {/*            {isAdmin && student.uspa?.burbleLicense && (*/}
      {/*              <i className="fe fe-bold text-gray-dark ml-2" />*/}
      {/*            )}*/}
      {/*          </Text>*/}
      {/*          <Text.Small className="d-block">*/}
      {/*            {studentOrg?.expires ? (*/}
      {/*              <>*/}
      {/*                <span className={`status-icon ${orgStatusIcon}`} />*/}
      {/*                {studentOrg?.expires}*/}
      {/*              </>*/}
      {/*            ) : (*/}
      {/*              ''*/}
      {/*            )}*/}
      {/*          </Text.Small>*/}
      {/*        </>*/}
      {/*      ) : (*/}
      {/*        <>*/}
      {/*          <OrgMembershipModal*/}
      {/*            studentId={student.student_id}*/}
      {/*            teamId={teamId}*/}
      {/*          />*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*</Table.Col>*/}
      <Table.Col className="text-right">
        <Dropdown
          triggerContent={<Button color={'white'} icon={'more-vertical'} />}
          position="bottom-end"
          arrow={true}
          arrowPosition="right"
          class="cursor-pointer"
          toggle={false}
          itemsObject={[
            {
              className: 'm-0 p-0',
              value: (
                <a href={'mailto:' + student.email} className="text-muted">
                  <i className="fe fe-mail text-muted m-2" />
                  Email
                </a>
              )
            },
            {
              className: 'm-0 p-0',
              value: (
                <a
                  href={
                    '/events/registrations/' +
                    student.user_registrations_id +
                    '/' +
                    student.student_id
                  }
                  className="text-muted"
                >
                  <i className="fe fe-user text-muted m-2" />
                  View Registration
                </a>
              )
            }
          ]}
        />
      </Table.Col>
    </>
  )
}

const RecentCamps = ({ studentId, campId, campsJoined }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = () => setIsOpen(!isOpen)

  // const { data, error, loading } = useQuery(GET_STUDENT_CAMPS, {
  //   variables: { getStudentCampsInput: { studentId: Number(studentId) } }
  // })
  //
  // const studentCamps = data?.getStudentCamps.filter(
  //   (camp) => Number(camp.camp_id) !== Number(campId)
  // )

  return (
    <>
      <Button
        icon="calendar"
        color="white"
        className="text-muted mr-2"
        onClick={toggleModal}
        disabled={campsJoined <= 0}
      >
        {' '}
        {campsJoined}
      </Button>
      {isOpen && (
        <Modal
          open={isOpen}
          content={
            <StudentRecentCamps studentId={studentId} currentCampId={campId} />
          }
          onClose={toggleModal}
        />
      )}
    </>
  )
}

export const OrgMembershipModal = ({ studentId, teamId }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = () => setIsOpen(!isOpen)
  return (
    <>
      <Button icon="plus" color="secondary" size="sm" onClick={toggleModal}>
        CREDENTIALS
      </Button>
      <Modal
        open={isOpen}
        content={
          <RegistrationOrgCredentialForm
            studentId={studentId}
            toggle={toggleModal}
            team_id={teamId}
          />
        }
        onClose={toggleModal}
      />
    </>
  )
}

export default EventRegistrationListItem
