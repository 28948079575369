import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import { GET_ORGS } from "graphql/GET_ORGS";
import moment from "moment/moment";
import React, { useMemo } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Dimmer, Form, Grid } from "tabler-react";
import { ADD_STUDENT_ORG_CREDENTIAL } from "../../../graphql/ADD_STUDENT_ORG_CREDENTIAL";
import { EDIT_STUDENT_ORG_CREDENTIAL } from "../../../graphql/EDIT_STUDENT_ORG_CREDENTIAL";
import { useRootStore } from "../../../hooks";
import useGetCurrentUserType from "../../../modules/common/hooks/useGetCurrentUserType";
import {
  ABCDLicensesAPFOptions,
  ABCDLicensesCSPAOptions,
  ABCDLicensesDFVOptions,
  ABCDLicensesOptions,
} from "../../constants";

export const FAI_ORG_ID = 167;
export const CSPA_ORG_ID = 2;
export const APF_ORG_ID = 3;
export const DFV_ORG_ID = 16;

const ManualAdLicenseForm = ({ license, licenses }) => {
  const { isStudent, isAdmin } = useGetCurrentUserType();
  const { currentUser } = useRootStore();

  const { data: nacData, loading } = useQuery(GET_ORGS, {
    variables: {
      OrgFilter: {
        org_type: "nac",
      },
    },
  });
  const nacList =
    nacData?.orgs
      .filter((i) => i.org_type === "nac")
      .map((i) => ({
        value: i.id,
        label: i.slug.toUpperCase() + " " + i.name,
      })) || [];
  const [addStudentOrgCredential] = useMutation(ADD_STUDENT_ORG_CREDENTIAL, {
    refetchQueries: ["GET_ORG_CREDENTIALS"],
    onCompleted: () => {
      toast.success("Credential added successfully");
    },
  });

  const [updateStudentOrgCredential] = useMutation(
    EDIT_STUDENT_ORG_CREDENTIAL,
    {
      refetchQueries: ["GET_ORG_CREDENTIALS"],
      onCompleted: () => {
        toast.success("Credential updated successfully");
      },
    },
  );

  const licenseList = useMemo(() => {
    let selectedList = ABCDLicensesOptions;
    const org_id = Number(license?.org_id);
    if (org_id === CSPA_ORG_ID) selectedList = ABCDLicensesCSPAOptions;
    if (org_id === APF_ORG_ID) selectedList = ABCDLicensesAPFOptions;
    if (org_id === DFV_ORG_ID) selectedList = ABCDLicensesDFVOptions;

    return selectedList.map((option) => {
      return {
        value: option,
        label: option,
      };
    });
  }, [license]);

  const renderForm = ({
    handleChange,
    setFieldValue,
    handleSubmit,
    values,
    dirty,
  }) => {
    return (
      <Grid.Row>
        <Grid.Col xs={12} sm={12} md={6} lg={6}>
          <Form.Group label="">
            <Form.InputGroup>
              {Number(values.org_id) === FAI_ORG_ID && (
                <Select
                  className={"w-75"}
                  label="NAC"
                  name="nac_id"
                  placeholder="Select NAC"
                  searchText={false}
                  options={nacList}
                  onChange={(e) => {
                    setFieldValue("nac_id", e.value);
                  }}
                  value={
                    nacList.find(
                      (i) => Number(i.value) === Number(values.nac_id),
                    ) || null
                  }
                />
              )}

              {Number(values.org_id) !== FAI_ORG_ID && (
                <Select
                  className={"w-50"}
                  label="License"
                  name="license"
                  placeholder="Add license..."
                  searchText={false}
                  options={licenseList.filter(
                    (i) => !licenses.find((l) => l.license === i.value),
                  )}
                  onChange={(e) => {
                    setFieldValue("license", e.value);
                  }}
                  value={
                    licenseList.find((i) => i.value === values.license) || null
                  }
                />
              )}

              {values.id && (
                <Form.InputGroupAppend>
                  <Button
                    icon={values.verified_on ? "check-circle" : "alert-circle"}
                    color="white"
                    className={
                      values.verified_on ? "text-success" : "text-danger"
                    }
                    disabled={values.verified_on || isStudent}
                    onClick={() => {
                      const licenseClone = { ...values };
                      licenseClone.verified_by = currentUser.id.toString();
                      licenseClone.verified_on = moment().format("YYYY-MM-DD");
                      updateStudentOrgCredential({
                        variables: {
                          updateOrgCredentialInput: licenseClone,
                        },
                      });
                    }}
                  >
                    {values.verified_on
                      ? ""
                      : !values.verified_on && isStudent
                      ? "Unverified"
                      : "Verify"}
                  </Button>
                </Form.InputGroupAppend>
              )}
            </Form.InputGroup>
          </Form.Group>
        </Grid.Col>
        <Grid.Col sm={6} md={6}>
          <Form.Group label="">
            <Form.InputGroup>
              <Form.Input
                name="license_number"
                placeholder=""
                value={values?.license_number}
                onChange={handleChange}
                disabled={values.verified_on && !isAdmin}
              />
              {dirty && (
                <Form.InputGroupAppend>
                  <Button
                    color="primary"
                    className="ml-2"
                    onClick={handleSubmit}
                  >
                    {values.id ? "UPDATE" : "SAVE"}
                  </Button>
                </Form.InputGroupAppend>
              )}
            </Form.InputGroup>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
    );
  };
  return (
    <Dimmer loader={loading} active={loading}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          id: license?.id || undefined,
          org_id: license?.org_id,
          student_id: license?.student_id,
          verified_on: license?.verified_on || null,
          verified_by: license?.verified_by || null,
          license: license?.license || "",
          license_number: license?.license_number || "",
          nac_id: license?.nac_id || null,
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (license.id) {
            if (isStudent) {
              values.verified_by = null;
              values.verified_on = null;
            }
            updateStudentOrgCredential({
              variables: {
                updateOrgCredentialInput: {
                  ...values,
                },
              },
            });
          } else {
            if (!isStudent) {
              values.verified_by = currentUser.id.toString();
              values.verified_on = moment().format("YYYY-MM-DD");
            }
            addStudentOrgCredential({
              variables: {
                addOrgCredentialInput: {
                  ...values,
                },
              },
            });
          }
          resetForm();
          setSubmitting(false);
        }}
      >
        {(formData) => renderForm(formData)}
      </Formik>
    </Dimmer>
  );
};

export default ManualAdLicenseForm;
