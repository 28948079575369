import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import { GET_STUDENTS_ON_DASHBOARD } from 'graphql/GET_STUDENTS_ON_DASHBOARD'
import { observer } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import moment from 'moment'
import { useContext } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Button, Card, Form, Grid, Text } from 'tabler-react'
import { capitalizeName } from 'utils/stringFormat'
import * as Yup from 'yup'
import { countries, EHostnames } from '../../../components/constants'
import FormField from '../../../components/FormField'
import autocompleteStyles from '../../../components/Students/Forms/autocompleteStyles'
import { EUserTypes } from '../../../components/User/userTypes'
import { UserStoreContext } from '../../../contexts/userStoreContext'
import { GET_STUDENT_BY_ID } from '../../../graphql/GET_STUDENT_BY_ID'
import { UPDATE_STUDENT_MUTATION } from '../../../graphql/UPDATE_STUDENT_MUTATION'
import { useRootStore } from '../../../hooks/useRootStore'
import useGetCurrentUserType from '../../../modules/common/hooks/useGetCurrentUserType'
import { formatHeight } from '../../../utils/numberFormat'
import UserVerify from '../UserVerify'

interface IUserProfileFormProp {
  userProfile?: any
}

const UserProfileForm = ({ userProfile }: IUserProfileFormProp) => {
  const { user } = useContext(UserStoreContext)
  const { isAdmin, isCoachSuperAdmin, isStudent } = useGetCurrentUserType()
  const rootStore = useRootStore()
  const { currentUser, currentCoachTeam, domainData } = rootStore

  const updateStudentValidationSchema = Yup.object().shape({
    first_name: Yup.string().required('This field is required.'),
    last_name: Yup.string().required('This field is required.')
  })

  const profile = userProfile

  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION, {
    onCompleted: () => toast.success('Profile Updated.')
  })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        address1: profile?.address1,
        address2: profile?.address2,
        city: profile?.city,
        country: profile?.country,
        dob: profile?.dob ? moment(profile?.dob).format('MM/DD/YYYY') : '',
        email: profile?.email,
        first_name: profile?.first_name,
        id: Number(profile?.id),
        jump_weight: profile?.jump_weight,
        last_name: profile?.last_name,
        postal_code: profile?.postal_code,
        province: profile?.province,
        weight: profile?.weight,
        preferred_name: profile?.preferred_name,
        middle_name: profile?.middle_name,
        height: profile?.height,
        preference_24h: profile?.preference_24h,
        preference_units: profile?.preference_units,
        shirt_size_id: profile?.shirt_size_id,
        stance: profile?.stance,
        reach: profile?.reach
      }}
      validationSchema={updateStudentValidationSchema}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        let date = null
        if (!values.dob) {
          values.dob = null
        } else {
          date = moment(values.dob)
          if (!date.isValid()) {
            setFieldError('dob', 'Please enter a valid date')
            setSubmitting(false)
            return
          }
        }

        if (!values.height) {
          values.height = null
        }

        if (!values.weight) {
          values.weight = null
        }

        if (!values.jump_weight) {
          values.jump_weight = null
        }

        if (!values.shirt_size_id) {
          values.shirt_size_id = null
        }

        const refetch = ![
          EUserTypes.student.toString(),
          EUserTypes.admin.toString()
        ].includes(currentUser.type)
          ? [
              {
                query: GET_STUDENTS_ON_DASHBOARD,
                variables: {
                  team_id: currentCoachTeam?.id
                }
              }
            ]
          : [
              {
                query: GET_STUDENT_BY_ID,
                variables: {
                  student_id: values.id
                }
              }
            ]
        //
        // if (currentUser.type === EUserTypes.admin)
        //   refetch = [];

        await updateStudent({
          variables: {
            student: {
              ...values,
              dob: date ? date.format('YYYY-MM-DD') : date,
              first_name: capitalizeName(values.first_name),
              last_name: capitalizeName(values.last_name)
            }
          },
          refetchQueries: refetch
        })

        applySnapshot(user, {
          ...user,
          ...values,
          id: values.id.toString(),
          dob: date ? date.format('YYYY-MM-DD') : date
        })

        setSubmitting(false)
        //resetForm()
      }}
    >
      {({
        values,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched
      }) => (
        <>
          <form onSubmit={handleSubmit}>
            {location.pathname === '/user/settings' && (
            <Card.Header>
              {!isStudent &&
                <Card.Title><span className='text-muted'>This is your global user account</span></Card.Title>
              }
              <Card.Options>
                <Button
                  icon="user-check"
                  color="white"
                  disabled
                >
                  SKY.D: {values.id}
                </Button>
              </Card.Options>
            </Card.Header>
            )}
            <Card.Body>
              <Grid.Row>
                <Grid.Col xs={12} sm={12} md={12} lg={4}>
                  <FormField
                    name="first_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="First Name"
                    type="text"
                    value={values.first_name || ''}
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={12} lg={4}>
                  <FormField
                    name="middle_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Middle Name (optional)"
                    type="text"
                    value={values.middle_name || ''}
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={12} lg={4}>
                  <FormField
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Last Name"
                    value={values.last_name || ''}
                    type="text"
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                  <FormField
                    name="preferred_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Preferred Name (optional)"
                    type="text"
                    value={values.preferred_name || ''}
                  />
                </Grid.Col>
                {(isAdmin || isCoachSuperAdmin) && (
                  <Grid.Col xs={12} sm={12} md={12} lg={6}>
                    <FormField
                      name="company_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Company Name"
                      type="text"
                      // value={values.company_name || ""}
                    />
                    <Button color="white" className="text-primary">
                      Change User to Company
                    </Button>
                  </Grid.Col>
                )}
              </Grid.Row>
              <hr />
              <Grid.Row>
                <Grid.Col xs={12} sm={12} md={12} lg={6}>
                  <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Select
                      backspaceRemovesValue={true}
                      escapeClearsValue={true}
                      getOptionLabel={(option: { name: string }) => option.name}
                      getOptionValue={(option: { name: string }) => option.name}
                      isClearable={true}
                      name="country"
                      options={countries}
                      onChange={(params: { name: string }) => {
                        setFieldValue('country', params?.name ?? null)
                      }}
                      styles={autocompleteStyles}
                      value={
                        values.country &&
                        countries[
                          Object.keys(countries).find(
                            (key) => countries[key].name === values.country
                          )
                        ]
                      }
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col xs={12} sm={12} md={12} lg={6} ignoreCol={true}>
                  <FormField
                    label="Address 1"
                    name="address1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Address 1"
                    type="text"
                    value={values.address1 || ''}
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={12} lg={6} ignoreCol={true}>
                  <FormField
                    label="Address 2"
                    name="address2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="(optional)"
                    type="text"
                    value={values.address2 || ''}
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={12} lg={6} ignoreCol={true}>
                  <FormField
                    label="City"
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="City"
                    type="text"
                    value={values.city || ''}
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={12} lg={3}>
                  <FormField
                    label={
                      values.country === 'United States'
                        ? 'State'
                        : values.country === 'Canada'
                        ? 'Province'
                        : values.country === 'Australia'
                        ? 'State'
                        : 'State/Province'
                    }
                    name="province"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder=""
                    type="text"
                    value={values.province || ''}
                  />
                </Grid.Col>
                <Grid.Col md={3}>
                  <FormField
                    label={
                      values.country === 'United States'
                        ? 'Zip Code'
                        : values.country === 'Canada'
                        ? 'Postal Code'
                        : values.country === 'Australia'
                        ? 'Postcode'
                        : 'Zip/Postal Code'
                    }
                    name="postal_code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder=""
                    type="text"
                    value={values.postal_code || ''}
                  />
                </Grid.Col>
              </Grid.Row>
              {currentUser.type === EUserTypes.admin && (
                <>
                  <hr />
                  <Grid.Row>
                    <Grid.Col md={3}>
                      <Form.Switch
                        name="preference_24h"
                        label="24H Preferrence"
                        checked={values.preference_24h}
                        onChange={(e) => {
                          setFieldValue('preference_24h', e.target.checked)
                        }}
                        className={'float-right'}
                      />
                    </Grid.Col>
                    <Grid.Col md={3}>
                      <Form.Switch
                        name="preference_units"
                        label="Metric (cm/kg)"
                        checked={values.preference_units}
                        onChange={(e) => {
                          setFieldValue('preference_units', e.target.checked)
                        }}
                        className={'float-right'}
                      />
                    </Grid.Col>
                  </Grid.Row>
                </>
              )}
              <hr />
              <Grid.Row>
                <Grid.Col sm={12} md={6} ignoreCol={true}>
                  <Form.Group label="D.O.B. (Date of Birth)">
                    <Form.MaskedInput
                      placeholder="MM/DD/YYYY"
                      mask={[
                        /\d/,
                        /\d/,
                        '/',
                        /\d/,
                        /\d/,
                        '/',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/
                      ]}
                      name="dob"
                      value={values.dob}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="field-error text-danger">
                      {errors.dob && touched.dob && errors.dob}
                    </span>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col xs={12} sm={12} md={6} lg={3}>
                  <FormField
                    appendright={
                      <Form.InputGroupAppend>
                        <Form.InputGroupText>in</Form.InputGroupText>
                      </Form.InputGroupAppend>
                    }
                    appendbottom={
                      values.height > 0 ? (
                        <Text.Small className={'ml-2'}>
                          {formatHeight(values.height)}
                        </Text.Small>
                      ) : null
                    }
                    label="Height"
                    name="height"
                    onBlur={handleBlur}
                    onChange={(e) =>
                      setFieldValue(
                        'height',
                        Math.floor(Number(e.target.value))
                      )
                    }
                    placeholder=""
                    type="number"
                    value={values.height || ''}
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={6} lg={3}>
                  <FormField
                    appendright={
                      <Form.InputGroupAppend>
                        <Form.InputGroupText>lbs</Form.InputGroupText>
                      </Form.InputGroupAppend>
                    }
                    appendbottom={
                      values.weight > 0 ? (
                        <Text.Small className={'ml-1'}>
                          {(values.weight * 0.45).toFixed(1) + ' kg'}
                        </Text.Small>
                      ) : null
                    }
                    label="Weight"
                    name="weight"
                    onBlur={handleBlur}
                    onChange={(e) =>
                      setFieldValue(
                        'weight',
                        Math.floor(Number(e.target.value))
                      )
                    }
                    value={values.weight || ''}
                    type="number"
                  />
                </Grid.Col>
                {currentUser.type !== EUserTypes.coach &&
                  domainData.key === EHostnames.SKYCRU && (
                    <Grid.Col xs={12} sm={12} md={6} lg={3}>
                      <FormField
                        appendright={
                          <Form.InputGroupAppend>
                            <Form.InputGroupText>lbs</Form.InputGroupText>
                          </Form.InputGroupAppend>
                        }
                        label="Jump Weight"
                        name="jump_weight"
                        onBlur={handleBlur}
                        onChange={(e) =>
                          setFieldValue(
                            'jump_weight',
                            Math.floor(Number(e.target.value))
                          )
                        }
                        value={values.jump_weight || ''}
                        type="number"
                      />
                    </Grid.Col>
                  )}
                {domainData.key === EHostnames.FAIRFIGHTS && (
                  <>
                    <Grid.Col xs={6} sm={6} md={6} lg={3}>
                      <FormField
                        appendright={
                          <Form.InputGroupAppend>
                            <Form.InputGroupText>cm</Form.InputGroupText>
                          </Form.InputGroupAppend>
                        }
                        label="Reach"
                        name="reach"
                        onBlur={handleBlur}
                        onChange={(e) =>
                          setFieldValue(
                            'reach',
                            Math.floor(Number(e.target.value))
                          )
                        }
                        value={values.reach || ''}
                        type="number"
                      />
                    </Grid.Col>
                    <Grid.Col xs={6} sm={6} md={6} lg={3}>
                      <Form.Group>
                        <Form.Label>Stance</Form.Label>
                        <Select
                          backspaceRemovesValue={true}
                          escapeClearsValue={true}
                          getOptionLabel={(option: { name: string }) =>
                            option.name
                          }
                          getOptionValue={(option: { value: number }) =>
                            option.value
                          }
                          isClearable={true}
                          name="stance"
                          options={[
                            { name: 'Orthodox', value: 'Orthodox' },
                            { name: 'Southpaw', value: 'Southpaw' },
                            { name: 'Switch', value: 'Switch' }
                          ]}
                          onChange={(params: { value: number }) => {
                            setFieldValue('stance', params?.value ?? null)
                          }}
                          styles={autocompleteStyles}
                          value={
                            values.stance && [
                              { name: values.stance, value: values.stance }
                            ]
                          }
                        />
                      </Form.Group>
                    </Grid.Col>
                  </>
                )}
              </Grid.Row>

              {/* HIDE FOR NOW */}
              {/* <Grid.Row>
                <Grid.Col xs={6} sm={6} md={6} lg={4}>
                  <Form.Group>
                    <Form.Label>Shirt size</Form.Label>
                    <Select
                      backspaceRemovesValue={true}
                      escapeClearsValue={true}
                      getOptionLabel={(option: { name: string }) => option.name}
                      getOptionValue={(option: { value: number }) =>
                        option.value
                      }
                      isClearable={true}
                      name="shirt_size_id"
                      options={SHIRT_SIZES}
                      onChange={(params: { value: number }) => {
                        setFieldValue('shirt_size_id', params?.value ?? null)
                      }}
                      styles={autocompleteStyles}
                      value={
                        values.shirt_size_id &&
                        SHIRT_SIZES.filter(
                          (size) => size.value === values.shirt_size_id
                        )
                      }
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row> */}
            </Card.Body>
            <Card.Footer>
              <Button.List align="right">
                {![
                  EUserTypes.student.toString(),
                  EUserTypes.dzm.toString()
                ].includes(currentUser.type) && (
                  <UserVerify userId={userProfile.id} />
                )}
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  pill
                  color="gray-dark"
                  size="sm"
                  type="submit"
                >
                  UPDATE
                </Button>
              </Button.List>
            </Card.Footer>
          </form>
        </>
      )}
    </Formik>
  )
}

export default observer(UserProfileForm)
