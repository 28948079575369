import React from 'react';
import { Button, Form } from 'tabler-react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { DEFAULT_LIMIT } from '../../constants';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { EDIT_USER_POST } from '../../../graphql/EDIT_USER_POST';
import { GET_USER_POSTS } from '../../../graphql/GET_USER_POSTS';

const EditUserPostValidationSchema = Yup.object().shape({
  content: Yup.string().required('This field is required.')
});

interface IUserPostForm {
  userPost: any;
  setEditting: any;
}

const UserPostForm = ({ userPost, setEditting }: IUserPostForm) => {
  const [editUserPost] = useMutation(EDIT_USER_POST);
  const initValues = {
    ...userPost
  };


  const renderForm = ({ values, handleSubmit, handleChange, errors }) => (
    <>
      <Form.Textarea
        name='content'
        placeholder='Add notes'
        rows={3}
        type='text'
        onChange={handleChange}
        value={values.content}
      />
      {errors.content && (
        <span className='field-error text-danger'>{errors.content}</span>
      )}
      <Button.List className='mt-4' align='right'>
        <Button
          pill
          color='white'
          size='sm'
          onClick={() => setEditting(false)}
        >
          CLOSE
        </Button>
        <Button
          pill
          color='primary'
          size='sm'
          type='submit'
          onClick={() => handleSubmit()}
        >
          SAVE
        </Button>
      </Button.List>
    </>
  );
  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={EditUserPostValidationSchema}
        initialValues={initValues}
        onSubmit={async (values, onSubmitHelpers) => {

          onSubmitHelpers.setFieldValue('note', ' ');
          await editUserPost({
            variables: {
              updateUserPostInput: {
                id: values.id,
                content: values.content
              }
            },
            refetchQueries: [
              {
                query: GET_USER_POSTS, variables: {
                  userPostOptions: {
                    camp_id: userPost.camp_id,
                    limit: DEFAULT_LIMIT,
                    offset: 0
                  }
                }
              }
            ]
          });
          onSubmitHelpers.resetForm({ values: initValues });
          setEditting(false);
        }}
      >
        {(formikData) => renderForm(formikData)}
      </Formik>

    </>
  );
};

export default observer(UserPostForm);
