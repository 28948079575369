import { useQuery } from '@apollo/client'
import { EUserTypes } from 'components/User/userTypes'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useHistory, useParams } from 'react-router'
import {
  Alert,
  Button,
  Card,
  Container,
  Dimmer,
  Grid,
  Header,
  Icon,
  Media,
  Page,
  Text
} from 'tabler-react'

import GoogleMap from '../../components/GoogleMap'
import { locationStoreContext } from '../../contexts/LocationStoreContext'
import { GET_CAMPS } from '../../graphql/GET_CAMPS'
import { GET_LOCATION_BY_ID } from '../../graphql/GET_LOCATION_BY_ID'
import { useRootStore } from '../../hooks/useRootStore'
import CalendarLink from '../Calendar/CalendarLink'

import StarLocation from './Form/StarLocation'
import { RootStoreType } from '../../models'
import { LocationStoreType } from '../../models/LocationStore'
import { Types } from '../../types/graphql'
import moment from 'moment/moment'

const LocationListItemView = () => {
  const history = useHistory()
  const locationStore: LocationStoreType = useContext(locationStoreContext)
  const { locationId }: any = useParams()
  const rootStore: RootStoreType = useRootStore()
  const { currentUser } = rootStore

  const [location, setLocation] = useState(null)
  const { loading, data } = useQuery(GET_LOCATION_BY_ID, {
    variables: {
      id: locationId
    }
  })

  const { loading: campLoading, data: campData } = useQuery(GET_CAMPS, {
    variables: {
      filter: {
        isDateAgnostic: false,
        isPresent: true,
        isPublished: true,
        location_id: Number(locationId)
      }
    }
  })

  const camps: Types.Camp[] = useMemo((): Types.Camp[] => {
    if (!campLoading && campData) {
      const campsList: Types.Camp[] = [...(campData?.getCamps || [])].filter(
        (event) => {
          return moment(event.end) >= moment()
        }
      )

      if (campsList.length > 1) {
        return campsList.sort((a: Types.Camp, b: Types.Camp): number => {
          const da: Date = new Date(a.start)
          const db: Date = new Date(b.start)

          return da > db ? 1 : -1
        })
      }

      return campsList
    }

    return []
  }, [campData, campLoading])

  useEffect((): void => {
    if (!loading && data?.location) {
      locationStore.storeLocation(data.location)
      setLocation(locationStore.getLocationById(locationId))
    }
    // Eslint-disable-next-line
  }, [loading, data])

  return (
    <Dimmer active={loading} loader={loading}>
      {location ? (
        <>
          <Page.MapHeader>
            <Card.Map>
              <GoogleMap
                blackAndWhite={true}
                lat={location.latitude}
                lng={location.longitude}
              />
            </Card.Map>
          </Page.MapHeader>
          <Container>
            <Grid.Row cards>
              <Grid.Col xs={12} sm={12} md={6} lg={4}>
                <Card>
                  <Card.Header>
                    <Card.Title>{`${location.type.toUpperCase()}`}</Card.Title>
                    <Card.Options>
                      <Button.List>
                        {currentUser?.type === EUserTypes.student && (
                          <StarLocation locationId={Number(locationId)} />
                        )}
                        {currentUser?.type === EUserTypes.admin && (
                          <Button color="white" disabled>
                            EDIT
                          </Button>
                        )}
                      </Button.List>
                    </Card.Options>
                  </Card.Header>
                  {/*
                  <Card.Map>
                    <GoogleMap lat={location.latitude} lng={location.longitude} />
                  </Card.Map>
                  */}
                  <Card.Body>
                    <Media className="mb-5">
                      <Media.Body>
                        <Header.H5>
                          {location.name}{' '}
                          {location.owner_id ? (
                            <i className="fe fe-user-check text-success ml-2" />
                          ) : null}
                        </Header.H5>
                        <address className="text-muted small">
                          {location.address}
                          <br />
                          {location.city} {location.province}
                        </address>
                        <address className="text-muted small">
                          {location.country}
                        </address>
                      </Media.Body>
                    </Media>

                    <Grid.Row>
                      <Grid.Col width={6}>
                        <Header.H6></Header.H6>
                        <p>
                          <a href={`tel:${location.phone}`}>{location.phone}</a>
                        </p>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col>
                        <p>
                          <a
                            href={location.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {location.url}
                          </a>
                        </p>
                      </Grid.Col>
                    </Grid.Row>
                  </Card.Body>
                  {currentUser?.type === EUserTypes.admin && (
                    <Card.Footer>
                      <Grid.Col width={12}>
                        {`${location.latitude}, ${location.longitude}`}
                      </Grid.Col>
                    </Card.Footer>
                  )}
                </Card>
                {/* add location.burble_dz_id and location.dizio_loadcall */}
                {location.burble_dz_id && (
                  <Button
                    block
                    icon="upload-cloud"
                    color="primary"
                    className="mb-3 mx-0 text-white"
                    RootComponent="a"
                    href={`https://dzm.burblesoft.com/jmp?dz_id=${location.burble_dz_id}`}
                    //  href={`https://${location.dizio_loadcall}.reservations.dizio.app/loadcall`}
                    target="_blank"
                  >
                    View Live Manifest
                  </Button>
                )}
                {location.dizio_loadcall && (
                  <Button
                    block
                    icon="upload-cloud"
                    color="primary"
                    className="mb-3 mx-0 text-white"
                    RootComponent="a"
                    href={`https://${location.dizio_loadcall}.reservations.dizio.app/loadcall`}
                    target="_blank"
                  >
                    View Live Manifest
                  </Button>
                )}
                {!location.owner_id && location.type === 'dropzone' && (
                  <Card>
                    <Card.Body>
                      <Alert icon="alert-circle" type="primary">
                        <strong>
                          <a href="https://skycru.com/dzm" target="_blank">
                            Claim this Dropzone!
                          </a>
                        </strong>
                      </Alert>
                      <Text>
                        REFER YOUR DZ! If they register for a SKYCRU DZM
                        account, you can receive a full day of Jump Tickets!
                      </Text>
                    </Card.Body>
                  </Card>
                )}
                {!location.owner_id && location.type === 'tunnel' && (
                  <Card>
                    <Card.Body>
                      <Alert icon="alert-circle" type="primary">
                        <strong>
                          <a href="https://skycru.com/tunnel" target="_blank">
                            Claim this Tunnel!
                          </a>
                        </strong>
                      </Alert>
                      <Text>
                        REFER YOUR TUNNEL! If they register for a SKYCRU TUNNEL
                        account, you can receive up to an hour of tunnel time!
                      </Text>
                    </Card.Body>
                  </Card>
                )}
                {camps.length > 0 && (
                  <Card>
                    <Card.Header>
                      <Card.Title>
                        <Icon
                          name="calendar"
                          className="mr-2 ml-0 text-primary"
                        />
                        Upcoming Events
                      </Card.Title>
                      <Card.Options>
                        <Button
                          size={'sm'}
                          color={'white'}
                          className={'cursor-pointer float-right'}
                          onClick={() => {
                            rootStore.setFilter(
                              'calendarLocationFilter',
                              Number(locationId)
                            )
                            history.push('/calendar')
                          }}
                        >
                          Calendar
                        </Button>
                      </Card.Options>
                    </Card.Header>
                    <Card.Body>
                      <Dimmer active={campLoading} loader={campLoading}>
                        {camps.map(
                          (event: Types.Camp): JSX.Element => (
                            <Grid.Row key={event.id} className={'mb-4'}>
                              <Grid.Col>
                                <CalendarLink
                                  eventName={event.camp_name}
                                  eventId={Number(event.id)}
                                />
                                <Text.Small className="d-block text-muted">
                                  <Moment format="MMM D">{event.start}</Moment>
                                  {' - '}
                                  {event.team_name}
                                </Text.Small>
                              </Grid.Col>
                            </Grid.Row>
                          )
                        )}
                      </Dimmer>
                    </Card.Body>
                  </Card>
                )}
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={6} lg={8}>
                <Card>
                  <Card.Header>
                    <Card.Title>About {location.name}</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Grid.Row>
                      <Grid.Col>
                        <p>{location.description}</p>
                      </Grid.Col>
                    </Grid.Row>
                  </Card.Body>
                </Card>
                {currentUser?.type === EUserTypes.admin &&
                  location.type === 'dropzone' && (
                    <Card>
                      <Card.Header>
                        <Card.Title>Are you ready to Jump?</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Grid.Row>
                          <Grid.Col>
                            <Alert icon="x-circle" type="danger">
                              <strong>USPA:</strong> Unknown
                            </Alert>
                            <Alert icon="x-circle" type="danger">
                              <strong>RESERVE:</strong> Unknown
                            </Alert>
                            <Alert icon="x-circle" type="danger">
                              <strong>CURRENCY:</strong> Unknown
                            </Alert>
                            <Alert icon="x-circle" type="danger">
                              <strong>WAIVER:</strong> Contact Manifest
                            </Alert>
                          </Grid.Col>
                        </Grid.Row>
                      </Card.Body>
                    </Card>
                  )}
              </Grid.Col>
            </Grid.Row>
          </Container>
        </>
      ) : (
        <Alert type="danger">{`location with id: ${locationId} not found`}</Alert>
      )}
    </Dimmer>
  )
}

export default observer(LocationListItemView);
