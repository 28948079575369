import React from 'react'
import { Card, Header, Text } from 'tabler-react'
import moment from 'moment'
import { Types } from '../../types/graphql'


/**
 * Props for the Greeting component.
 */
interface OrgCredentialMembershipCardProps {
  /**
   * membership entry for org
   */
  membershipCredential: Types.OrgCredential;
  /**
   * org item
   */
  org: Types.TeamOrg;
  /**
   * list of licenses for current org
   */
  licenses?: Types.OrgCredential[];
}

const OrgCredentialMembershipCard = ({
  org,
  membershipCredential,
                                       licenses
}: OrgCredentialMembershipCardProps) => {
  return (
    <Card>
      <Card.Body>
        <Header.H5>
          {org.slug ? org.slug.toUpperCase() + ': ' : 'Membership: '}
          <strong>{membershipCredential.membership}</strong>
        </Header.H5>
        <Text className="mb-3">
          Expires:{' '}
          <span
            className={`${
              moment(membershipCredential.expires).isBefore(moment())
                ? 'text-danger'
                : 'text-success'
            }`}
          >
            {moment(membershipCredential.expires)
              .locale('en')
              .format('MMM D, YYYY') ?? null}
          </span>
        </Text>
        {licenses.length > 0 && (
          <span className="mt-3">
            {licenses.map((license) => (
              <div key={license.id}>
                <i className="fe fe-award text-primary mr-2" />
                {license.license}{' '}
                <span
                  className={`${
                    license.expires
                      ? moment(license.expires).isBefore(moment())
                        ? 'text-danger'
                        : 'text-success'
                      : 'text-muted'
                  }`}
                >
                  {license.expires
                    ? moment(license.expires).locale('en').format('MMM D, YYYY')
                    : 'Unknown'}
                </span>
              </div>
            ))}
          </span>
        )}
      </Card.Body>
      <Card.Footer>
        <Text.Small className="text-muted">{org.name}</Text.Small>
      </Card.Footer>
    </Card>
  )
};

export default OrgCredentialMembershipCard;
