import { useQuery } from '@apollo/client'
import GroupFormModal from 'components/Calendar/Modal/GroupFormModal'
import { GET_GROUPS } from 'graphql/GET_GROUPS'
import React, { useMemo, useState } from 'react'
import { Button, Card, Dimmer, Grid, Tag } from 'tabler-react'
import useRouter from 'use-react-router'
import { GET_PAYMENT_FEE_BY_TEAM_ID } from '../../graphql/GET_PAYMENT_FEE_BY_TEAM_ID'
import { GET_STRIPE_PUBLIC_KEY } from '../../graphql/GET_STRIPE_PUBLIC_KEY'
import { GET_PAYMENT_FEE_BY_TEAM_ID as GET_PAYMENT_FEE_BY_TEAM_ID_TYPE } from '../../graphql/types/GET_PAYMENT_FEE_BY_TEAM_ID'
import { Types } from '../../types/graphql'
import { Loading } from '../Loading'
import CompetitionGroupsListItem from './CompetitionGroupsListItem'
import Select from 'react-select'
import { useGetGroupsByDiscipline } from '../../modules/group/hooks/groups'
import GroupsPDFButton from '../../modules/group/components/buttons/GroupsPDFButton'

interface CompetitionGroupsListProps {
  campId: string
  teamId: string
  campName: string
  students: Types.CampStudents[]
  groupStatus: string
}

const CompetitionGroupsList = ({
  campId,
  teamId,
  campName,
  students,
  groupStatus
}: CompetitionGroupsListProps) => {
  const { history } = useRouter()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { data, loading } = useQuery(GET_GROUPS, {
    variables: {
      filter: {
        camp_id: Number(campId),
        team_id: teamId,
        order_source: 'discipline'
      }
    }
  })

  const groupList = useMemo(() => {
    if (data) {
      return data.groupList.filter((group) => group.status === groupStatus)
    }
    return []
  }, [data?.groupList, loading, groupStatus])
  const { loading: loading1, data: stripeData } = useQuery(
    GET_STRIPE_PUBLIC_KEY
  )

  const stripeKey = useMemo(
    () => stripeData?.getStripePublicKey,

    [stripeData?.getStripePublicKey]
  )

  const { loading: loading2, data: paymentData } =
    useQuery<GET_PAYMENT_FEE_BY_TEAM_ID_TYPE>(GET_PAYMENT_FEE_BY_TEAM_ID, {
      variables: {
        teamId: Number(teamId)
      }
    })

  const paymentFee =
    paymentData?.getPaymentFeeByTeamId.payment_fee_customer / 100

  const { disciplines, groupsByDiscipline } =
    useGetGroupsByDiscipline(groupList)

  const [filterDiscipline, setFilterDiscipline] = useState<number | null>(null)

  if (loading1 || loading2) {
    return <Loading />
  }

  return (
    <Card.Body>
      <Dimmer active={loading} loading={loading}>
        {data && (
          <>
            <Grid.Row>
              <Grid.Col
                xs={12}
                sm={12}
                md={2}
                lg={2}
                className={'d-print-none'}
              >
                <Button
                  block
                  icon="plus"
                  color="secondary"
                  className="mb-5"
                  onClick={() => {
                    setIsModalOpen(true)
                  }}
                >
                  GROUP
                </Button>
                {students.length > 0 && (
                  <div>
                    <h4>Participants</h4>
                    <div className="overflow-scrolly pr-1">
                      {students.map((student) => {
                        // Check if student is in multiple groups
                        // If so, display indicator icon
                        const studentGroups = groupList.filter((group) => {
                          return group.members.some(
                            (member) => Number(member.id) === student.student_id
                          )
                        })
                        return (
                          <div key={`list-${student.id}`}>
                            <Button
                              block
                              outline={studentGroups.length === 0}
                              icon={
                                studentGroups.length === 1
                                  ? 'user'
                                  : studentGroups.length > 1
                                  ? 'users'
                                  : 'user-x'
                              }
                              color={
                                studentGroups.length > 1 ? 'info' : 'primary'
                              }
                              size="sm"
                              className="mb-2 text-left"
                              onClick={() => {
                                history.push(
                                  `/events/registrations/${student.user_registrations_id}/${student.student_id}`
                                )
                              }}
                            >
                              {`${student.first_name} ${student.last_name}`}
                            </Button>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={10} lg={10}>
                <Grid.Row className="pb-3 pt-0 d-print-none">
                  {disciplines.length > 0 && (
                    <>
                      <Grid.Col
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        className="text-right pb-2"
                      ></Grid.Col>
                      <Grid.Col
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        className="d-print-none text-right pb-2"
                      >
                        <span className="float-right">
                          {groupsByDiscipline && disciplines && (
                            <GroupsPDFButton
                              groups={groupsByDiscipline}
                              disciplines={disciplines}
                              filter={filterDiscipline}
                              campName={campName}
                            />
                          )}
                        </span>
                        {}
                        <Select
                          getOptionLabel={(option) => (
                            <>
                              <span>{option.label}</span>
                              <Tag className={'float-right'}>
                                {groupsByDiscipline[option.value].length}
                              </Tag>
                            </>
                          )}
                          name="discipline"
                          isClearable={true}
                          options={disciplines}
                          placeholder="Filter by Discipline"
                          onChange={(param) => {
                            if (!param) {
                              setFilterDiscipline(null)
                              return
                            }
                            setFilterDiscipline(param.value)
                          }}
                          value={
                            disciplines.find(
                              (d) =>
                                Number(d.value) === Number(filterDiscipline)
                            ) ?? null
                          }
                          styles={{
                            menuPortal: (base) => ({ ...base, Index: 9999 })
                          }}
                          menuPortalTarget={document.body}
                          className="float-right w-75"
                        />
                      </Grid.Col>
                    </>
                  )}
                </Grid.Row>
                {disciplines
                  .filter((discipline) => {
                    if (filterDiscipline) {
                      return discipline.value === filterDiscipline
                    }
                    return true
                  })
                  .map((discipline) => {
                    return (
                      <>
                        <Grid.Row key={discipline.value}>
                          <Grid.Col>
                            <h2>{discipline.label}</h2>
                          </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                          {groupsByDiscipline[discipline.value].map((group) => (
                            <Grid.Col md={4} key={group.id}>
                              <div>
                                <CompetitionGroupsListItem
                                  group={group}
                                  groupList={groupList}
                                  stripeKey={stripeKey}
                                  paymentFee={paymentFee}
                                />
                              </div>
                            </Grid.Col>
                          ))}
                        </Grid.Row>
                      </>
                    )
                  })}
              </Grid.Col>
            </Grid.Row>
            <GroupFormModal
              isOpen={isModalOpen}
              toggle={setIsModalOpen}
              campId={campId}
              teamId={teamId}
            />
          </>
        )}
      </Dimmer>
    </Card.Body>
  )
}

export default CompetitionGroupsList
