import { useMutation } from '@apollo/client'
import { CRM_CONTACT_TYPES } from 'components/constants'
import { EDIT_USER_NOTE } from 'graphql/EDIT_USER_NOTE'
import { GET_USER_NOTES } from 'graphql/GET_USER_NOTES'
import { useRootStore } from 'hooks'
import { observer } from 'mobx-react'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import moment from 'moment/moment'
import React, { useMemo, useState } from 'react'
import { Card, Tag, Text } from 'tabler-react'
import { ADD_USER_EVENT } from '../../graphql/ADD_USER_EVENT'
import { GET_ALL_USER_REGISTRATION_EVENTS } from '../../graphql/GET_ALL_USER_REGISTRATION_EVENTS'
import { Types } from '../../types/graphql'
import { EUserEventsId } from '../User/UserEvents'
import StudentNoteEditForm from './Forms/StudentNoteEditForm'
import { useGetStudentOverride } from '../../modules/user/hooks/user'

interface IStudentNoteListItemProps {
  userNote: Types.UserNote
  filter: {
    student_id: number
    registration_id: number
    team_id: number
    limit: number
    offset: number
  }
}

const StudentNoteListItem = ({
  userNote,
  filter
}: IStudentNoteListItemProps) => {
  const rootStore = useRootStore()
  const { currentUser } = rootStore

  const { isAdmin, isCoachAdmin, isCoachSuperAdmin, isStudent } =
    useGetCurrentUserType()
  const isAuthor = currentUser.id === userNote.created_by

  const [editting, setEditting] = useState(false)
  const [userEvent] = useMutation(ADD_USER_EVENT)

  const [editUserNote] = useMutation(EDIT_USER_NOTE, {
    onCompleted: (result) => {
      if (result.editUserNote) {
        userEvent({
          variables: {
            userEvent: {
              user_event_type_id: EUserEventsId.unote_update,
              student_id: userNote.student_id,
              status: 'Updated',
              team_id: filter.team_id,
              user_registration_id: Number(userNote.registration_id)
            }
          },
          refetchQueries: [
            {
              query: GET_ALL_USER_REGISTRATION_EVENTS,
              variables: {
                filter: {
                  userRegistrationId: userNote.registration_id
                },
                limit: 20,
                page: 1
              }
            }
          ]
        })
      }
    }
  })

  const handleTogglePublic = async () => {
    await editUserNote({
      variables: {
        updateUserNoteInput: {
          id: userNote.id,
          is_public: userNote.is_public === 1 ? 0 : 1
        }
      },
      refetchQueries: [
        {
          query: GET_USER_NOTES,
          variables: {
            userNoteOptions: filter
          }
        }
      ]
    })
  }

  const crmType = useMemo(() => {
    return CRM_CONTACT_TYPES.find(
      (type) => type.value === userNote.crm_contact_type_id
    )
  }, [userNote])
  const isStudentOverride = useGetStudentOverride(userNote.student_id)
  const canEdit =
    !isStudentOverride &&
    (isAdmin || isCoachAdmin || isCoachSuperAdmin || isAuthor)

  return (
    <div>
      <Card className="p-0 mb-0">
        <Card.Body
          className={
            userNote.is_public === 0 ? 'bg-secondary text-white' : 'bg-light'
          }
        >
          {editting ? (
            <StudentNoteEditForm
              studentNote={userNote}
              setEditting={setEditting}
              filter={filter}
            />
          ) : (
            <Text
              className={'ws-prewrap ' + (canEdit && 'cursor-pointer')}
              onClick={() => {
                if (canEdit) {
                  setEditting(true)
                }

                return
              }}
            >
              {userNote.note}
            </Text>
          )}
        </Card.Body>
      </Card>
      <Text.Small className="float-right m-1">
        {userNote.registration_id && !isStudentOverride && (
          <i
            className={`fe ${
              userNote.is_public === 0
                ? 'fe-lock text-secondary'
                : 'fe-users text-muted'
            } mr-2 cursor-pointer`}
            onClick={() => handleTogglePublic()}
          />
        )}
        <span className="text-muted">
          {userNote.is_public === 0 ? 'Private Note' : 'Shared Note'}
        </span>
      </Text.Small>
      <Text.Small className="float-left text-muted m-1 pb-3">
        {moment(userNote.created_on).format('MMM DD, YYYY - h:mm')}
        {' by '}
        <strong>{userNote.coach.first_name}</strong>
        <Tag className="ml-3">
          {userNote?.registration_id && userNote?.camp_name}
        </Tag>
        <Tag className="ml-3">
          {userNote?.crm_contact_type_id && crmType?.label}
        </Tag>
      </Text.Small>
    </div>
  )
}

export default observer(StudentNoteListItem)
