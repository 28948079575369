import React from 'react'
import { CSVLink } from 'react-csv'
import { useQuery } from '@apollo/client'
import { GET_TEAM_REGISTRATION_HISTORY } from '../../../../graphql/GET_TEAM_REGISTRATION_HISTORY'

const EventHistoryCsvButton = ({ teamId, campId, start }) => {
  const { data, loading } = useQuery(GET_TEAM_REGISTRATION_HISTORY, {
    variables: {
      teamId,
      campId,
      start
    }
  })
  const csvData = data?.getCampRegitrationHistory.csvData || []
  return (
    <CSVLink
      data={csvData}
      filename="event_history.csv"
      className="ml-0 text-secondary"
      onClick={(event) => {
        if (loading) event.preventDefault()
      }} // Prevent download
    >
      <i className={'mr-2 fe fe-' + (loading ? 'loader' : 'download')} />
      Event History
    </CSVLink>
  )
}

export default EventHistoryCsvButton
