import React, { useMemo } from 'react'
import { Types } from '../../../../types/graphql'
import useRouter from 'use-react-router'
import { useQuery } from '@apollo/client'
import { GET_SPORTS_BY_STUDENT_ID as GET_SPORTS_BY_STUDENT_ID_TYPE } from '../../../../graphql/types/GET_SPORTS_BY_STUDENT_ID'
import { GET_SPORTS_BY_STUDENT_ID } from '../../../../graphql/GET_SPORTS_BY_STUDENT_ID'
import { Button, Dimmer, Form, Header } from 'tabler-react'

interface UserCalendarSportsFilterProps {
  studentId: number
  onClickProps?: (id: number) => void
  activeFilter?: number[]
}

const UserCalendarSportsFilter = ({
  studentId,
  onClickProps,
  activeFilter
}: UserCalendarSportsFilterProps) => {
  const { history } = useRouter()

  const { data: studentSports, loading: studentSportsLoading } =
    useQuery<GET_SPORTS_BY_STUDENT_ID_TYPE>(GET_SPORTS_BY_STUDENT_ID, {
      variables: {
        student_id: studentId
      }
    })

  const studentSportRows: Types.SportTypes[] = useMemo(
    (): Types.SportTypes[] =>
      studentSports?.getSportsByStudentId
        .filter((sport) => sport.is_active && sport.sub === null)
        .map((item) => item) ?? [],
    [studentSports]
  )

  const handleSportsClick = (sport: Types.SportTypes) => {
    if (onClickProps) {
      onClickProps(Number(sport.id))
    }
  }
  return (
    <Dimmer active={studentSportsLoading} loader={studentSportsLoading}>
      <Header.H4>Activities</Header.H4>
      {studentSportRows.length <= 0 && (
        <>
          <Button.List align="center mt-3">
            <Button
              color="primary"
              size="sm"
              className=""
              onClick={(): void => history.push('/user/settings')}
            >
              Add Activities
            </Button>
          </Button.List>
        </>
      )}
      {studentSportRows.map(
        (sportType: Types.SportTypes): JSX.Element => (
          <Form.Checkbox
            key={sportType.id}
            checked={activeFilter?.includes(Number(sportType.id))}
            onChange={() => handleSportsClick(sportType)}
            label={sportType.name}
          />
        )
      )}
    </Dimmer>
  )
}

export default UserCalendarSportsFilter
