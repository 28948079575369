export const useGetSubSportTypes = (majorActivityIds, sportTypesData) => {
  if (majorActivityIds.length === 0) {
    return []
  }

  return majorActivityIds.flatMap(
    (majorActivityId) =>
      sportTypesData?.getSportTypes
        .filter((sportType) => sportType.sub === majorActivityId)
        ?.map(({ id, name }) => ({
          id,
          name,
          hasSub: sportTypesData?.getSportTypes.some(
            (sportType) => sportType.sub === id
          )
        })) ?? []
  )
}
