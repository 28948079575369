import gql from 'graphql-tag'

export const GET_COACH_BY_ID = gql`
  query GET_COACH_BY_ID($coach_id: Int!) {
    getCoachById(coach_id: $coach_id) {
      id
      first_name
      last_name
      email
      phone_number
      phone_country_code
      is_disabled_on
      registration_id
      student_id
    }
  }
`
