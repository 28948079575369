import BulkEmailModal from 'components/Event/Modals/BulkEmailModal'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import {
  Avatar,
  Button,
  Card,
  Form,
  Grid,
  Icon,
  Table,
  Text
} from 'tabler-react'
import { Types } from 'types/graphql'
import useReactRouter from 'use-react-router'
import { OrgMembershipModal } from '../../../components/Event/EventRegistrationListItem'

type EventControllerProps = {
  campData: Types.Camp
  confirmedStatus: Types.TeamRegistrationStatus
  registeredStudents: Types.CampStudentListItem[]
  campRequirements: string[]
}
const EventController = ({
  campData,
  confirmedStatus,
  registeredStudents,
  campRequirements
}: EventControllerProps) => {
  const { history } = useReactRouter()
  const [selectedParticipant, setSelectedParticipant] = useState<
    Types.CampStudentListItem[]
  >([])
  const [isBulkEmailModalOpen, setIsBulkEmailModalOpen] =
    useState<boolean>(false)

  const isBurbleRequired = campRequirements.includes('burble')

  const getAge = (dateString) => {
    const today = new Date()
    const birthDate = new Date(dateString)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }

  const participants = useMemo(() => {
    if (!registeredStudents) return []

    const validStatuses = ['Confirmed', 'Accepted', 'Invite Confirmed', 'Pending', 'Invite Sent']
    return registeredStudents.filter((student) =>
      validStatuses.includes(student.status)
    )
  }, [registeredStudents])

  const participantsCSV = useMemo(() => {
    if (!registeredStudents) return []

    return registeredStudents
      .filter((student) => student.status === confirmedStatus.name)
      .map((student) => {
        let studentOrg = null
        let studentLicense = null
        if (student.orgCredentials.length > 0) {
          studentOrg = student.orgCredentials[0]
          studentLicense = student.orgCredentials.find(
            (org) =>
              org.org_id === studentOrg.org_id && (org.license || org.nac_slug)
          )
        }

        const burble = isBurbleRequired
          ? { burble: student?.uspa?.burbleLicense?.merits_id || '' }
          : undefined

        return {
          userId: student.student_id,
          name: student.first_name + ' ' + student.last_name,
          registrationId: student.user_registrations_id,
          code: student.registration_code,
          address: `${student?.city ?? ''} ${student?.province ?? ''}`,
          org: student.uspa?.memberLicense
            ? 'USPA (merit)'
            : studentOrg?.org_slug?.toUpperCase(),
          membership: student.uspa?.memberLicense
            ? student.uspa?.memberLicense.merits_uspa_member_id
            : studentOrg?.membership,
          expires: student.uspa?.memberLicense
            ? student.uspa?.memberLicense?.merits_uspa_member_expiration
              ? moment(
                  student.uspa?.memberLicense?.merits_uspa_member_expiration
                ).format('YYYY-MM-DD')
              : ''
            : studentOrg?.expires
            ? moment(studentOrg?.expires).format('YYYY-MM-DD')
            : '',
          license: student.uspa?.userMerit
            ? student.uspa?.userMerit.merits_uspa_license ??
              student.uspa?.userMerit.template_title
            : studentLicense?.nac_slug
            ? `${
                (studentLicense?.nac_slug?.toUpperCase() ?? '') +
                ' ' +
                (studentLicense?.license_number ?? '')
              }`
            : `${
                (studentLicense?.license ?? '') +
                ' ' +
                (studentLicense?.license_number ?? '')
              }`,
          dob: student?.dob ? moment(student.dob).format('YYYY-MM-DD') : '',
          gender: student.gender,
          country: studentLicense?.nac_country,
          ...burble
        }
      })
  }, [registeredStudents])

  const [selectAll, setSelectAll] = useState<boolean>(false)

  const handleToggleSelectAll = (selectedAll) => {
    if (selectedAll) {
      setSelectedParticipant(
        registeredStudents.filter((student) => student.status === 'Confirmed')
      )
    } else {
      setSelectedParticipant([])
    }
  }

  const handleToggleSelected = (id: number) => {
    const pool = registeredStudents.filter(
      (student) => student.status === 'Confirmed'
    )
    if (
      selectedParticipant?.find((student) => {
        return student.user_registrations_id === id
      })
    ) {
      setSelectedParticipant(
        (prev) => prev?.filter((p) => p.user_registrations_id !== id) ?? []
      )
    } else {
      setSelectedParticipant((prev) => [
        ...(prev ?? []),
        pool.find((student) => student.user_registrations_id === id)
      ])
    }
  }

  return (
    <>
      <Card.Body>
        <Grid.Row className="p-3">
          <Grid.Col width={1} className="pt-2">
            <Form.Label>
              <input
                id={`selectAll`}
                name={'select_all'}
                type="checkbox"
                checked={selectAll}
                onClick={() => {
                  setSelectAll(!selectAll)
                  handleToggleSelectAll(!selectAll)
                }}
                onChange={() => {}}
                className="float-left pb-2"
              />
            </Form.Label>
          </Grid.Col>
          <Grid.Col width={11} className="text-right pb-2">
            <Button
              outline={selectedParticipant.length !== 0}
              icon="mail"
              color={selectedParticipant.length !== 0 ? 'secondary' : 'white'}
              size={'sm'}
              className="mr-2"
              onClick={() => {
                setIsBulkEmailModalOpen(true)
              }}
              disabled={selectedParticipant.length === 0}
            >
              EMAIL
            </Button>
            <Button outline color="secondary" size="sm">
              <CSVLink
                data={participantsCSV}
                filename="controller_report.csv"
                className="ml-0 text-secondary"
              >
                <Icon name="download" className="mr-1" />
                CSV
              </CSVLink>
            </Button>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={12}>
            <Table responsive className="table-vcenter text-nowrap">
              <Table.Body>
                {participants.map((student) => {
                  let studentOrg = null
                  let studentLicense = null
                  if (student.orgCredentials.length > 0) {
                    studentOrg = student.orgCredentials[0]
                    const studentLicenses = student.orgCredentials
                      .filter(
                        (org) =>
                          org.org_id === studentOrg.org_id &&
                          (org.license || org.nac_slug)
                      )
                      .sort((a, b) => a.license.localeCompare(b.license))
                    studentLicense =
                      studentLicenses?.length > 0
                        ? studentLicenses[studentLicenses.length - 1]
                        : null
                  }
                  let statusIcon = 'bg-danger'

                  if (
                    moment(student.end).diff(studentOrg?.expires, 'days') > 0
                  ) {
                    statusIcon = 'bg-warning'
                  }

                  if (
                    moment(student.end).diff(studentOrg?.expires, 'days') < 0
                  ) {
                    statusIcon = 'bg-success'
                  }

                  if (moment().diff(studentOrg?.expires, 'days') > 0) {
                    statusIcon = 'bg-danger'
                  }

                  const burble = student.uspa?.burbleLicense

                  return (
                    <Table.Row key={student.id}>
                      <Table.Col>
                        <input
                          id={`participant-${student.student_id}`}
                          name={'selected'}
                          type="checkbox"
                          checked={
                            !!selectedParticipant.find(
                              (p) =>
                                p.user_registrations_id ===
                                student.user_registrations_id
                            )
                          }
                          onClick={() =>
                            handleToggleSelected(student.user_registrations_id)
                          }
                          onChange={() => {}}
                        />
                      </Table.Col>
                      {/* */}
                      <Table.Col>
                        <Avatar
                          size="md"
                          className="cursor-pointer"
                          status={student.status_color ?? 'danger'}
                          imageURL={
                            student?.profile_avatar
                              ? `/api/s3/uploads/${student.profile_avatar}`
                              : '//www.gravatar.com/avatar?d=mp'
                          }
                          onClick={() =>
                            history.push(
                              `/events/registrations/${student.user_registrations_id}/${student.student_id}`
                            )
                          }
                        />
                      </Table.Col>
                      <Table.Col>
                        <strong>
                          <Text
                            className="cursor-pointer d-block"
                            onClick={() =>
                              history.push(
                                `/events/registrations/${student.user_registrations_id}/${student.student_id}`
                              )
                            }
                          >
                            {student.first_name + ' ' + student.last_name}
                          </Text>
                          <Text.Small className="d-block text-muted">
                            {student.city ?? ''}
                            {student.province && ', ' + student.province}
                            {student.country !== 'United States'
                              ? student.country
                              : ''}
                          </Text.Small>
                        </strong>
                      </Table.Col>
                      <Table.Col>
                        <span className={'float-left'}>
                          {Number(studentOrg?.org_id) === 1 &&
                          student.uspa?.memberLicense ? (
                            <>
                              <Text>
                                USPA{' '}
                                {student.uspa.memberLicense
                                  ?.merits_uspa_member_id ??
                                  student.uspa.memberLicense?.template_title}
                                <i className="fe fe-check-circle text-primary ml-2" />
                              </Text>
                              <Text.Small className="d-block">
                                <span
                                  className={`status-icon ${
                                    student.uspa.isLicenseExpired
                                      ? 'bg-danger'
                                      : student.uspa.isLicenseExpiring
                                      ? 'bg-warning'
                                      : 'bg-success'
                                  }`}
                                />
                                {student.uspa.memberLicense
                                  ?.merits_uspa_member_expiration ? (
                                  <>
                                    {
                                      student.uspa.memberLicense
                                        ?.merits_uspa_member_expiration
                                    }
                                  </>
                                ) : (
                                  'Lifetime Member'
                                )}
                              </Text.Small>
                              {/*
                                  <img src={uspa_logo} className={"my-auto w-25"} />
                                */}
                            </>
                          ) : (
                            <>
                              {studentOrg?.membership ? (
                                <>
                                  <Text>
                                    {(studentOrg?.org_slug.toUpperCase() ??
                                      '') +
                                      ' ' +
                                      (studentOrg?.membership ?? '')}
                                  </Text>
                                  <Text.Small className="d-block">
                                    {studentOrg?.expires ? (
                                      <>
                                        <span
                                          className={`status-icon ${statusIcon}`}
                                        />
                                        {studentOrg?.expires}
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </Text.Small>
                                </>
                              ) : (
                                <>
                                  <OrgMembershipModal
                                    studentId={student.student_id}
                                    teamId={campData.team_id}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </span>
                        <span className={'float-left mt-1'}></span>
                        {isBurbleRequired && burble && (
                          <i className="fe fe-bold text-gray-dark ml-2" />
                        )}
                      </Table.Col>
                      <Table.Col>
                        {student.uspa?.userMerit ? (
                          <>
                            {student.uspa?.userMerit.merits_uspa_license ??
                              student.uspa?.userMerit.template_title}
                          </>
                        ) : (
                          <>
                            {studentLicense?.nac_slug
                              ? `${
                                  (studentLicense?.nac_slug?.toUpperCase() ??
                                    '') +
                                  ' ' +
                                  (studentLicense?.license_number ?? '')
                                }`
                              : `${
                                  (studentLicense?.license ?? '') +
                                  ' ' +
                                  (studentLicense?.license_number ?? '')
                                }`}
                          </>
                        )}
                      </Table.Col>
                      <Table.Col>
                        {student.dob && (
                          <>
                            <Text>
                              <strong>{getAge(student.dob) + ' '}</strong>
                            </Text>
                            <Text.Small className="text-muted">
                              {'DOB: ' +
                                moment(student.dob).format('YYYY-MM-DD') +
                                ' '}
                            </Text.Small>
                          </>
                        )}
                      </Table.Col>
                      {/* Only display Gender and Country if it is a requirement for the event, otherwise do not display */}
                      <Table.Col className="text-capitalize">
                        {student.gender}
                      </Table.Col>
                      <Table.Col>{studentLicense?.nac_country}</Table.Col>
                      <Table.Col>
                        <Icon name="more-vertical" />
                      </Table.Col>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            <BulkEmailModal
              isOpen={isBulkEmailModalOpen}
              toggle={() => setIsBulkEmailModalOpen(false)}
              students={selectedParticipant}
              teamId={Number(campData.team_id)}
              campId={Number(campData.id)}
            />
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </>
  )
}

export default EventController
