import { API, DomainResponse, MeResponse } from 'api'
import {
  cast,
  destroy,
  detach,
  flow,
  getEnv,
  Instance,
  SnapshotIn,
  types
} from 'mobx-state-tree'
import { EUserTypes } from '../components/User/userTypes'

export const Admin = types
  .model({
    id: types.optional(types.string, '', [null]),
    is_disabled: types.optional(types.boolean, false, [null]),
    password: types.optional(types.string, '', [null, undefined]),
    token: types.optional(types.string, '', [null]),
    username: types.optional(types.string, '', [null])
  })
  .actions((self) => ({
    set<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>
    >(key: K, value: T) {
      self[key] = cast(value)
    }
  }))

const Coach = types
  .model({
    id: types.optional(types.number, 0, [null]),
    first_name: types.optional(types.string, '', [null]),
    last_name: types.optional(types.string, '', [null]),
    email: types.optional(types.string, '', [null]),
    phone_number: types.optional(types.string, '', [null]),
    phone_country_code: types.optional(types.number, 1, [null, undefined]),
    email_private: types.optional(types.number, 0, [null, undefined]), // email_private
    phone_private: types.optional(types.number, 0, [null, undefined]), // phone_private
    is_disabled_on: types.optional(types.string, '', [null]),
    registration_id: types.optional(types.string, '', [null, undefined]),
    profile_avatar: types.optional(types.string, '', [null, undefined]),
    profile_picture: types.optional(types.string, '', [null, undefined]),
    student_id: types.optional(types.string, '', [null, undefined])
  })
  .actions((self) => ({
    set<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>
    >(key: K, value: T) {
      self[key] = cast(value)
    }
  }))

const CoachTeam = types.model({
  id: types.optional(types.number, 0, [undefined]),
  account_type_id: types.optional(types.number, 0, [undefined]),
  account_type_slug: types.optional(types.string, '', [null]),
  coach_business_type: types.optional(types.string, '', [null]),
  currency: types.optional(types.string, '', [null]),
  default_location_id: types.optional(types.number, 0, [null, undefined]),
  feature_accounting: types.optional(types.boolean, false, [null, undefined]),
  feature_alpha: types.optional(types.boolean, false, [null, undefined]),
  feature_open_add_students: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  feature_app_reports: types.optional(types.boolean, false, [null, undefined]),
  feature_booking_calendar: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  feature_camps: types.optional(types.boolean, false, [null, undefined]),
  feature_registrations: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  feature_slot_registrations: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  feature_cancel_transactions: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  feature_disable_students: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  feature_transfer_time: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  feature_recurring_transactions: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  feature_student_progression: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  feature_coach_account: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  feature_dzm_account: types.optional(types.boolean, false, [null, undefined]),
  feature_rigger_account: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  feature_manifest: types.optional(types.boolean, false, [null, undefined]),
  feature_require_uspa: types.optional(types.boolean, false, [null, undefined]),
  feature_schedule_open: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  feature_dashboard: types.optional(types.boolean, false, [null, undefined]),
  feature_dashboard_groups: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  feature_edit_transactions: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  feature_paid_status: types.optional(types.boolean, false, [null, undefined]),
  feature_products: types.optional(types.boolean, false, [null, undefined]),
  feature_payments: types.optional(types.boolean, false, [null, undefined]),
  feature_locked_account_actions: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  student_contact_required: types.optional(types.boolean, false, [
    null,
    undefined
  ]),
  preference_units: types.optional(types.boolean, false, [null, undefined]),
  feature_crm: types.optional(types.boolean, false, [null, undefined]),
  feature_invoices: types.optional(types.boolean, false, [null, undefined]),
  feature_waivers: types.optional(types.boolean, false, [null, undefined]),
  name: types.optional(types.string, '', [undefined]),
  slug: types.optional(types.string, '', [null, undefined]),
  stripe_account_id: types.optional(types.string, '', [null, undefined]),
  team_email: types.optional(types.string, '', [null, undefined]),
  team_favicon: types.optional(types.string, '', [null, undefined]),
  team_logo_header: types.optional(types.string, '', [null, undefined])
})

const Event = types
  .model({
    id: types.optional(types.string, '', [null]),
    camp_name: types.optional(types.string, '', [null]),
    camp_description: types.optional(types.string, '', [null]),
    location: types.optional(types.string, '', [null]),
    location_id: types.optional(types.number, 0, [null]),
    start: types.optional(types.string, '', [null]),
    end: types.optional(types.string, '', [null]),
    is_public: types.optional(types.boolean, false, [null])
  })
  .actions((self) => ({
    changeLocationId(newId: number) {
      self.location_id = newId
    },
    changeLocationName(newLocationName: string) {
      self.location = newLocationName
    }
  }))

const UserRole = types.model({
  role_id: types.optional(types.number, 0),
  key: types.optional(types.string, ''),
  name: types.optional(types.string, '')
})

const CurrentUser = types
  .model('CurrentUserModel', {
    email: types.optional(types.string, ''),
    first_name: types.optional(types.string, ''),
    id: types.optional(types.number, 0),
    is_company: types.optional(types.number, 0, [null, undefined]),
    last_name: types.optional(types.string, ''),
    phone_country_code: types.optional(types.number, 0, [null, undefined]),
    phone_number: types.optional(types.string, '', [null, undefined]),
    profile_avatar: types.optional(types.string, '', [null, undefined]),
    profile_picture: types.optional(types.string, '', [null, undefined]),
    token: types.optional(types.string, ''),
    type: types.optional(types.string, ''),
    username: types.optional(types.string, ''),
    email_private: types.optional(types.number, 0, [null, undefined]),
    phone_private: types.optional(types.number, 0, [null, undefined]),
    weight: types.optional(types.number, 0, [null, undefined]),
    userRoles: types.optional(types.array(UserRole), [], [null, undefined]),
    student_id: types.optional(types.string, '', [null, undefined])
  })
  .actions((self) => ({
    set<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>
    >(key: K, value: T) {
      self[key] = cast(value)
    }
  }))

const DomainData = types.model({
  key: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  siteTag: types.optional(types.string, '')
})

// provide api as a dependency to the store, for testing purposes
export const rootStoreDefault = (api: API, testing = false) => {
  return RootStore.create(
    {
      coaches: undefined,
      coachTeams: undefined,
      currentUser: undefined,
      isAuthenticated: false,
      pageState: 'START',
      testing
    },
    {
      api
    }
  )
}

const rootModel = {
  admins: types.optional(types.array(Admin), []),
  authMeritTotal: types.optional(types.number, 0),
  campTotal: types.optional(types.number, 0),
  coach: types.maybe(Coach),
  coaches: types.optional(types.array(Coach), []),
  coachTeams: types.optional(types.array(CoachTeam), []),
  currentCoachTeam: types.maybe(CoachTeam),
  currentUser: types.maybe(CurrentUser),
  events: types.optional(types.array(Event), []),
  error: types.maybe(types.string),
  isAuthenticated: types.boolean,
  loading: types.maybe(types.boolean),
  pageState: types.enumeration('PageState', [
    'START',
    'LOADING',
    'AUTH',
    'ANON'
  ]),
  paymentProcessedTotal: types.optional(types.number, 0.0),
  paymentsTotal: types.optional(types.number, 0),
  recentCoaches: types.optional(types.array(Coach), []),
  registeredStudentsTotal: types.optional(types.number, 0),
  registrationsTotal: types.optional(types.number, 0),
  studentsTotal: types.optional(types.number, 0),
  testing: types.boolean,
  userMeritTotal: types.optional(types.number, 0),
  redirectUrl: types.optional(types.string, '/'),
  defaultSearchKey: types.optional(types.string, ''),
  calendarSportsTypeFilter: types.optional(types.number, 0, [null, undefined]),
  calendarTeamFilter: types.optional(types.number, 0, [null, undefined]),
  calendarLocationFilter: types.optional(types.number, 0, [null, undefined]),
  hasMissingData: types.optional(types.number, 0, [null, undefined]),
  domainData: types.optional(types.maybe(DomainData), {})
}

export const RootStore = types
  .model('RootStore', rootModel)
  .views((self) => ({
    get message() {
      return self.pageState
    }
  }))
  .actions((self) => ({
    addCoach(newCoach) {
      self.coaches.push(newCoach)
    },
    loadCoach(coach: any) {
      self.coach = { ...coach }
    },
    updateCoach(coach: any) {
      self.coach = { ...coach }
      const index = self.coaches.findIndex((c) => c.id === coach.id)
      self.coaches[index] = { ...coach }
    },
    getCoachById(id) {
      return self.coaches.find((coach) => coach.id === id)
    },
    detach(treeItem) {
      if (treeItem) {
        detach(treeItem)
      }
    },
    me: flow(function* () {
      self.pageState = 'LOADING'
      const { error, data }: { error: any; data: MeResponse } = yield getEnv(
        self
      ).api.queryMe()

      if (!error && Object.keys(data).length) {
        self.pageState = 'AUTH'
        self.isAuthenticated = true
        if (data.hasMissingData === 1 && self.hasMissingData <= 0) {
          self.hasMissingData = 1
        }
        self.currentUser = CurrentUser.create({
          id: data.user_id,
          type: data.type,
          username: data.email,
          email: data.email,
          first_name: data.first_name || '',
          last_name: data.last_name || '',
          phone_number: data.phone_number,
          phone_country_code: data.phone_country_code,
          profile_avatar: data.profile_avatar,
          profile_picture: data.profile_picture,
          token: data.token,
          email_private: data?.email_private,
          phone_private: data?.phone_private,
          weight: data?.weight,
          is_company: data?.is_company,
          userRoles: data?.userRoles,
          student_id: data?.student_id
        })
        self.domainData = data?.domain

        if (
          self.currentUser.type === EUserTypes.coach &&
          self.currentCoachTeam?.coach_business_type
        ) {
          self.currentUser.type = self.currentCoachTeam.coach_business_type
        }
      } else {
        const { error, data }: { error: any; data: DomainResponse } =
          yield getEnv(self).api.queryDomain()
        self.domainData = data
        self.isAuthenticated = false
        self.pageState = 'ANON'
      }
    }),
    logout() {
      localStorage.clear()
    },
    remove(treeItem) {
      if (treeItem) {
        destroy(treeItem)
      }
    },
    setError(error: string) {
      self.error = error
    },
    setRedirect(redirect: string) {
      self.redirectUrl = redirect
    },
    setSearch(key: string) {
      self.defaultSearchKey = key
    },
    setFilter(key: string, value: string | number) {
      self[key] = value
    },
    setLoading(loading: boolean) {
      self.loading = loading
    },
    sortCoaches() {
      const sortedCoaches = self.coaches
        .slice()
        .sort((coachA, coachB) =>
          coachA.first_name.localeCompare(coachB.first_name)
        )
      self.coaches.replace(sortedCoaches)
    },
    setHasMissingData(value: number) {
      self.hasMissingData = value
    }
  }))

export interface RootStoreType extends Instance<typeof RootStore> {}
