import React from 'react'
import { formatMoney } from '../../../utils/numberFormat'
import { Grid, StampCard, Text } from 'tabler-react'
import { useQuery } from '@apollo/client'
import { GET_CAMP_GROUP_PAYMENT_SUMMARY } from '../../../graphql/GET_CAMP_GROUP_PAYMENT_SUMMARY'

interface GroupPaymentsCardProps {
  campId: number
}

const GroupPaymentsCard = ({ campId }: GroupPaymentsCardProps) => {
  const { data, loading } = useQuery(GET_CAMP_GROUP_PAYMENT_SUMMARY, {
    variables: {
      camp_id: campId
    }
  })

  const paymentData = data?.getCampGroupsPaymentSummary

  if (!paymentData) return null

  return (
    <>
      <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
        <span>
          <StampCard
            className="mb-0"
            color="primary"
            icon="dollar-sign"
            header={
              <Text>
                <span>
                  {
                    paymentData.payments.filter(
                      (payment) => payment.status === 'Paid'
                    ).length
                  }{' '}
                  <small>Payments</small>
                </span>
              </Text>
            }
            footer={
              <Text>
                <span>
                  {formatMoney(
                    paymentData.totalPaid / 100,
                    paymentData.currency
                  )}
                </span>
              </Text>
            }
          />
        </span>
      </Grid.Col>
      <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
        <span>
          <StampCard
            className="mb-0"
            color="secondary"
            icon="dollar-sign"
            header={
              <Text>
                {
                  paymentData.payments.filter(
                    (payment) => payment.status === 'Comp'
                  ).length
                }{' '}
                <small>Comp Payments</small>
              </Text>
            }
            footer={
              <Text>{`${formatMoney(
                paymentData.totalComp / 100,
                paymentData.currency
              )}`}</Text>
            }
          />
        </span>
      </Grid.Col>
      <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
        <span>
          <StampCard
            className="mb-0"
            color="danger"
            icon="alert-triangle"
            header={
              <Text>
                {paymentData.haveBalance} <small>Have Balance</small>
              </Text>
            }
            footer={
              <Text>{`${formatMoney(
                paymentData.outstanding / 100,
                paymentData.currency
              )}`}</Text>
            }
          />
        </span>
      </Grid.Col>
    </>
  )
}

export default GroupPaymentsCard
