/**
 * Map of coach account types
 * coach: Coach
 * dzm: DZM
 * events: Event Organizer
 * manufacturer: Manufacturer
 * org: Organization
 * rigger: Rigger
 * services: Services
 * wtm: Wind Tunnel
 * academy: Academy
 * promoter: Fight Promoter
 */

export const COACH_ACCOUNT_TYPES = {
  coach: 'Coach',
  dzm: 'DZM',
  events: 'Event Organizer',
  manufacturer: 'Manufacturer',
  org: 'Organization',
  rigger: 'Rigger',
  services: 'Services',
  wtm: 'Wind Tunnel',
  academy: 'Academy',
  promoter: 'Fight Promoter'
};
