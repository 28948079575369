import React, { useState } from 'react'
import { Card, List } from 'tabler-react'
import StudentHomeTeamList from './StudentHomeTeamList'

const WhatYouWant = () => {
  const [displayTeamList, setDisplayTeamList] = useState(false)
  const [teamOrgType, setTeamOrgType] = useState(null)
  return (
    <Card statusColor="blue" title="What are you looking for?">
      <Card.Body>
        <List unstyled>

          <List.Item>
            <span
              className={'cursor-pointer text-primary'}
              onClick={() => {
                setTeamOrgType('coach')
                setDisplayTeamList(true)
              }}
            >
              Coaching
            </span>
          </List.Item>
          <List.Item>
            <span
              className={'cursor-pointer text-primary'}
              onClick={() => {
                setTeamOrgType('dzm')
                setDisplayTeamList(true)
              }}
            >
              Dropzones
            </span>
          </List.Item>
          <List.Item>
            <span
              className={'cursor-pointer text-primary'}
              onClick={() => {
                setTeamOrgType('events')
                setDisplayTeamList(true)
              }}
            >
              Event Organizers
            </span>
          </List.Item>
          <List.Item>
            <span
              className={'cursor-pointer text-primary'}
              onClick={() => {
                setTeamOrgType('rigger')
                setDisplayTeamList(true)
              }}
            >
              Riggers
            </span>
          </List.Item>
          <List.Item>
            <span
              className={'cursor-pointer text-primary'}
              onClick={() => {
                setTeamOrgType('wtm')
                setDisplayTeamList(true)
              }}
            >
              Wind Tunnels
            </span>
          </List.Item>
        </List>
      </Card.Body>
      {displayTeamList && teamOrgType && (
        <Card.Body>
          <StudentHomeTeamList type={teamOrgType} />
        </Card.Body>
      )}
    </Card>
  )
}

export default WhatYouWant
