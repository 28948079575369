import gql from 'graphql-tag'

export const GET_ADMIN_SEARCH_QUERY = gql`
  query GET_ADMIN_SEARCH_QUERY($q: String!) {
    getAdminSearchQuery(q: $q) {
      teams {
        teamId
        teamName
        teamSlug
      }

      locations {
        locationId
        locationName
        locationType
      }

      students {
        studentId
        studentFirstName
        studentLastName
        studentRegistrationId
      }

      coaches {
        coachId
        coachFirstName
        coachLastName
        coachRegistrationId
      }
      events {
        campId
        campName
        sportTypeName
        locationName
      }
    }
  }
`
