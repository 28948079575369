import { useMutation, useQuery } from '@apollo/client'
import UserAccountForm from 'components/User/Forms/UserAccountForm'
import { EUserTypes } from 'components/User/userTypes'
import { UserStoreContext } from 'contexts/userStoreContext'
import { GET_STUDENT_BY_ID } from 'graphql/GET_STUDENT_BY_ID'
import { UPDATE_STUDENT_MUTATION } from 'graphql/UPDATE_STUDENT_MUTATION'
import { useRootStore } from 'hooks'
import { observer } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Card, Dimmer, Icon } from 'tabler-react'

const SkycruAccountDetails = () => {
  const rootStore = useRootStore()
  const { currentUser } = rootStore

  const { user } = useContext(UserStoreContext)

  const [isCardOpen, setIsCardOpen] = useState(
    !user?.phone_number && !user?.email
  )

  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION, {
    onCompleted: () => toast.success('Profile Updated.')
  })

  const { loading, error, data } = useQuery(GET_STUDENT_BY_ID, {
    variables: { student_id: user?.id }
  })

  useEffect(() => {
    if (!loading && data) {
      applySnapshot(user, {
        ...user,
        ...data.getStudentById
      })
    }
  }, [data])

  if (error) {
    return <p>{`Error: ${error.message}`}</p>
  }

  const handleDisableStudent = async () => {
    await updateStudent({
      refetchQueries: [
        {
          query: GET_STUDENT_BY_ID,
          variables: {
            student_id: user.id
          }
        }
      ],
      variables: {
        student: {
          id: user.id,
          is_disabled: user.is_disabled_on ? false : true
        }
      }
    })
  }

  const toggleBodyHandle = () => {
    setIsCardOpen(!isCardOpen)
  }

  return (
    <Card statusColor="gray-dark">
      <Card.Header>
        <Card.Title>
          <Icon
            onClick={() => toggleBodyHandle()}
            name="lock"
            className="mr-2 ml-0 text-secondary"
          />
          <Link
            to="#"
            className="text-decoration-none"
            onClick={() => toggleBodyHandle()}
          >
            Sign-in & Security
          </Link>
        </Card.Title>
        {currentUser.type === EUserTypes.admin && (
          <Card.Options>
            <Button.List align="right">
              <Button
                icon="x-circle"
                className="btn btn-white text-danger"
                onClick={() => handleDisableStudent()}
              />
              <Link
                to={`/admin/user-events/${user.id}`}
                className="btn btn-white text-blue"
              >
                <Icon name="activity" />
              </Link>
            </Button.List>
          </Card.Options>
        )}
      </Card.Header>
      {user.is_disabled_on && (
        <Button disabled>
          <strong>DISABLED: </strong>
          {new Date(user.is_disabled_on).toLocaleString()}
        </Button>
      )}
      {isCardOpen && (
        <Dimmer active={loading} loader={loading}>
          <UserAccountForm />
        </Dimmer>
      )}
    </Card>
  )
}

export default observer(SkycruAccountDetails)
