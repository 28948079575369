import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Card, Dropdown, Icon } from 'tabler-react'
import { DELETE_BIN_ITEMS } from '../../graphql/DELETE_BIN_ITEMS'
import { GET_JOBS_BY_STORAGE } from '../../graphql/GET_JOBS_BY_STORAGE'
import { UPDATE_JOB } from '../../graphql/UPDATE_JOB'
import { useRootStore } from '../../hooks'
import { EUserTypes } from '../User/userTypes'
import JobGearStorageItemGear from './JobGearStorageItemGear'
import GearStorageTransferModal from './Modal/GearStorageTransferModal'

interface JobListItemProps {
  jobList: any
}

const JobGearStorageItem = ({ jobList }: JobListItemProps) => {
  const rootStore = useRootStore()
  const { currentUser, currentCoachTeam } = rootStore
  const [showMore, setShowMore] = useState(false)
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [clearBin] = useMutation(DELETE_BIN_ITEMS, {
    onCompleted: () => toast.success('Bin is not empty.')
  })
  const [updateJob] = useMutation(UPDATE_JOB, {
    onCompleted: () => toast.success('Item removed from bin.')
  })
  const filterOption =
    currentUser.type !== EUserTypes.admin
      ? { team_id: currentCoachTeam?.id }
      : {}
  const handleClearBinClick = async () => {
    if (window.confirm('Clear all items from this bin?')) {
      await clearBin({
        variables: { binId: jobList[0]?.job_storage },
        refetchQueries: [
          {
            query: GET_JOBS_BY_STORAGE,
            variables: {
              jobListFilter: {
                order: 'asc',
                ...filterOption
              }
            }
          }
        ]
      })
    }
  }

  const handleRemoveItem = async (jobId) => {
    if (window.confirm('Remove item from bin?')) {
      await updateJob({
        variables: {
          updateJobInput: {
            id: jobId,
            job_storage: null
          }
        },
        refetchQueries: [
          {
            query: GET_JOBS_BY_STORAGE,
            variables: {
              jobListFilter: {
                order: 'asc',
                ...filterOption
              }
            }
          }
        ]
      })
    }
  }
  return (
    <>
      <Card className="mb-0 bg-light">
        <Card.Header>
          <Card.Title>
            Bin: <strong>{jobList[0]?.job_storage}</strong>
            {jobList.length > 1 && (
              <Button
                size={'sm'}
                className="bg-light text-muted"
                icon={showMore ? 'chevron-up' : 'chevron-down'}
                onClick={() => setShowMore(!showMore)}
              />
            )}
          </Card.Title>
          <Card.Options>
            <Dropdown
              className={'float-right cursor-pointer '}
              toggle={false}
              position="bottom-end"
              arrow={true}
              arrowPosition="right"
              triggerContent={<Icon name="more-vertical" />}
              items={[
                <>
                  <Dropdown.Item
                    key={`clear-bin-${jobList[0]?.job_storage}`}
                    icon="x"
                    as="Button"
                    className="text-muted"
                    onClick={() => handleClearBinClick()}
                  >
                    CLEAR
                  </Dropdown.Item>
                  <Dropdown.Item
                    key={`move-bin-${jobList[0]?.job_storage}`}
                    icon="move"
                    as="Button"
                    className="text-muted"
                    onClick={() => setShowTransferModal(true)}
                  >
                    MOVE GEAR
                  </Dropdown.Item>
                </>
              ]}
            />
          </Card.Options>
        </Card.Header>
      </Card>
      {jobList.slice(0, showMore ? jobList.length : 1).map((job) => (
        <JobGearStorageItemGear
          job={job}
          handleRemoveItem={handleRemoveItem}
          key={job.id}
        />
      ))}
      <GearStorageTransferModal
        show={showTransferModal}
        onHide={() => setShowTransferModal(false)}
        jobList={jobList}
        bin={jobList[0]?.job_storage}
      />
    </>
  )
}

export default JobGearStorageItem
