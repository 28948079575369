import React, { useMemo } from 'react'
import { Button } from 'tabler-react'
import useRouter from 'use-react-router'
import {
  useGetCurrentUserType,
  useGetUserIsCoachSuperAdmin
} from '../modules/common/hooks/useGetCurrentUserType'
import SwitchTeamButton from '../modules/team/components/buttons/SwitchTeamButton'
import { useRootStore } from '../hooks'

const PageNoAccess = (props: any) => {
  const { history } = useRouter()
  const { currentUser, currentCoachTeam, coachTeams } = useRootStore()
  const isCoachSuperAdmin = useGetUserIsCoachSuperAdmin()
  const { isStudent } = useGetCurrentUserType()
  const hasCoachAccess = useMemo(() => {
    if (isStudent) return false

    return !!coachTeams.find((team) => team.id === props?.teamId)
  }, [isStudent, coachTeams, props?.teamId])
  return (
    <>
      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="row">
              <div className="col mx-auto">
                <div className="text-center mb-6">
                  <h1 className="mb-5 display-1 text-muted">401</h1>
                  <h2 className="mb-4">Unauthorized Access</h2>
                  <h4 className="mb-4 text-muted">
                    You are not authorized to access this page!
                  </h4>
                  <h4 className="mb-4 text-muted">
                    If you believe this is an error, click the blue chat button
                    below to request assistance.
                  </h4>
                  <Button.List align="center">
                    <Button
                      icon="chevron-left"
                      color="primary"
                      className=""
                      onClick={() => history.goBack()}
                    >
                      BACK
                    </Button>
                    <Button
                      icon="home"
                      color="secondary"
                      className=""
                      onClick={() => history.push(`/`)}
                    >
                      GO HOME
                    </Button>
                  </Button.List>
                </div>
              </div>
            </div>
            {!isCoachSuperAdmin && !isStudent && hasCoachAccess && (
              <div className="col mx-auto">
                <div className="text-center mb-6">
                  <SwitchTeamButton teamId={props?.teamId} />
                </div>
              </div>
            )}
            {isCoachSuperAdmin && props?.teamId && (
              <div className="col mx-auto">
                <div className="text-center mb-6">
                  <SwitchTeamButton teamId={props?.teamId} />
                </div>
              </div>
            )}

            {!currentCoachTeam && (
              <div className="col mx-auto">
                <div className="text-center mb-6">
                  <h4 className="mb-4 text-muted">
                    You must be associated with this event to view this page.
                  </h4>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PageNoAccess
