import gql from 'graphql-tag'

export const SEND_GROUP_EMAIL = gql`
  mutation SEND_GROUP_EMAIL(
    $groupId: ID!
    $message: String!
    $subject: String!
    $coachId: ID!
    $ids: [ID!]!
  ) {
    sendGroupEmail(
      groupId: $groupId
      message: $message
      subject: $subject
      coachId: $coachId
      ids: $ids
    )
  }
`
