import { useQuery } from '@apollo/client'
import { GET_COACH_BY_ID } from '../../../graphql/GET_COACH_BY_ID'
import { GET_COACH_BY_ID as GET_COACH_BY_ID_TYPE } from '../../../graphql/types/GET_COACH_BY_ID'
import useGetCurrentUserType from '../../common/hooks/useGetCurrentUserType'

export const useGetCoachById = (id: number) => {
  const { isAdmin, isStudent } = useGetCurrentUserType()
  const { data, loading } = useQuery<GET_COACH_BY_ID_TYPE>(GET_COACH_BY_ID, {
    variables: { coach_id: Number(id) },
    skip: isAdmin || isStudent
  })

  const coach = data?.getCoachById

  return { coach, loading }
}
