import { useMutation } from '@apollo/client'
import { EUserEventsId } from 'components/User/UserEvents'
import { ADD_USER_EVENT } from 'graphql/ADD_USER_EVENT'
import { DELETE_CAMP_STUDENT } from 'graphql/DELETE_CAMP_STUDENT'
import { GET_ALL_USER_REGISTRATION_EVENTS } from 'graphql/GET_ALL_USER_REGISTRATION_EVENTS'
import { GET_CAMP_BY_ID } from 'graphql/GET_CAMP_BY_ID'
import { GET_REGISTERED_STUDENTS } from 'graphql/GET_REGISTERED_STUDENTS'
import { GET_REGISTERED_STUDENTS_COUNT } from 'graphql/GET_REGISTERED_STUDENTS_COUNT'
import { GET_USER_REGISTRATIONS_BY_IDS } from 'graphql/GET_USER_REGISTRATIONS_BY_IDS'
import { UPDATE_USER_REGISTRATION } from 'graphql/UPDATE_USER_REGISTRATION'
import { useRootStore } from 'hooks'
import moment from 'moment/moment'
import React, { useMemo } from 'react'
import { toast } from 'react-toastify'
import { Button, Dropdown, Icon } from 'tabler-react'
import useReactRouter from 'use-react-router'
import useGetCurrentUserType from '../../../../../modules/common/hooks/useGetCurrentUserType'
import { Types } from '../../../../../types/graphql'
import useGetRegistrationStatus from '../../../../team-registration-status/hooks/useGetRegistrationStatus'
import { useGetStudentOverride } from '../../../hooks/user'

type Props = {
  campId: Types.UserRegistrationsById['camp_id']
  registrationStart: Types.UserRegistrationsById['start']
  status: Types.UserRegistrationsById['status']
  teamId: Types.UserRegistrationsById['team_id']
  studentId: Types.UserRegistrationsById['student_id'] | number
  userRegId: number
  isEditable?: boolean
}

const UserRegistrationStatusDropdown = ({
  campId,
  registrationStart,
  status,
  studentId,
  userRegId,
  teamId,
  isEditable
}: Props) => {
  const { history } = useReactRouter()
  const { currentUser } = useRootStore()
  const { isAdmin, isCoachSuperAdmin } = useGetCurrentUserType()
  const isStudentOverride = useGetStudentOverride(studentId)
  const [statusUpdate, setStatusUpdate] = React.useState<string>(status)

  const [removeCampStudent] = useMutation(DELETE_CAMP_STUDENT)
  const [userEvent] = useMutation(ADD_USER_EVENT)
  const [updateUserRegistration] = useMutation(UPDATE_USER_REGISTRATION, {
    onCompleted: (result) => {
      if (result.updateUserRegistration) {
        userEvent({
          variables: {
            userEvent: {
              user_event_type_id: EUserEventsId.reg_status,
              student_id: studentId,
              status: statusUpdate,
              camp_id: Number(campId),
              team_id: teamId,
              user_registration_id: userRegId,
              coach_id: !isAdmin && !isStudentOverride ? currentUser.id : null
            }
          },
          refetchQueries: [
            {
              query: GET_ALL_USER_REGISTRATION_EVENTS,
              variables: {
                filter: {
                  userRegistrationId: userRegId
                },
                limit: 20,
                page: 1
              }
            }
          ]
        })
        toast.success('SUCCESS! Registration has been updated!')
      }
    }
  })

  const { teamStatusOptions } = useGetRegistrationStatus(teamId)

  const updateUserRegistrationStatus = async (newStatus, id) => {
    if (newStatus.toLowerCase() === status.toLowerCase()) return
    setStatusUpdate(newStatus)
    if (window.confirm('Update registration status to: ' + newStatus)) {
      if (newStatus === 'Deleted' && isAdmin) {
        // database delete action here
        await removeCampStudent({
          variables: {
            registrationId: userRegId
          },
          refetchQueries: [
            {
              query: GET_REGISTERED_STUDENTS,
              variables: {
                campId
              }
            },
            {
              query: GET_CAMP_BY_ID,
              variables: { campId: Number(campId) }
            },
            {
              query: GET_USER_REGISTRATIONS_BY_IDS,
              variables: {
                userRegistrationsId: userRegId
              }
            },
            {
              query: GET_REGISTERED_STUDENTS_COUNT,
              variables: {
                campId: Number(campId)
              }
            }
          ]
        })
        history.push('/calendar/' + campId)
      } else {
        updateUserRegistration({
          variables: {
            userRegistration: {
              id: userRegId,
              status: newStatus,
              registration_status_id: id,
              coach_id: currentUser.id
            }
          },
          refetchQueries: [
            {
              query: GET_REGISTERED_STUDENTS,
              variables: {
                campId
              }
            },
            {
              query: GET_CAMP_BY_ID,
              variables: { campId: Number(campId) }
            },
            {
              query: GET_USER_REGISTRATIONS_BY_IDS,
              variables: {
                userRegistrationsId: userRegId
              }
            },
            {
              query: GET_REGISTERED_STUDENTS_COUNT,
              variables: {
                campId: Number(campId)
              }
            }
          ]
        })
      }
    }
  }

  const deleteStatusItem =
    isAdmin || isCoachSuperAdmin
      ? {
          value: (
            <Button icon="x-circle" className="text-danger" color={'white'}>
              Deleted
            </Button>
          ),
          onClick: () => updateUserRegistrationStatus('Deleted', null)
        }
      : ''
  const currentStatus = teamStatusOptions.find(
    (teamStatusOption) => teamStatusOption.name === status
  )

  const withdrawnStatusItem = useMemo(() => {
    const withdrawnStatus = teamStatusOptions.find(
      (option) => option.name === 'Withdrawn'
    )
    const stat =
      teamStatusOptions.find(
        (teamStatusOption) => teamStatusOption.name === status
      ) ?? null

    if (!isStudentOverride) return {}

    if (
      withdrawnStatus &&
      stat?.name !== withdrawnStatus.name &&
      moment().diff(moment(registrationStart), 'days') < 0
    ) {
      return {
        value: (
          <span className="text-danger cursor-pointer">
            {withdrawnStatus.icon && (
              <Icon name={withdrawnStatus.icon} className="mr-2" />
            )}
            {withdrawnStatus.name}
          </span>
        ),
        onClick: () =>
          updateUserRegistrationStatus(withdrawnStatus.name, withdrawnStatus.id)
      }
    }

    return {}
  }, [teamStatusOptions, isStudentOverride, registrationStart, status])

  const standardStatusItems = teamStatusOptions.map((stat) => {
    return {
      className: 'm-0 p-0',
      value: (
        <span
          className={`${stat.color && 'text-' + stat.color} cursor-pointer`}
        >
          <Icon name={stat.icon} className="mr-2" />
          {stat.name}
        </span>
      ),
      onClick: () => updateUserRegistrationStatus(stat.name, stat.id)
    }
  })
  const options =
    !isAdmin && !isStudentOverride
      ? standardStatusItems
      : isStudentOverride && Object.keys(withdrawnStatusItem).length > 0
      ? [withdrawnStatusItem]
      : isAdmin && [deleteStatusItem]

  if (!isEditable || !options) {
    return (
      <Button
        color={'white'}
        icon={currentStatus?.icon}
        className={currentStatus?.color && 'text-' + currentStatus.color}
      >
        {status}
      </Button>
    )
  }

  return (
    <Dropdown
      triggerContent={
        <Button
          color={'white'}
          icon={currentStatus?.icon}
          className={currentStatus?.color && 'text-' + currentStatus.color}
        >
          {status}
        </Button>
      }
      position="bottom-end"
      arrow={true}
      arrowPosition="right"
      toggle={false}
      itemsObject={options}
      className="m-0 p-0 cursor-pointer"
    />
  )
}

export default UserRegistrationStatusDropdown
