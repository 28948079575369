import { useLazyQuery } from "@apollo/client";
import { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Icon, Table, Tag, Text } from "tabler-react";
import useReactRouter from "use-react-router";
import { UserStoreContext } from "../../contexts/userStoreContext";
import { GET_STUDENT_BY_ID } from "../../graphql/GET_STUDENT_BY_ID";
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType';
import { formatAccountBalance } from "../../utils/formatAccountBalance";
import EditTransactionForm from "./Modal/EditTransactionForm";
import ViewTransactionDetails from "./Modal/ViewTransactionDetails";
import moment from 'moment';

interface ITransactionListItemProps {
  transaction: any;
}

const TransactionListItem = ({ transaction }: ITransactionListItemProps) => {
  const { history } = useReactRouter();
  const userStore = useContext(UserStoreContext);
  const { user } = userStore;
  const {isAdmin, isCoachSuperAdmin} = useGetCurrentUserType();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [getStudent] = useLazyQuery(GET_STUDENT_BY_ID, {
    onCompleted: (student) => {
      userStore.detach(user);
      userStore.loadUser(student?.getStudentById);
      history.push("/user-details");
    },
  });
  const handleTeamClick = (team_id) => {
    history.push(`/team-details/${team_id}`);
  };

  const handleStudentClick = async (student_id) => {
    await getStudent({
      variables: { student_id: parseInt(student_id) },
      onCompleted: (student) => {
        userStore.detach(user);
        userStore.loadUser(student?.getStudentById);
        history.push("/user-details");
      },
    });
  };

  const handleRedirectToTransactions = async (studentId, teamId) => {
    await getStudent({
      variables: { student_id: parseInt(studentId) },
      onCompleted: (student) => {
        userStore.detach(user);
        userStore.loadUser(student?.getStudentById);
        history.push(`/user-details/${studentId}/student-teams/${teamId}`);
      },
    });
  };

  const amount = useMemo(() => {
    return formatAccountBalance(
      transaction.minutes,
      transaction.accountTypeSlug,
    );
  }, [transaction]);

  return (
    <>
      <EditTransactionForm
        transaction={transaction}
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
      />
      <ViewTransactionDetails
        transaction={transaction}
        isModalOpen={isViewModalOpen}
        toggleModal={setIsViewModalOpen}
        teamId={transaction.teamId}
        studentId={transaction.studentId}
      />
      {isAdmin && (
        <Table.Col>
          <Text RootComponent="span" muted>
            {transaction.id}
          </Text>
        </Table.Col>
      )}
      <Table.Col>
        <Link
          className={`${
            transaction.transactionTypeSlug.toLowerCase() === "cancel" &&
            "text-muted"
          }`}
          to="#"
          onClick={() =>
            handleRedirectToTransactions(
              transaction.studentId,
              transaction.teamId,
            )
          }
        >
          {transaction.studentFirstName} {transaction.studentLastName}
        </Link>
      </Table.Col>
      <Table.Col>
        <Tag>
          {transaction.transactionTypeName}
        </Tag>
      </Table.Col>
      <Table.Col>
        {moment(transaction.created_on).locale(navigator.language).format('L LT')}
        {/* TESTING FOR ADMIN PURPOSES */}
        {/* (isAdmin || isCoachSuperAdmin) && (
          <Text.Small className="text-muted d-block">
          {new Date(transaction.created_on).toLocaleString(undefined, { timeZone: 'UTC' })} UTC
          </Text.Small>
        )*/}
      </Table.Col>
      {isAdmin && (
        <Table.Col>
          <Link to="#" onClick={() => handleTeamClick(transaction.teamId)}>
            {transaction.teamName}
          </Link>
        </Table.Col>
      )}
      <Table.Col>
        {transaction.coachFirstName} {transaction.coachLastName}
      </Table.Col>
      <Table.Col>
        {transaction.timeAdded ? (
          <Text
            muted={transaction.transactionTypeSlug.toLowerCase() === "cancel"}
            color="success"
          >
            +{amount}
          </Text>
        ) : (
          <Text
            muted={transaction.transactionTypeSlug.toLowerCase() === "cancel"}
            color="danger"
          >
            -{amount}
          </Text>
        )}
      </Table.Col>
      <Table.Col>{transaction.notes}</Table.Col>
      <Table.Col>
        <Dropdown
          position="bottom-end"
          arrow={true}
          arrowPosition="right"
          triggerContent={<Icon name={"more-vertical"} />}
          toggle={false}
          className="cursor-pointer"
          itemsObject={[
            { value: "Edit", onClick: () => setIsModalOpen(true) },
            { value: "Transaction Details", onClick: () => setIsViewModalOpen(true) },
            {
              value: "View Customer",
              onClick: () => handleStudentClick(transaction.studentId),
            },
          ]}
        />
      </Table.Col>
    </>
  );
};

export default TransactionListItem;
