import { useApolloClient } from '@apollo/client'

interface IAutoCompleteProps {
  query: any
  options?: any
  minInputLength?: number
}

const MIN_INPUT_SEARCH_LENGTH = 1

export const useAutoCompleteAdminSearch = (props: IAutoCompleteProps) => {
  const client = useApolloClient()
  const minInputLength = props.minInputLength || MIN_INPUT_SEARCH_LENGTH

  const loadOptions = async (input: string) => {
    if (!input || input.length < minInputLength) {
      return
    }

    const { data } = await client.query({
      query: props.query,
      variables: { q: input },
      fetchPolicy: 'network-only'
    })

    const defaultOptions = [
      {
        label: input,
        value: input,
        type: 'none'
      }
    ]

    if (!data?.getAdminSearchQuery) {
      return defaultOptions
    }

    const teams = data.getAdminSearchQuery?.teams
      .filter((team) => !!team.teamSlug)
      .map((team) => ({
        label: team.teamName,
        id: `team-${team.teamId}`,
        value: team.teamId,
        type: 'team',
        slug: team.teamSlug
      }))

    const locations = data.getAdminSearchQuery?.locations.map((location) => ({
      label: location.locationName,
      id: `location-${location.locationId}`,
      value: location.locationId,
      type: 'location'
    }))

    const students = data.getAdminSearchQuery?.students.map((student) => ({
      label: `${student.studentFirstName} ${student.studentLastName}`,
      id: `student-${student.studentId}`,
      value: student.studentId,
      type: 'student',
      registrationId: student.studentRegistrationId
    }))

    const coaches = data.getAdminSearchQuery?.coaches.map((coach) => ({
      label: `${coach.coachFirstName} ${coach.coachLastName}`,
      id: `coach-${coach.coachId}`,
      value: coach.coachId,
      type: 'coach',
      registrationId: coach.coachRegistrationId
    }))

    const events = data.getAdminSearchQuery?.events.map((event) => ({
      label: `${event.campName} - ${event.locationName}`,
      id: `event-${event.campId}`,
      value: event.campId,
      type: 'event'
    }))

    return [
      ...teams,
      ...locations,
      ...students,
      ...coaches,
      ...events,
      ...defaultOptions
    ]
  }

  return {
    loadOptions
  }
}
