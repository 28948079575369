import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { EDomainId, EHostnames } from 'components/constants'
import { Formik } from 'formik'
import { observer } from 'mobx-react'
import { applySnapshot, getSnapshot } from 'mobx-state-tree'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import autoCompleteStyles from 'modules/common/styles/autocompleteStyles'
import { useContext, useEffect, useMemo, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'
import { Alert, Button, Card, Dimmer, Form, Grid } from 'tabler-react'
import useReactRouter from 'use-react-router'
import * as Yup from 'yup'
import { TeamStoreContext } from '../../../contexts/teamStoreContext'
import { ADD_LOCATIONS_TO_TEAM } from '../../../graphql/ADD_LOCATIONS_TO_TEAM'
import { ADD_ORGS_TO_TEAM } from '../../../graphql/ADD_ORGS_TO_TEAM'
import { ADD_TEAM } from '../../../graphql/ADD_TEAM'
import { GET_LOCATIONS_BY_FILTER_QUERY } from '../../../graphql/GET_LOCATIONS_BY_FILTER_QUERY'
import { INSERT_COACHES_TO_TEAM_MUTATION } from '../../../graphql/INSERT_COACHES_TO_TEAM_MUTATION'
import { TEAM_DETAILS_QUERY } from '../../../graphql/TEAM_DETAILS_QUERY'
import { TEAM_DETAILS_QUERY as TEAM_DETAILS_QUERY_TYPE } from '../../../graphql/types/TEAM_DETAILS_QUERY'
import { UPDATE_TEAM_BY_ID } from '../../../graphql/UPDATE_TEAM_BY_ID'
import { useRootStore } from '../../../hooks'
import { useAutocomplete } from '../../../hooks/useAutocomplete'
import { Types } from '../../../types/graphql'
import { EInputTypes } from '../../FormField'
import TextEditor from '../../text-editor/TextEditor'
import TeamLocationsSelect from './TeamLocationsSelect'
import TeamOrgSelect from './TeamOrgSelect'

const updateTeamValidationSchema = Yup.object().shape({
  coach_account_type: Yup.string().optional(),
  coach_business_type: Yup.string().nullable(),
  default_account_type_id: Yup.number()
    .required('Please select one from the options')
    .test(
      'Is positive?',
      'Please select one from the options',
      (value: number): boolean => value > 0
    ),
  default_currency: Yup.string().required('This field is required.'),
  name: Yup.string().required('This field is required.'),
  preference_units: Yup.boolean()
    .oneOf([false, true])
    .required('This field is required.'),
  team_bio: Yup.string(),
  team_email: Yup.string().email('Invalid email.'),
  team_phone: Yup.string()
})

enum EReactSelectInputNames {
  default_location_id = 'default_location_id',
  default_account_type_id = 'default_account_type_id'
}

const coachAccountTypeOptions: Array<{
  label: string
  value: string
  domain: string
}> = [
  { value: 'feature_coach_account', label: 'Coach', domain: EHostnames.SKYCRU },
  { value: 'feature_dzm_account', label: 'DZM', domain: EHostnames.SKYCRU },
  {
    value: 'feature_rigger_account',
    label: 'Rigger',
    domain: EHostnames.SKYCRU
  },
  {
    value: 'feature_academy_account',
    label: 'Academy',
    domain: EHostnames.FAIRFIGHTS
  },
  {
    value: 'feature_promoter_account',
    label: 'Promoter',
    domain: EHostnames.FAIRFIGHTS
  }
]

// Default Currency Options
export const currencyOptions: Array<{ label: string; value: string }> = [
  { value: 'AED', label: 'AED' },
  { value: 'AUD', label: 'AUD' },
  { value: 'BRL', label: 'BRL' },
  { value: 'CAD', label: 'CAD' },
  { value: 'CHF', label: 'CHF' },
  { value: 'CNY', label: 'CNY' },
  { value: 'CZK', label: 'CZK' },
  { value: 'EUR', label: 'EUR' },
  { value: 'GBP', label: 'GBP' },
  { value: 'JPY', label: 'JPY' },
  { value: 'MOP', label: 'MOP' },
  { value: 'PHP', label: 'PHP' },
  { value: 'USD', label: 'USD' },
  { value: 'ZAR', label: 'ZAR' }
]

interface ITeamProfileProps {
  team?: any
  teamClone?: any
}

const TeamProfile = ({ team, teamClone }: ITeamProfileProps) => {
  const teamStore = useContext(TeamStoreContext)
  const { history } = useReactRouter()

  const { domainData } = useRootStore()
  const { isAdmin, isCoachSuperAdmin, isOrg } = useGetCurrentUserType()

  const [selectedCoaches, setSelectedCoaches] = useState([])
  const [selectedLocations, setSelectedLocations] = useState([])
  const [selectedOrgs, setSelectedOrgs] = useState([])
  const [coaches, setCoaches] = useState<Types.TeamCoach[]>([])

  const [updateTeamById] = useMutation(UPDATE_TEAM_BY_ID)
  const [insertCoachesToTeam] = useMutation(INSERT_COACHES_TO_TEAM_MUTATION)
  const [insertOrgsToTeam] = useMutation(ADD_ORGS_TO_TEAM)
  const [insertLocationsToTeam] = useMutation(ADD_LOCATIONS_TO_TEAM)

  const { loadOptions } = useAutocomplete({
    query: GET_LOCATIONS_BY_FILTER_QUERY
  })

  const [addTeam] = useMutation(ADD_TEAM, {
    onError: (error: ApolloError) =>
      toast.error(error?.graphQLErrors[0]?.message),
    onCompleted: () => toast.success('Team Added.')
  })

  const { loading, error, data } = useQuery<TEAM_DETAILS_QUERY_TYPE>(
    TEAM_DETAILS_QUERY,
    {
      variables: {
        team_id: Number(teamClone?.id) || 0
      }
    }
  )

  const accountTypes = useMemo<Array<Partial<Types.AccountType>>>(
    () =>
      data?.getAllAccountTypes.map((accountType) => ({
        id: accountType.id,
        name: accountType.name,
        slug: accountType.slug
      })) ?? [], // Eslint-disable-next-line
    [data?.getAllAccountTypes, loading]
  )

  const defaultAccountType = useMemo(() => {
    // Find slug USD
    const defaultAccountType = accountTypes.find(
      (accountType) => accountType.slug === 'USD'
    )

    return defaultAccountType
  }, [accountTypes])

  useEffect(() => {
    if (!loading && data) {
      const coachList = [...data.geCoachesByTeamId]
      setCoaches(coachList)
      setSelectedCoaches(
        coachList
          .map((coach: Types.TeamCoach) => (coach.is_active ? coach.id : null))
          .filter((n) => n)
      )
    }
  }, [data]) // Eslint-disable-line

  if (error) {
    return <p>{`Error: ${error.message}`}</p>
  }

  const changeCoachHandle = (selectedCoaches: Types.TeamCoach[]) => {
    if (selectedCoaches) {
      const selected: number[] = selectedCoaches.map(
        (coach: Types.TeamCoach) => coach.id
      )

      setSelectedCoaches(selected)

      setCoaches(
        coaches.map((coach: Types.TeamCoach) => {
          const coachClone = { ...coach }
          if (selected.includes(coach.id)) {
            coachClone.is_active = true
          } else {
            coachClone.is_active = false
          }

          return coachClone
        })
      )
    } else {
      setCoaches(
        coaches.filter((coach: Types.TeamCoach) => {
          coach.is_active = false

          return coach
        })
      )
    }
  }

  const mstHandleChange = (e, inputName: string = '') => {
    if (!e) {
      return
    }

    if (inputName) {
      if (inputName === EReactSelectInputNames.default_location_id) {
        teamClone.set('default_location_id', Number(e.id))

        return
      }

      teamClone.set(inputName, e.id)
    } else {
      const target = e.target

      teamClone.set(
        target.name,
        target.type === EInputTypes.checkbox ? target.checked : target.value
      )
    }
  }

  const getInitialValues = () =>
    teamClone
      ? {
          id: Number(teamClone.id),
          name: teamClone.name,
          team_bio: teamClone.team_bio,
          team_email: teamClone.team_email,
          team_phone: teamClone.team_phone.replace(/\D/g, ''),
          team_phone_country_code: Number(teamClone.team_phone_country_code),
          team_url: teamClone.team_url,
          paypal_me: teamClone.paypal_me,
          preference_units: teamClone.preference_units,
          is_published: teamClone.is_published,
          is_public: teamClone.is_public,
          default_account_type_id: teamClone.default_account_type_id,
          default_location_id: teamClone.default_location_id
            ? teamClone.default_location_id
            : null,
          default_currency: teamClone.default_currency,
          coaches
        }
      : {
          coach_account_type: '',
          coach_business_type: null,
          coaches,
          default_account_type_id: defaultAccountType?.id,
          default_currency: 'USD',
          default_location_id: null,
          is_public: false,
          is_published: false,
          name: '',
          paypal_me: '',
          preference_units: false,
          team_bio: '',
          team_email: '',
          team_phone: '',
          team_phone_country_code: '',
          team_url: ''
        }

  return (
    <Dimmer active={loading} loader={loading}>
      <Formik
        enableReinitialize={true}
        initialValues={getInitialValues()}
        validationSchema={updateTeamValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (teamClone) {
            const { coaches, ...restValues } = values
            teamClone.set('team_bio', restValues.team_bio)

            await updateTeamById({
              refetchQueries: ['GET_TEAMS', 'GET_TEAM_BY_SLUG'],
              variables: {
                team: {
                  default_location_id: values.default_location_id ?? null,
                  ...restValues
                }
              },
              update(_, { data: { updateTeamById } }) {
                if (updateTeamById) {
                  applySnapshot(team, getSnapshot(teamClone))
                  toast.success('Team Updated.')
                }
              }
            })

            await insertLocationsToTeam({
              variables: {
                teamId: Number(values.id),
                locationIds: selectedLocations
              }
            })
            if (isAdmin) {
              await insertCoachesToTeam({
                variables: {
                  team_id: Number(values.id),
                  coaches: selectedCoaches
                }
              })
              await insertOrgsToTeam({
                variables: { teamId: Number(values.id), orgIds: selectedOrgs }
              })
            }
          } else {
            const coach_account_type =
              values.coach_account_type && domainData.key === EHostnames.SKYCRU
                ? {
                    [values.coach_account_type]: true
                  }
                : {}

            const newTeam = await addTeam({
              variables: {
                team: {
                  coach_business_type: values.coach_business_type,
                  default_account_type_id: parseInt(
                    values.default_account_type_id
                  ),
                  default_currency: values.default_currency,
                  default_location_id: values.default_location_id
                    ? parseInt(values.default_location_id)
                    : null,
                  is_public: values.is_public,
                  is_published: values.is_published,
                  name: values.name,
                  preference_units: values.preference_units,
                  team_bio: values.team_bio,
                  team_email: values.team_email,
                  team_phone: values.team_phone,
                  team_url: values.team_url,
                  domain: EDomainId[domainData.key],
                  ...coach_account_type
                }
              },
              update(_, { data: { addTeam } }) {
                teamStore.addTeam(addTeam)
              }
            })

            if (isAdmin) {
              await insertCoachesToTeam({
                variables: {
                  team_id: Number(newTeam.data.addTeam.id),
                  coaches: selectedCoaches
                }
              })
            }

            setSelectedCoaches([])
            history.push(`/team-details/${newTeam.data.addTeam.id}`)
          }

          setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleSubmit,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <>
              <Card.Body>
                <Grid.Row>
                  {!teamClone && (
                    <>
                      <Grid.Col sm={12} md={6} lg={6}></Grid.Col>
                      <Grid.Col sm={12} md={6} lg={6}>
                        <Form.Group label="">
                          <Select
                            name="coach_account_type"
                            isClearable={true}
                            placeholder="Select Business Type"
                            options={coachAccountTypeOptions.filter(
                              (cato) => cato.domain === domainData.key
                            )}
                            onChange={(e) => {
                              setFieldValue('coach_account_type', e.value)
                              setFieldValue(
                                'coach_business_type',
                                e.label.toLowerCase()
                              )
                            }}
                            value={coachAccountTypeOptions.find(
                              (option) =>
                                option.value === values.coach_account_type
                            )}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </>
                  )}
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <Form.Group label="Name">
                      <Form.Input
                        name="name"
                        onChange={(e) =>
                          teamClone ? mstHandleChange(e) : handleChange(e)
                        }
                        placeholder="Name"
                        value={values.name}
                      />
                      <span className="field-error text-danger">
                        {errors.name && touched.name && errors.name}
                      </span>
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col>
                    <Form.Group label="Description">
                      <TextEditor
                        placeholder="Add a team bio"
                        value={values.team_bio}
                        onChange={(content: string) => {
                          setFieldValue('team_bio', content)
                        }}
                      />
                      <span className="field-error text-danger">
                        {errors.team_bio && touched.team_bio && errors.team_bio}
                      </span>
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col sm={12} md={6}>
                    <Form.Group label="Phone">
                      <PhoneInput
                        inputProps={{
                          className: 'form-control w-100',
                          name: 'team_phone',
                          onChange: (e) => {
                            e.preventDefault()
                            const target = e.target
                            const phone = target.value.split(' ')
                            const countryCode = phone.shift().replace(/\D/g, '')
                            const phoneNumber = phone
                              .join('')
                              .replace(/\D/g, '')
                            setFieldValue('team_phone', phoneNumber)
                            setFieldValue(
                              'team_phone_country_code',
                              Number(countryCode)
                            )
                          },
                          required: true
                        }}
                        value={`${
                          values.team_phone_country_code
                        }${values.team_phone.replace(/\D/g, '')}`}
                      />
                      <span className="field-error text-danger">
                        {errors.team_phone &&
                          touched.team_phone &&
                          errors.team_phone}
                      </span>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6}>
                    <Form.Group label="Email">
                      <Form.Input
                        icon="mail"
                        name="team_email"
                        onChange={(e) =>
                          teamClone ? mstHandleChange(e) : handleChange(e)
                        }
                        placeholder="Email"
                        value={values.team_email}
                      />
                      <span className="field-error text-danger">
                        {errors.team_email &&
                          touched.team_email &&
                          errors.team_email}
                      </span>
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col width={12}>
                    <Form.Group label="Website">
                      <Form.InputGroup>
                      <Form.InputGroupAppend>
                        <Button
                          icon="globe"
                          color="secondary"
                          disabled
                        >
                          https://
                        </Button>
                      </Form.InputGroupAppend>
                      <Form.Input
                        name="team_url"
                        onChange={(e) =>
                          teamClone ? mstHandleChange(e) : handleChange(e)
                        }
                        placeholder="Team URL"
                        value={values.team_url}
                      />
                      <span className="field-error text-danger">
                        {errors.team_url && touched.team_url && errors.team_url}
                      </span>
                      </Form.InputGroup>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col sm={12} md={6}>
                    <Form.Group label="Slug">
                      <Form.Input
                        icon="at-sign"
                        name="username"
                        placeholder="Contact Support to request your slug"
                        value={team.slug}
                        disabled
                      />
                      <span className="field-error text-danger">
                        {errors.team_url && touched.team_url && errors.team_url}
                      </span>
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
              <Card.Body>
                <Grid.Row>
                  <Grid.Col sm={6} md={6} lg={6}>
                    <Form.Group label="Default Location">
                      <AsyncSelect
                        backspaceRemovesValue={true}
                        className="input-group"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                          LoadingIndicator: () => null
                        }}
                        defaultValue={
                          teamClone && teamClone.default_location_id !== 0
                            ? {
                                id: teamClone.default_location_id,
                                name: teamClone.default_location_name
                              }
                            : null
                        }
                        escapeClearsValue={true}
                        getOptionLabel={(option: Types.Location) => option.name}
                        getOptionValue={(option: Types.Location) => option.id}
                        isClearable={true}
                        loadOptions={loadOptions}
                        name="default_location_id"
                        onChange={(inputValue) => {
                          if (!inputValue) {
                            setFieldValue(
                              EReactSelectInputNames.default_location_id,
                              null
                            )

                            return
                          }

                          if (teamClone) {
                            mstHandleChange(
                              inputValue,
                              EReactSelectInputNames.default_location_id
                            )
                          } else {
                            setFieldValue(
                              EReactSelectInputNames.default_location_id,
                              inputValue.id
                            )
                          }
                        }}
                        placeholder={
                          isAdmin
                            ? 'Search for default location'
                            : 'Leave empty for flexible locations'
                        }
                        styles={autoCompleteStyles}
                        isDisabled={!isAdmin}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col sm={6} md={3} lg={3}>
                    <Form.Group label="Currency">
                      <Select
                        name="default_currency"
                        isClearable={true}
                        options={currencyOptions}
                        onChange={(inputValue) => {
                          if (!inputValue) {
                            setFieldValue('default_currency', '')

                            return
                          }

                          setFieldValue('default_currency', inputValue.value)
                        }}
                        value={currencyOptions.find(
                          (option) => option.value === values.default_currency
                        )}
                        isDisabled={!isAdmin && values}
                      />
                      <span className="field-error text-danger">
                        {errors.default_currency &&
                          touched.default_currency &&
                          errors.default_currency}
                      </span>
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                {(isAdmin ||
                  isCoachSuperAdmin ||
                  (isOrg && team.feature_camps)) && (
                  <Grid.Row>
                    <Grid.Col width={12}>
                      <Form.Group label="Additional Locations">
                        <TeamLocationsSelect
                          teamId={Number(team?.id)}
                          selectedLocations={selectedLocations}
                          setSelectedLocations={setSelectedLocations}
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                )}
              </Card.Body>
              {isAdmin && (
                <>
                  <Card.Body>
                    <Alert type="info">
                      <strong>ADMIN SETTINGS:</strong> Following section is ONLY
                      visible to ADMIN users.
                    </Alert>
                    <Grid.Row>
                      <Grid.Col sm={12} md={6} lg={6}>
                        <Form.Group label="Default Account Type">
                          <Select
                            name="default_account_type_id"
                            isClearable={true}
                            getOptionLabel={(option: Types.AccountType) =>
                              option.name
                            }
                            getOptionValue={(option: Types.AccountType) =>
                              option.id
                            }
                            options={accountTypes}
                            onChange={(e) =>
                              teamClone
                                ? mstHandleChange(
                                    e,
                                    EReactSelectInputNames.default_account_type_id
                                  )
                                : setFieldValue(
                                    EReactSelectInputNames.default_account_type_id,
                                    e.id
                                  )
                            }
                            value={accountTypes.filter(
                              (accountType) =>
                                accountType.id ===
                                values.default_account_type_id
                            )}
                          />
                          <span className="field-error text-danger">
                            {errors.default_account_type_id &&
                              touched.default_account_type_id &&
                              errors.default_account_type_id}
                          </span>
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col width={12}>
                        <Form.Group label="Coach User Accounts">
                          <Select
                            name="coaches"
                            isMulti
                            placeholder="Select Coach Users..."
                            isClearable={true}
                            getOptionLabel={(option: Types.TeamCoach) =>
                              `${option.first_name} ${option.last_name} (${option.id})`
                            }
                            getOptionValue={(option: Types.TeamCoach) =>
                              option.id
                            }
                            options={values.coaches}
                            onChange={changeCoachHandle}
                            value={values.coaches.filter(
                              (coach: Types.TeamCoach) =>
                                coach.is_active === true
                            )}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col>
                        <Form.Group label="Team Orgs">
                          <TeamOrgSelect
                            teamId={team?.id}
                            setSelectedOrgs={setSelectedOrgs}
                            selectedOrgs={selectedOrgs}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row className="mt-5">
                      <Grid.Col sm={4} md={4}>
                        <Form.Group>
                          <Form.Switch
                            type="checkbox"
                            name="preference_units"
                            onChange={(e) =>
                              teamClone ? mstHandleChange(e) : handleChange(e)
                            }
                            checked={values.preference_units ? true : false}
                            label="Metric (km, kg)"
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={4} md={4}>
                        <Form.Group>
                          <Form.Switch
                            type="checkbox"
                            name="is_public"
                            onChange={(e) =>
                              teamClone ? mstHandleChange(e) : handleChange(e)
                            }
                            checked={values.is_public ? true : false}
                            label="Public"
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={4} md={4}>
                        <Form.Group>
                          <Form.Switch
                            type="checkbox"
                            name="is_published"
                            onChange={(e) =>
                              teamClone ? mstHandleChange(e) : handleChange(e)
                            }
                            checked={values.is_published ? true : false}
                            label="Published"
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                  </Card.Body>
                </>
              )}
              <Card.Footer>
                <Button.List align="right">
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    pill
                    color="gray-dark"
                    size="sm"
                    type="submit"
                  >
                    {teamClone ? 'UPDATE' : 'ADD'}
                  </Button>
                </Button.List>
              </Card.Footer>
            </>
          </form>
        )}
      </Formik>
    </Dimmer>
  )
}

export default observer(TeamProfile)
