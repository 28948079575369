import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import Wrapper from '../layout'
import UserSettings from '../../components/User/UserSettings'
import { UserStoreContext } from '../../contexts/userStoreContext'
import { useRootStore } from '../../hooks'
import useGetStudentById from '../../modules/student/hooks/useGetStudentById'
import { EUserTypes } from '../../components/User/userTypes'

const UserSettingsPage = (props) => {
  const userStore = useContext(UserStoreContext)
  const { currentUser } = useRootStore()

  const { data, loading } = useGetStudentById(
    String(currentUser?.student_id || currentUser?.id)
  )
  return (
    <Wrapper {...props} title="Settings">
      <div className="container">
        <UserSettings
          student={data?.getStudentById}
          user={currentUser}
          type={EUserTypes.student}
        />
      </div>
    </Wrapper>
  )
}

export default observer(UserSettingsPage);
