import {
  ApolloError,
  useLazyQuery,
  useMutation,
  useQuery
} from '@apollo/client'
import { ADD_EVENT_ORGANIZER } from 'graphql/ADD_EVENT_ORGANIZER'
import { GET_EVENT_ORGANIZERS } from 'graphql/GET_EVENT_ORGANIZERS'
import { REMOVE_USER_ROLE } from 'graphql/REMOVE_USER_ROLE'
import { GET_ORGANIZER_COACHES as GET_ORGANIZER_COACHES_TYPE } from 'graphql/types/GET_ORGANIZER_COACHES'
import useGetEventOrganizers from 'modules/user/roles/hooks/useGetEventOrganizers'
import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'
import { Grid, Tag, Text } from 'tabler-react'
import autoCompleteStyles from '../../../../../components/Students/Forms/autocompleteStyles'
import { GET_ORGANIZER_COACHES } from '../../../../../graphql/GET_ORGANIZER_COACHES'
import { GET_ORGANIZER_COACHES_BY_EMAIL } from '../../../../../graphql/GET_ORGANIZER_COACHES_BY_EMAIL'
import { Types } from '../../../../../types/graphql'
import { validateEmail } from '../../../../../utils/validators'
import useGetCurrentUserType from '../../../../common/hooks/useGetCurrentUserType'

const EventOrganizersSelectForm = () => {
  const { eventId } = useParams<{ eventId: string }>()
  const { isAdmin, isCoachSuperAdmin } = useGetCurrentUserType()

  const [addEventOrganizer] = useMutation(ADD_EVENT_ORGANIZER, {
    onCompleted: () => {
      toast.success('Coach added to event')
    },
    onError: (error: ApolloError) => {
      toast.error(error.graphQLErrors[0].message)
    }
  })

  const [removeUserRole] = useMutation(REMOVE_USER_ROLE, {
    onCompleted: () => {
      toast.success('Coach removed from event')
    },
    onError: (error: ApolloError) => {
      toast.error(error.graphQLErrors[0].message)
    }
  })

  const { data: coachesData } = useQuery<GET_ORGANIZER_COACHES_TYPE>(
    GET_ORGANIZER_COACHES,
    {
      skip: !isAdmin && !isCoachSuperAdmin
    }
  )

  const { eventOrganizersData } = useGetEventOrganizers({
    eventId: Number(eventId)
  })

  const [getCoachByEmail] = useLazyQuery(GET_ORGANIZER_COACHES_BY_EMAIL)

  const handleSearch = async (value: string) => {
    if (validateEmail(value)) {
      const coachData = await getCoachByEmail({
        variables: {
          email: value
        }
      }).then((result) => {
        return result.data?.getOrganizerCoachByEmail ?? null
      })

      if (
        coachData &&
        !eventOrganizersData?.getEventOrganizers.find(
          (organizer) => organizer.coach_id === coachData.id
        )
      ) {
        return [
          {
            id: coachData.id,
            name: `${coachData.first_name} ${coachData.last_name}`
          }
        ]
      }
    }

    return []
  }

  const coachOptions = useMemo(() => {
    if ((isAdmin || isCoachSuperAdmin) && coachesData?.getOrganizerCoaches) {
      return (
        coachesData?.getOrganizerCoaches
          // Remove existing organizers from the list of coaches
          .filter(
            (coach) =>
              !eventOrganizersData?.getEventOrganizers.find(
                (organizer) => organizer.coach_id === coach.id
              )
          )
          .map(({ id, first_name, last_name }) => ({
            id,
            name: `${first_name} ${last_name}`
          })) ?? []
      )
    }
    return []
  }, [
    coachesData?.getOrganizerCoaches,
    eventOrganizersData?.getEventOrganizers
  ])

  const eventOrganizerRows = useMemo(
    () =>
      eventOrganizersData?.getEventOrganizers.map(
        ({ id, coach_first_name, coach_last_name }) => ({
          id,
          name: `${coach_first_name} ${coach_last_name}`
        })
      ) ?? [],
    [eventOrganizersData?.getEventOrganizers]
  )

  const onSelectCoach = async (option: Types.Coach) => {
    await addEventOrganizer({
      variables: {
        addEventOrganizerInput: {
          role_id: 16,
          camp_id: Number(eventId),
          coach_id: option.id
        }
      },
      refetchQueries: [
        {
          query: GET_EVENT_ORGANIZERS,
          variables: {
            params: {
              role_id: 16,
              camp_id: Number(eventId)
            }
          }
        }
      ]
    })
  }

  const onRemoveEventOrganizer = async (id: number) => {
    await removeUserRole({
      variables: {
        userRoleId: id
      },
      refetchQueries: [
        {
          query: GET_EVENT_ORGANIZERS,
          variables: {
            params: {
              role_id: 16,
              camp_id: Number(eventId)
            }
          }
        }
      ]
    })
  }

  return (
    <>
      <Grid.Row className="mb-3">
        <Grid.Col>
          {(isAdmin || isCoachSuperAdmin) ? (
            <Select
              isClearable={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              options={coachOptions}
              onChange={onSelectCoach}
              value={null}
              placeholder="Select Organizer..."
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              formatOptionLabel={(option) => (
                <div className="d-flex justify-content-between">
                  <span>{option.name}</span>
                  <Tag>{option.id}</Tag>
                </div>
              )}
            />
          ) : (
            <AsyncSelect
              backspaceRemovesValue={true}
              className="input-group"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null
              }}
              escapeClearsValue={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isClearable={true}
              formatOptionLabel={(option) => (
                <div className="d-flex justify-content-between">
                  <span>{option.name}</span>
                  <Text muted>ID: {option.id}</Text>
                </div>
              )}
              loadOptions={handleSearch}
              onChange={onSelectCoach}
              value={null}
              placeholder="Enter organizer email"
              styles={autoCompleteStyles}
              menuPortalTarget={document.body}
            />
          )}
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Tag.List>
            {eventOrganizerRows?.map((role) => (
              <Tag
                key={role.id}
                remove
                onClick={async () => {
                  await onRemoveEventOrganizer(role.id)
                }}
              >
                {role.name}
              </Tag>
            ))}
          </Tag.List>
        </Grid.Col>
      </Grid.Row>
    </>
  )
}

export default EventOrganizersSelectForm
