import React from 'react'
import { Document, Page, Text as PDFText, View } from '@react-pdf/renderer'
import styles from '../../../../utils/pdfStyles'
import { Types } from 'types/graphql'

interface CompetitionGroupsPdfProps {
  campName: string
  groups: any
  disciplines: { value: number; label: string }[]
  filter?: number
}

const CompetitionGroupsPdf = ({
  campName,
  groups: groupsByDiscipline,
  disciplines,
  filter
}: CompetitionGroupsPdfProps) => {
  return (
    <Document>
      <Page style={styles.body}>
        <View
          style={{
            flexDirection: 'column',
            gap: 30
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <PDFText style={styles.title}>{campName}</PDFText>
          </View>
          {disciplines
            .filter((discipline) => {
              if (filter) {
                return discipline.value === filter
              }
              return true
            })
            .map((discipline) => {
              return (
                <View
                  key={`discipline-${discipline.value}`}
                  style={{ borderBottom: '1px solid #000' }}
                >
                  <View style={styles.boxRow}>
                    <PDFText
                      style={{ ...styles.textMedium, fontWeight: 'bold' }}
                    >
                      {discipline.label ?? 'No Discipline Selected'}
                    </PDFText>
                  </View>
                  <View style={styles.box}>
                    <View style={styles.boxRow}>
                      {groupsByDiscipline[discipline.value].map(
                        (group: Types.Group) => (
                          <View key={group.id} style={styles.boxCol}>
                            <View style={styles.boxRow}>
                              <PDFText
                                style={{
                                  ...styles.textMedium,
                                  fontSize: 12,
                                  fontWeight: 'bold',
                                  marginBottom: 10
                                }}
                              >
                                {group.name}
                              </PDFText>
                            </View>

                            {group.members.map((member) => (
                              <View key={member.id} style={styles.boxRow}>
                                <PDFText
                                  style={{
                                    ...styles.textSmall,
                                    fontWeight: 'light'
                                  }}
                                >
                                  {member.first_name} {member.last_name}
                                </PDFText>
                              </View>
                            ))}
                          </View>
                        )
                      )}
                    </View>
                  </View>
                </View>
              )
            })}
        </View>
      </Page>
    </Document>
  )
}

export default CompetitionGroupsPdf
