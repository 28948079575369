import React, { useMemo } from 'react'
import { Alert, Button, Card, Text } from 'tabler-react'
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType'
import { useGetRegistrationGroups } from '../../modules/group/hooks/useGetRegistrationGroups'
import { Types } from '../../types/graphql'
import RegistrationGroupFormModal from './Modals/RegistrationGroupFormModal'
import RegistrationGroupItem from './RegistrationGroupItem'
import GroupsSearch from '../../modules/group/components/forms/GroupsSearch'
import AddStudentToRegistrationGroupModal from './Modals/AddStudentToRegistrationGroupModal'
import moment from 'moment'

interface RegistrationGroupsProps {
  registrationId?: number
  disciplineId?: number
  teamId?: number
  campId?: number
  eventTypeId?: string
  roleId?: number
  currency?: string
  paymentDescription?: string
  paymentEmail?: string
  isRegistrationGroups?: boolean
  paypal?: boolean
  venmo?: boolean
  studentId?: number
  campEnd?: string
}

const groupManagerRoles = [
  20, // Delegation Member
  21, // Manager
  24, // head of delegation
  26 // NAC
]

const RegistrationGroups = ({
  registrationId,
  disciplineId,
  roleId,
  teamId,
  campId,
  eventTypeId,
  currency,
  paymentDescription,
  paymentEmail,
  isRegistrationGroups,
  paypal,
  venmo,
  studentId,
  campEnd
}: RegistrationGroupsProps) => {
  const { isAdmin, isCoachSuperAdmin, isOrg } = useGetCurrentUserType()
  const [isOpen, setIsOpen] = React.useState(false)
  const [registrationGroup, setRegistrationGroup] = React.useState(null)
  const { data: groupsData, loading: groupsLoading } = useGetRegistrationGroups(
    {
      registrationId,
      status: 'Active'
    }
  )
  const isGroupManager =
    groupManagerRoles.includes(roleId) || isAdmin || isCoachSuperAdmin || isOrg

  const groupClickHandle = (group) => {
    setRegistrationGroup(group)
    setIsOpen(true)
    if (isOpenGroupSearch) {
      toggleGroupSearch()
    }
  }

  const [isOpenGroupSearch, setIsOpenGroupSearch] = React.useState(false)
  const [isAddStudentOpen, setIsAddStudentOpen] = React.useState(false)
  const [group, setGroup] = React.useState<Types.Group | null>(null)
  const toggleGroupSearch = () => {
    setIsOpenGroupSearch(!isOpenGroupSearch)
  }
  const toggleAddStudent = () => {
    setIsAddStudentOpen(!isAddStudentOpen)
  }

  const handleAddStudent = (groupProp) => {
    toggleGroupSearch()
    toggleAddStudent()
    setGroup(groupProp)
  }
  const groups = useMemo(() => {
    if (!groupsLoading && groupsData) {
      return groupsData
    }

    return []
  }, [groupsData, groupsLoading])

  const isCampExpired = useMemo(() => {
    return (
      moment(campEnd).isBefore(moment()) &&
      moment().diff(moment(campEnd), 'days') >= 7
    )
  }, [campEnd])

  if (!isOrg && eventTypeId !== '2') return null

  return (
    <Card>
      <Card.Header className="border-bottom-0">
        <Card.Title>
          <i className="fe fe-users text-primary mr-2" />
          {!isOpenGroupSearch ? 'Groups' : ''}
        </Card.Title>
        <Card.Options>
          {!isOpenGroupSearch && !isCampExpired && (
            <>
              <Button
                outline
                icon={'search'}
                color={'info'}
                size={'sm'}
                onClick={() => toggleGroupSearch()}
              >
                Add/Join Team
              </Button>
            </>
          )}
          {isOpenGroupSearch && (
            <GroupsSearch
              campId={campId}
              createGroup={groupClickHandle}
              addStudentAction={handleAddStudent}
              studentId={studentId}
              teamId={teamId}
              registrationId={registrationId}
              toggleForm={toggleGroupSearch}
            />
          )}
          {isAddStudentOpen && (
            <AddStudentToRegistrationGroupModal
              campId={campId}
              group={group}
              isOpen={isAddStudentOpen}
              toggle={toggleAddStudent}
              studentId={studentId}
              registrationId={registrationId}
            />
          )}
          {isAdmin && !isOpenGroupSearch && (
            <>
              <Button
                icon={'users'}
                color={'primary'}
                size={'sm'}
                className={'ml-2'}
                onClick={() =>
                  groupClickHandle({
                    team_id: teamId,
                    user_registration_id: registrationId,
                    discipline_id: disciplineId
                  })
                }
              />
            </>
          )}
        </Card.Options>
      </Card.Header>
      {groups.length <= 0 ? (
        <Card.Footer>
          <Alert type="info" className="text-center cursor-pointer" onClick={() => toggleGroupSearch()}>
            <strong>No Groups</strong>
          </Alert>
        </Card.Footer>
      ) : (
        <Card.Footer>
          <Text.Small className="text-muted">
            Each group member is required to register. For large teams, you can{' '}
            <a href="https://skycru.com/competitor-import" target="_blank">
              request a competitor import
            </a>
            .
          </Text.Small>
        </Card.Footer>
      )}
      {groups.map((group) => (
        <RegistrationGroupItem
          group={group}
          key={group.id}
          campId={campId}
          isGroupManager={isGroupManager}
          groupClickHandle={groupClickHandle}
          currency={currency}
          paymentDescription={`${paymentDescription}`}
          paymentEmail={paymentEmail}
          isRegistrationGroups={isRegistrationGroups}
          paypal={paypal}
          venmo={venmo}
        />
      ))}
      <RegistrationGroupFormModal
        group={registrationGroup}
        campId={campId}
        toggle={() => setIsOpen(false)}
        isOpen={isOpen}
      />
    </Card>
  )
}

export default RegistrationGroups
