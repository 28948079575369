import React from 'react'
import { useMutation } from '@apollo/client'
import { UPDATE_STUDENT_MUTATION } from '../../../graphql/UPDATE_STUDENT_MUTATION'
import FormField from '../../FormField'
import Modal from '../../Modal'
import { Formik } from 'formik'
import { GET_USER_REGISTRATIONS_BY_IDS } from '../../../graphql/GET_USER_REGISTRATIONS_BY_IDS'
import { Button, Form, Grid, Text } from 'tabler-react'
import { formatHeight } from '../../../utils/numberFormat'
import { countries, SHIRT_SIZES } from '../../constants'
import Select from 'react-select'
import autocompleteStyles from '../../Students/Forms/autocompleteStyles'
import moment from 'moment/moment'

interface RegistrationStudentDetailUpdateModalProps {
  isModalOpen: boolean
  toggleModal: any
  student: any
  field: string
}

const RegistrationStudentDetailUpdate = ({
  isModalOpen,
  toggleModal,
  student,
  field
}: RegistrationStudentDetailUpdateModalProps) => {
  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION)
  const renderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
    handleChange
  }) => (
    <Modal
      content={
        <form onSubmit={handleSubmit}>
          <Grid.Row>
            <Grid.Col xs={12} sm={12} lg={6}>
              {field === 'jump_count' && (
                <Form.Group label="Total Jumps">
                  <FormField
                    type="number"
                    placeholder="Jumps"
                    name="jump_count"
                    onChange={(e) => {
                      setFieldValue(
                        'jump_count',
                        Math.floor(Number(e.target.value))
                      )
                    }}
                    value={values.jump_count}
                  />
                </Form.Group>
              )}

              {field === 'burble' && (
                <Form.Group label="Burble ID" className="mb-0">
                  <FormField
                    type="number"
                    placeholder="Enter User ID from Burble"
                    name="burble_id"
                    onChange={(e) => {
                      setFieldValue('burble_id', Number(e.target.value))
                    }}
                    value={values.burble_id}
                  />
                  <Text.Small className="text-muted">Enter the User ID of your Burble Account.</Text.Small>
                </Form.Group>
              )}

              {field === 'tunnel_time' && (
                <Form.Group label="Total Tunnel Time">
                  <FormField
                    type="number"
                    placeholder="tunnel time"
                    name="tunnel_time"
                    onChange={(e) => {
                      setFieldValue(
                        'tunnel_time',
                        Math.floor(Number(e.target.value))
                      )
                    }}
                    value={values.tunnel_time}
                  />
                </Form.Group>
              )}

              {field === 'height' && (
                <FormField
                  appendright={
                    <Form.InputGroupAppend>
                      <Form.InputGroupText>in</Form.InputGroupText>
                    </Form.InputGroupAppend>
                  }
                  appendbottom={
                    values.height > 0 && (
                      <Text.Small className={'ml-1'}>
                        {formatHeight(values.height)}
                      </Text.Small>
                    )
                  }
                  type="number"
                  placeholder="Height"
                  name="height"
                  onChange={(e) => {
                    setFieldValue('height', Math.floor(Number(e.target.value)))
                  }}
                  value={values.height}
                />
              )}

              {field === 'dob' && (
                <Form.Group label="DOB">
                  <Form.MaskedInput
                    placeholder="MM/DD/YYYY"
                    mask={[
                      /\d/,
                      /\d/,
                      '/',
                      /\d/,
                      /\d/,
                      '/',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/
                    ]}
                    name="dob"
                    onChange={handleChange}
                    value={values.dob}
                  />
                  <span className="field-error text-danger">
                    {errors.dob && touched.dob && errors.dob}
                  </span>
                </Form.Group>
              )}

              {field === 'shirt_size_id' && (
                <Form.Group label="Shirt Size">
                  <Select
                    backspaceRemovesValue={true}
                    escapeClearsValue={true}
                    getOptionLabel={(option: { name: string }) => option.name}
                    getOptionValue={(option: { value: number }) => option.value}
                    isClearable={true}
                    name="shirt_size_id"
                    options={SHIRT_SIZES}
                    onChange={(params: { value: number }) => {
                      setFieldValue('shirt_size_id', params?.value ?? null)
                    }}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 })
                    }}
                    menuPortalTarget={document.body}
                    value={
                      values.shirt_size_id &&
                      SHIRT_SIZES.filter(
                        (size) => size.value === values.shirt_size_id
                      )
                    }
                  />
                </Form.Group>
              )}
              {field === 'weight' && (
                <Form.Group label="Weight">
                  <FormField
                    appendright={
                      <Form.InputGroupAppend>
                        <Form.InputGroupText>lbs</Form.InputGroupText>
                      </Form.InputGroupAppend>
                    }
                    appendbottom={
                      values.weight > 0 && (
                        <Text.Small className={'ml-1'}>
                          {(values.weight * 0.45).toFixed(1) + ' kg'}
                        </Text.Small>
                      )
                    }
                    type="number"
                    placeholder="weight"
                    name="weight"
                    onChange={(e) =>
                      setFieldValue(
                        'weight',
                        Math.floor(Number(e.target.value))
                      )
                    }
                    value={values.weight}
                  />
                </Form.Group>
              )}
              {field === 'country' && (
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Select
                    backspaceRemovesValue={true}
                    escapeClearsValue={true}
                    getOptionLabel={(option: { name: string }) => option.name}
                    getOptionValue={(option: { name: string }) => option.name}
                    isClearable={true}
                    name="country"
                    options={countries}
                    onChange={(params: { name: string }) => {
                      setFieldValue('country', params?.name ?? null)
                    }}
                    styles={autocompleteStyles}
                    value={
                      values.country &&
                      countries[
                        Object.keys(countries).find(
                          (key) => countries[key].name === values.country
                        )
                      ]
                    }
                  />
                </Form.Group>
              )}
            </Grid.Col>
          </Grid.Row>
        </form>
      }
      actions={
        <>
          <Button
            onClick={toggleModal}
            pill
            color={'white'}
            className="float-right btn-sm"
          >
            CANCEL
          </Button>
          <Button
            onClick={handleSubmit}
            type="submit"
            disabled={isSubmitting}
            pill
            className="btn-gray-dark float-right btn-sm"
          >
            {isSubmitting ? 'Processing...' : 'SAVE'}
          </Button>
        </>
      }
      open={isModalOpen}
      title={`${student.first_name} ${student.last_name}`}
      onClose={toggleModal}
    />
  )
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: student?.student_id,
        height: student?.height,
        weight: student?.weight,
        shirt_size_id: student?.shirt_size_id,
        dob: student?.dob ? moment(student?.dob).format('MM/DD/YYYY') : '',
        phone_number: student?.phone_number ?? '',
        phone_country_code: student?.phone_country_code ?? '',
        email: student?.email ?? '',
        jump_count: student?.jump_count,
        tunnel_time: student?.tunnel_time,
        country: student?.country ?? '',
        burble_id: student?.burble_id ?? ''
      }}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        const date = moment(values.dob)
        const dateValue = !date.isValid()
          ? null
          : moment(values.dob).format('YYYY-MM-DD')
        updateStudent({
          variables: {
            student: {
              id: student?.student_id,
              jump_count:
                field === 'jump_count' ? values.jump_count : undefined,
              height:
                field === 'height'
                  ? values.height > 0
                    ? values.height
                    : null
                  : undefined,
              weight:
                field === 'weight'
                  ? values.weight > 0
                    ? values.weight
                    : null
                  : undefined,
              shirt_size_id:
                field === 'shirt_size_id' ? values.shirt_size_id : undefined,
              dob: field === 'dob' ? dateValue : undefined,
              tunnel_time:
                field === 'tunnel_time' ? values.tunnel_time : undefined,
              country: field === 'country' ? values.country : undefined,
              phone_number:
                field === 'contact' ? values.phone_number : undefined,
              phone_country_code:
                field === 'contact' ? values.phone_country_code : undefined,
              email: field === 'contact' ? values.email : undefined,
              burble_id: field === 'burble' ? values.burble_id : undefined
            }
          },
          refetchQueries: [
            {
              query: GET_USER_REGISTRATIONS_BY_IDS,
              variables: {
                userRegistrationsId: Number(student?.user_registrations_id)
              }
            }
          ]
        })
        toggleModal(!isModalOpen)
        setSubmitting(false)
        resetForm()
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  )
}

export default RegistrationStudentDetailUpdate
